import * as businessConst from "constants/business_association";
import * as businessProductConst from "constants/product_business";
import * as businessInvoiceConst from "constants/invoice_business";
import * as businessEmployee from "constants/employee_business";
import { toastError } from "helpers/toastHelper";
let initState = {
  list_business_association: {
    data: [],
    error: null,
    isLoading: false,
  },
  business_detail: {
    data: null,
    error: null,
    isLoading: false,
  },
  business_product: {
    data: [],
    error: null,
    isLoading: false,
  },
  business_invoice: {
    data: [],
    error: null,
    isLoading: false,
  },
  business_invoice_detail: {
    data: null,
    error: null,
    isLoading: false,
  },
  business_employee: {
    data: [],
    error: null,
    isLoading: false,
  },
};

const reducer = (state = initState, action) => {
  switch (action.type) {
    case businessConst.FETCH_LIST_BUSINESS: {
      return {
        ...state,
        list_business_association: {
          data: initState.list_business_association.data,
          error: null,
          isLoading: true,
        },
      };
    }

    case businessConst.FETCH_LIST_BUSINESS_SUCCESS: {
      const { data } = action.payload;
      return {
        ...state,
        list_business_association: {
          data: data.data,
          error: null,
          isLoading: false,
        },
      };
    }

    case businessConst.FETCH_LIST_BUSINESS_FAILED: {
      const { error } = action.payload;
      return {
        ...state,
      };
    }
    case businessConst.FETCH_BUSINESS_DETAIL: {
      return {
        ...state,
        business_detail: {
          data: initState.business_detail.data,
          error: null,
          isLoading: true,
        },
      };
    }

    case businessConst.FETCH_BUSINESS_DETAIL_SUCCESS: {
      const { data } = action.payload;
      return {
        ...state,
        business_detail: {
          data: data.data,
          error: null,
          isLoading: false,
        },
      };
    }

    case businessConst.FETCH_BUSINESS_DETAIL_FAILED: {
      const { error } = action.payload;
      return {
        ...state,
      };
    }

    case businessProductConst.FETCH_PRODUCT_FROM_BUSINESS: {
      return {
        ...state,
        business_product: {
          data: initState.business_product.data,
          error: null,
          isLoading: true,
        },
      };
    }

    case businessProductConst.FETCH_PRODUCT_FROM_BUSINESS_SUCCESS: {
      const { data } = action.payload;
      return {
        ...state,
        business_product: {
          data: data.data,
          error: null,
          isLoading: false,
        },
      };
    }

    case businessProductConst.FETCH_PRODUCT_FROM_BUSINESS_FAILED: {
      const { error } = action.payload;
      toastError(error.data);
      return {
        ...state,
        ...state.business_product,
        isLoading: false,
        error: error.data,
      };
    }

    case businessProductConst.DELETE_BUSINESS_PRODUCT: {
      return {
        ...state,
        ...state.business_product,
        isLoading: true,
      };
    }

    case businessProductConst.DELETE_BUSINESS_PRODUCT_SUCCESS: {
      const { data } = action.payload;
      return {
        ...state,
        business_product: {
          data: state.business_product.data.filter((product) => product.id !== data.productId),
          error: null,
          isLoading: false,
        },
      };
    }

    case businessProductConst.DELETE_BUSINESS_PRODUCT_FAILED: {
      const { error } = action.payload;
      return {
        ...state,
        business_product: {
          data: state.business_product.data,
          error: error.data,
          isLoading: false,
        },
      };
    }

    case businessProductConst.CREATE_BUSINESS_PRODUCT: {
      return {
        ...state,
        ...state.business_product,
        isLoading: true,
      };
    }

    case businessProductConst.CREATE_BUSINESS_PRODUCT_SUCCESS: {
      return {
        ...state,
      };
    }

    case businessProductConst.CREATE_BUSINESS_PRODUCT_FAILED: {
      const { error } = action.payload;
      return {
        ...state,
        ...state.business_product,
        isLoading: false,
        error: error,
      };
    }

    case businessProductConst.IMPORT_BUSINESS_PRODUCT: {
      return {
        ...state,
        ...state.business_product,
        isLoading: true,
      };
    }

    case businessProductConst.IMPORT_BUSINESS_PRODUCT_SUCCESS: {
      const { data } = action.payload;
      return {
        ...state,
        business_product: {
          data: data.data.list_products
            .filter((item) => item.message !== "update")
            .concat(...state.business_product.data),
          isLoading: false,
          error: data.data.errors,
        },
      };
    }

    case businessProductConst.IMPORT_BUSINESS_PRODUCT_FAILED: {
      const { error } = action.payload;
      return {
        ...state,
        business_product: {
          data: [...state.business_product.data],
          isLoading: false,
          error: error?.data?.errors,
        },
      };
    }

    case businessProductConst.UPDATE_BUSINESS_PRODUCT: {
      return {
        ...state,
        ...state.business_product,
        isLoading: true,
        error: null,
      };
    }

    case businessProductConst.UPDATE_BUSINESS_PRODUCT_SUCCESS: {
      return {
        ...state,
        ...state.business_product,
        isLoading: false,
        error: null,
      };
    }

    case businessProductConst.UPDATE_BUSINESS_PRODUCT_FAILED: {
      const { error } = action.payload;
      return {
        ...state,
        ...state.business_product,
        isLoading: false,
        error: error.data,
      };
    }

    case businessInvoiceConst.FETCH_INVOICE_BUSINESS: {
      return {
        ...state,
        ...state.business_invoice,
        isLoading: true,
        error: null,
      };
    }
    case businessInvoiceConst.FETCH_INVOICE_BUSINESS_SUCCESS: {
      const { data } = action.payload;
      return {
        ...state,
        business_invoice: {
          data: data.data,
          isLoading: false,
          error: null,
        },
      };
    }

    case businessInvoiceConst.FETCH_INVOICE_BUSINESS_FAILED: {
      const { error } = action.payload;
      toastError(error.data);
      return {
        ...state,
        ...state.business_invoice,
        isLoading: false,
        error: error.data,
      };
    }

    case businessInvoiceConst.FETCH_INVOICE_BUSINESS_DETAIL: {
      return {
        ...state,
        ...state.business_invoice_detail,
        isLoading: true,
        error: null,
      };
    }

    case businessInvoiceConst.FETCH_INVOICE_BUSINESS_DETAIL_SUCCESS: {
      const { data } = action.payload;
      return {
        ...state,
        business_invoice_detail: {
          data: data.data,
          isLoading: false,
          error: null,
        },
      };
    }

    case businessInvoiceConst.FETCH_INVOICE_BUSINESS_DETAIL_FAILED: {
      const { error } = action.payload;
      return {
        ...state,
        business_invoice_detail: {
          ...state.business_invoice_detail,
          isLoading: false,
          error: error.data,
        },
      };
    }

    case businessInvoiceConst.UPDATE_INVOICE_BUSINESS: {
      return {
        ...state,
        ...state.business_invoice_detail,
        isLoading: true,
        error: null,
      };
    }
    case businessInvoiceConst.UPDATE_INVOICE_BUSINESS_SUCCESS: {
      const { data } = action.payload;
      return {
        ...state,
        business_invoice_detail: {
          data: data.data,
          isLoading: false,
          error: null,
        },
      };
    }

    case businessInvoiceConst.UPDATE_INVOICE_BUSINESS_FAILED: {
      const { error } = action.payload;
      return {
        ...state,
        ...state.business_invoice_detail,
        isLoading: false,
        error: error.data,
      };
    }
    case businessEmployee.FETCH_LIST_EMPLOYEE: {
      return {
        ...state,
        ...state.business_employee,
        isLoading: true,
        error: null,
      };
    }

    case businessEmployee.FETCH_LIST_EMPLOYEE_SUCCESS: {
      const { data } = action.payload;
      return {
        ...state,
        business_employee: {
          data: data.data,
          isLoading: false,
          error: null,
        },
      };
    }

    case businessEmployee.FETCH_LIST_EMPLOYEE_FAILED: {
      const { error } = action.payload;
      toastError(error.data);
      return {
        ...state,
        ...state.business_employee,
        isLoading: false,
        error: error.data,
      };
    }

    case businessEmployee.ADD_NEW_EMPLOYEE: {
      return {
        ...state,
        ...state.business_employee,
        isLoading: true,
        error: null,
      };
    }

    case businessEmployee.ADD_NEW_EMPLOYEE_SUCCESS: {
      const { data } = action.payload;
      return {
        ...state,
        business_employee: {
          data: [data.data, ...state.business_employee.data],
          isLoading: false,
          error: null,
        },
      };
    }

    case businessEmployee.ADD_NEW_EMPLOYEE_FAILED: {
      const { error } = action.payload;
      toastError(error.data);
      return {
        ...state,
        ...state.business_employee,
        isLoading: false,
        error: error.data,
      };
    }

    case businessEmployee.UPDATE_EMPLOYEE: {
      return {
        ...state,
        ...state.business_employee,
        isLoading: false,
        error: null,
      };
    }

    case businessEmployee.UPDATE_EMPLOYEE_SUCCESS: {
      const { data } = action.payload;
      return {
        ...state,
        business_employee: {
          data: state.business_employee.data.map((item) => {
            if (item.id === data.data.id) {
              item = data.data;
            }
            return item;
          }),
          isLoading: false,
          error: null,
        },
      };
    }
    case businessEmployee.UPDATE_EMPLOYEE_FAILED: {
      const { error } = action.payload;
      toastError(error.data);
      return {
        ...state,
        ...state.business_employee,
        isLoading: false,
        error: error.data,
      };
    }

    case businessEmployee.DELETE_EMPLOYEE: {
      return {
        ...state,
        ...state.business_employee,
        isLoading: true,
        error: null,
      };
    }

    case businessEmployee.DELETE_EMPLOYEE_SUCCESS: {
      const { data } = action.payload;
      return {
        ...state,
        business_employee: {
          data: state.business_employee.data.filter((it) => it.id !== data.employeeId),
          isLoading: false,
          error: null,
        },
      };
    }

    case businessEmployee.DELETE_EMPLOYEE_FAILED: {
      const { error } = action.payload;
      return {
        ...state,
        ...state.business_employee,
        isLoading: false,
        error: error.data,
      };
    }
    default:
      return {
        ...state,
      };
  }
};
export default reducer;
