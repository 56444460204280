import React, { useEffect } from "react";
import LeftSidebar from "components/left_sidebar";
import AddMetaTags from "components/add_meta_tags";
import { Link, useNavigate, useParams } from "react-router-dom";
import { Card } from "react-bootstrap";
import {fetchDetailInvoice, updateStatusInvoice} from "actions/invoice";
import { useDispatch, useSelector } from "react-redux";
import NumberFormat from "components/number_format";
import moment from "moment/moment";
import useInvoice from "../../context/Inovice/useInvoice";
import DetectAction from "./Components/DetectActionByStatus";
import Swal from "sweetalert2";
import {imageUrl} from 'lib';

const Detail = ({type}) => {
  const { invoiceId } = useParams();
  const dispatch = useDispatch();
  const {invoiceStatus} = useInvoice();
  const { data}  = useSelector(state => state.invoices.detailInvoice);

  useEffect(() => {
    dispatch(fetchDetailInvoice({type: type, invoiceId: invoiceId}));
  }, [invoiceId]);


  const handleSellerUpdateStatusInvoice = (status) => {
    if(status === "cancelled") {
      Swal.fire({
        title: 'Bạn có chắc chắn?',
        text: "Hủy đơn hàng",
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'OK',
        cancelButtonText: "Đóng"}
        ).then((result) => {
        if (result.isConfirmed) {
          dispatch(updateStatusInvoice({invoiceUserId: invoiceId, status: status, type: type}))
        }
      });
    }else{
      dispatch(updateStatusInvoice({invoiceUserId: invoiceId, status: status, type: type}))
    }
  }
  const handleBuyerUpdateStatusInvoice = () => {
    Swal.fire({
      title: 'Bạn có chắc chắn?',
      text: "Hủy đơn hàng",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'OK',
      cancelButtonText: "Đóng"
    }).then((result) => {
      if (result.isConfirmed) {
        dispatch(updateStatusInvoice({invoiceUserId: invoiceId, status: data.status, type: type}))
      }
    });
  }

  return (
    <>
      <AddMetaTags title={`Thông tin đơn hàng - ${invoiceId}`} />
      <div className="detail-invoice">
        <div className="row">
          <LeftSidebar />
          <div className="col-md-9 col-sm-12">
            <section>
              <Card>
                <Card.Header>
                  Thông tin đơn hàng
                </Card.Header>
                <Card.Body>
                  <div className="row">
                    <div className="col-md-6">
                      <p>
                        <strong>Người nhận: </strong>
                        <span>{data.name}</span>
                      </p>
                      <p>
                        <strong>Số điện thoại: </strong>
                        <span>{data.phone_number}</span>
                      </p>
                      <p>
                        <strong>Email: </strong>
                        <span>{data.email}</span>
                      </p>
                      <p>
                        <strong>Địa chỉ giao hàng: </strong>
                        <span>{data.shipping_address}</span>
                      </p>
                      <p>
                        <strong>Trạng thái: </strong>
                        <span className={`badge badge-pill ${invoiceStatus[(data.status)]?.className}`}>{invoiceStatus[(data.status)]?.text}</span>
                      </p>
                    </div>
                    <div className="col-md-6">
                      <p>
                          <strong>Người bán: </strong>
                          <span>{data.seller_name}</span>
                        </p>
                      <p>
                        <strong>Hình thức giao dịch: </strong>
                        <span>Tiền mặt</span>
                      </p>
                      <p>
                        <strong>Tổng tiền: </strong>
                        <span><NumberFormat>{data.price_total}</NumberFormat>đ</span>
                      </p>
                      <p>
                        <strong>Thời gian đặt: </strong>
                        <span>{moment(data.created_at).format("YYYY-MM-DD HH:mm:ss")}</span>
                      </p>
                      <p>
                        <strong>Số lượng mặt hàng: </strong>
                        <span>{data.quantity}</span>
                      </p>
                    </div>
                  </div>
                </Card.Body>
              </Card>
            </section>
            <section className="mb-3">
              <div className="row">
                <div className="col-md-12">
                <Card className="mt-3">
                  <Card.Header>
                    <p className="fw-bold">Danh sách mặt hàng</p>
                  </Card.Header>
                  <Card.Body>
                  <table className="table align-middle mb-0 bg-white ">
                    <thead className="bg-light">
                      <tr>
                        <th>Tên sản phẩm</th>
                        <th>Giá tiền</th>
                        <th>số lượng</th>
                        <th></th>
                      </tr>
                    </thead>
                    <tbody>
                      {data.invoice_items?.map((product) => {
                        return (
                          <tr key={product?.id}>
                            <td>
                              <div className="d-flex align-items-center">
                                <Link to={`/san-pham/${product?.product_id}`}>
                                  <img
                                    src={product?.product_images === null || product?.product_images === undefined ? '/images/photos/no_image.png' : imageUrl(product?.product_images[0]?.name)}
                                    className="rounded-circle"
                                    alt=""
                                    style={{ width: "45px", height: "45px" }}
                                  />
                                </Link>
                                <div className="ms-3">
                                <Link to={`/san-pham/${product?.product_id}`}>
                                  <p className="fw-bold mb-1">{product?.product_name}</p>
                                </Link>
                                </div>
                              </div>
                            </td>
                            <td>
                              <p className="fw-bold mb-1">
                                <NumberFormat>{product?.price}</NumberFormat>₫
                              </p>
                            </td>
                            <td>
                              <div className="cart-quantity">
                                {product?.quantity}
                              </div>
                            </td>
                            <td>
                            </td>
                          </tr>
                        );
                      })}
                       <div className='total-price'>
                          <p className='fw-bold mb-1 mt-2'>
                            Tổng tiền: <NumberFormat>{data?.price_total}</NumberFormat>₫
                          </p>
                        </div>
                    </tbody>
                  </table>
                  </Card.Body>
                </Card>
                </div>
              </div>
            </section>
            <DetectAction type={type} status={data.status}>
              {type === "seller" ?
                (<div className="mt-2 d-flex">
                    <button className="btn btn-danger" onClick={() => handleSellerUpdateStatusInvoice('cancelled')}>Hủy</button>
                    <button className="btn btn-success ml-2" onClick={() => handleSellerUpdateStatusInvoice('done')}>Hoàn thành</button>
                </div>) :
                <div className="mt-2 d-flex">
                  <button className="btn btn-danger" onClick={() => handleBuyerUpdateStatusInvoice()}>Hủy</button>
                </div>
              }
            </DetectAction>
          </div>
        </div>
      </div>
    </>
  );
};

export default Detail;
