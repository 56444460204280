import React from "react";
import CommentInput from "../../../components/CommentInput";
function InputBox({
  commentContent,
  setCommentContent,
  submitReplyComment,
  typeInput,
  comment,
  current_user,
}) {
  return (
    <div className="box-child-cmt">
      <img
        className="bg-img-orgnal avtar40 position-absolute img-cmt-box"
        style={{ paddingTop: "0", width: "40px", height: "40px", left: "0" }}
        src={current_user?.image ?? "/images/avatar.png"}
      />

      <CommentInput
        component="div"
        onChange={setCommentContent}
        content={
          typeInput === "reply"
            ? commentContent
            : typeInput === "edit"
            ? comment.content
            : ""
        }
        placeholder={"Type your content"}
        typeInput={typeInput}
      />

      <div className="d-flex justify-content-end mt-2">
        <div
          className="position-absolute btn-send-cmt"
          style={{
            backgroundImage: 'url("/images/send-message.png")',
            width: "23px",
            height: "23px",
            right: "10px",
          }}
          onClick={() => submitReplyComment(commentContent, typeInput)}
        ></div>
      </div>
    </div>
  );
}
export default InputBox;
