import moment from "moment/moment";
import AddMetaTags from "components/add_meta_tags";
import LeftSidebar from "components/left_sidebar";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { useEffect, useState, useContext } from "react";
import * as marketActions from "actions/gia_ca_thi_truong";
import * as postActions from "actions/post";
import { useParams } from "react-router-dom";
import LineChart from "./components/lineChart";
import NumberFormat from "components/number_format";
import _ from "lodash";
import Modal from "react-bootstrap/Modal";
import { reportPriceApi } from "apis/gia_ca_thi_truong";
import AppContext from "components/app_context";
import { toastError, toastErrorLogin, toastSuccess } from "helpers/toastHelper";
import { Link } from "react-router-dom";
import DayPicker from "./components/DatePicker";
import History from "./components/history";
import { checkPriceDiffer } from "../../lib";
import ShareSocialGroup from "../../components/ShareSocialGroup/ShareSocialGroup";
import Form from "react-bootstrap/Form";
import Spinner from "react-bootstrap/Spinner";

function ThongTinGiaCaThiTruong(props) {
  function DropdownDatePicker() {
    return (
      <Form.Select
        onChange={(e) => getSelectDateValue(e.target.value)}
        value={selectedDate ?? ""}
      >
        <option hidden>Choose a date</option>
        <option value={0}>Today</option>
        <option value={1}>Yesterday</option>
        <option value={7}>7 days before</option>
        <option value={14}>14 days before</option>
        <option value={28}>28 days before</option>
        <option value={30}>30 days before</option>
        <option value="date-picker">Choose date range</option>
      </Form.Select>
    );
  }
  function IsFetchingData() {
    return (
      <div className="mt-3">
        <Spinner animation="border" variant="success" />
      </div>
    );
  }
  const { marketId } = useParams();
  const { current_user, isMobile } = useContext(AppContext);
  const { fetchMarketPrices, likeMarketPrice, dislikeMarketPrice } =
    props.marketActionsCreators;
  const { market_place, data, isLoading, isEmpty } = props.marketDetail;
  const { userShareArticle } = props.postActionsCreator;
  const [showReportPrice, setShowReportPrice] = useState(false);
  const [reportPrice, setReportPrice] = useState("");
  const [reportId, setReportId] = useState(null);
  const [marketPlaceId, setMarketPlaceId] = useState(null);
  const isDownPrice = data[0]?.price_difference < 0;
  const [hasFiltered, setHasFiltered] = useState(false);
  // datepicker state
  const [dateRange, setDateRange] = useState([null, null]);
  const [startDate, endDate] = dateRange;
  const [selectedDate, setSelectedDate] = useState(null);
  const getSelectDateValue = (value) => setSelectedDate(value);
  //datepicker state
  useEffect(() => {
    fetchMarketPrices({ id: marketId });
  }, [ marketId, likeMarketPrice]);
  const handleCloseReportPrice = () => setShowReportPrice(false);
  const userReportPrice = (price_id) => {
    setReportId(price_id);
    if (current_user) {
      setShowReportPrice(true);
    } else {
      toastErrorLogin(isMobile);
    }
  };
  const submitReportPrice = (reason) => {
    if (reason !== "") {
      reportPriceApi({
        price_id: reportId,
        market_price_id: market_place.id,
        content: reason,
      });
      setShowReportPrice(false);
      toastSuccess("Report success");
      setReportPrice("");
      setReportId(null);
    } else {
      toastError("Type your reason ");
    }
  };

  const handleClickButton = () => {
    if (!current_user) {
      toastErrorLogin(isMobile);
    } else {
      if (!market_place.user_liked) {
        likeMarketPrice({
          id: current_user?.id,
          classable_type: market_place.classable_type,
          classable_id: market_place.classable_id,
        });
      } else {
        dislikeMarketPrice({
          id: current_user?.id,
          classable_type: market_place.classable_type,
          classable_id: market_place.classable_id,
        });
      }
    }
  };

  const shareMarketPriceToHomePage = () => {
    if (!current_user) {
      toastErrorLogin(isMobile);
    } else {
      let link = window.location.href;
      userShareArticle({
        title: link,
        post_type: "public",
        user_shared_id: current_user.id,
        shared_link: link,
        shared_link_status: 200,
      });
      setMarketPlaceId(null);
    }
  };

  const linkToContributeForm = (e) => {
    if (current_user === null) {
      e.preventDefault();
      toastErrorLogin(isMobile);
    }
  };
  const handleSelectedDateValue = (value) => {
    const date = new Date();
    const result = new Date(date.getTime());
    result.setDate(date.getDate() - Number(value));
    return result;
  };

  const sortDataFunc = async (message) => {
    if (message === "reset") {
      setSelectedDate(null);
      setHasFiltered(false)
      return fetchMarketPrices({
        id: marketId,
      });
    }
    const start_date =
      selectedDate !== "date-picker"
        ? await handleSelectedDateValue(selectedDate)
        : startDate;
    const end_date = selectedDate !== "date-picker" ? new Date() : endDate;

    fetchMarketPrices({
      id: marketId,
      start_date,
      end_date,
    });
    setHasFiltered(true)
  };

  const priceDifferent = data[0]?.price_difference | 0;
  return (
    <>
      <AddMetaTags title={"Giá cả thị trường"} />
      <div className="gctt-detail">
        <div className="row">
          <LeftSidebar isShoWidgetMarketPrice={false} />
          <div className="col-md-9 col-sm-12">
            <div className="mkt-price-details">
              <div className="d-flex pt-1 pb-3 mb-0 detl-title align-items-center">
                <h4 className="title-line-lt text-uppercase fw-bold">
                  Market Price Detail
                </h4>
                <div className="ms-auto d-flex">
                  <ShareSocialGroup
                    shareMarketPriceToHomePage={shareMarketPriceToHomePage}
                    setMarketPlaceId={setMarketPlaceId}
                    marketPlaceId={marketPlaceId}
                    market_place={market_place}
                    share2Nong={true}
                  />
                  <Link
                    to={`/modules/gia-ca-thi-truong/dong-gop?id=${market_place.id}`}
                    className="btn btn-sm contribute ml-3"
                    onClick={linkToContributeForm}
                  >
                    <img
                      src="/images/donggop_icon.png"
                      alt="image"
                      title="Đóng Góp"
                    />
                    <span className="txt">Contribute</span>
                  </Link>
                </div>
              </div>
              <div className="row prod-gctt-inf green-light-bg px-2 py-3">
                <div className="col-md-8 col-sm-12">
                  <div className="d-flex lst-item-detail">
                    <div className="d-flex align-items-center">
                      <div className="img-inf me-3">
                        <img
                          src={
                            market_place?.image ?? "/images/photos/no_image.png"
                          }
                          alt="detail photo"
                          title="detail photo"
                          className="img-cover"
                        />
                      </div>
                      <div className="prod-inf">
                        <h4 className="mb-2 line-clamp-02">
                          {market_place && market_place.title}
                        </h4>
                        <div className="mb-1">
                          <span className="icon-location01 mr-1"></span>
                          <span>
                            {market_place && market_place.district_name}
                          </span>
                          ,
                          <span className="ml-1">
                            {market_place && market_place.province_name}
                          </span>
                        </div>
                        <div className="d-flex align-items-center mb-1">
                          {" "}
                          <span className="icon-time align-middle me-1"></span>
                          <span>
                            {moment(
                              market_place && market_place.last_price_updated_at
                            ).format("DD/MM/YYYY  h:mm A")}
                          </span>
                        </div>
                        <div className="user d-flex align-items-center">
                          <span className="icon-user01 align-middle me-1"></span>
                          <span>{market_place?.user_name ?? "Admin"}</span>
                        </div>
                      </div>
                    </div>
                    <div className="ms-auto">
                      <div className="red-clr text-right">
                        <span className="pri-detls">
                          <NumberFormat>{data && data[0]?.price}</NumberFormat>
                        </span>
                        <span className="fs-5 fw-semibold ms-1">
                          $/{market_place && market_place.unit}
                        </span>
                      </div>
                      <div className="text-right">
                        {priceDifferent != 0 ? (
                          isDownPrice === true ? (
                            <span className="desc-pri">
                              <img
                                src="/images/down-arrow.png"
                                style={{
                                  width: "10px",
                                  height: "10px",
                                  marginBottom: "2px",
                                }}
                              />
                              <NumberFormat>
                                {checkPriceDiffer(
                                  data[0]?.price_difference | 0
                                )}
                              </NumberFormat>{" "}
                              $/
                              {market_place && market_place.unit}
                            </span>
                          ) : (
                            <span className="incr-pri">
                              <img
                                src="/images/incr-arr.png"
                                style={{
                                  width: "10px",
                                  height: "10px",
                                  marginBottom: "2px",
                                }}
                              />{" "}
                              +
                              <NumberFormat>
                                {checkPriceDiffer(
                                  data[0]?.price_difference | 0
                                )}
                              </NumberFormat>{" "}
                              $/
                              {market_place && market_place.unit}
                            </span>
                          )
                        ) : (
                          priceDifferent === 0 && (
                            <img
                              src="/images/2arr-yel.png"
                              style={{
                                width: "20px",
                                height: "20px",
                                marginBottom: "2px",
                              }}
                            />
                          )
                        )}
                      </div>
                      <div className="d-mobile w-100">
                        <div className="d-flex justify-content-end">
                          {!market_place.user_liked ? (
                            <div
                              className="ms-auto"
                              onClick={() => handleClickButton()}
                            >
                              <div className="like-price-icon d-flex align-items-center">
                                <i className="icon-tag me-2"></i>
                                <span className="fw-semibold">Favourite</span>
                              </div>{" "}
                            </div>
                          ) : (
                            <div
                              className="like-price-icon-active d-flex ms-auto"
                              onClick={() => handleClickButton()}
                            >
                              <span className="fs-3 ">
                                <i className="icon-tag me-2"></i>
                              </span>
                              <span className="fw-semibold">Favourited</span>
                            </div>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-md-4 col-sm-12 d-desktop">
                  <div className="d-flex">
                    {!market_place.user_liked ? (
                      <div
                        className="ms-auto"
                        onClick={() => handleClickButton()}
                      >
                        <div className="like-price-icon d-flex align-items-center">
                          <i className="icon-tag me-2"></i>
                          <span className="fw-semibold">Favourite</span>
                        </div>
                      </div>
                    ) : (
                      <div
                        className="like-price-icon-active d-flex ms-auto"
                        onClick={() => handleClickButton()}
                      >
                        <span className="fs-4">
                          <i className="icon-tag me-2"></i>
                        </span>
                        <span className="fw-semibold">Favourited</span>
                      </div>
                    )}
                  </div>
                </div>
              </div>
              <div className="mt-3 mb-2 d-flex  date-picker justify-content-start align-item-center">
                <div className="market-date-picker">
                  <DropdownDatePicker />
                </div>

                <div className="ml-2">
                  <button
                    className="btn-filter mr-2"
                    onClick={() => sortDataFunc()}
                  >
                    Filter
                  </button>
                  {selectedDate && (
                    <button
                      className="btn btn-md btn-warning"
                      onClick={() => sortDataFunc("reset")}
                      style={{color: "#fff", height: "40px", marginBottom:'5px'}}
                    >
                      x
                    </button>
                  )}
                </div>
              </div>
              <div className="date-picker">
                {selectedDate === "date-picker" && (
                  <DayPicker
                    dateRange={dateRange}
                    setDateRange={setDateRange}
                    startDate={startDate}
                    endDate={endDate}
                  />
                )}
              </div>
              {isLoading ? (
                <IsFetchingData />
              ) : (
                <div className="chartjs">
                  <LineChart market_place={market_place} data={data} hasFiltered={hasFiltered} dataEmpty={isEmpty} />
                </div>
              )}
              <History
                data={data}
                userReportPrice={userReportPrice}
                market_place={market_place}
              />
              <Modal
                show={showReportPrice}
                onHide={handleCloseReportPrice}
                aria-labelledby="contained-modal-title-vcenter"
                centered
              >
                <Modal.Header closeButton>
                  <Modal.Title>Report this market price</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                  <input
                    className="form-control"
                    name="message"
                    placeholder="Type your reason"
                    value={reportPrice}
                    onChange={(e) => setReportPrice(e.target.value)}
                  />
                </Modal.Body>
                <Modal.Footer>
                  <button
                    type="button"
                    className="btn btn-primary"
                    onClick={() => submitReportPrice(reportPrice)}
                  >
                    Submit
                  </button>
                </Modal.Footer>
              </Modal>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
export const mapStateToProps = (state) => {
  return {
    marketDetail: state.market.marketDetail,
  };
};
export const mapDispatchToProps = (dispatch) => {
  return {
    postActionsCreator: bindActionCreators(postActions, dispatch),
    marketActionsCreators: bindActionCreators(marketActions, dispatch),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ThongTinGiaCaThiTruong);
