import React, { Component } from "react";
import PropTypes from "prop-types";
import ReactPlayer from "react-player";
import { isMobile } from "react-device-detect";
class Images extends Component {
  static defaultProps = {
    images: [],
    hideOverlay: true,
    renderOverlay: () => "Preview Image",
    overlayBackgroundColor: "#222222",
    onClickEach: null,
    countFrom: 5,
  };

  constructor(props) {
    super(props);

    this.state = {
      modal: false,
      countFrom:
        props.countFrom > 0 && props.countFrom < 5 ? props.countFrom : 5,
      conditionalRender: false,
    };

    this.openModal = this.openModal.bind(this);
    this.onClose = this.onClose.bind(this);

    if (props.countFrom <= 0 || props.countFrom > 5) {
      console.warn("countFrom is limited to 5!");
    }
  }

  openModal(index) {
    const { onClickEach, images } = this.props;

    if (onClickEach) {
      return onClickEach({ src: images[index], index });
    }

    this.setState({ modal: true, url: images[index], index });
  }

  onClose() {
    this.setState({ modal: false });
  }

  onShowImages(postId, imageId, type = "video") {
    this.props.navigate(`/p/${postId}/i/${imageId}`, {
      state: {
        backgroundLocation: this.props.location,
        type: type,
      },
    });
  }

  renderOne() {
    const { post } = this.props;
    const { images } = this.props;
    const { countFrom } = this.state;
    const overlay =
      images.length > countFrom && countFrom === 1
        ? this.renderCountOverlay(true)
        : this.renderOverlay();
    const isVideo = images[0].includes(".mp4") || images[0].includes(".mov");
    const class_content = isVideo ? "video" : "images";
    const height = isMobile ? "195px" : "286px";

    return (
      <div className={class_content}>
        {!isVideo && (
          <div className="group-img01">
            <div
              className="bg-img"
              onClick={() =>
                this.onShowImages(post.id, post.images[0].id, "image")
              }
              style={{ background: `url(${images[0]})` }}
            ></div>
          </div>
        )}
        {isVideo && (
          <ReactPlayer
            onClick={() =>
              this.onShowImages(post.id, post.images[0].id, "video")
            }
            url={images[0]}
            width="100%"
            weight={height}
            controls={true}
          />
        )}
      </div>
    );
  }
  renderTwo() {
    const { post } = this.props;
    const { images } = this.props;
    const { listImage } = this.props;
    const { countFrom } = this.state;
    const isVideo = images[0].includes(".mp4") || images[0].includes(".mov");
    const isVideo_1 = images[1].includes(".mp4") || images[1].includes(".mov");
    const height = isMobile ? "195px" : "auto";
    const hight_two = isMobile ? "195px" : "auto";
    const overlay =
      images.length > countFrom && [2, 3].includes(+countFrom)
        ? this.renderCountOverlay(true)
        : this.renderOverlay();
    const conditionalRender =
      [3, 4].includes(images.length) ||
      (images.length > +countFrom && [3, 4].includes(+countFrom));
    return (
      <div>
        <div className="group-img02 d-flex d-grid gap-1">
          {isVideo && (
            <ReactPlayer
              onClick={() =>
                this.onShowImages(post.id, listImage[0].id, "video")
              }
              url={images[0]}
              width="100%"
              weight={height}
              controls={true}
              className="first_video"
            />
          )}
          {!isVideo && (
            <div
              className="bg-img"
              onClick={() =>
                this.onShowImages(post.id, listImage[0].id, "image")
              }
              style={{
                background: `url(${conditionalRender ? images[1] : images[0]})`,
              }}
            >
              {this.renderOverlay()}
            </div>
          )}
          {isVideo_1 && (
            <ReactPlayer
              url={images[1]}
              width="100%"
              weight={hight_two}
              controls={true}
              onClick={() =>
                this.onShowImages(post.id, listImage[1].id, "video")
              }
            />
          )}
          {!isVideo_1 && (
            <div
              className="bg-img"
              onClick={() =>
                this.onShowImages(post.id, listImage[1].id, "image")
              }
              style={{
                background: `url(${conditionalRender ? images[2] : images[1]})`,
              }}
            >
              {overlay}
            </div>
          )}
        </div>
      </div>
    );
  }

  renderThree() {
    const { post } = this.props;
    const { images } = this.props;
    const { listImage } = this.props;
    const { countFrom } = this.state;
    const isVideo = images[0].includes(".mp4") || images[0].includes(".mov");
    const isVideo_1 = images[1].includes(".mp4") || images[1].includes(".mov");
    const isVideo_2 = images[2].includes(".mp4") || images[2].includes(".mov");

    const height = isMobile ? "195px" : "auto";
    const hight_two = isMobile ? "195px" : "auto";
    const overlay = this.renderOverlay();
    const overlayCount =
      images.length > countFrom && [3, 4].includes(+countFrom)
        ? this.renderCountOverlay(true)
        : this.renderOverlay();
    const Wrapper = ({ overlayCount }) => <div>{overlayCount}</div>;
    return (
      <div>
        <div className="group-img03 d-flex d-grid gap-1">
          <div className="w-67">
            {isVideo && (
              <ReactPlayer
                onClick={() =>
                  this.onShowImages(post.id, listImage[0].id, "image")
                }
                url={images[0]}
                width="100%"
                height="404px"
                controls={true}
                className="first_video"
              />
            )}
            {!isVideo && (
              <div
                className="bg-img main-img"
                onClick={() =>
                  this.onShowImages(post.id, listImage[0].id, "image")
                }
                style={{ background: `url(${images[0]})` }}
              >
                {overlay}
              </div>
            )}
          </div>
          <div className="w-33 d-grid gap-1">
            {isVideo_1 && (
              <ReactPlayer
                onClick={() =>
                  this.onShowImages(post.id, listImage[1].id, "video")
                }
                url={images[1]}
                width="100%"
                height={"200px"}
                controls={true}
              />
            )}
            {!isVideo_1 && (
              <div
                className="bg-img sub-img"
                style={{
                  background: `url(${images[1]})`,
                  height: `${isVideo ? "200px" : ""}`,
                }}
                onClick={() =>
                  this.onShowImages(post.id, listImage[1].id, "image")
                }
              >
                {overlay}
              </div>
            )}
            <div
              className="img-plus"
              onClick={() =>
                this.onShowImages(post.id, listImage[2].id, "image")
              }
            >
              {isVideo_2 && (
                <>
                  {" "}
                  <ReactPlayer
                     onClick={() => this.onShowImages(post.id, listImage[2].id, 'video')}
                    url={images[2]}
                    width="100%"
                    height="200px"
                    controls={true}
                    className="video-overlay"
                  />
                  <div
                    style={{
                      marginTop: "20px",
                      left: "35%",
                      position: "absolute",
                    }}
                  >
                    {overlayCount}
                  </div>
                </>
              )}
              {!isVideo_2 && (
                <div
                  className="bg-img sub-img"
                  onClick={() =>
                    this.onShowImages(post.id, listImage[2].id, "image")
                  }
                  style={{
                    background: `url(${images[2]})`,
                    height: `${isVideo ? "200px" : ""}`,
                  }}
                >
                  <div style={{ marginTop: "45px" }}>{overlayCount}</div>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    );
  }

  renderOverlay(id) {
    const { hideOverlay, renderOverlay, overlayBackgroundColor } = this.props;

    if (hideOverlay) {
      return false;
    }

    return [
      <div
        key={`cover-${id}`}
        className="cover slide"
        style={{ backgroundColor: overlayBackgroundColor }}
      ></div>,
      <div
        key={`cover-text-${id}`}
        className="cover-text slide animate-text"
        style={{ fontSize: "100%" }}
      >
        {renderOverlay()}
      </div>,
    ];
  }

  renderCountOverlay(more) {
    const { images } = this.props;
    const { countFrom } = this.state;
    const extra = images.length - (countFrom && countFrom > 5 ? 5 : countFrom);

    return [
      more && <div key="count" className="cover"></div>,
      more && (
        <div
          key="count-sub"
          className="cover-text"
          style={{ fontSize: "200%" }}
        >
          <p style={{ color: "#fff", fontWeight: "bolder" }}>+{extra}</p>
        </div>
      ),
    ];
  }

  render() {
    const { modal, index, countFrom } = this.state;
    const { images } = this.props;
    const imagesToShow = [...images];

    if (countFrom && images.length > countFrom) {
      imagesToShow.length = countFrom;
    }

    return (
      <div className="grid-container">
        {imagesToShow.length === 1 && this.renderOne()}
        {imagesToShow.length === 2 &&
          imagesToShow.length !== 4 &&
          this.renderTwo()}
        {imagesToShow.length >= 3 && this.renderThree()}
        {/* {modal && <Modal onClose={this.onClose} index={index} images={images} />} */}
      </div>
    );
  }
}

Images.propTypes = {
  images: PropTypes.array.isRequired,
  hideOverlay: PropTypes.bool,
  renderOverlay: PropTypes.func,
  overlayBackgroundColor: PropTypes.string,
  onClickEach: PropTypes.func,
  countFrom: PropTypes.number,
};

export default Images;
