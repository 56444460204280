export const FETCH_CATALOGUE_GIFT_AND_PRODUCT = 'FETCH_CATALOGUE_GIFT_AND_PRODUCT';
export const FETCH_CATALOGUE_GIFT_AND_PRODUCT_SUCCESS = 'FETCH_CATALOGUE_GIFT_AND_PRODUCT_SUCCESS';
export const FETCH_CATALOGUE_GIFT_AND_PRODUCT_FAILED = 'FETCH_CATALOGUE_GIFT_AND_PRODUCT_FAILED';

export const EXCHANGE_POINTS = 'EXCHANGE_POINTS';
export const EXCHANGE_POINTS_SUCCESS = 'EXCHANGE_POINTS_SUCCESS';
export const EXCHANGE_POINTS_FAILED = 'EXCHANGE_POINTS_FAILED';

export const FETCH_POINTS_TRANSACTION = 'FETCH_POINTS_TRANSACTION';
export const FETCH_POINTS_TRANSACTION_SUCCESS = 'FETCH_POINTS_TRANSACTION_SUCCESS';
export const FETCH_POINTS_TRANSACTION_FAILED = 'FETCH_POINTS_TRANSACTION_FAILED';

export const FETCH_LIST_TOP_UP = 'FETCH_LIST_TOP_UP';
export const FETCH_LIST_TOP_UP_SUCCESS = 'FETCH_LIST_TOP_UP_SUCCESS';
export const FETCH_LIST_TOP_UP_FAILED = 'FETCH_LIST_TOP_UP_FAILED';

export const TOP_UP_DATA_USER = 'TOP_UP_DATA_USER';
export const TOP_UP_DATA_USER_SUCCESS = 'TOP_UP_DATA_USER_SUCCESS';
export const TOP_UP_DATA_USER_FAILED = 'TOP_UP_DATA_USER_FAILED';

export const FETCH_LIST_GIFT = 'FETCH_LIST_GIFT';
export const FETCH_LIST_GIFT_SUCCESS = 'FETCH_LIST_GIFT_SUCCESS';
export const FETCH_LIST_GIFT_FAILED = 'FETCH_LIST_GIFT_FAILED';

export const UPDATE_QUANTITY_OF_GIFT = 'UPDATE_QUANTITY_OF_GIFT';
export const UPDATE_QUANTITY_OF_GIFT_SUCCESS = 'UPDATE_QUANTITY_OF_GIFT_SUCCESS';
export const UPDATE_QUANTITY_OF_GIFT_FAILED = 'UPDATE_QUANTITY_OF_GIFT_FAILED';

