import React from "react";
function Producer({
  setManufactorName,
  setManufactorAddress,
  setManufactorPhone,
  setManufactorEmail,
  manufactorLogo,
  manufactorName,
  manufactorAddress,
  manufactorEmail,
  manufactorPhone,
  uploadImage,
  removeImage,
}) {
  return (
    <>
      <div className="row">
        <div className="traceability-form mt-2">
          <div className="row">
            <label>Tên chủ sở hữu</label>
            <input
              className="traceability-input"
              type="text"
              placeholder="Tên chủ sở hữu (đơn vị sản xuất)"
              value={manufactorName}
              onChange={(e) => setManufactorName(e.target.value)}
            />
          </div>
          <div className="row">
            <label>Địa chỉ trụ sở</label>
            <input
              className="traceability-input"
              type="text"
              placeholder="Địa chỉ trụ sở"
              value={manufactorAddress}
              onChange={(e) => setManufactorAddress(e.target.value)}
            />
          </div>
          <div className="row">
            <label>Điện thoại liên hệ</label>
            <input
              className="traceability-input"
              type="number"
              placeholder="Điện thoại liên hệ"
              value={manufactorPhone}
              onChange={(e) => setManufactorPhone(e.target.value)}
            />
          </div>
          <div className="row">
            <label>Email</label>
            <input
              className="traceability-input"
              type="email"
              placeholder="Email"
              value={manufactorEmail}
              onChange={(e) => setManufactorEmail(e.target.value)}
            />
          </div>
          <div className="row">
            <div className="d-flex justify-content-between">
              <label htmlFor="myFile" className="text-uppercase mb-2">
                Hình ảnh Logo
              </label>
              <span
                onChange={(e) => {
                  uploadImage(e);
                }}>
                <span className="icon-camera" />
              </span>
            </div>
            {manufactorLogo && (
              <div>
                <img
                  src={URL.createObjectURL(manufactorLogo)}
                  alt="avatar"
                  className="traceaility-image position-relative"
                />
                <button
                  className="btn remove-img position-absolute bg-secondary"
                  onClick={() => removeImage(manufactorLogo, "manufactor")}>
                  x
                </button>
              </div>
            )}
            <input
              type="file"
              id="myfile"
              name="myfile"
              lang="vi"
              style={{ height: "45px" }}
              className="form-control"
              onChange={(e) => {
                uploadImage(e, "manufactor");
              }}
            />
          </div>
        </div>
      </div>
    </>
  );
}
export default Producer;
