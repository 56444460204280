import React, { useState, useEffect } from "react";
import ReactStars from "react-rating-stars-component";
import ReactTimeAgo from "react-time-ago";
import parse from "html-react-parser";
import { Link } from "react-router-dom";
export default function CommentBody(props) {
  const { comment } = props;
  const [isShowFull, setIsShowFull] = useState(false);
  const [validCmtLenght, setValidCmtLenght] = useState(true);
  const content = comment && parse(comment.content.replace(/\n/g, "<br />"));
  const avatar_user = comment.user.image ?? "/images/avatar.png";

  const handleShowMorComment = (id) => {
    setIsShowFull(true);
    $(`.cmt-${id}`).removeClass("cmt-box-height");
  };
  useEffect(() => {
    if (comment.content.length > 252) {
      setValidCmtLenght(false);
    }
  }, [comment]);

  const ShowMoreButton = ({ onClick }) => (
    <span onClick={onClick}>
      <span
        className="hover pointer "
        style={{
          fontSize: "14px",
          fontStyle: "normal",
          fontWeight: "600",
        }}>
        ...More
      </span>
    </span>
  );
  return (
    <>
      <div className="user-inf d-flex align-items-center border-0">
        <Link to={`/nguoi-dung/${comment.user_id}`}>
          <img src={avatar_user} alt="avatar" />
        </Link>
        <div className="d-inline-block">
          <Link to={`/nguoi-dung/${comment.user_id}`}>
            <p className="user-name">{comment.user.name}</p>
          </Link>
          <div className="d-flex align-items-center">
            <p className="post-time ">
              {" "}
              <span className="icon-time align-middle"></span> <ReactTimeAgo date={Date.parse(comment.created_at)} />
            </p>
          </div>
          {comment && comment.rate > 0 && (
            <ReactStars count={5} size={15} activeColor="#ffd700" value={comment.rate} edit={false} isHalf={true} />
          )}
        </div>
      </div>
      {!Array.isArray(content) ? (
        <p className={`txt-cmt cmt-${comment.id}`} {...content.props}>
          {(content.props?.children.length > 200 || content.length > 200) && !isShowFull ? (
            <React.Fragment>
              {content.props?.children.slice(0, 200) || content.slice(0, 200)}
              <ShowMoreButton onClick={() => handleShowMorComment(comment.id)} />
            </React.Fragment>
          ) : (
            content
          )}
        </p>
      ) : (
        <>
          <p className={`txt-cmt cmt-${comment.id} ${!validCmtLenght ? "cmt-box-height" : ""} `}>{content} </p>
          <div style={{ marginLeft: "4rem" }}>
            {!validCmtLenght && !isShowFull && <ShowMoreButton onClick={() => handleShowMorComment(comment.id)} />}
          </div>
        </>
      )}
    </>
  );
}
