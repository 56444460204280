import React, { useState, useEffect, useRef } from "react";

export default function CommentInput(props) {
  const { content, onChange, typeInput } = props;
  const [initialValue, setInit] = useState(content);
  const editableDivRef = useRef(null);

  useEffect(() => {
    // Focus the editable div when the component mounts
    editableDivRef.current.focus();
    const moveCursorToEnd = () => {
      const selection = window.getSelection();
      const range = document.createRange();
      range.selectNodeContents(editableDivRef.current);
      range.collapse(false);
      selection.removeAllRanges();
      selection.addRange(range);
    };
    moveCursorToEnd();
  }, []);

  useEffect(() => {
    if (typeInput === "edit") {
      setInit(content);
    }
    if (typeInput === "reply" || typeInput === "") {
      setInit("");
    }
  }, [content, typeInput]);

  const handleInput = (e) => {
    if (onChange) {
      onChange(e.target.innerHTML);
    }
  };

  return (
    <div
      ref={editableDivRef} // Assign the ref to the div element
      contentEditable
      className=" input-box"
      style={{
        border: "1px solid #bbbbbb",
        backgroundColor: "white",
        padding: "10px 35px 10px 20px",
      }}
      {...props}
      onInput={(e) => handleInput(e)}
      dangerouslySetInnerHTML={{ __html: initialValue }}></div>
  );
}
