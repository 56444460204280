import * as marketConst from "constants/gia_ca_thi_truong";
import { toastError } from "helpers/toastHelper";
import { checkLoadMore } from "lib";
const initialState = {
  listMarketPrices: {
    data: [],
    error: null,
    isLoading: false,
    isLoadMore: false,
  },
  marketDetail: {
    data: [],
    error: false,
    isLoading: false,
    isEmpty: false,
    market_place: {},
  },
  followingMarketPrice: {
    data: [],
    isLoading: false,
    isLoadMore: false,
    error: null,
  },
  leftSidebarMarketPrice: {
    data: [],
    isLoading: false,
    error: null,
  },
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case marketConst.FETCH_ALL_MARKET_PRICES: {
      return {
        ...state,
        listMarketPrices: {
          data: initialState.listMarketPrices.data,
          isLoading: true,
          error: null,
          isLoadMore: true,
        },
      };
    }

    case marketConst.FETCH_ALL_MARKET_PRICES_SUCCESS: {
      const { data } = action.payload;
      return {
        ...state,
        listMarketPrices: {
          data: data.data,
          isLoading: false,
          error: null,
          isLoadMore: checkLoadMore(data),
        },
      };
    }

    case marketConst.FETCH_ALL_MARKET_PRICES_FAILED: {
      const { error } = action.payload;
      toastError(error);
      return {
        ...state,
        listMarketPrices: {
          data: initialState.listMarketPrices.data,
          isLoading: false,
          isLoadMore: false,
          error: error,
        },
      };
    }

    case marketConst.FETCH_SIDEBAR_MARKET_PRICES: {
      return {
        ...state,
        leftSidebarMarketPrice: {
          data: initialState.listMarketPrices.data,
          isLoading: true,
          error: null,
        },
      };
    }

    case marketConst.FETCH_SIDEBAR_MARKET_PRICES_SUCCESS: {
      const { data } = action.payload;
      return {
        ...state,
        leftSidebarMarketPrice: {
          data: data.data,
          isLoading: false,
          error: null,
        },
      };
    }

    case marketConst.FETCH_SIDEBAR_MARKET_PRICES_FAILED: {
      const { error } = action.payload;
      return {
        ...state,
      };
    }
    case marketConst.FETCH_MARKET_PRICES: {
      return {
        ...state,
        marketDetail: {
          ...state.marketDetail,
          isLoading: true,
        },
      };
    }
    case marketConst.FETCH_MARKET_PRICES_SUCCESS: {
      const { data } = action.payload;
      if (data.data.length > 0) {
        return {
          ...state,
          marketDetail: {
            ...state.marketDetail,
            data: data.data,
            error: false,
            isLoading: false,
            isEmpty: false,
            market_place: data.market_place,
          },
        };
      }
      else {
        return {
          ...state,
          marketDetail: {
            ...state.marketDetail,
            error: false,
            isLoading: false,
            isEmpty: true,
            market_place: data.market_place,
          },
        }
      }
    }
    case marketConst.FETCH_MARKET_PRICES_FAILED: {
      const { error } = action.payload;
      toastError("Không tìm thấy giá");
      return {
        ...state,
        marketDetail: {
          ...state.marketDetail,
          isLoading: false,
          isEmpty: true,
          error: null,
        },
      };
    }

    case marketConst.FETCH_LOAD_MORE_MARKET_PRICES: {
      return {
        ...state,
        listMarketPrices: {
          data: state.listMarketPrices.data,
          isLoading: true,
          isLoadMore: false,
          error: null,
        },
      };
    }

    case marketConst.FETCH_LOAD_MORE_MARKET_PRICES_SUCCESS: {
      const { data } = action.payload.data;
      const isLoadMore = data.length > 0;
      return {
        ...state,
        listMarketPrices: {
          data: [...state.listMarketPrices.data, ...data],
          isLoading: false,
          isLoadMore: isLoadMore,
          error: null,
        },
      };
    }

    case marketConst.FETCH_LOAD_MORE_MARKET_PRICES_FAILED: {
      const { error } = action.payload;
      toastError(error);
      return {
        ...state,
        listMarketPrices: {
          data: initialState.listMarketPrices.data,
          isLoading: false,
          isLoadMore: false,
          error: error,
        },
      };
    }

    case marketConst.FETCH_FOLLOWING_MARKET_PRICE: {
      return {
        ...state,
        followingMarketPrice: {
          data: initialState.followingMarketPrice.data,
          isLoading: true,
          isLoadMore: true,
          error: null,
        },
      };
    }

    case marketConst.FETCH_FOLLOWING_MARKET_PRICE_SUCCESS: {
      const { data } = action.payload;
      return {
        ...state,
        followingMarketPrice: {
          data: data.data,
          isLoading: false,
          isLoadMore: checkLoadMore(data),
          error: null,
        },
      };
    }

    case marketConst.LIKE_MARKET_PRICE: {
      return {
        ...state,
      };
    }

    case marketConst.LIKE_MARKET_PRICE_SUCCESS: {
      const { data } = action.payload;
      return {
        ...state,
        listMarketPrices: {
          data: state.listMarketPrices.data.map((market) => {
            if (market.id == data.data.likeable_id) {
              market.user_liked = true;
            }
            return market;
          }),
          isLoading: false,
          error: null,
        },
        marketDetail: {
          ...state.marketDetail,
          market_place: {
            ...state.marketDetail.market_place,
            user_liked: true,
          },
        },
      };
    }

    case marketConst.DISLIKE_MARKET_PRICE: {
      return {
        ...state,
      };
    }

    case marketConst.DISLIKE_MARKET_PRICE_SUCCESS: {
      const { data } = action.payload;
      return {
        ...state,
        listMarketPrices: {
          data: state.listMarketPrices.data.map((market) => {
            if (market.id == data.marketPriceId) {
              market.user_liked = false;
            }
            return market;
          }),
          isLoading: false,
          error: null,
        },
        marketDetail: {
          ...state.marketDetail,
          market_place: {
            ...state.marketDetail.market_place,
            user_liked: false,
          },
        },
        followingMarketPrice: {
          data: state.followingMarketPrice.data.filter((item) => item.id != data.marketPriceId),
        },
      };
    }

    default:
      return state;
  }
};
export default reducer;
