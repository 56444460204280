import { takeLatest, takeEvery, all } from 'redux-saga/effects';
import * as productTypes from 'constants/product';
import * as postTypes from 'constants/post';
import * as authTypes from 'constants/auth';
import * as articleTypes from 'constants/article';
import * as locationTypes from 'constants/location';
import * as notificationTypes from 'constants/notification';
import * as fertilizerTypes from 'constants/fertilizer';
import * as track_activityTypes from 'constants/track_activity';
import * as diagnosticTypes from 'constants/diagnostic';
import * as weatherTypes from 'constants/weather';
import * as marketTypes from 'constants/gia_ca_thi_truong';
import * as expertTypes from 'constants/expert';
import * as callTypes from 'constants/call';
import * as sitemapType from 'constants/sitemap';
import * as managerTypes from 'constants/manager';
import * as postDetailTypes from 'constants/postDetail';
import * as traceabilityTypes from 'constants/traceability';
import * as technicalProcessTypes from 'constants/technical_process';
import * as GiftTypes from 'constants/gift';
import * as AlbumsTypes from 'constants/albums';
import * as cartTypes from 'constants/cart';
import * as invoiceTypes from 'constants/invoice';
import * as homeTypes from 'constants/home';
import * as businessTypes from 'constants/business_association';
import * as businessProductTypes from 'constants/product_business';
import * as businessInvoiceTypes from 'constants/invoice_business';
import * as businessEmployeeTypes from 'constants/employee_business';
import {
  fetchListTechnicalProcess,
  fetchTechnicalProcessDetail,
  fetchLoadMoreTechnicalProcessItem,
  createTechnicalProcessComment,
  fetchTechnicalProcessComment,
  likeTechnicalProcessComment,
  reportTechnicalProcessComment,
  dislikeTechnicalProcessComment,
  userDeleteTechnicalProcessComment,
  managerDestroyTechnicalProcessComment,
  userReplyTechchnicalProcessComment,
  userUpdateTechnicalProcessComment,
  managerDeleteChildTechnicalProcessComment,
  userDeleteChildTechnicalProcessComment,
  fetchTechnicalProcessChildrenComment,
  userUpdateTechnicalProcessChildrenComment,
  userReplyTechchnicalProcessChildComment,
  managerUpdateTechnicalComment,
  userFetchCatalogueTechnicalProcess,
  userFetchCataloguesChildren,
  userFetchCatalogueDetail,
  userResetDataCatalogueChildren,
} from 'sagas/technical_process';

import {
  fetchTraceabilities,
  deleteTraceabilities,
  scanTraceabilities,
  newTraceabilities,
  fetchTraceabilitiesDetail,
} from 'sagas/traceability';
import {
  userLogin,
  userLogout,
  userUpdateProfile,
  followUserItem,
  fetchFollowings,
  unFollowUser,
  sharePointUser,
  userShowDeepLink,
  userFetchSidebarFollowing,
  userFetchListFollowingPost,
  addCurrentURL,
  fetchInfoUser,
  unFollowFavouritePost,
  userLikeFollowingPost,
  userUnikeFollowingPost,
  fetchPointOfUser,
  updatePointOfUser
} from 'sagas/auth';

import {
  fetchPosts,
  fetchLoadMoreHomePosts,
  fetchUserPosts,
  fetchPostCatalogues,
  userLikePost,
  userLikeComment,
  userUnlikePost,
  userAddPost,
  userFavoritesPost,
  userDeletePost,
  userCreateCommentPost,
  userDeleteCommentPost,
  userSharePost,
  userReportPost,
  userReplacePost,
  fetchCommentPost,
  fetchCommentAnswer,
  userReplyCommentPost,
  ReportComment,
  userUnlikeComment,
  userUpdateComment,
  userUpdateCommentAnswer,
  userLoadMoreCommentsPost,
  userShareArticleAndVideo,
  userFetchPostDetail,
  managerDeleteComments,
  userFollowPost,
  userUnFollowPost,
  userReportImage,
  userFetchPickTop,
  fetchAnsComments,
  userLikePostDetailPage,
  userUnikePostDetailPage,
} from 'sagas/post';

import {
  fetchProducts,
  fetchProductDetail,
  fetchLoadMoreProducts,
  fetchHighlightProducts,
  fetchUserProducts,
  fetchRelationProducts,
  fetchCatalogueOfProduct,
  fetchUserCommentProducts,
  userCreateCommentProduct,
  fetchUserFavoritesProducts,
  userCreateFavouriteProduct,
  userDeleteFavouriteProduct,
  fetchProductsByUser,
  fetchSidebarProduct,
  userLikeCommentProduct,
  userUnLikeCommentProduct,
  userReportCommentProduct,
  userReplyCommentProduct,
  userUpdateCommentProduct,
  userUpdateChildComment,
  userDestroyChildComment,
  userDestroyComment,
  managerClickDeleteComment,
  managerClickDeleteSubComment,
  fetchLoadMoreComment,
  managerUpdateCommentProduct,
  managerUpdateChildComment,
  fetchChildCatalogueOfProductByParent,
  userFetchParentCatalogue,
  fetchAnsCommentProduct,
} from 'sagas/product';

import {
  fetchArticles,
  fetchArticleDetail,
  fetchSidebarArticles,
  fetchRelationArticles,
  fetchUserCommentArticles,
  userCreateCommentArticles,
  fetchFeatureArticles,
  fetchFavoriteArtiles,
  createFavoriteArticles,
  fetchObjectHashTags,
  deleteFavoriteArticles,
  fetchUserAnswerCommentArticles,
  UserReplyCommentArticle,
  UserCreateLikeComment,
  UserCreateUnLikeComment,
  UserLoadMoreCommentsArticles,
  userReportCommentArticle,
  userReplyComment,
  UpdateCommentArticle,
  UpdateChildComment,
  managerUpdateCommentArticle,
  managerUpdateChildCommentArticle,
  managerDestroyCommentArticle,
  managerDestroyChildCommentArticle,
  userDestroyCommentArticle,
  userDestroyChildCommentArticle,
  fetchLoadMoreCommentsArticles,
} from 'sagas/article';

import {
  fetchNotifications,
  userDeleteNotification,
  fetchCountNotifications,
  userViewNotification,
  fetchLoadMoreNotifications,
} from 'sagas/notification';

import {
  fetchAllMarketPrices,
  fetchMarketPriceDetail,
  fetchSideBarMarketPrices,
  fetchLoadMoreMarketPrices,
  userLikeMarketPrice,
  userDislikeMarketPrice,
  userFetchFollowingMarketPrice,
} from 'sagas/gia_ca_thi_truong';

import {
  fetchWeather,
  fetchWeatherPlaces,
  fetchWeatherAddress,
  UserFetchWeatherAudio,
  UserFetchWeatherSchedule,
  UserDeletehWeatherSchedule,
  userFetchWeatherScheduleId,
} from 'sagas/weather';

import { fetchProvinces, fetchDistricts } from 'sagas/location';

import { fetchSiteMap } from 'sagas/sitemap';

import { createActivity } from 'sagas/track_activity';

import { fetchFertilizer } from 'sagas/fertilizer';
import { fetchDiagnostic, fetchDiagnosticDetail } from 'sagas/diagnostic';
import { fetchExpert } from 'sagas/expert';
import { createCallExpert } from 'sagas/call';
import {
  managerUpdateComments,
  managerUpdateAnswerComments,
  managerDeletePosts,
  managerFetchCommentWarning,
  managerDeleteWarnings,
  managerFetchContributeTechnicalProcess,
  managerFetchContributePests,
  managerFetchContributeMarketPrice,
  managerApproveContributeTechnicalProcess,
  managerDeleteContributeTechnicalProcess,
  managerApproveContributePests,
  managerDeleteContributePests,
  managerDeleteContributeMarketPrice,
  managerApproveContributeMarketPrice,
} from 'sagas/manager';
import {
  fetchCommentPostDetail,
  likeCommentPostDetail,
  userLikePostDetail,
  fetchSubCommentPostDetail,
  userCommentPostDetail,
  userReplyCommentPostDetail,
  userEditCommentPostDetail,
  userDeleteCommentPostDetail,
  userDeleteSubCommentPostDetail,
  userEditSubCommentPostDetail,
  userFastFollowUserInPost,
  userFastUnFollowUserInPost,
} from 'sagas/postDetail';
import { fetchInitialDataDetailPage } from './postDetail';
import { createProduct, deleteProductByOwner, updateProduct } from './product';
import {
  fetchCataloguesAndGift,
  userExchangePointsForGift,
  userFetchPointsTransaction,
  userFetchTopUp,
  userTopUpData,
  userFetchListGift,
  userUpdateQuantityExchangeOfGift
} from 'sagas/gift';
import {
  userFetchAlbums,
  userFetchAlbumsId,
  userFetchImageAlbums,
  userUploadImageAlbums,
  userDestroyImageAlbums,
  userAddNewAlbum,
  userMoveImageToOtherAlbums,
  userDeleteAlbums,
  userEditAlbums,
} from 'sagas/albums';
import {
  userAddProductToCart,
  userUpdateNumberProductInCart,
  userCalculatePriceProductInCart,
  userRemoveProductFromCart,
} from 'sagas/cart';

import {
  userCreateInvoice,
  userGetBuyingInvoicesList,
  userGetSellingInvoicesList,
  userFetchDetailInvoice,
  userUpdateStatusInvoice,
} from 'sagas/invoice';

import { userSearchHome } from 'sagas/home';
import {
  userFetchListBusinessAssociation,
  userFetchBusinessAssociationDetail,
  userFetchBusinessProduct,
  userDeleteBusinessProduct,
  userCreateBusinessProduct,
  userUpdateBusinessProduct,
  userFetchBusinessInvoice,
  userFetchBusinessInvoiceDetail,
  userUpdateBusinessInvoice,
  userFetchBusinessEmployee,
  userAddNewBusinessEmployee,
  userUpdateBusinessEmployee,
  userDeleteBusinessEmployee,
  userImportBusinessProduct,
} from 'sagas/business';

function* rootSaga() {
  yield takeLatest(authTypes.LOGIN, userLogin);
  yield takeLatest(authTypes.LOGOUT, userLogout);
  yield takeLatest(authTypes.UPDATE_USER, userUpdateProfile);
  yield takeLatest(authTypes.FOLLOW_USER, followUserItem);
  yield takeLatest(authTypes.FETCH_FOLLOWING, fetchFollowings);
  yield takeLatest(authTypes.UNFOLOW, unFollowUser);
  yield takeLatest(authTypes.SHARE_POINT, sharePointUser);
  yield takeLatest(authTypes.FOLLOW_POST, userFollowPost);
  yield takeLatest(authTypes.UNFOLLOW_POST, userUnFollowPost);
  yield takeLatest(authTypes.SHOW_DEEP_LINK, userShowDeepLink);
  yield takeLatest(authTypes.FETCH_SIDEBAR_FOLLOWING, userFetchSidebarFollowing);
  yield takeLatest(authTypes.FETCH_FOLLOW_POST, userFetchListFollowingPost);
  yield takeLatest(authTypes.ADD_CURRENT_URL, addCurrentURL);
  yield takeLatest(authTypes.FETCH_INFO_USER, fetchInfoUser);
  yield takeLatest(authTypes.UNFOLLOW_FAVORITES_POST, unFollowFavouritePost);
  yield takeLatest(authTypes.UNLIKE_FOLLOWING_POST, userUnikeFollowingPost);
  yield takeLatest(authTypes.LIKE_FOLLOWING_POST, userLikeFollowingPost);
  yield takeLatest(authTypes.FETCH_POINT_OF_USER, fetchPointOfUser);
  yield takeLatest(authTypes.UPDATE_POINT_OF_USER, updatePointOfUser);
  //post
  yield takeLatest(postTypes.UNLIKE_COMMENT, userUnlikeComment);
  yield takeLatest(postTypes.REPORT_COMMENT, ReportComment);
  yield takeLatest(postTypes.FETCH_POST, fetchPosts);
  yield takeLatest(postTypes.FETCH_POST_CATALOGUE, fetchPostCatalogues);
  yield takeLatest(postTypes.FETCH_USER_POST, fetchUserPosts);
  yield takeLatest(postTypes.LOAD_MORE_POST, fetchLoadMoreHomePosts);
  yield takeLatest(postTypes.LIKE_POST, userLikePost);
  yield takeLatest(postTypes.LIKE_POST_DETAIL_PAGE, userLikePostDetailPage);
  yield takeLatest(postTypes.UNLIKE_POST_DETAIL_PAGE, userUnikePostDetailPage);
  yield takeLatest(postTypes.LIKE_COMMENT, userLikeComment);
  yield takeLatest(postTypes.UNLIKE_POST, userUnlikePost);
  yield takeLatest(postTypes.ADD_POST, userAddPost);
  yield takeLatest(postTypes.REPLY_COMMENT_POST, userReplyCommentPost);
  yield takeLatest(postTypes.CREATE_COMMENT_POST, userCreateCommentPost);
  yield takeLatest(postTypes.DELETE_COMMENT_POST, userDeleteCommentPost);
  yield takeLatest(postTypes.FETCH_FAVORITES_POST, userFavoritesPost);
  yield takeLatest(postTypes.DELETE_POST, userDeletePost);
  yield takeLatest(postTypes.SHARE_POST, userSharePost);
  yield takeLatest(postTypes.REPORT_POST, userReportPost);
  yield takeLatest(postTypes.REPLACE_POST_ID, userReplacePost);
  yield takeLatest(postTypes.FETCH_COMMENT_POST, fetchCommentPost);
  yield takeLatest(postTypes.FETCH_COMMENT_ANSWERS, fetchCommentAnswer);
  yield takeLatest(postTypes.UPDATE_COMMENT, userUpdateComment);
  yield takeLatest(postTypes.UPDATE_ANSWER_COMMENT, userUpdateCommentAnswer);
  yield takeLatest(postTypes.LOAD_MORE_COMMENT_POST, userLoadMoreCommentsPost);
  yield takeLatest(postTypes.USER_SHARE_ARTICLE, userShareArticleAndVideo);
  yield takeLatest(postTypes.FETCH_DETAIL_POST, userFetchPostDetail);
  yield takeLatest(postTypes.FETCH_PICK_TOP, userFetchPickTop);
  yield takeLatest(postTypes.REPORT_IMAGE_POST, userReportImage);
  yield takeLatest(postTypes.FETCH_ANSWER_COMMENT, fetchAnsComments);
  //post detail page
  yield takeLatest(postDetailTypes.FETCH_POST_DETAIL, fetchInitialDataDetailPage);
  yield takeLatest(postDetailTypes.FETCH_COMMENT_POST_DETAIL, fetchCommentPostDetail);
  yield takeLatest(postDetailTypes.LIKE_POST_DETAIL, userLikePostDetail);
  yield takeLatest(postDetailTypes.LIKE_COMMENT_POST_DETAIL, likeCommentPostDetail);
  yield takeLatest(postDetailTypes.FETCH_SUBCOMMENT_POST_DETAIL, fetchSubCommentPostDetail);
  yield takeLatest(postDetailTypes.COMMENT_POST_DETAIL, userCommentPostDetail);
  yield takeLatest(postDetailTypes.REPLY_COMMENT_POST_DETAIL, userReplyCommentPostDetail);
  yield takeLatest(postDetailTypes.EDIT_COMMENT_POST_DETAIL, userEditCommentPostDetail);
  yield takeLatest(postDetailTypes.DELETE_COMMENT_POST_DETAIL, userDeleteCommentPostDetail);
  yield takeLatest(postDetailTypes.DELETE_SUBCOMMENT_POST_DETAIL, userDeleteSubCommentPostDetail);
  yield takeLatest(postDetailTypes.EDIT_SUBCOMMENT_POST_DETAIL, userEditSubCommentPostDetail);
  yield takeLatest(postDetailTypes.FAST_FOLLOW_USER_IN_POST_DETAIL, userFastFollowUserInPost);
  yield takeLatest(postDetailTypes.FAST_UNFOLLOW_USER_IN_POST_DETAIL, userFastUnFollowUserInPost);

  //article
  yield takeLatest(articleTypes.MANAGER_DESTROY_CHILD_COMMENT, managerDestroyChildCommentArticle);
  yield takeLatest(articleTypes.MANAGER_DESTROY_COMMENT, managerDestroyCommentArticle);
  yield takeLatest(articleTypes.MANAGER_UPDATE_CHILD_COMMENT, managerUpdateChildCommentArticle);
  yield takeLatest(articleTypes.MANAGER_UPDATE_COMMENT, managerUpdateCommentArticle);
  yield takeLatest(articleTypes.USER_DESTROY_COMMENT, userDestroyCommentArticle);
  yield takeLatest(articleTypes.USER_DESTROY_CHILD_COMMENT, userDestroyChildCommentArticle);
  yield takeLatest(articleTypes.FETCH_LOAD_MORE_COMMENT_ARTICLE, fetchLoadMoreCommentsArticles);
  yield takeLatest(articleTypes.UPDATE_CHILD_COMMENT, UpdateChildComment);
  yield takeLatest(articleTypes.UPDATE_COMMENT, UpdateCommentArticle);
  yield takeLatest(articleTypes.REPLY_COMMENT, userReplyComment);
  yield takeLatest(articleTypes.REPORT_COMMENT_ARTICLE, userReportCommentArticle);
  yield takeLatest(articleTypes.FETCH_ARTICLE, fetchArticles);
  yield takeLatest(articleTypes.FETCH_RELATION_ARTICLE, fetchRelationArticles);
  yield takeLatest(articleTypes.FETCH_SIDEBAR_ARTICLE, fetchSidebarArticles);
  yield takeLatest(articleTypes.FETCH_ARTICLE_DETAIL_ID, fetchArticleDetail);
  yield takeLatest(articleTypes.FETCH_ARTICLE_COMMENT, fetchUserCommentArticles);
  yield takeLatest(articleTypes.CREATE_ARTICLE_COMMENT, userCreateCommentArticles);
  yield takeLatest(articleTypes.FETCH_FEATURE_ARTICLE, fetchFeatureArticles);
  yield takeLatest(articleTypes.FETCH_FAVORITES_ARTICLE, fetchFavoriteArtiles);
  yield takeLatest(articleTypes.CREATE_FAVORITE_ARTICLE, createFavoriteArticles);
  yield takeLatest(articleTypes.FETCH_OBJECT_HASHTAG, fetchObjectHashTags);
  yield takeLatest(articleTypes.DELETE_FAVORITE_ARTICLE, deleteFavoriteArticles);
  yield takeLatest(articleTypes.FETCH_COMMENT_ANSWER_ARTICLE, fetchUserAnswerCommentArticles);
  yield takeLatest(articleTypes.CREATE_ANSWER_COMMENT_ARTICLE, UserReplyCommentArticle);
  yield takeLatest(articleTypes.CREATE_LIKE_COMMENT_ARTICLE, UserCreateLikeComment);
  yield takeLatest(articleTypes.CREATE_UNLIKE_COMMENT_ARTICLE, UserCreateUnLikeComment);
  yield takeLatest(articleTypes.LOAD_MORE_COMMENT_ARTICLE, UserLoadMoreCommentsArticles);

  // product
  yield takeLatest(productTypes.MANAGER_UPDATE_COMMENT, managerUpdateCommentProduct);
  yield takeLatest(productTypes.MANAGER_UPDATE_CHILD_COMMENT, managerUpdateChildComment);
  yield takeLatest(productTypes.LOAD_MORE_COMMENT, fetchLoadMoreComment);
  yield takeLatest(productTypes.MANAGER_DELETE_COMMENT, managerClickDeleteComment);
  yield takeLatest(productTypes.MANAGER_DELETE_SUB_COMMENT, managerClickDeleteSubComment);
  yield takeLatest(productTypes.DESTROY_COMMENT, userDestroyComment);
  yield takeLatest(productTypes.DESTROY_CHILD_COMMENT, userDestroyChildComment);
  yield takeLatest(productTypes.UPDATE_COMMENT_PRODUCT, userUpdateCommentProduct);
  yield takeLatest(productTypes.UPDATE_CHILD_COMMENT, userUpdateChildComment);
  yield takeLatest(productTypes.REPLY_COMMENT_PRODUCT, userReplyCommentProduct);
  yield takeLatest(productTypes.REPORT_COMMENT_PRODUCT, userReportCommentProduct);
  yield takeLatest(productTypes.UNLIKE_COMMENT_PRODUCT, userUnLikeCommentProduct);
  yield takeLatest(productTypes.LIKE_COMMENT_PRODUCT, userLikeCommentProduct);
  yield takeLatest(productTypes.FETCH_PRODUCT_DETAIL_ID, fetchProductDetail);
  yield takeLatest(productTypes.FETCH_PRODUCT, fetchProducts);
  yield takeLatest(productTypes.FETCH_HIGHLIGHT_PRODUCT, fetchHighlightProducts);
  yield takeLatest(productTypes.LOAD_MORE_PRODUCT, fetchLoadMoreProducts);
  yield takeLatest(productTypes.FETCH_USER_PRODUCT, fetchUserProducts);
  yield takeLatest(productTypes.FETCH_RELATION_PRODUCT, fetchRelationProducts);
  yield takeLatest(productTypes.FETCH_CATALOGUE_OF_PRODUCT, fetchCatalogueOfProduct);
  yield takeLatest(productTypes.FETCH_CHILD_CATALOGUE_OF_PRODUCT, fetchChildCatalogueOfProductByParent);
  yield takeLatest(productTypes.FETCH_PRODUCT_COMMENT, fetchUserCommentProducts);
  yield takeLatest(productTypes.CREATE_PRODUCT_COMMENT, userCreateCommentProduct);
  yield takeLatest(productTypes.FETCH_FAVORITES_PRODUCT, fetchUserFavoritesProducts);
  yield takeLatest(productTypes.CREATE_FAVORITE_PRODUCT, userCreateFavouriteProduct);
  yield takeLatest(productTypes.DELETE_FAVORITE_PRODUCT, userDeleteFavouriteProduct);
  yield takeLatest(productTypes.FETCH_PRODUCT_BY_USER, fetchProductsByUser);
  yield takeLatest(productTypes.FETCH_SIDEBAR_PRODUCT, fetchSidebarProduct);
  yield takeLatest(productTypes.CREATE_PRODUCT, createProduct);
  yield takeLatest(productTypes.UPDATE_PRODUCT, updateProduct);
  yield takeLatest(productTypes.DELETE_PRODUCT, deleteProductByOwner);
  yield takeLatest(productTypes.FETCH_PARENT_CATALOG, userFetchParentCatalogue);
  yield takeLatest(productTypes.FETCH_ANSWER_COMMENT, fetchAnsCommentProduct);

  yield takeLatest(locationTypes.FETCH_PROVINCE, fetchProvinces);
  yield takeLatest(locationTypes.FETCH_DISTRICT, fetchDistricts);
  yield takeLatest(notificationTypes.FETCH_NOTIFICATION, fetchNotifications);
  yield takeLatest(notificationTypes.DELETE_NOTIFICATION, userDeleteNotification);
  yield takeLatest(notificationTypes.COUNT_NOTIFICATION, fetchCountNotifications);

  yield takeLatest(notificationTypes.VIEW_NOTIFICATION, userViewNotification);
  yield takeLatest(notificationTypes.LOAD_MORE_NOTIFICATION, fetchLoadMoreNotifications);
  yield takeLatest(fertilizerTypes.FETCH_FERTILIZER, fetchFertilizer);
  // track_activity
  yield takeLatest(track_activityTypes.CREATE_TRACK_ACTIVITY, createActivity);
  //diagnostic
  yield takeLatest(diagnosticTypes.FETCH_DIAGNOSTICS, fetchDiagnostic);
  yield takeLatest(diagnosticTypes.FETCH_DIAGNOSTICS_DETAILS, fetchDiagnosticDetail);
  // gia ca thi truong
  yield takeLatest(marketTypes.FETCH_ALL_MARKET_PRICES, fetchAllMarketPrices);
  yield takeLatest(marketTypes.FETCH_MARKET_PRICES, fetchMarketPriceDetail);
  yield takeLatest(marketTypes.LIKE_MARKET_PRICE, userLikeMarketPrice);
  yield takeLatest(marketTypes.DISLIKE_MARKET_PRICE, userDislikeMarketPrice);
  yield takeLatest(marketTypes.FETCH_SIDEBAR_MARKET_PRICES, fetchSideBarMarketPrices);
  yield takeLatest(marketTypes.FETCH_LOAD_MORE_MARKET_PRICES, fetchLoadMoreMarketPrices);
  yield takeLatest(marketTypes.FETCH_FOLLOWING_MARKET_PRICE, userFetchFollowingMarketPrice);
  //weather
  yield takeLatest(weatherTypes.FETCH_WEATHER, fetchWeather);
  yield takeLatest(weatherTypes.FETCH_WEATHER_PLACES, fetchWeatherPlaces);
  yield takeLatest(weatherTypes.FETCH_WEATHER_ADDRESS, fetchWeatherAddress);
  yield takeLatest(weatherTypes.FETCH_WEATHER_AUDIO, UserFetchWeatherAudio);
  yield takeLatest(weatherTypes.FETCH_WEATHER_SCHEDULE, UserFetchWeatherSchedule);
  yield takeLatest(weatherTypes.DELETE_WEATHER_SCHEDULE, UserDeletehWeatherSchedule);
  yield takeLatest(weatherTypes.FETCH_WEATHER_SCHEDULE_BY_ID, userFetchWeatherScheduleId);

  //diagnostic
  yield takeLatest(diagnosticTypes.FETCH_DIAGNOSTICS, fetchDiagnostic);
  yield takeLatest(diagnosticTypes.FETCH_DIAGNOSTICS_DETAILS, fetchDiagnosticDetail);
  //expert
  yield takeLatest(expertTypes.FETCH_EXPERTS, fetchExpert);
  // call
  yield takeLatest(callTypes.CREATE_CALL, createCallExpert);
  // sitemap
  yield takeLatest(sitemapType.FETCH_SITEMAP, fetchSiteMap);
  //manager
  yield takeLatest(managerTypes.MANAGER_UPDATE_COMMENT, managerUpdateComments);
  yield takeLatest(managerTypes.MANAGER_UPDATE_ANSWER_COMMENT, managerUpdateAnswerComments);
  yield takeLatest(managerTypes.MANAGER_DELETE_POST, managerDeletePosts);
  yield takeLatest(managerTypes.FETCH_WARNING_COMMENT, managerFetchCommentWarning);
  yield takeLatest(managerTypes.DELETE_WARNING_COMMENT, managerDeleteWarnings);
  yield takeLatest(managerTypes.MANAGER_DELETE_COMMENT_POST_DETAIL, managerDeleteComments);
  yield takeLatest(managerTypes.FETCH_TECHNICAL_PROCESS_FOR_APPROVE, managerFetchContributeTechnicalProcess);
  yield takeLatest(managerTypes.FETCH_CONTRIBUTE_PESTS, managerFetchContributePests);
  yield takeLatest(managerTypes.FETCH_CONTRIBUTE_MARKET_PRICE, managerFetchContributeMarketPrice);
  yield takeLatest(managerTypes.MANAGE_CONTRIBUTE_TECHNICAL_PROCESS, managerApproveContributeTechnicalProcess);
  yield takeLatest(managerTypes.MANAGE_DELETE_CONTRIBUTE_TECHNICAL_PROCESS, managerDeleteContributeTechnicalProcess);
  yield takeLatest(managerTypes.MANAGE_CONTRIBUTE_PESTS, managerApproveContributePests);
  yield takeLatest(managerTypes.MANAGE_DELETE_CONTRIBUTE_PESTS, managerDeleteContributePests);
  yield takeLatest(managerTypes.MANAGER_DELETE_CONTRIBUTE_MARKET_PRICE, managerDeleteContributeMarketPrice);
  yield takeLatest(managerTypes.MANAGE_APPROVE_CONTRIBUTE_MARKET_PRICE, managerApproveContributeMarketPrice);

  //traceability
  yield takeLatest(traceabilityTypes.FETCH_TRACEABILITY_DETAIL, fetchTraceabilitiesDetail);
  yield takeLatest(traceabilityTypes.FETCH_TRACEABILITY, fetchTraceabilities);
  yield takeLatest(traceabilityTypes.DELETE_TRACEABILITY, deleteTraceabilities);
  yield takeLatest(traceabilityTypes.SCAN_TRACEABILITY, scanTraceabilities);
  yield takeLatest(traceabilityTypes.NEW_TRACEABILITY, newTraceabilities);
  // quy trinh kyx thuat
  yield takeLatest(technicalProcessTypes.FETCH_TECHNICAL_PROCESS, fetchListTechnicalProcess);
  yield takeLatest(technicalProcessTypes.FETCH_TECHNICAL_PROCESS_ID, fetchTechnicalProcessDetail);
  yield takeLatest(technicalProcessTypes.FETCH_LOAD_MORE_TECHNICAL_PROCESS, fetchLoadMoreTechnicalProcessItem);
  yield takeLatest(technicalProcessTypes.CREATE_COMMENT_TECHNICAL_PROCESS, createTechnicalProcessComment);
  yield takeLatest(technicalProcessTypes.FETCH_COMMENT_TECHNICAL_PROCESS, fetchTechnicalProcessComment);
  yield takeLatest(technicalProcessTypes.LIKE_COMMENT_TECHNICAL_PROCESS, likeTechnicalProcessComment);
  yield takeLatest(technicalProcessTypes.DISLIKE_COMMENT_TECHNICAL_PROCESS, dislikeTechnicalProcessComment);
  yield takeLatest(technicalProcessTypes.REPORT_COMMENT_TECHNICAL_PROCESS, reportTechnicalProcessComment);
  yield takeLatest(technicalProcessTypes.DELETE_COMMENT_TECHNICAL_PROCESS, userDeleteTechnicalProcessComment);
  yield takeLatest(technicalProcessTypes.REPLY_COMMENT_TECHNICAL_PROCESS, userReplyTechchnicalProcessComment);
  yield takeLatest(technicalProcessTypes.UPDATE_COMMENT_TECHNICAL_PROCESS, userUpdateTechnicalProcessComment);
  yield takeLatest(technicalProcessTypes.MANAGER_UPDATE_COMMENT_TECHNICAL_PROCESS, managerUpdateTechnicalComment);
  yield takeLatest(
    technicalProcessTypes.MANAGER_DELETE_COMMENT_TECHNICAL_PROCESS,
    managerDestroyTechnicalProcessComment,
  );
  yield takeLatest(technicalProcessTypes.MANAGER_DELETE_CHILD_COMMENT, managerDeleteChildTechnicalProcessComment);
  yield takeLatest(
    technicalProcessTypes.DELETE_CHILD_COMMENT_TECHNICAL_PROCESS,
    userDeleteChildTechnicalProcessComment,
  );
  yield takeLatest(
    technicalProcessTypes.UPDATE_CHILD_COMMENT_TECHNICAL_PROCESS,
    userUpdateTechnicalProcessChildrenComment,
  );
  yield takeLatest(
    technicalProcessTypes.REPLY_CHILD_COMMENT_TECHNICAL_PROCESS,
    userReplyTechchnicalProcessChildComment,
  );
  yield takeLatest(technicalProcessTypes.FETCH_CHILD_COMMENT_TECHNICAL_PROCESS, fetchTechnicalProcessChildrenComment);
  yield takeLatest(technicalProcessTypes.FETCH_CATALOGUE_TECHNICAL_PROCESS, userFetchCatalogueTechnicalProcess);
  yield takeLatest(technicalProcessTypes.FETCH_CATALOGUES_CHILDREN_TECHNICAL_PROCESS, userFetchCataloguesChildren);
  yield takeLatest(technicalProcessTypes.FETCH_DETAIL_CATALOGUES, userFetchCatalogueDetail);
  yield takeLatest(technicalProcessTypes.RESET_DATA_CHILDREN_TECHNICAL_PROCESS, userResetDataCatalogueChildren);

  //gift store
  yield takeLatest(GiftTypes.FETCH_CATALOGUE_GIFT_AND_PRODUCT, fetchCataloguesAndGift);
  yield takeLatest(GiftTypes.EXCHANGE_POINTS, userExchangePointsForGift);
  yield takeLatest(GiftTypes.FETCH_POINTS_TRANSACTION, userFetchPointsTransaction);
  yield takeLatest(GiftTypes.FETCH_LIST_TOP_UP, userFetchTopUp);
  yield takeLatest(GiftTypes.TOP_UP_DATA_USER, userTopUpData);
  yield takeLatest(GiftTypes.FETCH_LIST_GIFT, userFetchListGift);
  yield takeLatest(GiftTypes.UPDATE_QUANTITY_OF_GIFT, userUpdateQuantityExchangeOfGift);

  //user albums
  yield takeLatest(AlbumsTypes.FETCH_USER_ALBUMS, userFetchAlbums);
  yield takeLatest(AlbumsTypes.FETCH_USER_ALBUMS_ID, userFetchAlbumsId);
  yield takeLatest(AlbumsTypes.FETCH_LIST_IMAGE_ALBUMS, userFetchImageAlbums);
  yield takeLatest(AlbumsTypes.UPLOAD_IMAGE_TO_ALBUMS, userUploadImageAlbums);
  yield takeLatest(AlbumsTypes.DELETE_IMAGE_ALBUMS, userDestroyImageAlbums);
  yield takeLatest(AlbumsTypes.CREATE_AN_ALBUM, userAddNewAlbum);
  yield takeLatest(AlbumsTypes.MOVE_IMAGE_TO_OTHER_ALBUM, userMoveImageToOtherAlbums);
  yield takeLatest(AlbumsTypes.DELETE_USER_ALBUMS, userDeleteAlbums);
  yield takeLatest(AlbumsTypes.EDIT_USER_ALBUMS, userEditAlbums);

  //cart
  yield takeLatest(cartTypes.ADD_TO_CART, userAddProductToCart);
  yield takeLatest(cartTypes.UPDATE_NUMBER_PRODUCT_IN_CART, userUpdateNumberProductInCart);
  yield takeLatest(cartTypes.CALCULATE_TOTAL_PRICE_IN_CART, userCalculatePriceProductInCart);
  yield takeLatest(cartTypes.REMOVE_PRODUCT_FROM_CART, userRemoveProductFromCart);

  //invoice
  yield takeLatest(invoiceTypes.CREATE_INVOICE, userCreateInvoice);
  yield takeLatest(invoiceTypes.FETCH_BUYING_INVOICES, userGetBuyingInvoicesList);
  yield takeLatest(invoiceTypes.FETCH_DETAIL_INVOICE, userFetchDetailInvoice);
  yield takeLatest(invoiceTypes.FETCH_SELLING_INVOICES, userGetSellingInvoicesList);
  yield takeLatest(invoiceTypes.UPDATE_STATUS_INVOICE, userUpdateStatusInvoice);

  // home
  yield takeLatest(homeTypes.SEARCH_WITH_KEYWORD, userSearchHome);

  // business association
  yield takeLatest(businessTypes.FETCH_LIST_BUSINESS, userFetchListBusinessAssociation);
  yield takeLatest(businessTypes.FETCH_BUSINESS_DETAIL, userFetchBusinessAssociationDetail);
  yield takeLatest(businessProductTypes.FETCH_PRODUCT_FROM_BUSINESS, userFetchBusinessProduct);
  yield takeLatest(businessProductTypes.DELETE_BUSINESS_PRODUCT, userDeleteBusinessProduct);
  yield takeLatest(businessProductTypes.CREATE_BUSINESS_PRODUCT, userCreateBusinessProduct);
  yield takeLatest(businessProductTypes.UPDATE_BUSINESS_PRODUCT, userUpdateBusinessProduct);
  yield takeLatest(businessInvoiceTypes.FETCH_INVOICE_BUSINESS, userFetchBusinessInvoice);
  yield takeLatest(businessInvoiceTypes.FETCH_INVOICE_BUSINESS_DETAIL, userFetchBusinessInvoiceDetail);
  yield takeLatest(businessInvoiceTypes.UPDATE_INVOICE_BUSINESS, userUpdateBusinessInvoice);
  yield takeLatest(businessEmployeeTypes.FETCH_LIST_EMPLOYEE, userFetchBusinessEmployee);
  yield takeLatest(businessEmployeeTypes.ADD_NEW_EMPLOYEE, userAddNewBusinessEmployee);
  yield takeLatest(businessEmployeeTypes.UPDATE_EMPLOYEE, userUpdateBusinessEmployee);
  yield takeLatest(businessEmployeeTypes.DELETE_EMPLOYEE, userDeleteBusinessEmployee);
  yield takeLatest(businessProductTypes.IMPORT_BUSINESS_PRODUCT, userImportBusinessProduct);
}

export default rootSaga;
