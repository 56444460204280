import React, { useContext, useEffect, useState, useRef, useMemo } from "react";
import { Link, NavLink } from "react-router-dom";
import AppContext from "components/app_context";
import { useTranslation } from "react-i18next";
import { toastErrorLogin } from "helpers/toastHelper";
import { getAppModules, getConfigWheelData } from "apis/app";
import { useDispatch, useSelector } from "react-redux";
import { fetchSideBarMarketPrices } from "actions/gia_ca_thi_truong";
import { fetchWeather } from "actions/weather";
import { fetchSidebarFollowings } from "actions/auth";
import { imageUrl } from "lib";
import moment from "moment";
import { toastError } from "helpers/toastHelper";
import SidebarWeather from "../widgets/sidebar_weather";
import SidebarMarket from "../widgets/sidebar_market";
import { fetchListBusiness } from "actions/business";
import _ from "lodash";
function LeftSidebar(props) {
  const { current_user, isMobile } = useContext(AppContext);
  const isMounted = useRef(true);
  const { t } = useTranslation();
  const [appType, setAppType] = useState([]);
  const dispatch = useDispatch();
  const { data } = useSelector((state) => state.market.leftSidebarMarketPrice);
  const { data: listWeathers, isLoading } = useSelector(
    (state) => state.weather.listWeathers
  );
  const { data: sidebarFollowing } = useSelector(
    (state) => state.login.sidebarFollowing
  );
  const { data: listBusiness } = useSelector(
    (state) => state.business.list_business_association
  );

  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [statusWheel, setStatusWheel] = useState(null);
  const today = moment().format("YYYY-MM-DD");

  useEffect(() => {
    if (current_user) {
      dispatch(fetchSidebarFollowings({ limit: 5, page: 1 }));
    }
    dispatch(fetchWeather({ lat: 10.8058, lng: 106.6382 }));
    dispatch(
      fetchSideBarMarketPrices({
        page: 1,
        limit: 10,
      })
    );
  }, [current_user]);

  const fetchAppModules = async () => {
    let moduleArr = [
      "npk",
      "weather",
      "technical_process",
      "traceability",
      "shop_gitf",
    ];
    const response = await getAppModules();
    const { data } = response.data;
    data.filter((module) => {
      if (moduleArr.includes(module.app_type))
        setAppType((appType) => [...appType, module.app_type]);
    });
  };

  useEffect(() => {
    fetchAppModules();
    if (current_user) dispatch(fetchListBusiness({ page: 1, limit: 1 }));
    return () => {
      isMounted.current = false;
    };
  }, [current_user]);

  const onClick = (e) => {
    if (current_user === null) {
      e.preventDefault();
      toastErrorLogin(isMobile);
    }
  };

  const moveToMarketPrice = (e) => {
    if (current_user === null) {
      e.preventDefault();
      toastError("Please login first!");
      return;
    }
  };
  return (
    <div className="col-md-3 col-sm-12 d-desktop">
      <div className="panel-left">
        <div>
          <ul>
            {current_user && (
              <>
                <li className="hover bg-hover p-2 mt-2">
                  <NavLink to="/nguoi-dung/trang-ca-nhan" onClick={onClick}>
                    <span className="icon-user"></span>
                    <span className="txt">{t("user.profile")}</span>
                  </NavLink>
                </li>
                <li className="hover bg-hover p-2">
                  <NavLink to="/nguoi-dung/san-pham" onClick={onClick}>
                    <span className="icon-prod"></span>
                    <span className="txt">{t("user.product")}</span>
                  </NavLink>
                </li>
                <li className="hover bg-hover p-2">
                  <NavLink to="/nguoi-dung/don-hang-cua-toi" onClick={onClick}>
                    <span className="icon-order"></span>
                    <span className="txt">{t("user.invoice")}</span>
                  </NavLink>
                </li>
                <li className="hover bg-hover p-2">
                  <NavLink
                    to="/nguoi-dung/san-pham-yeu-thich"
                    onClick={onClick}
                  >
                    <span className="icon-tag"></span>
                    <span className="txt">{t("user.saved")}</span>
                  </NavLink>
                </li>
                <li className="hover bg-hover p-2">
                  <NavLink to="/nguoi-dung/bai-viet" onClick={onClick}>
                    <span className="icon-news-solid"></span>
                    <span className="txt">{t("user.article")}</span>
                  </NavLink>
                </li>
              </>
            )}
            {current_user &&
              current_user?.manager_type !== "member" &&
              current_user?.manager_type !== null && (
                <li className="hover bg-hover p-2">
                  <NavLink to="/nguoi-dung/quan-ly-binh-luan" onClick={onClick}>
                    <span className="icon-news-solid"></span>
                    <span className="txt">{t("admin.report")}</span>
                  </NavLink>
                </li>
              )}
            {current_user &&
              current_user?.manager_type == "admin" &&
              current_user?.manager_type !== null && (
                <li className="hover bg-hover p-2">
                  <NavLink to="/nguoi-dung/quan-ly-dong-gop" onClick={onClick}>
                    <span className="icon-news-solid"></span>
                    <span className="txt">{t("admin.contributes")}</span>
                  </NavLink>
                </li>
              )}
            {current_user && listBusiness.length > 0 && (
              <li className="hover bg-hover p-2">
                <NavLink
                  to={`/lien-ket-danh-nghiep/chuc-nang/${
                    _.first(listBusiness)?.id
                  }`}
                  onClick={onClick}
                >
                  <span className="icon-farmer-market"></span>
                  <span className="txt">{_.first(listBusiness)?.name}</span>
                </NavLink>
              </li>
            )}
          </ul>
        </div>
        <div>
          <h4
            className="title-txt text-center"
            style={{ color: "#000", fontSize: "1.4rem" }}
          >
           {t("label.shortcut")}
          </h4>
          <ul>
            {appType.includes("weather") && (
              <li className="hover bg-hover p-2">
                <NavLink to="/modules/thoi-tiet" exact="true" end>
                  <span className="icon-weather02"></span>
                  <span className="txt">{t("label.weather")}</span>
                </NavLink>
              </li>
            )}
            <li className="hover bg-hover p-2">
              <NavLink to="/cho-2nong">
                <span className="icon-farmer-market" />
                <span className="txt">{t("label.market")}</span>
              </NavLink>
            </li>

            {appType.includes("npk") && (
              <li className="hover bg-hover p-2">
                <NavLink to="/modules/npk" exact="true">
                  <span className="icon-mix-npk"></span>
                  <span className="txt">{t("label.npk_calcutalter")}</span>
                </NavLink>
              </li>
            )}
            {appType.includes("traceability") && (
              <li className="hover bg-hover p-2">
                <NavLink to="/modules/truy-xuat-nguon-goc" exact="true" end>
                  <span className="icon-find-origin"></span>
                  <span className="txt">{t("label.traceability")}</span>
                </NavLink>
              </li>
            )}
            {appType.includes("technical_process") && (
              <li className="hover bg-hover p-2">
                <NavLink to="/quy-trinh-ky-thuat" exact="true" end>
                  <span className="icon-tech-process"></span>
                  <span className="txt">{t("label.technical_process")}</span>
                </NavLink>
              </li>
            )}
            {appType.includes("shop_gitf") && (
              <li className="hover bg-hover p-2">
                <NavLink to="/shop-qua-tang" exact="true">
                  <span className="icon-gift"></span>
                  <span className="txt">{t("label.gift")}</span>
                </NavLink>
              </li>
            )}
            {/* <li>
              <NavLink to="/modules/goi-chuyen-gia" exact="true">
                <span className="icon-video-call"></span>
                <span className="txt">Gọi chuyên gia</span>
              </NavLink>
            </li> */}
          </ul>
        </div>
        {current_user && sidebarFollowing.lenght > 0 && (
          <div className="memb-follow">
            <h4
              className="title-txt text-center"
              style={{ color: "#000", fontSize: "1.4rem" }}
            >
              thành viên theo dõi
            </h4>
            {sidebarFollowing.map(function (follow, index) {
              let userImage = follow.image
                ? imageUrl(follow.image)
                : "/images/avatar.png";
              return (
                <div
                  className="memb-item d-flex align-items-center"
                  key={index}
                >
                  <img
                    src={userImage}
                    alt="product photo"
                    title="product photo"
                    className="img-cover"
                  />
                  <div className="inf-box w-100">
                    <div className="fs-7 fw-semibold">
                      <Link to={`/nguoi-dung/${follow.id}`}>{follow.name}</Link>
                    </div>
                    <div className="d-flex align-items-center">
                      <div>
                        <small>Điểm:</small>
                        <span className="fs-7 price-clr fw-semibold ml-1">
                          {follow.points}
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
              );
            })}
          </div>
        )}
      </div>
     <SidebarMarket data={data} />
      <SidebarWeather
        listWeathers={listWeathers}
        current_user={current_user}
        isLoading={isLoading}
      />
      {/*
        <LuckyWheel
          startDate={startDate}
          endDate={endDate}
          statusWheel={statusWheel}
          today={today}
          current_user={current_user}
        />
          */}
    </div>
  );
}

export default React.memo(LeftSidebar);
