import translationVN from 'i18n/locales/vi.json';
import translationEN from 'i18n/locales/en.json';
import i18n from "i18next";
import LanguageDetector from "i18next-browser-languagedetector";
import { initReactI18next } from "react-i18next";

i18n
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    resources: {
      vi: {
        translation: translationVN
      },
      en: {
        translation: translationEN
      }
    },
    lng: "en",
    fallbackLng: "en",
    debug: false,
  });

export default i18n;
