import React from "react";
import { Line } from "react-chartjs-2";
import "chartjs-adapter-date-fns";
import moment from "moment/moment";
import _ from "lodash";
import Chart from "chart.js/auto";

function LineChart(props) {
  const { market_place, data, hasFiltered, dataEmpty } = props;
  const sevenDayOfData = hasFiltered && !dataEmpty ? data:  data.slice(0, 7);
  const sortedData = _.sortBy(sevenDayOfData, ["created_at"]);
  const dataChart = {
    labels: sortedData.map((data) => moment(data.created_at).format("DD/MM")),
    datasets: [
      {
        label: "Giá " + (market_place && market_place.title),
        backgroundColor: "rgb(0, 154, 89)",
        borderColor: "rgb(0, 154, 89)",
        pointBackgroundColor: "rgb(245, 132, 2)",
        pointBorderColor: "rgb(245, 132, 2)",
        pointBorderWidth: "3",  
        pointHoverBorderWidth: "3",
        data: sortedData.map((data) => data.price),
      },
    ],
  };
  const options = {
    responsive: true,
    scales: {
      y: {
        ticks: {
          callback: (yValue) => {
            return Math.floor(yValue);
          },
        },
      },
    },
  };

  return <Line data={dataChart} options={options} />;
}

export default React.memo(LineChart);
