import React, { useState } from "react";
import moment from "moment/moment";
import NumberFormat from "components/number_format";

export default function History(props) {
  const { data, userReportPrice, market_place } = props;
  const [numItems, setNumItems] = useState(7);
  const history_data = data;
  const [loadMore, setLoadMore] = useState(false);
  const handleShowMore = () => {
    setNumItems(data.length);
    setLoadMore(true);
  };
  return (
    <div className="mkt-price-hist">
      <div className="d-flex align-items-center justify-content-between">
        <div>
          <span className="icon-chart"></span>
          <span className="txt">History</span>
        </div>
      </div>
      <div className="col-sm-12" style={{width: "100%", overflowX: "scroll"}}>
        <table className="table table-striped">
          <thead>
            <tr>
              <th scope="col" className="align-middle">
                Area
              </th>
              <th scope="col" className="align-middle">
               Price
              </th>
              <th scope="col" className="align-middle">
                Date
              </th>
              <th scope="col" className="align-middle">
                Author
              </th>
              <th scope="col" className="align-middle"></th>
            </tr>
          </thead>
          <tbody>
            {history_data.slice(0, numItems).map(function (market) {
              return (
                <tr key={market.id}>
                  <td className="align-middle">
                    {market_place && market_place?.province_name}
                  </td>
                  {!!market.min_price && !!market.max_price ? (
                    <td className="align-middle">
                      <span className="red-clr">
                        <NumberFormat>{market.price}</NumberFormat> $/
                        {market_place.unit}
                      </span>
                    </td>
                  ) : (
                    <td className="align-middle red-clr">
                      <NumberFormat>{market.price}</NumberFormat> $/
                      {market_place.unit}
                    </td>
                  )}
                  <td className="align-middle">
                    {moment(market.created_at).format("DD/MM/YYYY  h:mm A")}
                  </td>
                  <td className="align-middle" style={{ paddingLeft: "10px" }}>
                    {market.user_name ?? "Admin"}
                  </td>
                  <td className="align-middle" style={{ paddingLeft: "10px" }}>
                    <div
                      className="hover-underline d-inline-block"
                      onClick={() => userReportPrice(market.id)}
                    >
                      <span
                        className="icon-waning text-warning fs-5"
                        title="Report"
                      ></span>
                    </div>
                  </td>
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>
      {!loadMore && data.length > 7 && (
        <div>
          <div
            className="post-more background-clr-f5  mt-3 w-100  hover"
            onClick={handleShowMore}
          >
            <span className="txt">
              <u
                style={{
                  fontSize: "14px",
                  fontWeight: "500",
                  textTransform: "none",
                }}
              >
                More
              </u>
            </span>
            <span className="icon-arrow-solid"></span>
          </div>
        </div>
      )}
    </div>
  );
}
