import { call, put } from 'redux-saga/effects';
import {
  fetchProvinceSuccess,
  fetchProvinceFailed,
  fetchDistrictSuccess,
  fetchDistrictFailed
} from 'actions/location'

import {
  getProvinces,
  getDistricts
} from 'apis/location'
import {STATUS_CODE} from 'constants/index'


export function* fetchProvinces({ payload }) {
  try {
    const { params } = payload;
    const resp = yield call(getProvinces, params);
    const { data, status } = resp;
    if (status === STATUS_CODE.SUCCESS) {
      yield put(fetchProvinceSuccess(data));
    } else {
      yield put(fetchProvinceFailed(data));
    }
  } catch (error) {
  }
}

export function* fetchDistricts({ payload }) {
  try {
    const { params } = payload;
    const resp = yield call(getDistricts, params);
    const { data, status } = resp;
    if (status === STATUS_CODE.SUCCESS) {
      yield put(fetchDistrictSuccess(data));
    } else {
      yield put(fetchDistrictFailed(data));
    }
  } catch (error) {
  }
}
