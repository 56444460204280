import React from "react";

const ErrorList = ({ error }) => {
  const errorArray = error.error.split(",");
  return (
    <div>
      {error.title && <div className="mt-2">{error.title}</div>}
      {errorArray.map((errorItem, index) => (
        <li key={index} className="ml-2">
          {errorItem}
        </li>
      ))}
    </div>
  );
};
const ErrorsMessage = ({ errors }) => {
  if (!errors || errors.length === 0) {
    return null;
  }
  return (
    <div className="my-3 error-rendered">
      <div className="m-2">
        <h5>Lỗi: </h5>
        {errors.length > 0 && errors?.map((item, index) => <ErrorList key={index} error={item} />)}
      </div>
    </div>
  );
};

export default React.memo(ErrorsMessage);
