import * as locationConst from 'constants/location'
import { toastError } from 'helpers/toastHelper'

const initialState = {
  listProvinces: {
    data: [],
    error: null,
    isLoading: false
  },
  listDistricts: {
    data: [],
    error: null,
    isLoading: false
  }
}

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case locationConst.FETCH_PROVINCE: {
      return {
        ...state,
        listProvinces: {
          data: initialState.listProvinces.data,
          isLoading: true,
          error: null
        }

      };
    }
    case locationConst.FETCH_PROVINCE_SUCCESS: {
      const { data } = action.payload;
      return {
        ...state,
        listProvinces: {
          data: data.data,
          isLoading: false,
          error: null
        },
      };
    }
    case locationConst.FETCH_PROVINCE_FAILED: {
      const { error } = action.payload;
      toastError(error);
      return {
        ...state,
        listProvinces: {
          data: initialState.listProvinces.data,
          isLoading: false,
          error: error
        }

      };
    }
    case locationConst.FETCH_DISTRICT: {
      return {
        ...state,
        listDistricts: {
          data: initialState.listDistricts.data,
          isLoading: true,
          error: null
        }

      };
    }
    case locationConst.FETCH_DISTRICT_SUCCESS: {
      const { data } = action.payload;
      return {
        ...state,
        listDistricts: {
          data: data.data,
          isLoading: false,
          error: null
        },
      };
    }
    case locationConst.FETCH_DISTRICT_FAILED: {
      const { error } = action.payload;
      toastError(error);
      return {
        ...state,
        listDistricts: {
          data: initialState.listDistricts.data,
          isLoading: false,
          error: error
        }

      };
    }
    default:
      return state;
  }
}

export default reducer
