import * as homeConst from "constants/home";

const initialState = {
  search_result: {
    data: [],
    extra: {
      loading: false,
      error: null,
      status: 'close',
    },
  },
};

export const reducer = (state = initialState, action) => {
  switch (action.type) {
    case homeConst.TYPING_SEARCH: {
      return {
        ...state,
        search_result: {
          data: initialState.search_result.data,
          extra: {
            loading: true,
            error: null,
          },
        },
      };
    }

    case homeConst.SEARCH_WITH_KEYWORD: {
      return {
        ...state,
        search_result: {
          data: initialState.search_result.data,
          extra: {
            loading: true,
            error: null,
            status: 'show',
          }
        },
      };
    }
    case homeConst.SEARCH_WITH_KEYWORD_SUCCESS: {
      const { data } = action.payload;
      return {
        ...state,
        search_result: {
          data: data.data,
          extra: {
            loading: false,
            error: null,
            status: 'show'
          },
        },
      };
    }
    case homeConst.SEARCH_WITH_KEYWORD_FAILED: {
      const { error } = action.payload;
      return {
        ...state,
        search_result: {
          data: initialState.search_result.data,
          extra: {
            loading: false,
            error: error.data,
            status: 'show'
          },
        }
       
      };
    }
    case homeConst.UPDATE_STATUS_SEARCH: {
      const {status} = action.payload;
      return {
        ...state,
        search_result: {
          data: state.search_result.data,
          extra: {
            loading: false,
            status: status,
          }
        }
      }
    }
    
    case homeConst.RESET_DATA:{
      return {
        ...state,
        search_result: {
          data: [],
          extra: {
            loading: false,
            status: 'close',
          }
        }
      }
    }
    default:
      return { ...state };
  }
};

export default reducer;
