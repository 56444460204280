export const FETCH_LIST_EMPLOYEE = 'FETCH_LIST_EMPLOYEE';
export const FETCH_LIST_EMPLOYEE_SUCCESS = 'FETCH_LIST_EMPLOYEE_SUCCESS';
export const FETCH_LIST_EMPLOYEE_FAILED = 'FETCH_LIST_EMPLOYEE_FAILED';

export const UPDATE_EMPLOYEE = 'UPDATE_EMPLOYEE';
export const UPDATE_EMPLOYEE_SUCCESS = 'UPDATE_EMPLOYEE_SUCCESS';
export const UPDATE_EMPLOYEE_FAILED = 'UPDATE_EMPLOYEE_FAILED';

export const DELETE_EMPLOYEE = 'DELETE_EMPLOYEE';
export const DELETE_EMPLOYEE_SUCCESS = 'DELETE_EMPLOYEE_SUCCESS';
export const DELETE_EMPLOYEE_FAILED = 'DELETE_EMPLOYEE_FAILED';

export const FETCH_EMPLOYEE_DETAIL = 'FETCH_EMPLOYEE_DETAIL';
export const FETCH_EMPLOYEE_DETAIL_SUCCESS = 'FETCH_EMPLOYEE_DETAIL_SUCCESS';
export const FETCH_EMPLOYEE_DETAIL_FAILED = 'FETCH_EMPLOYEE_DETAIL_FAILED';

export const ADD_NEW_EMPLOYEE = 'ADD_NEW_EMPLOYEE';
export const ADD_NEW_EMPLOYEE_SUCCESS = 'ADD_NEW_EMPLOYEE_SUCCESS';
export const ADD_NEW_EMPLOYEE_FAILED = 'ADD_NEW_EMPLOYEE_FAILED';
