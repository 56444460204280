import React, { useEffect, useState, useContext, useRef } from "react";
import { getArticles } from "apis/article";
import { DOMAIN } from "constants";
import { Link } from "react-router-dom";
import _ from "lodash";
import AppContext from "components/app_context";
import { validURL } from "lib";
import moment from "moment";
import { useTranslation } from "react-i18next";
function ArticleOfCatalogue(props) {
  const { t } = useTranslation("translation");
  const isMounted = useRef(true);
  let { catalogue_ids } = props;
  let [articles, setArticles] = useState([]);
  const [numItems, setNumItems] = useState(5);
  const [loadMore, setLoadMore] = useState(false);
  const [dataLength, setDataLength] = useState(null);
  const handleClick = () => {
    setNumItems((numItems) => numItems + 4);
    setLoadMore(true);
  };
  const { keyword, hashtag } = useContext(AppContext);
  useEffect(() => {
    isMounted.current = true;
    getArticles({
      catalogue_id: catalogue_ids,
      article_type: "Article",
      keyword: keyword || hashtag.replace("#", ""),
      limit: 21,
    }).then((res) => {
      if (isMounted.current) {
        let result_article = res["data"]["data"];
        if (result_article) {
          setArticles(res.data.data);
          setDataLength(res.data.data.length);
        }
      }
    });
    return () => {
      isMounted.current = false;
    };
  }, [keyword, hashtag]);

  const renderFirstGroup = (article) => {
    let image = validURL(article.image)
      ? `${article.image}`
      : `${DOMAIN}${article.image}`;
    return (
      <Link to={`/tin-tuc/${article?.slug}`}>
        <h5 className="title-line-lt text-uppercase fw-bold">
          {article?.article_catalogue_name}
        </h5>
        {/* <img src={image} alt="" className="article-image-first" /> */}
        <div
          className="article-image-first  bg-img-orgnal article-bg position-relative"
          style={{
            backgroundImage: `url(${
              !article.image ? "/images/photos/no_image.png" : article.image
            })`,
            backgroundSize: "cover !important",
            width: "100%",
            paddingTop: "50%",
            borderRadius: "2px",
            backgroundPosition: "50%!important",
            marginBottom: "5px",
          }}
        ></div>
        <div className="main-news">
          <h5 className="fw-bold first-art-title ">{article.title}</h5>
          <div className="mb-3">
            <div className="d-inline-block">
              <span className="icon-time align-middle mr-1"></span>
              <small>{moment(article?.created_at).format("DD-MM-YYYY")}</small>
            </div>
            <div className="d-inline-block ml-3 ">
              <small>
                <span
                  className="icon-eye-line01 align-middle mr-1"
                  style={{ color: "#919191", fontSize: "13px" }}
                ></span>
                <small>{article?.viewed}</small>
              </small>
            </div>
            <div className="d-inline-block ml-3 ">
              <small>
                <span
                  className="icon-comment-line align-middle mr-1"
                  style={{ color: "#919191", fontSize: "13px" }}
                ></span>
                <small>{article?.total_comment | 0}</small>
              </small>
            </div>
          </div>
        </div>
      </Link>
    );
  };
  return (
    <>
      <div className="cntry-news mt-2">
        {articles.length > 0 && renderFirstGroup(_.first(articles))}
        <div className="row news-more ">
          {_.chunk(_.slice(articles, 1, numItems), 2).map(function (
            article,
            index
          ) {
            return (
              <div className="col-md-6 col-sm-12" key={index}>
                {article.map(function (value) {
                  let image = validURL(value.image)
                    ? `${value.image}`
                    : `${DOMAIN}${value.image}`;
                  return (
                    <Link
                      key={value.id}
                      to={`/tin-tuc/${value?.slug}`}
                      className="news-item d-flex align-items-center  sub-news-item px-1"
                    >
                      <div className="pt-show">
                        <div
                          className="bg-img"
                          style={{
                            backgroundImage: `url(${
                              !value.image
                                ? "/images/photos/no_image.png"
                                : value.image
                            })`,
                          }}
                        />
                      </div>
                      <div>
                        <div className="ctnt line-clamp-02 artile-title">
                          {value.title}
                        </div>
                        <div className="mb-1">
                          <div className="d-inline-block">
                            <span className="icon-time align-middle mr-1"></span>
                            <small>
                              {moment(value?.created_at).format("DD-MM-YYYY")}
                            </small>
                          </div>
                          <div className="d-inline-block ml-3">
                            <small>
                              <span
                                className="icon-eye-line01 align-middle mr-1"
                                style={{ color: "#919191", fontSize: "13px" }}
                              ></span>
                              <small>{value?.viewed}</small>
                            </small>
                          </div>
                          <div className="d-inline-block ml-3">
                            <small>
                              <span className="icon-comment-line align-middle mr-1"></span>
                              <small>{value?.total_comment | 0}</small>
                            </small>
                          </div>
                        </div>
                      </div>
                    </Link>
                  );
                })}
              </div>
            );
          })}
          {dataLength != numItems && dataLength > 6 && (
            <div
              onClick={() => handleClick()}
              className="product-more  hoverr border-0 w-100 mb-2 "
              style={{
                backgroundColor: "f5f5f5",
                borderRadius: "5px",
                marginBottom: "10px",
              }}
            >
              <span className="txt fw-500 hoverr">
                <u>{t("button.more")}</u>
              </span>
              <span
                className="icon-arrow-solid"
                style={{ fontSize: "5px", marginLeft: "4px", color: "grey" }}
              ></span>
            </div>
          )}
        </div>
      </div>
    </>
  );
}

export default ArticleOfCatalogue;
