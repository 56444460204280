import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import FadeIn from "react-fade-in";
import NumberFormat from "components/number_format";
import { getProducts } from "apis/product";
import { imageUrl } from "lib/index";

function Product(props) {
  const [product, setProduct] = useState([]);
  useEffect(() => {
    getProducts({ catalogue_ids: props.catalogue_ids, page: 1, limit: 8 }).then((res) => {
      let result_article = res["data"]["data"];
      if (result_article) {
        setProduct(result_article);
      }
    });
  }, []);

  return (
    <>
      {product.length > 0 && (
        <div className="mt-1">
          <h5 className="title-line-lt text-uppercase fw-bold">
            <Link to={`/san-pham/danh-muc/${props.catalogue_ids}`}>
              <span className="d-block">{props.catalogue_name}</span>
            </Link>
          </h5>

          <FadeIn className="row prod-list" childClassName="col-md-3 col-sm-6 col-xs-12">
            {product.map(function (pro, index) {
              let product_image =
                pro.images[0] !== undefined ? imageUrl(pro.images[0]?.name) : "/images/product-image-default.png";
              return (
                <div className="item-lst hover" key={index}>
                  <Link to={`/san-pham/${pro?.slug}`}>
                    <div className="bg-img-orgnal" style={{ background: `url(${product_image})` }} />
                    <div className="prod-inf">
                      <div className="txt-name">{pro?.title}</div>
                      {pro?.shop?.province_name ? (
                        <div className="locatn">
                          <span className="icon-location01" />
                          <span className="txt-city">{pro?.shop?.province_name}</span>
                        </div>
                      ) : (
                        <div className="locatn">
                          <span className="icon-location01" />
                          <span className="txt-city"> Unknown</span>
                        </div>
                      )}
                      <div className="price-item">
                        <div className="price01">
                          <span className="d-block text-muted">Retail price:</span>
                          {pro.retail_price > 0 ? (
                            <span className="price-clr">
                              &nbsp;
                              <NumberFormat>{pro?.retail_price}</NumberFormat> $/{pro.unit_name}
                            </span>
                          ) : (
                            <span className="price-clr">Contact</span>
                          )}
                        </div>
                      </div>
                    </div>
                  </Link>
                </div>
              );
            })}
          </FadeIn>
        </div>
      )}
    </>
  );
}

export default Product;
