import { useDispatch, useSelector } from "react-redux";
import {showDeepLink} from "actions/auth";

function DeepLink (){
  const isShowMenuDownLoad = useSelector(state => state.login.show_menu);
  let {show} = isShowMenuDownLoad;
  const dispatch = useDispatch();
  const handleShowDeepLink = () => {
    dispatch(showDeepLink());
  }
  
  return(
   <>
    {show && <div className="d-mobile w-100 sticky-top">
      <div className="mrgb-10 go-to-app align-items-center d-flex position-relative">
        <img src="/images/app-icon.png" alt="app download" title="app download" className="app-icon" />
        <div>
          <p className="mrgb-0 fw-bold">Diễn đàn nông nghiệp</p>
          <p className="mrgb-0 grey-clr">Đăng ký trên app ngay</p>
          <p className="mrgb-0 fw-bold second-clr">Sử dụng nhiều tính năng</p>
        </div>
          <span className="icon-close d-block position-absolute" onClick={() => handleShowDeepLink()}></span>
          <a href="https://cho2nong.page.link/social" className="ms-auto btn-open">Mở App</a>
        </div>
      </div>
    }
   </>
  )
}
export default DeepLink;