import React, { useEffect } from 'react'
import { useLocation, useNavigate } from "react-router-dom"
import { bindActionCreators } from 'redux'
import { connect, useDispatch } from 'react-redux'
import * as authActions from 'actions/auth'
import AuthPlaceholder from "./placeholder/auth"
import FadeIn from "react-fade-in"
import { redirectLogin } from "lib"
import { useSelector } from 'react-redux'
import {setParamsTrackActivity} from 'lib';
import {createTrackActivity} from 'actions/track_activity';

function Auth(props) {
  let { authActionsCreator } = props;
  let { fetchLogin } = authActionsCreator;
  let search = useLocation().search;
  let access_token = new URLSearchParams(search).get('access_token');
  let redirect_url = useSelector(state=> state.login.current_url.path)
  let {isLogin } = props.user_data;
  let navigate = useNavigate();
  const dispatch = useDispatch();

  let chat_session = localStorage.getItem("chat_session") || "{}";
  let sessionId = JSON.parse(chat_session)?.session_id;

  function defaultChat() {
    window.WebChat.default(
      {
        customData: { language: "vi" },
        socketUrl: "https://core.chatbot.hainong.vn",
        initPayload: "/start_conversation",
        title: "Trợ lý Hai Nông",
        inputTextFieldHint: "Nội dung",
        mainColor: "#009a59",
        userBackgroundColor: "#009a59",
        closeImage: "https://chatbot.hainong.vn/chatbot_1.png",
        openLauncherImage: "https://chatbot.hainong.vn/chatbot_1.png",
        speechToTextUrl: "https://dev.panel.hainong.vn/api/v2/speech_to_texts",
        textToSpeechUrl: "https://dev.panel.hainong.vn/api/v2/text_to_speeds",
        sessionConfirmCallback: setupSlots(sessionId),
        onWidgetEvent: {
          onChatOpen: () => {console.log("Tao mở chat")},
          onChatClose: () => {console.log("Tao đóng đó nha")}
        }
      },
      null
    );
  }

  function setupSlots(sessionId) {
    let user_storage = localStorage.getItem("user_storage") || null;
    let user_data = JSON.parse(user_storage).user_data
    let user_token = access_token
    let user_name = user_data?.current_user?.name;
    if (!user_token) {
      return;
    }

    const xhr = new XMLHttpRequest();
    var url = `https://core.chatbot.hainong.vn/conversations/${sessionId}/tracker/events`
    var data = [
      {
        "event": "slot",
        "name": "user_token",
        "value": user_token
      },
      {
        "event": "slot",
        "name": "user_name",
        "value": user_name
      }
    ]
    xhr.open('POST', url);
    xhr.setRequestHeader('Content-Type', 'application/json');
    const jsonData = JSON.stringify(data);

    xhr.onload = function () {
      if (xhr.status === 200) {
        console.info("Success");
      } else {
        console.info("False");
        console.error('Error:', xhr.statusText);
      }
    };

    xhr.send(jsonData);
  }

  useEffect(() => {
    if(access_token === null){
      redirectLogin();
    }else{
      // resetChat();
      fetchLogin(access_token);
      dispatch(createTrackActivity(setParamsTrackActivity("User login", "POST", {}, "user_login")));
    }
  }, [fetchLogin, access_token]);

  useEffect(() => {
    if(!isLogin){
      setTimeout(() => {
        window.location.reload();
      }, 1000);
      navigate(redirect_url);
    }
  }, [isLogin]);

  return (
    <section className="vh-100">
      <div className="container py-5 h-100">
        <FadeIn>
          <AuthPlaceholder />
        </FadeIn>
      </div>
    </section>
  );
}


const mapStateToProps = state => {
  return {
    user_data: state.login.user_data
  };
};

const mapDispatchToProps = dispatch => ({
  authActionsCreator: bindActionCreators(authActions, dispatch)
});

export default connect(mapStateToProps, mapDispatchToProps)(Auth);
