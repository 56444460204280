import *  as homeConst from 'constants/home';

export const typingInputSearch = (params) => {
  return {
    type: homeConst.TYPING_SEARCH,
    payload: {params}
  }
}

export const searchHome = (keyword) => {
  return {
    type: homeConst.SEARCH_WITH_KEYWORD,
    payload: {keyword}
  }
}

export const searchHomeSuccess = (data) => {
  return {
    type: homeConst.SEARCH_WITH_KEYWORD_SUCCESS,
    payload: {data}
  }
}

export const searchHomeFailed = (error) => {
  return {
    type: homeConst.SEARCH_WITH_KEYWORD_FAILED,
    payload: {error}
  }
}

export const updateStatusSearch = (status) => {
  return {
    type: homeConst.UPDATE_STATUS_SEARCH,
    payload: {status}
  }
}

export const resetData = () => {
  return {
    type: homeConst.RESET_DATA
  }
}

