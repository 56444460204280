import React, { useEffect } from "react";
import AddMetaTags from "components/add_meta_tags";
import LeftSidebar from "components/left_sidebar";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate, useParams } from "react-router-dom";
import { fetchBusinessDetail } from "actions/business";
import { toastError } from "helpers/toastHelper";

function BusinessAssociationDetail() {
  const dispatch = useDispatch();
  const { businessId } = useParams();
  const { data, isLoading } = useSelector((state) => state.business.business_detail);
  const user = useSelector((state) => state.login.user_data.current_user);
  const navigate = useNavigate();

  useEffect(() => {
    if (user) {
      dispatch(fetchBusinessDetail(businessId));
    } else {
      navigate("/");
      toastError("Please login first!");
    }
  }, [user, businessId]);

  return (
    <>
      <AddMetaTags title={"Danh sách chức năng liên kết doanh nghiệp"} />
      <div className="list-action-business">
        <div className="row">
          <LeftSidebar />
          <div className="col-md-9 col-sm-12">
            <div className="container">
              <div className="row">
                <div className="d-flex justify-content-between">
                  <h5 className="title-line-lt text-uppercase fw-bold">
                    <Link to={`/lien-ket-danh-nghiep/chuc-nang/${businessId}`}>
                      <span className="d-block">Thông tin doanh nghiệp</span>
                    </Link>
                  </h5>
                </div>
              </div>
              <div className="row p-3">
                <div
                  className="article-image-first  bg-img-orgnal article-bg position-relative"
                  style={{
                    backgroundImage: `url(${data?.image})`,
                    backgroundSize: "cover !important",
                    width: "100%",
                    paddingTop: "50%",
                    borderRadius: "2px",
                    backgroundPosition: "50%!important",
                  }}></div>
              </div>
              <div className="row mt-2">
                <h3 className="fw-bold">{data?.name}</h3>
              </div>
              <div className="row d-flex align-items-center">
                <div className="fw-bold d-flex align-items-center">
                  Địa chỉ:{" "}
                  <span className="btn">
                    {data?.address}, {data?.district_name}, {data?.province_name},
                  </span>
                </div>
              </div>
              <div className="row d-flex">
                <div className="fw-bold  d-flex align-items-center">
                  Email: <span className="btn ">{data?.email}</span>
                </div>
              </div>
              <div className="row d-flex">
                <div className="fw-bold  d-flex align-items-center">
                  Điện thoại: <span className="btn">{data?.phone}</span>
                </div>
              </div>

              <div className="row d-flex">
                <div className="fw-bold  d-flex align-items-center">
                  Website:{" "}
                  <a href={data?.website} className="btn btn-link" target="_blank">
                    <span style={{ color: "#3b71ca" }}>{data?.website}</span>
                  </a>
                </div>
              </div>
              <div className="row bussiness-content">
                <div className="fw-bold">Mô tả: </div>
                <div dangerouslySetInnerHTML={{ __html: data?.content }}></div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default BusinessAssociationDetail;
