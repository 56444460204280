import React from "react";
import ContentLoader from "react-content-loader";
import * as _ from 'lodash';

function SidebarArticlesPlaceholder(props) {
  return(
    <div className="loader">
      {_.range(5).map(function (post, index) {
        return(
          <div key={index} className="row">
            <div className="col-6-77">
              <ContentLoader
                speed={3}
                backgroundColor="#cfcfcf"
                foregroundColor="#e7dede"
                width="100%"
                height="130"
              >
                <rect x="10" y="10" width="100%" height="130px" />
              </ContentLoader>
            </div>
          </div>
        )
      })}
    </div>
  )
};

export default SidebarArticlesPlaceholder;
