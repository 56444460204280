import React from "react";
import { toastSuccess } from "helpers/toastHelper";
export default function CopyUrl({ url }) {
  const copyToClipboard = () => {
    navigator.clipboard
      .writeText(url)
      .then(() => {
        toastSuccess("Sao chép thành công");
      })
      .catch((error) => {});
  };

  return (
    <a role="button" onClick={copyToClipboard}>
      <img
        src="/images/link (3).png"
        alt="2Nong-share"
        style={{
          height: "32",
          width: "32",
          cursor: "pointer",
          backgroundColor: "#009858",
          borderRadius: "3px",
        }}
      />
      <span className="hover ml-1" style={{ fontSize: "16px", color: "#000" }}>
        Copy link
      </span>
    </a>
  );
}
