import React, { useEffect, useContext, useCallback } from "react";
import { bindActionCreators } from "redux";
import { connect, useDispatch } from "react-redux";
import * as articleActions from "actions/article";
import ListArticles from "./components/listArticle";
import LeftSidebar from "components/left_sidebar";
import RightSidebar from "components/right_sidebar";
import AddMetaTags from "components/add_meta_tags";
import AppContext from "components/app_context";
import ScrollToTop from "components/ScrollToTop";
import _ from "lodash";
import {setParamsTrackActivity} from 'lib';
import {createTrackActivity} from 'actions/track_activity';

function Articles(props) {
  let { articleActionsCreator } = props;
  let { fetchArticle } = articleActionsCreator;
  let { data, isLoading } = props.listArticles;
  let { data: objectHashtags } = props.listObjectHashTags;
  const { keyword, setIsLoadingNav, hashtag } = useContext(AppContext);
  const dispatch = useDispatch();

  const searchArticle = useCallback(
    _.debounce(
      (keyword) =>
        fetchArticle({
          keyword: keyword,
          article_type: "Article",
          hash_tag: keyword,
        }),
      3000
    ),
    // setIsLoadingNav(isLoading)
  );

  useEffect(() => {
    if (keyword !== "") {
      searchArticle(keyword);
    } else {
      fetchArticle({
        keyword: keyword || hashtag.replace("#", ""),
        article_type: "Article",
        limit: 33
      });
    }
  }, [fetchArticle, keyword, hashtag]);

  useEffect(() => {
    dispatch(createTrackActivity(setParamsTrackActivity("List articles", "GET", {}, "articles")));
  },[])

  return (
    <ScrollToTop>
      <div className="articles">
        <AddMetaTags title={"Danh sách tin tức"} />
        <div className="row">
          <LeftSidebar />
          <ListArticles
            data={data}
            isLoading={isLoading}
            objectHashtags={objectHashtags}
          />
          <RightSidebar />
        </div>
      </div>
    </ScrollToTop>
  );
}

const mapStateToProps = (state) => {
  return {
    listArticles: state.article.listArticles,
    listObjectHashTags: state.article.object_hashtags,
  };
};

const mapDispatchToProps = (dispatch, ownProps) => {
  return {
    articleActionsCreator: bindActionCreators(articleActions, dispatch),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Articles);
