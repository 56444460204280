import * as albumsConst from 'constants/albums';

export const fetchUserAlbums = (params) => {
  return {
    type: albumsConst.FETCH_USER_ALBUMS,
    payload: {
      params
    }
  }
}

export const fetchUserAlbumsSuccess = (data) => {
  return {
    type: albumsConst.FETCH_USER_ALBUMS_SUCCESS,
    payload: {data}
  }
}

export const fetchUserAlbumsFailed = (error) => {
  return {
    type: albumsConst.FETCH_USER_ALBUMS_FAILED,
    payload: {error}
  }
}

export const fetchUserAlbumsId = (params) => {
  return {
    type: albumsConst.FETCH_USER_ALBUMS_ID,
    payload: {
      params
    }
  }
}

export const fetchUserAlbumsIdSuccess= (data) => {
  return {
    type: albumsConst.FETCH_USER_ALBUMS_ID_SUCCESS,
    payload: {
      data
    }
  }
}

export const fetchUserAlbumsIdFailed = (error) => {
  return {
    type: albumsConst.FETCH_USER_ALBUMS_ID_FAILED,
    payload: {
      error
    }
  }
}

export const fetchListImageAlbums = (params) => {
  return {
    type: albumsConst.FETCH_LIST_IMAGE_ALBUMS,
    payload: {params}
  }
}

export const fetchListImageAlbumsSuccess = (data) => {
  return {
    type: albumsConst.FETCH_LIST_IMAGE_ALBUMS_SUCCESS,
    payload: {data}
  }
}


export const fetchListImageAlbumsFailed = (error) => {
  return {
    type: albumsConst.FETCH_LIST_IMAGE_ALBUMS_FAILED,
    payload: {error}
  }
}

export const uploadImageToAlbums = (params, albumsId) => {
  return {
    type: albumsConst.UPLOAD_IMAGE_TO_ALBUMS,
    payload: {params, albumsId}
  }
}

export const uploadImageToAlbumsSuccess = (data) => {
  return {
    type: albumsConst.UPLOAD_IMAGE_TO_ALBUMS_SUCCESS,
    payload: {data}
  }
}

export const uploadImageToAlbumsFailed = (error) => {
  return {
    type: albumsConst.UPLOAD_IMAGE_TO_ALBUMS_FAILED,
    payload: {error}
  }
}

export const deleteImageAlbums = (albumsId, imageId) => {
  return {
    type: albumsConst.DELETE_IMAGE_ALBUMS,
    payload: { albumsId, imageId}
  }
}

export const deleteImageAlbumsSuccess = (data) => {
  return {
    type: albumsConst.DELETE_IMAGE_ALBUMS_SUCCESS,
    payload: {
      data
    }
  }
}

export const deleteImageAlbumsFailed = (error) => {
  return {
    type: albumsConst.DELETE_IMAGE_ALBUMS_FAILED,
    payload: {
      error
    }
  }
}

export const createAnAlbum = (params) => {
  return {
    type: albumsConst.CREATE_AN_ALBUM,
    payload: {
      params
    }
  }
}

export const createAnAlbumSuccess = (data) => {
  return {
    type: albumsConst.CREATE_AN_ALBUM_SUCCESS,
    payload: {  
      data
    }
  }
}

export const createAnAlbumFailed = (error) => {
  return {
    type: albumsConst.CREATE_AN_ALBUM_FAILED,
    payload: {
      error
    }
  }
}

export const moveImageToOtherAlbums = (albumsId ,imageId, otherAlbumsId) => {
  return {
    type: albumsConst.MOVE_IMAGE_TO_OTHER_ALBUM,
    payload: {
      albumsId ,imageId, otherAlbumsId
    }
  }
}

export const moveImageToOtherAlbumsSuccess = (data) => {
  return {
    type: albumsConst.MOVE_IMAGE_TO_OTHER_ALBUM_SUCCESS,
    payload: {
      data
    }
  }
}

export const moveImageToOtherAlbumsFailed = (error) => {
  return {
    type: albumsConst.MOVE_IMAGE_TO_OTHER_ALBUM_FAILED,
    payload: {
      error
    }
  }
}

export const editUserAlbums = (params) => {
  return {
    type: albumsConst.EDIT_USER_ALBUMS,
    payload: {
      params
    }
  }
}

export const editUserAlbumsSuccess = (data) => {
  return {
    type: albumsConst.EDIT_USER_ALBUMS_SUCCESS,
    payload: {
      data
    }
  }
}

export const editUserAlbumsFailed = (data) => {
  return {
    type: albumsConst.EDIT_USER_ALBUMS_FAILED,
    payload: {
      data
    }
  }
}

export const destroyUserAlbums = (params) => {
  return {
    type: albumsConst.DELETE_USER_ALBUMS,
    payload: {
      params
    }
  }
}

export const destroyUserAlbumsSuccess = (data) => {
  return {
    type: albumsConst.DELETE_USER_ALBUMS_SUCCESS,
    payload: {
      data
    }
  }
}

export const destroyUserAlbumsFailed = (error) => {
  return {
    type: albumsConst.DELETE_USER_ALBUMS_FAILED,
    payload: {
      error
    }
  }
}
