import React, { useEffect, useState } from "react";
import AddMetaTags from "components/add_meta_tags";
import LeftSidebar from "components/left_sidebar";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import * as managerActions from "actions/manager";
import moment from "moment/moment";
import DataTable from "react-data-table-component";
import { Link } from "react-router-dom";

function ManagerComment(props) {
  let { managerFetchCommentWarnings, managerDeleteWarning } = props.managerActionsCreator;
  let { data, isLoading, total_warning } = props.listWarningComment;
  let [type, setType] = useState("Post");
  let [warningType, setWarningType] = useState("comment");
  let [perPage, setPerPage] = useState(20);

  useEffect(() => {
    managerFetchCommentWarnings({
      report_type: warningType,
      page: 1,
      commentable_type: type,
      limit: perPage,
    });
  }, []);

  const handleFetchWarningsComment = (type) => {
    managerFetchCommentWarnings({
      report_type: warningType,
      page: 1,
      commentable_type: type,
      limit: perPage,
    });
  };

  const handlePageChange = (page, type) => {
    managerFetchCommentWarnings({
      report_type: warningType,
      page: page,
      commentable_type: type,
      limit: perPage,
    });
  };

  const handlePerRowsChange = async (newPerPage, page, type) => {
    managerFetchCommentWarnings({
      report_type: warningType,
      page: page,
      commentable_type: "Post",
      limit: newPerPage,
    });
    setPerPage(newPerPage);
  };

  const fetchWarningPost = () => {
    managerFetchCommentWarnings({
      report_type: "post",
      page: 1,
      commentable_type: "Post",
      limit: perPage,
    });
  };

  const handleDeleteWarning = (id, classable_type) => {
    managerDeleteWarning({ id: id, classable_type: classable_type });
  };

  const mock = [];

  function detectLinkToWarningsComment(id) {
    if (type === "CommentPost") {
      return `/p/${id}`;
    } else if (type === "CommentArticle") {
      return `/tin-tuc/${id}`;
    } else if (type === "CommentVideo") {
      return `/videos/${id}`;
    } else if (type === "Post") {
      return `/p/${id}`;
    } else {
      return `/san-pham/${id}`;
    }
  }

  function checkData(data) {
    if (data.post) {
      return data.post_id;
    } else {
      return data.warningable?.commentable_id;
    }
  }
  data &&
    data.map((el) => {
      let data = {
        comment_id: checkData(el),
        post_id: el.post ? el.post.id : el.classable_id,
        warning_id: el.id,
        name: el.user?.name,
        reason: el.reason,
        content: el.warningable?.content ? el.warningable?.content : el.post?.title,
        data: moment(el.created_at).format("DD/MM/YYYY  H:M"),
        classable_type: el.classable_type,
      };
      mock.push(data);
    });

  const columns = [
    {
      name: "NGƯỜI BÁO",
      selector: (row) => row.name,
      sortable: true,
    },
    {
      name: "LÝ DO",
      selector: (row) => row.reason,
      sortable: true,
    },
    {
      name: "NỘI DUNG",
      cell: (row) => (
        <Link to={detectLinkToWarningsComment(row.comment_id)} className="waring-comment-post">
          {row.content}
        </Link>
      ),
      sortable: true,
    },
    {
      name: "NGÀY GỬI",
      selector: (row) => row.data,
      sortable: true,
    },
    {
      cell: (row) => (
        <button
          className="btn btn-danger btn-sm"
          onClick={() => handleDeleteWarning(row.warning_id, row.classable_type)}>
          Xóa vi phạm
        </button>
      ),
    },
  ];
  const paginationComponentOptions = {
    rowsPerPageText: "Tổng",
    rangeSeparatorText: "của",
    selectAllRowsItem: true,
    selectAllRowsItemText: "Tất cả",
  };

  const customStyles = {
    headRow: {
      style: {
        fontSize: "18px",
      },
    },
    rows: {
      style: {
        fontSize: "15px",
      },
    },
  };

  return (
    <div className="manager-comment">
      <AddMetaTags title={"Quản lý bình luận vi phạm"} />
      <div className="row">
        <LeftSidebar />
        <div className="col-md-9 col-sm-12">
          <h5 className="title-line-lt text-uppercase fw-bold">
            <span className="d-block">Quản lý vi phạm</span>
          </h5>
          <div className="cont-center saved-items">
            <ul className="nav nav-tabs justify-content-evenly" id="myTab" role="tablist">
              <li className="nav-item" role="presentation">
                <button
                  className="nav-link active"
                  id="news-tab"
                  data-bs-toggle="tab"
                  data-bs-target="#news-saved"
                  type="button"
                  role="tab"
                  aria-controls="news-saved"
                  aria-selected="true"
                  onClick={() => {
                    setWarningType("comment");
                    handleFetchWarningsComment("Post");
                    setType("CommentPost");
                  }}>
                  Bình luận bài viết
                </button>
              </li>
              <li className="nav-item" role="presentation">
                <button
                  className="nav-link"
                  id="prod-tab"
                  data-bs-toggle="tab"
                  data-bs-target="#prod-saved"
                  type="button"
                  role="tab"
                  aria-controls="prod-saved"
                  aria-selected="false"
                  onClick={() => {
                    setWarningType("comment");
                    setType("CommentArticle");
                    handleFetchWarningsComment("Article");
                  }}>
                  Bình luận tin nông nghiệp
                </button>
              </li>
              <li className="nav-item" role="presentation">
                <button
                  className="nav-link"
                  id="video-tab"
                  data-bs-toggle="tab"
                  data-bs-target="#video-saved"
                  type="button"
                  role="tab"
                  aria-controls="video-saved"
                  aria-selected="false"
                  onClick={() => {
                    setWarningType("comment");
                    setType("CommentVideo");
                    handleFetchWarningsComment("Video");
                  }}>
                  Bình luận tin video
                </button>
              </li>
              <li className="nav-item" role="presentation">
                <button
                  className="nav-link"
                  id="product-tab"
                  data-bs-toggle="tab"
                  data-bs-target="#product-saved"
                  type="button"
                  role="tab"
                  aria-controls="product-saved"
                  aria-selected="false"
                  onClick={() => {
                    setWarningType("comment");
                    setType("CommentProduct");
                    handleFetchWarningsComment("Product");
                  }}>
                  Bình luận sản phẩm
                </button>
              </li>
              <li className="nav-item" role="presentation">
                <button
                  className="nav-link"
                  id="post-tab"
                  data-bs-toggle="tab"
                  data-bs-target="#post-saved"
                  type="button"
                  role="tab"
                  aria-controls="post-saved"
                  aria-selected="false"
                  onClick={() => {
                    setType("Post");
                    fetchWarningPost();
                    // setWarningType('post')
                  }}>
                  Bài viết
                </button>
              </li>
            </ul>
            <div className="tab-content mt-3" id="myTabContent">
              <div className="tab-pane fade show active" id="news-saved" role="tabpanel" aria-labelledby="news-tab">
                {mock.length > 0 && (
                  <DataTable
                    columns={columns}
                    data={mock && mock}
                    pagination
                    progressPending={isLoading}
                    paginationComponentOptions={paginationComponentOptions}
                    onChangeRowsPerPage={handlePerRowsChange}
                    paginationServer
                    onChangePage={handlePageChange}
                    customStyles={customStyles}
                    paginationPerPage={20}
                    paginationTotalRows={total_warning && total_warning}
                  />
                )}
              </div>
              <div className="tab-pane fade" id="prod-saved" role="tabpanel" aria-labelledby="prod-tab">
                {mock.length > 0 && (
                  <DataTable
                    columns={columns}
                    data={mock && mock}
                    pagination
                    paginationComponentOptions={paginationComponentOptions}
                    customStyles={customStyles}
                    paginationRowsPerPageOptions={[20, 40, 60, 100]}
                    paginationTotalRows={total_warning}
                  />
                )}
              </div>
              <div className="tab-pane fade" id="video-saved" role="tabpanel" aria-labelledby="video-tab">
                {mock.length > 0 && (
                  <DataTable
                    columns={columns}
                    data={mock && mock}
                    pagination
                    paginationComponentOptions={paginationComponentOptions}
                    customStyles={customStyles}
                    paginationRowsPerPageOptions={[20, 40, 60, 100]}
                    paginationTotalRows={total_warning}
                  />
                )}
              </div>
              <div className="tab-pane fade" id="product-saved" role="tabpanel" aria-labelledby="product-tab">
                {mock.length > 0 && (
                  <DataTable
                    columns={columns}
                    data={mock && mock}
                    pagination
                    paginationComponentOptions={paginationComponentOptions}
                    customStyles={customStyles}
                    paginationRowsPerPageOptions={[20, 40, 60, 100]}
                    paginationTotalRows={total_warning}
                  />
                )}
              </div>
              <div className="tab-pane fade" id="post-saved" role="tabpanel" aria-labelledby="post-tab">
                {mock.length > 0 && (
                  <DataTable
                    columns={columns}
                    data={mock && mock}
                    pagination
                    paginationComponentOptions={paginationComponentOptions}
                    customStyles={customStyles}
                    paginationRowsPerPageOptions={[20, 40, 60, 100]}
                    paginationTotalRows={total_warning}
                  />
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
const mapStateToProps = (state) => {
  return {
    listWarningComment: state.manager.warning_comments,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    managerActionsCreator: bindActionCreators(managerActions, dispatch),
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(ManagerComment);
