import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { fetchTraceabilityDetail } from "redux/actions/traceability";
import AddMetaTags from "components/add_meta_tags";
import LeftSidebar from "components/left_sidebar";
import InfoDetails from "./infoDetails";
import MaterialDetails from "./materialDetails";
import ProducerDetails from "./producerDetails";
import { useNavigate, useLocation } from "react-router-dom";
function DetailsTraceability(props) {
  const dispatch = useDispatch();
  const traceabilityItem = useSelector((state) => state.traceability.traceabilityItem);
  let { data } = traceabilityItem;
  let location = useLocation();
  let traceabilityId = location.pathname.split("/")[3];

  useEffect(() => {
    dispatch(fetchTraceabilityDetail(traceabilityId));
  }, []);

  const navigate = useNavigate();

  function handleClick() {
    navigate(-1);
  }
  return (
    <>
      <AddMetaTags title={"Truy xuất nguồn gốc"} />
      <div className="list_traceability">
        <div className="row">
          <LeftSidebar />
          <div className="col-md-9 col-sm-12">
            <div className="container traceability-home">
              <div className="">
                <h5>
                  <button
                    className="border-0   bg-white text-dark"
                    onClick={handleClick}
                    type="button"
                    style={{ fontSize: "30px" }}
                  >
                    &lsaquo;
                  </button>
                  &nbsp; Chi tiết nguồn gốc
                </h5>
              </div>
              <InfoDetails data={[data]} />
              <MaterialDetails data={[data]} />
              <ProducerDetails data={[data]} />
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
export default DetailsTraceability;
