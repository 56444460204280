import React, { useState, useEffect, useRef } from "react";
import { exportExampleExcel } from "./ExampleExcelFile";
import { useDispatch } from "react-redux";
import { importBusinessProduct } from "actions/business";
import { toastError } from "helpers/toastHelper";
import Spinner from "react-bootstrap/Spinner";
const ImportDataGroup = ({ businessId, errors, isLoading }) => {
  const dispatch = useDispatch();
  const inputRef = useRef(null);
  const [excelFile, setExcelFile] = useState(null);
  const [isImporting, setIsImporting] = useState(false);
  const fieldData = {
    business_association_id: Number(businessId),
    file_import: excelFile,
  };
  const excel_type =
    "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet";

  function LoadingAnimate(isImporting) {
    return (
      <>
        {isImporting.loading === true && (
          <div className="mr-2">
            <Spinner animation="border" variant="success" />
          </div>
        )}
      </>
    );
  }

  useEffect(() => {
    if (isLoading === false) setIsImporting(false);
  }, [errors, isLoading]);
  const uploadExcelProduct = (e) => {
    const file = e.target.files[0];
    if (file?.type !== excel_type) {
      e.target.value = null;
      return toastError("Chỉ cho phép định dạng file .xlsx");
    } else {
      setExcelFile(file);
    }
  };

  const importExcelFile = () => {
    if (excelFile === null) {
      return toastError("Vui lòng chọn file");
    }
    setIsImporting(true);
    const formData = new FormData();
    for (const key in fieldData) {
      formData.append(key, fieldData[key]);
    }
    dispatch(importBusinessProduct(formData));
    inputRef.current.value = null;
  };

  return (
    <div className="my-3 d-flex justify-content-start align-items-center upload-file-grp ">
      <LoadingAnimate loading={isImporting} />
      <input
        className="input-w"
        ref={inputRef}
        type="file"
        accept=".xlsx, .xls"
        onChange={(e) => {
          uploadExcelProduct(e);
        }}
      />
      <div className="button-action">
        <button
          className="btn btn-import-grp btn-primary mx-2"
          disabled={isImporting ? true : false}
          onClick={() => importExcelFile()}
        >
          Tải lên
        </button>

        <button
          className="btn btn-import-grp btn-warning text-uppercase fw-bold text-light"
          onClick={() => {
            exportExampleExcel();
          }}
        >
          Tải file mẫu
        </button>
      </div>
    </div>
  );
};

export default React.memo(ImportDataGroup);
