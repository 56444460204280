import React from "react";
import Comment from "./Comment";
import "./NewFeedComments.scss";

const NewFeedComments = ({
  comments,
  parent,
  visibilityFormReply,
  visibilityFormEdit,
  setVisibilityFormReply,
  handleUserInteractionComment,
  setVisibilityFormEdit,
  currentUser
}) => {
  return (
    comments.length > 0 &&
    comments.map((comment) => {
      const owner = comment.user_id === currentUser?.id
      return (
        <Comment
          comment={comment}
          key={comment.id}
          parent={parent}
          handleUserInteractionComment={handleUserInteractionComment}
          setVisibilityFormReply={setVisibilityFormReply}
          visibilityFormReply={visibilityFormReply}
          setVisibilityFormEdit={setVisibilityFormEdit}
          visibilityFormEdit={visibilityFormEdit}
          owner={owner}
          currentUser={currentUser}
        />
      );
    })
  );
};

export default NewFeedComments;
