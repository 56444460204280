import React from "react";
import { Link } from "react-router-dom";
export default function SharingArea(props) {
  const {
    article,
    getArticleWithTags,
    favoriteArticles,
    favoriteArticles_id,
    onclickDeleteFavoriteArticle,
    onClickFavourite,
    children,
  } = props;
  return (
    <div className="sharing-box d-flex justify-content-between mt-3">
      <div className="list_tags">
        <span className="me-2">Tags:</span>
        {article?.tags?.map(function (articleTag, index) {
          return (
            <Link
              to={`${article.classable_type === "Article" ? "/tin-tuc" : "/videos"}`}
              key={index}
              onClick={() => getArticleWithTags(articleTag)}
            >
              <span className="tags">{articleTag}</span>
            </Link>
          );
        })}
      </div>

      <div className="d-flex list-socials">
        {article?.classable_type === "Video" ? (
          ""
        ) : favoriteArticles && favoriteArticles.find((article_1) => article?.id == article_1?.id) ? (
          <a role="button" onClick={() => onclickDeleteFavoriteArticle(favoriteArticles_id?.favourite_id)}>
            <span className="icon-tag" style={{ color: "#009A59" }} />
          </a>
        ) : (
          <a role="button" onClick={() => onClickFavourite(article?.id)}>
            <span className="icon-tag" />
          </a>
        )}
        {children}
      </div>
    </div>
  );
}
