import * as postConst from "constants/post";

export const fetchPost = (params) => {
  return {
    type: postConst.FETCH_POST,
    payload: {
      params,
    },
  };
};

export const fetchPostSuccess = (data) => {
  return {
    type: postConst.FETCH_POST_SUCCESS,
    payload: {
      data,
    },
  };
};

export const fetchPostFailed = (error) => {
  return {
    type: postConst.FETCH_POST_FAILED,
    payload: {
      error,
    },
  };
};

export const addPost = (params) => {
  return {
    type: postConst.ADD_POST,
    payload: {
      params,
    },
  };
};

export const addPostSuccess = (data) => {
  return {
    type: postConst.ADD_POST_SUCCESS,
    payload: {
      data,
    },
  };
};

export const addPostFailed = (error) => {
  return {
    type: postConst.ADD_POST_FAILED,
    payload: {
      error,
    },
  };
};

export const likePost = (params) => {
  return {
    type: postConst.LIKE_POST,
    payload: {
      params,
    },
  };
};

export const likePostSuccess = (data) => {
  return {
    type: postConst.LIKE_POST_SUCCESS,
    payload: {
      data,
    },
  };
};

export const likePostFailed = (error) => {
  return {
    type: postConst.LIKE_POST_FAILED,
    payload: {
      error,
    },
  };
};
export const likePostDetailPage = (params) => {
  return {
    type: postConst.LIKE_POST_DETAIL_PAGE,
    payload: {
      params,
    },
  };
};

export const likePostDetailPageSuccess = (data) => {
  return {
    type: postConst.LIKE_POST_DETAIL_PAGE_SUCCESS,
    payload: {
      data,
    },
  };
};

export const likePostDetailPageFailed = (error) => {
  return {
    type: postConst.LIKE_POST_DETAIL_PAGE_FAILED,
    payload: {
      error,
    },
  };
};

export const unlikePostDetailPage = (params) => {
  return {
    type: postConst.UNLIKE_POST_DETAIL_PAGE,
    payload: {
      params,
    },
  };
};

export const unlikePostDetailPageSuccess = (data) => {
  return {
    type: postConst.UNLIKE_POST_DETAIL_PAGE_SUCCESS,
    payload: {
      data,
    },
  };
};

export const unlikePostDetailPageFailed = (error) => {
  return {
    type: postConst.UNLIKE_POST_DETAIL_PAGE_FAILED,
    payload: {
      error,
    },
  };
};

export const likeComment = (params) => {
  return {
    type: postConst.LIKE_COMMENT,
    payload: {
      params,
    },
  };
};

export const likeCommentSuccess = (data) => {
  return {
    type: postConst.LIKE_COMMENT_SUCCESS,
    payload: {
      data,
    },
  };
};

export const likeCommentFailed = (error) => {
  return {
    type: postConst.LIKE_COMMENT_FAILED,
    payload: {
      error,
    },
  };
};

export const unlikeComment = (params) => {
  return {
    type: postConst.UNLIKE_COMMENT,
    payload: {
      params,
    },
  };
};

export const unlikeCommentSuccess = (data) => {
  return {
    type: postConst.UNLIKE_COMMENT_SUCCESS,
    payload: {
      data,
    },
  };
};

export const unlikeCommentFailed = (error) => {
  return {
    type: postConst.UNLIKE_COMMENT_FAILED,
    payload: {
      error,
    },
  };
};

export const unlikePost = (params) => {
  return {
    type: postConst.UNLIKE_POST,
    payload: {
      params,
    },
  };
};

export const unlikePostSuccess = (data) => {
  return {
    type: postConst.UNLIKE_POST_SUCCESS,
    payload: {
      data,
    },
  };
};

export const unlikePostFailed = (error) => {
  return {
    type: postConst.UNLIKE_POST_FAILED,
    payload: {
      error,
    },
  };
};

export const fetchLoadMorePost = (params) => {
  return {
    type: postConst.LOAD_MORE_POST,
    payload: {
      params,
    },
  };
};

export const fetchLoadMorePostSuccess = (data) => {
  return {
    type: postConst.LOAD_MORE_POST_SUCCESS,
    payload: {
      data,
    },
  };
};

export const fetchLoadMorePostFailed = (error) => {
  return {
    type: postConst.LOAD_MORE_POST_FAILED,
    payload: {
      error,
    },
  };
};

export const fetchHighlightPost = (params) => {
  return {
    type: postConst.FETCH_HIGHLIGHT_POST,
    payload: {
      params,
    },
  };
};

export const fetchHighlightPostSuccess = (data) => {
  return {
    type: postConst.FETCH_HIGHLIGHT_POST_SUCCESS,
    payload: {
      data,
    },
  };
};

export const fetchHighlightPostFailed = (error) => {
  return {
    type: postConst.FETCH_HIGHLIGHT_POST_FAILED,
    payload: {
      error,
    },
  };
};

export const fetchUserPost = (params) => {
  return {
    type: postConst.FETCH_USER_POST,
    payload: {
      params,
    },
  };
};

export const fetchUserPostSuccess = (data) => {
  return {
    type: postConst.FETCH_USER_POST_SUCCESS,
    payload: {
      data,
    },
  };
};

export const fetchUserPostFailed = (error) => {
  return {
    type: postConst.FETCH_USER_POST_FAILED,
    payload: {
      error,
    },
  };
};

export const fetchPostCatalogue = (params) => {
  return {
    type: postConst.FETCH_POST_CATALOGUE,
    payload: {
      params,
    },
  };
};

export const fetchPostCatalogueSuccess = (data) => {
  return {
    type: postConst.FETCH_POST_CATALOGUE_SUCCESS,
    payload: {
      data,
    },
  };
};

export const fetchPostCatalogueFailed = (error) => {
  return {
    type: postConst.FETCH_POST_CATALOGUE_FAILED,
    payload: {
      error,
    },
  };
};

export const fetchFavoritesPost = (params) => {
  return {
    type: postConst.FETCH_FAVORITES_POST,
    payload: {
      params,
    },
  };
};

export const fetchFavoritesPostSuccess = (data) => {
  return {
    type: postConst.FETCH_FAVORITES_POST_SUCCESS,
    payload: {
      data,
    },
  };
};

export const fetchFavoritesPostFailed = (error) => {
  return {
    type: postConst.FETCH_FAVORITES_POST_FAILED,
    payload: {
      error,
    },
  };
};

export const deletePost = (id) => {
  return {
    type: postConst.DELETE_POST,
    payload: {
      id,
    },
  };
};

export const deletePostSuccess = (id) => {
  return {
    type: postConst.DELETE_POST_SUCCESS,
    payload: {
      id,
    },
  };
};

export const deleteCommentPostSuccess = (id) => {
  return {
    type: postConst.DELETE_COMMENT_POST_SUCCESS,
    payload: {
      id,
    },
  };
};

export const deletePostFailed = (error) => {
  return {
    type: postConst.DELETE_POST_FAILED,
    payload: {
      error,
    },
  };
};

export const createCommentPost = (params) => {
  return {
    type: postConst.CREATE_COMMENT_POST,
    payload: {
      params,
    },
  };
};

export const createCommentPostSuccess = (data) => {
  return {
    type: postConst.CREATE_COMMENT_POST_SUCCESS,
    payload: {
      data,
    },
  };
};

export const deleteCommentPost = (id) => {
  return {
    type: postConst.DELETE_COMMENT_POST,
    payload: {
      id,
    },
  };
};

export const createCommentPostFailed = (error) => {
  return {
    type: postConst.CREATE_COMMENT_POST_FAILED,
    payload: {
      error,
    },
  };
};

export const replyCommentPost = (params) => {
  return {
    type: postConst.REPLY_COMMENT_POST,
    payload: {
      params,
    },
  };
};

export const replyCommentPostSuccess = (data) => {
  return {
    type: postConst.REPLY_COMMENT_POST_SUCCESS,
    payload: {
      data,
    },
  };
};

export const replyCommentPostFailed = (error) => {
  return {
    type: postConst.REPLY_COMMENT_POST_FAILED,
    payload: {
      error,
    },
  };
};

export const fetchCommentPost = (params) => {
  return {
    type: postConst.FETCH_COMMENT_POST,
    payload: {
      params,
    },
  };
};

export const fetchCommentPostSuccess = (data) => {
  return {
    type: postConst.FETCH_COMMENT_POST_SUCCESS,
    payload: {
      data,
    },
  };
};

export const fetchCommentPostFailed = (error) => {
  return {
    type: postConst.FETCH_COMMENT_POST_FAILED,
    payload: {
      error,
    },
  };
};

export const fetchLoadMoreComments = (params) => {
  return {
    type: postConst.LOAD_MORE_COMMENT_POST,
    payload: {
      params,
    },
  };
};

export const fetchLoadMoreCommentsSuccess = (data) => {
  return {
    type: postConst.LOAD_MORE_COMMENT_POST_SUCCESS,
    payload: {
      data,
    },
  };
};

export const fetchAnswersComments = (params) => {
  return {
    type: postConst.FETCH_COMMENT_ANSWERS,
    payload: {
      params,
    },
  };
};

export const fetchAnswersCommentsSuccess = (data) => {
  return {
    type: postConst.FETCH_COMMENT_ANSWERS_SUCCESS,
    payload: {
      data,
    },
  };
};

export const fetchAnswersCommentsFailed = (error) => {
  return {
    type: postConst.FETCH_COMMENT_ANSWERS_FAILED,
    payload: {
      error,
    },
  };
};

export const sharePost = (params) => {
  return {
    type: postConst.SHARE_POST,
    payload: {
      params,
    },
  };
};

export const sharePostSuccess = (data) => {
  return {
    type: postConst.SHARE_POST_SUCCESS,
    payload: {
      data,
    },
  };
};

export const sharePostFailed = (error) => {
  return {
    type: postConst.SHARE_POST_FAILED,
    payload: {
      error,
    },
  };
};

export const reportPost = (params) => {
  return {
    type: postConst.REPORT_POST,
    payload: {
      params,
    },
  };
};

export const reportPostSuccess = (data) => {
  return {
    type: postConst.REPORT_POST_SUCCESS,
    payload: {
      data,
    },
  };
};

export const reportPostFailed = (error) => {
  return {
    type: postConst.REPORT_POST_FAILED,
    payload: {
      error,
    },
  };
};

export const replacePost = (id) => {
  return {
    type: postConst.REPLACE_POST_ID,
    payload: {
      id,
    },
  };
};

export const replacePostSuccess = (data) => {
  return {
    type: postConst.REPLACE_POST_ID_SUCCESS,
    payload: {
      data,
    },
  };
};

export const replacePostFailed = (error) => {
  return {
    type: postConst.REPLACE_POST_ID_FAILED,
    payload: {
      error,
    },
  };
};

export const reportComment = (params) => {
  return {
    type: postConst.REPORT_COMMENT,
    payload: {
      params,
    },
  };
};

export const reportCommentSuccess = (data) => {
  return {
    type: postConst.REPORT_COMMENT_SUCCESS,
    payload: {
      data,
    },
  };
};

export const reportCommentFailed = (error) => {
  return {
    type: postConst.REPORT_COMMENT_FAILED,
    payload: {
      error,
    },
  };
};

export const updateComment = (params) => {
  return {
    type: postConst.UPDATE_COMMENT,
    payload: {
      params,
    },
  };
};

export const updateCommentSuccess = (data) => {
  return {
    type: postConst.UPDATE_COMMENT_SUCCESS,
    payload: {
      data,
    },
  };
};

export const updateCommentFailed = (error) => {
  return {
    type: postConst.UPDATE_COMMENT_FAILED,
    payload: {
      error,
    },
  };
};

export const updateAnswerComment = (params) => {
  return {
    type: postConst.UPDATE_ANSWER_COMMENT,
    payload: {
      params,
    },
  };
};

export const updateAnswerCommentSuccess = (data) => {
  return {
    type: postConst.UPDATE_ANSWER_COMMENT_SUCCESS,
    payload: {
      data,
    },
  };
};

export const updateAnswerCommentFailed = (error) => {
  return {
    type: postConst.UPDATE_ANSWER_COMMENT_FAILED,
    payload: {
      error,
    },
  };
};

export const userShareArticle = (params) => {
  return {
    type: postConst.USER_SHARE_ARTICLE,
    payload: {
      params,
    },
  };
};

export const userShareArticleSuccess = (data) => {
  return {
    type: postConst.USER_SHARE_ARTICLE_SUCCESS,
    payload: {
      data,
    },
  };
};

export const userShareArticleFailed = (error) => {
  return {
    type: postConst.USER_SHARE_ARTICLE_FAILED,
    payload: {
      error,
    },
  };
};

export const fetchPostDetail = (id) => {
  return {
    type: postConst.FETCH_DETAIL_POST,
    payload: {
      id,
    },
  };
};

export const fetchPostDetailSuccess = (data) => {
  return {
    type: postConst.FETCH_DETAIL_POST_SUCCESS,
    payload: {
      data,
    },
  };
};

export const fetchPostDetailFailed = (error) => {
  return {
    type: postConst.FETCH_DETAIL_POST_FAILED,
    payload: {
      error,
    },
  };
};

export const fetchPickTop = (params) => {
  return {
    type: postConst.FETCH_PICK_TOP,
    payload: {
      params,
    },
  };
};

export const fetchPickTopSuccess = (data) => {
  return {
    type: postConst.FETCH_PICK_TOP_SUCCESS,
    payload: {
      data,
    },
  };
};

export const reportImagePost = (params) => {
  return {
    type: postConst.REPORT_IMAGE_POST,
    payload: {
      params,
    },
  };
};

export const reportImagePostSuccess = (data) => {
  return {
    type: postConst.REPORT_IMAGE_POST_SUCCESS,
    payload: {
      data,
    },
  };
};

export const reportImagePostFailed = (error) => {
  return {
    type: postConst.REPORT_IMAGE_POST_FAILED,
    payload: {
      error,
    },
  };
};

export const fastFollowUserPost = (shopId) => {
  return {
    type: postConst.FAST_FOLLOW_USER_IN_POST,
    payload: { shopId },
  };
};

export const fastUnFollowUserPost = (shopId) => {
  return {
    type: postConst.FAST_UNFOLLOW_USER_IN_POST,
    payload: { shopId },
  };
};

export const modifyDataDelete = (params) => {
  return {
    type: postConst.MODIFY_DATA_DELETE,
    payload: { params },
  };
};

export const resetData = () => {
  return {
    type: postConst.RESET_DATA,
  };
};
export const fetchAnswerComment = (params) => {
  return {
    type: postConst.FETCH_ANSWER_COMMENT,
    payload: {
      params,
    },
  };
};

export const fetchAnswerCommentSuccess = (data) => {
  return {
    type: postConst.FETCH_ANSWER_COMMENT_SUCCESS,
    payload: {
      data,
    },
  };
};
