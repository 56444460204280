import React from "react";
import Modal from "react-bootstrap/Modal";
import Loader from "components/loader";
import { Link } from "react-router-dom";

function ModalCode({ data, id, isLoading, isOpen, setIsOpen }) {
  const handleClose = () => {
    setIsOpen((isOpen) => isOpen == false);
  };

  return (
    <>
      <Modal show={isOpen} onHide={handleClose} className="modal-confirm">
        <Modal.Header className="flex-column">
          <h5>Kết quả thông tin truy xuất</h5>
        </Modal.Header>
        <Loader isLoading={isLoading} />
        <Modal.Body className="flex-column">
          <div>
            <div className="row">
              <img className="traceability-code-img" src={data[0]} alt="" />
            </div>
            <div className="row mt-2">
              <div className="col-md-12">
                <div className="font-weight-bold text-dark mt-1 ">
                  Tên sản phẩm: &nbsp;{data[1]}
                </div>
                <div className="font-weight-bold text-dark mt-1">Mã sản phẩm: &nbsp;{data[2]}</div>
                <div className="font-weight-bold text-dark  mt-1 traceability-code-src">
                  Địa chỉ nguyên liệu: &nbsp;{data[3]}
                </div>
                <div className="font-weight-bold text-dark mt-1 traceability-code-src">
                  Đơn vị sản xuất: &nbsp;{data[4]}
                </div>
              </div>
            </div>
          </div>
          ;
        </Modal.Body>

        <div className="row">
          <Link to={`/modules/truy-xuat-nguon-goc/${id}`}>
            <button type="button" className="save-btn btn-lg btn-block">
              Chi tiết
            </button>
          </Link>
        </div>
      </Modal>
    </>
  );
}
export default ModalCode;
