import React, {useEffect} from 'react';
import { useDispatch } from 'react-redux';
import { useLocation } from 'react-router-dom';
import {addCurrentURL} from 'actions/auth';

const CurrentURL = ({children}) => {
  let { pathname } = useLocation();
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(addCurrentURL(pathname))
    return () => {
      dispatch(addCurrentURL('/'))
    };
  }, [pathname, dispatch]);
  return children;
}

export default CurrentURL;