import * as fertilizerConst from 'constants/fertilizer';
const initialState = {
  list_fertilizer: {
    data: [],
    isLoading: false,
  }
}

export const reducer = (state = initialState, action) => {
  switch (action.type) {
    case fertilizerConst.FETCH_FERTILIZER: {
      return {
        ...state,
        list_fertilizer: {
          data: initialState.list_fertilizer.data,
          isLoading: true
        }
      }
    }

    case fertilizerConst.FETCH_FERTILIZER_SUCCESS: {
      const { data } = action.payload;
      return {
        ...state,
        list_fertilizer: {
          data: data.data
        }
      }
    }

    default: {
      return state;
    }
  }
}
export default reducer;