import React, { useEffect, useState } from "react";
import ReactTimeAgo from "react-time-ago";
import { useDispatch, useSelector } from "react-redux";
import InputSubCommentBox from "./inputSubComment";
import { toastError } from "helpers/toastHelper";
import {
  deleteChildTechnicalProcessComment,
  managerDeleteChildTechnicalProcessComment,
  updateTechnicalProcessChildComment,
  replyTechnicalProcessChildComment,
} from "redux/actions/technical_process";
import ModalDeleteConfirmComment from "components/shared/modalDeleteConfirmComment/modal";
import parse from "html-react-parser";
import { fetchTechnicalProcessChildComment } from "redux/actions/technical_process";
function SubComment({
  comment,
  current_user,
  userlikeOrDislikeComment,
  showReportModal,
  ModalReport,
  showInputBox,
  setShowInputBox,
  typeInput,
  setTypeInput,
}) {
  let listAnsComment = useSelector((state) => state.technicalProcess.listTechnicalProcessChildComment.data);
  const dispatch = useDispatch();
  let [subCommentId, setSubCommentId] = useState(null);
  let [subContent, setSubContent] = useState("");

  const deleteSubComment = (id, role) => {
    const deleteComment =
      role === "admin" || role === "smod"
        ? managerDeleteChildTechnicalProcessComment
        : deleteChildTechnicalProcessComment;
    dispatch(deleteComment({ id }));
    setSubCommentId(null);
  };
  useEffect(() => {
    dispatch(fetchTechnicalProcessChildComment({ id: comment.id }));
  }, [dispatch]);

  const handleShowInputBox = (commentId, replierId) => {
    if (replierId === undefined) {
      return toastError("Please login first!");
    }
    const id = commentId;
    setShowInputBox({
      id,
      replierId,
    });
  };
  const submitReplyChildComment = (content, type, replier_id) => {
    if (!current_user) {
      toastError("Please login first!");
      return;
    }

    if (content === "") {
      toastError("Type your content");
      return;
    }

    const commentData = {
      id: showInputBox.id,
      content: content,
    };

    if (type === "reply") {
      commentData.replier_id = current_user.id;
      dispatch(
        replyTechnicalProcessChildComment({
          id: comment.id,
          content: content,
          parent_id: showInputBox.id,
          replier_id: replier_id,
        })
      );
    } else {
      dispatch(updateTechnicalProcessChildComment(commentData));
    }
    setSubContent("");
    setShowInputBox(false);
  };

  return (
    <>
      {listAnsComment.length > 0 && (
        <>
          {listAnsComment.map(function (ans) {
            return (
              <div className=" ml-5 mt-1 position-relative" key={ans.id}>
                <div className="user-inf border-0">
                  <a href={`/nguoi-dung/`}>
                    <div
                      className="bg-img-orgnal avtar40"
                      style={{
                        backgroundImage: `url(${ans.user.image ?? "/images/avatar.png"})`,
                        width: "35px",
                        height: "35px",
                        marginRight: "5px",
                      }}
                    />
                  </a>
                  <div className="d-inline-block">
                    <a href={`/nguoi-dung/`}>
                      <p className="user-name">{ans.user.name}</p>
                    </a>
                    <p className="post-time">
                      <span className="icon-time align-middle"></span>{" "}
                      <ReactTimeAgo date={Date.parse(ans.created_at)} />
                    </p>
                  </div>

                  {subCommentId && (
                    <ModalDeleteConfirmComment
                      isOpen={subCommentId}
                      onDelete={() => deleteSubComment(ans.id, current_user?.manager_type)}
                      onToggle={() => setSubCommentId(null)}
                    />
                  )}
                  <div>
                    {ans.parent_id !== null && ans.replier_id !== null ? (
                      <p className="txt-cmt">
                        <span style={{ color: "blue" }}>@{ans?.replier?.name}</span>
                        &nbsp;
                        {ans.content && parse(ans.content.replace(/\n/g, "<br />"))}
                      </p>
                    ) : (
                      <p className="txt-cmt">{ans.content && parse(ans.content.replace(/\n/g, "<br />"))} </p>
                    )}
                    <div className="actions d-flex align-items-center my-2">
                      <div className={`${ans?.user_liked ? "active" : ""} like d-flex align-items-center mr-3`}>
                        <div className="d-inline-block">
                          <span
                            className="icon-like01"
                            onClick={() =>
                              userlikeOrDislikeComment(ans.classable_id, ans.user_liked, ans.classable_type)
                            }
                          ></span>
                          <span>{ans.user_liked ? "Dislike" : "Like"}</span>
                          <span>({ans.total_likes === null ? 0 : ans.total_likes})</span>
                        </div>
                      </div>
                      <div className="d-flex align-items-center" style={{ paddingLeft: "6px" }}>
                        <span
                          onClick={() => {
                            handleShowInputBox(ans.id, current_user?.id);
                            setTypeInput("reply");
                          }}
                        >
                          Reply
                        </span>
                      </div>
                      <div className="dropdown post-report ms-auto">
                        <button
                          className="btn dropdown-toggle"
                          type="button"
                          data-bs-toggle="dropdown"
                          aria-expanded="false"
                        >
                          <span className="icon-menu-dot"></span>
                        </button>
                        <ul className="dropdown-menu dropdown-menu-end" aria-labelledby="true">
                          <li className="dropdown-item">
                            <div className="" onClick={() => showReportModal(ans.id, ans.classable_type)}>
                              <a>Vi phạm</a>
                            </div>
                            <ModalReport />
                          </li>
                          {current_user?.id == ans.user_id ||
                          current_user?.manager_type == "admin" ||
                          current_user?.manager_type == "smod" ||
                          current_user?.manager_type == "mod" ? (
                            <li className="dropdown-item">
                              <div
                                className=""
                                onClick={() => {
                                  handleShowInputBox(ans.id, current_user?.id);
                                  setTypeInput("edit");
                                }}
                              >
                                <a>Edit</a>
                              </div>
                            </li>
                          ) : (
                            ""
                          )}
                          {current_user?.id === ans.user_id ||
                          current_user?.manager_type == "smod" ||
                          current_user?.manager_type == "admin" ? (
                            <li className="dropdown-item">
                              <div className="" onClick={() => setSubCommentId(ans.id)}>
                                <a>Delete</a>
                              </div>
                            </li>
                          ) : (
                            ""
                          )}
                        </ul>
                      </div>
                    </div>
                    {showInputBox.id === ans.id && (
                      <InputSubCommentBox
                        showInputBox={showInputBox}
                        setShowInputBox={setShowInputBox}
                        setSubContent={setSubContent}
                        subContent={subContent}
                        current_user={current_user}
                        submitReplyChildComment={submitReplyChildComment}
                        typeInput={typeInput}
                        ans={ans}
                      />
                    )}
                  </div>
                </div>
              </div>
            );
          })}
        </>
      )}
    </>
  );
}
export default SubComment;
