import React from "react";

function ProducerDetails({ data }) {
  return (
    <div className="row mt-3">
      <div className="col-md-12">
        <div className="green-clr font-weight-bold mb-1">Nhà sản xuất</div>
        {data &&
          data.map(function (item, index) {
            return (
              <div className="d-flex justify-content-between" key={index}>
                <div className="info ">
                  <div className="mb-1">
                    Tên chủ sở hữu (Đơn vị sản xuất): &nbsp; {item.manufactor?.manufacture_name}
                  </div>
                  <div className="mb-1">Địa chỉ trụ sở: &nbsp;{item.manufactor?.manufacture_address}</div>
                  <div className="mb-1">Điện thoại: &nbsp;{item.manufactor?.manufacture_phone}</div>
                  <div className="mb-1">Email: &nbsp;{item.manufactor?.manufacture_email}</div>
                </div>
                <div className=" d-block media">
                  <div className="image">
                    <div className=" font-weight-bold text-dark mb-1 "> Hình logo</div>
                    <div className="traceaility-image">
                      <img src={item.manufactor?.images[0]?.name} alt="" />
                    </div>
                  </div>
                </div>
              </div>
            );
          })}
      </div>
    </div>
  );
}
export default ProducerDetails;
