export const FETCH_TECHNICAL_PROCESS = "FETCH_TECHNICAL_PROCESS";
export const FETCH_TECHNICAL_PROCESS_SUCCESS = "FETCH_TECHNICAL_PROCESS_SUCCESS";
export const FETCH_TECHNICAL_PROCESS_FAILED = "FETCH_TECHNICAL_PROCESS_FAILED";

export const FETCH_TECHNICAL_PROCESS_ID = "FETCH_TECHNICAL_PROCESS_ID";
export const FETCH_TECHNICAL_PROCESS_ID_SUCCESS = "FETCH_TECHNICAL_PROCESS_ID_SUCCESS";
export const FETCH_TECHNICAL_PROCESS_ID_FAILED = "FETCH_TECHNICAL_PROCESS_ID_FAILED";

export const FETCH_LOAD_MORE_TECHNICAL_PROCESS = "FETCH_LOAD_MORE_TECHNICAL_PROCESS";
export const FETCH_LOAD_MORE_TECHNICAL_PROCESS_SUCCESS = "FETCH_LOAD_MORE_TECHNICAL_PROCESS_SUCCESS";
export const FETCH_LOAD_MORE_TECHNICAL_PROCESS_FAILED = "FETCH_LOAD_MORE_TECHNICAL_PROCESS_FAILED";

export const CREATE_COMMENT_TECHNICAL_PROCESS = "CREATE_COMMENT_TECHNICAL_PROCESS";
export const CREATE_COMMENT_TECHNICAL_PROCESS_SUCCESS = "CREATE_COMMENT_TECHNICAL_PROCESS_SUCCESS";
export const CREATE_COMMENT_TECHNICAL_PROCESS_FAILED = "CREATE_COMMENT_TECHNICAL_PROCESS_FAILED";

export const FETCH_COMMENT_TECHNICAL_PROCESS = "FETCH_COMMENT_TECHNICAL_PROCESS";
export const FETCH_COMMENT_TECHNICAL_PROCESS_SUCCESS = "FETCH_COMMENT_TECHNICAL_PROCESS_SUCCESS";
export const FETCH_COMMENT_TECHNICAL_PROCESS_FAILED = "FETCH_COMMENT_TECHNICAL_PROCESS_FAILED";

export const LIKE_COMMENT_TECHNICAL_PROCESS = "LIKE_COMMENT_TECHNICAL_PROCESS";
export const LIKE_COMMENT_TECHNICAL_PROCESS_SUCCESS = "LIKE_COMMENT_TECHNICAL_PROCESS_SUCCESS";
export const LIKE_COMMENT_TECHNICAL_PROCESS_FAILED = "LIKE_COMMENT_TECHNICAL_PROCESS_FAILED";

export const DISLIKE_COMMENT_TECHNICAL_PROCESS = "DISLIKE_COMMENT_TECHNICAL_PROCESS";
export const DISLIKE_COMMENT_TECHNICAL_PROCESS_SUCCESS = "DISLIKE_COMMENT_TECHNICAL_PROCESS_SUCCESS";
export const DISLIKE_COMMENT_TECHNICAL_PROCESS_FAILED = "DISLIKE_COMMENT_TECHNICAL_PROCESS_FAILED";

export const REPORT_COMMENT_TECHNICAL_PROCESS = "REPORT_COMMENT_TECHNICAL_PROCESS";
export const REPORT_COMMENT_TECHNICAL_PROCESS_SUCCESS = "REPORT_COMMENT_TECHNICAL_PROCESS_SUCCESS";
export const REPORT_COMMENT_TECHNICAL_PROCESS_FAILED = "REPORT_COMMENT_TECHNICAL_PROCESS_FAILED";

export const DELETE_COMMENT_TECHNICAL_PROCESS = "DELETE_COMMENT_TECHNICAL_PROCESS";
export const DELETE_COMMENT_TECHNICAL_PROCESS_SUCCESS = "DELETE_COMMENT_TECHNICAL_PROCESS_SUCCESS";
export const DELETE_COMMENT_TECHNICAL_PROCESS_FAILED = "DELETE_COMMENT_TECHNICAL_PROCESS_FAILED";

export const DELETE_CHILD_COMMENT_TECHNICAL_PROCESS = "DELETE_CHILD_COMMENT_TECHNICAL_PROCESS";
export const DELETE_CHILD_COMMENT_TECHNICAL_PROCESS_SUCCESS = "DELETE_CHILD_COMMENT_TECHNICAL_PROCESS_SUCCESS";
export const DELETE_CHILD_COMMENT_TECHNICAL_PROCESS_FAILED = "DELETE_CHILD_COMMENT_TECHNICAL_PROCESS_FAILED";

export const MANAGER_DELETE_COMMENT_TECHNICAL_PROCESS = "MANAGER_DELETE_COMMENT_TECHNICAL_PROCESS";
export const MANAGER_DELETE_COMMENT_TECHNICAL_PROCESS_SUCCESS = "MANAGER_DELETE_COMMENT_TECHNICAL_PROCESS_SUCCESS";
export const MANAGER_DELETE_COMMENT_TECHNICAL_PROCESS_FAILED = "MANAGER_DELETE_COMMENT_TECHNICAL_PROCESS_FAILED";

export const MANAGER_UPDATE_COMMENT_TECHNICAL_PROCESS = "MANAGER_UPDATE_COMMENT_TECHNICAL_PROCESS";
export const MANAGER_UPDATE_COMMENT_TECHNICAL_PROCESS_SUCCESS = "MANAGER_UPDATE_COMMENT_TECHNICAL_PROCESS_SUCCESS";
export const MANAGER_UPDATE_COMMENT_TECHNICAL_PROCESS_FAILED = "MANAGER_UPDATE_COMMENT_TECHNICAL_PROCESS_FAILED";

export const MANAGER_DELETE_CHILD_COMMENT = "MANAGER_DELETE_CHILD_COMMENT";
export const MANAGER_DELETE_CHILD_COMMENT_SUCCESS = "MANAGER_DELETE_CHILD_COMMENT_SUCCESS";
export const MANAGER_DELETE_CHILD_COMMENT_FAILED = "MANAGER_DELETE_CHILD_COMMENT_FAILED";

export const REPLY_COMMENT_TECHNICAL_PROCESS = "REPLY_COMMENT_TECHNICAL_PROCESS";
export const REPLY_COMMENT_TECHNICAL_PROCESS_SUCCESS = "REPLY_COMMENT_TECHNICAL_PROCESS_SUCCESS";
export const REPLY_COMMENT_TECHNICAL_PROCESS_FAILED = "REPLY_COMMENT_TECHNICAL_PROCESS_FAILED";

export const UPDATE_COMMENT_TECHNICAL_PROCESS = "UPDATE_COMMENT_TECHNICAL_PROCESS";
export const UPDATE_COMMENT_TECHNICAL_PROCESS_SUCCESS = "UPDATE_COMMENT_TECHNICAL_PROCESS_SUCCESS";
export const UPDATE_COMMENT_TECHNICAL_PROCESS_FAILED = "UPDATE_COMMENT_TECHNICAL_PROCESS_FAILED";

export const FETCH_CHILD_COMMENT_TECHNICAL_PROCESS = "FETCH_CHILD_COMMENT_TECHNICAL_PROCESS";
export const FETCH_CHILD_COMMENT_TECHNICAL_PROCESS_SUCCESS = "FETCH_CHILD_COMMENT_TECHNICAL_PROCESS_SUCCESS";
export const FETCH_CHILD_COMMENT_TECHNICAL_PROCESS_FAILED = "FETCH_CHILD_COMMENT_TECHNICAL_PROCESS_FAILED";

export const UPDATE_CHILD_COMMENT_TECHNICAL_PROCESS = "UPDATE_CHILD_COMMENT_TECHNICAL_PROCESS";
export const UPDATE_CHILD_COMMENT_TECHNICAL_PROCESS_SUCCESS = "UPDATE_CHILD_COMMENT_TECHNICAL_PROCESS_SUCCESS";
export const UPDATE_CHILD_COMMENT_TECHNICAL_PROCESS_FAILED = "UPDATE_CHILD_COMMENT_TECHNICAL_PROCESS_FAILED";

export const REPLY_CHILD_COMMENT_TECHNICAL_PROCESS = "REPLY_CHILD_COMMENT_TECHNICAL_PROCESS";
export const REPLY_CHILD_COMMENT_TECHNICAL_PROCESS_SUCCESS = "REPLY_CHILD_COMMENT_TECHNICAL_PROCESS_SUCCESS";
export const REPLY_CHILD_COMMENT_TECHNICAL_PROCESS_FAILED = "REPLY_CHILD_COMMENT_TECHNICAL_PROCESS_FAILED";

export const FETCH_CATALOGUE_TECHNICAL_PROCESS = 'FETCH_CATALOGUE_TECHNICAL_PROCESS';
export const FETCH_CATALOGUE_TECHNICAL_PROCESS_SUCCESS = 'FETCH_CATALOGUE_TECHNICAL_PROCESS_SUCCESS';

export const FETCH_CATALOGUES_CHILDREN_TECHNICAL_PROCESS = 'FETCH_CATALOGUES_CHILDREN_TECHNICAL_PROCESS';
export const FETCH_CATALOGUES_CHILDREN_TECHNICAL_PROCESS_SUCCESS = 'FETCH_CATALOGUES_CHILDREN_TECHNICAL_PROCESS_SUCCESS';
export const FETCH_CATALOGUES_CHILDREN_TECHNICAL_PROCESS_FAILED = 'FETCH_CATALOGUES_CHILDREN_TECHNICAL_PROCESS_FAILED';

export const FETCH_DETAIL_CATALOGUES = 'FETCH_DETAIL_CATALOGUES';
export const FETCH_DETAIL_CATALOGUES_SUCCESS = 'FETCH_DETAIL_CATALOGUES_SUCCESS';
export const FETCH_DETAIL_CATALOGUES_FAILED = 'FETCH_DETAIL_CATALOGUES_FAILED';

export const RESET_DATA_CHILDREN_TECHNICAL_PROCESS = 'RESET_DATA_CHILDREN_TECHNICAL_PROCESS';
export const RESET_DATA_CHILDREN_TECHNICAL_PROCESS_SUCCESS = 'RESET_DATA_CHILDREN_TECHNICAL_PROCESS_SUCCESS';
