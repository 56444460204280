export const ADD_TO_CART = 'ADD_TO_CART';
export const ADD_TO_CART_SUCCESS = 'ADD_TO_CART_SUCCESS';
export const ADD_TO_CART_FAILED = 'ADD_TO_CART_FAILED';

export const UPDATE_CART = 'UPDATE_CART';
export const UPDATE_CART_SUCCESS = 'UPDATE_CART_SUCCESS';
export const UPDATE_CART_FAILED = 'UPDATE_CART_FAILED';

export const REMOVE_PRODUCT_FROM_CART = 'REMOVE_PRODUCT_FROM_CART';
export const REMOVE_PRODUCT_FROM_CART_SUCCESS = 'REMOVE_PRODUCT_FROM_CART_SUCCESS';
export const REMOVE_PRODUCT_FROM_CART_FAILED = 'REMOVE_PRODUCT_FROM_CART_FAILED';

export const UPDATE_QUANTITY_CART = 'UPDATE_QUANTITY_CART';
export const UPDATE_QUANTITY_CART_SUCCESS = 'UPDATE_QUANTITY_CART_SUCCESS';
export const UPDATE_QUANTITY_CART_FAILED = 'UPDATE_QUANTITY_CART_FAILED';

export const UPDATE_TOTAL_PRICE_CART = 'UPDATE_TOTAL_PRICE_CART';
export const UPDATE_TOTAL_PRICE_CART_SUCCESS = 'UPDATE_TOTAL_PRICE_CART_SUCCESS';
export const UPDATE_TOTAL_PRICE_CART_FAILED = 'UPDATE_TOTAL_PRICE_CART_FAILED';

export const UPDATE_NUMBER_PRODUCT_IN_CART = 'UPDATE_NUMBER_PRODUCT_IN_CART';
export const UPDATE_NUMBER_PRODUCT_IN_CART_SUCCESS = 'UPDATE_NUMBER_PRODUCT_IN_CART_SUCCESS';
export const UPDATE_NUMBER_PRODUCT_IN_CART_FAILED = 'UPDATE_NUMBER_PRODUCT_IN_CART_FAILED';

export const CHANGE_QUANTITY_PRODUCT_IN_CART = 'CHANGE_QUANTITY_PRODUCT_IN_CART';
export const CHANGE_QUANTITY_PRODUCT_IN_CART_SUCCESS = 'CHANGE_QUANTITY_PRODUCT_IN_CART_SUCCESS';

export const CALCULATE_TOTAL_PRICE_IN_CART = 'CALCULATE_TOTAL_PRICE_IN_CART';
export const CALCULATE_TOTAL_PRICE_IN_CART_SUCCESS = 'CALCULATE_TOTAL_PRICE_IN_CART_SUCCESS';
