import React from "react";
import Instagram from "react-content-loader";

function AuthPlaceholder(props) {
  return(
    <div className="row auth-placeholder">
      <Instagram />
    </div>
  )
};

export default AuthPlaceholder;
