import useManageContribute from "../Hooks/useManageContribute";
import moment from "moment";
import NumberFormat from "components/number_format";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";

const MarketPrice = () => {
  const {
    key,
    contributeMarket,
    handleDeleteContributeMarketPrice,
    handleApproveApproveContributeMarketPrice,
    expandMore,
    numItems,
    popover,
    handleTitleClick,
    activeOverlay,
  } = useManageContribute();
  const { data } = contributeMarket;
  return (
    <>
      <table className="table ">
        <thead>
          <tr>
            <th scope="col" className="align-middle text-s">
              Sản phẩm
            </th>
            <th scope="col" className="align-middle text-s">
              Giá
            </th>
            <th scope="col" className="align-middle text-s">
              Khu vực
            </th>
            <th scope="col" className="align-middle text-s">
              Ngày đóng góp
            </th>
            <th scope="col" className="align-middle text-s">
              Người đăng
            </th>
            <th scope="col" className="align-middle text-s">
              Thao tác
            </th>
          </tr>
        </thead>
        <tbody>
          {data.length > 0 ? (
            data.slice(0, numItems).map(function (data) {
              return (
                <tr className="text-capitalize fs-14" key={data.id}>
                  <OverlayTrigger
                    trigger="click"
                    placement="right"
                    overlay={popover}
                    show={activeOverlay?.id === data.id && key === "market_price"}>
                    <td
                      className="align-middle mw-200 line-clamp-01 table-title"
                      onClick={() => handleTitleClick(data)}
                      onMouseOut={() => {
                        handleTitleClick({ id: 1 });
                      }}>
                      {data.title}
                    </td>
                  </OverlayTrigger>
                  <td className="align-middle">
                    <NumberFormat>{data.market_prices[0]?.price}</NumberFormat>
                    <span className="text-lowercase">đ</span>
                  </td>
                  <td className="align-middle">
                    {data.district_name}, {data.province_name}
                  </td>
                  <td className="align-middle">{moment(data.market_prices[0]?.created_at).format("DD-MM-YYYY")}</td>
                  <td className="align-middle">{data.user_name}</td>
                  <td className="align-middle table_actions" >
                    <button
                      className="btn btn-danger btn-sm mr-2"
                      onClick={() => handleDeleteContributeMarketPrice(data.id, data.market_prices[0].id)}>
                      Từ chối
                    </button>
                    <button
                      className="btn btn-success btn-sm"
                      onClick={() => handleApproveApproveContributeMarketPrice(data.market_prices[0].id)}>
                      Duyệt
                    </button>
                  </td>
                </tr>
              );
            })
          ) : (
            <div className="font-italic font-light text-capitalize mt-3">Không có đóng góp</div>
          )}
        </tbody>
      </table>
      {data.length > 10 && numItems < data.length && (
        <div className="post-more background-clr-f5  mt-3 w-100  hover" onClick={() => expandMore()}>
          <span className="txt">
            <u
              style={{
                fontSize: "14px",
                fontWeight: "500",
                textTransform: "none",
              }}>
              More
            </u>
          </span>
          <span className="icon-arrow-solid"></span>
        </div>
      )}
    </>
  );
};
export default MarketPrice;
