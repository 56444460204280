import React, {forwardRef} from "react";
import Loader from "components/loader";
import { useSelector } from "react-redux";
import SearchResultItem from "../SearchResultItem";
import Nodata from "../Nodata";

const  SearchResult = forwardRef((_,ref) => {
  let data = useSelector((state) => state.home.search_result.data);
  let loading = useSelector((state) => state.home.search_result.extra.loading);
  let error = useSelector((state) => state.home.search_result.extra.error);
  
  return (
    <React.Fragment>
        <div
          ref={ref}
          className="results-list scrollbar dropdown-menu dropdown-menu-end show ml-1 mt-2"
          style={{ zIndex: "9999999" }}
        >
          <Loader isLoading={loading} />
          {data.length > 0 ? <SearchResultItem data={data} /> : <Nodata loading={loading} error={error}/>}
        </div>
    </React.Fragment>
  );
})

export default SearchResult;
