import React from "react";
import DatePicker from "react-datepicker";

const DayPicker = (props) => {
  const { setDateRange, startDate, endDate } = props;

  return (
    <DatePicker
      placeholderText="Chọn ngày"
      selectsRange={true}
      startDate={startDate}
      endDate={endDate}
      dateFormat="dd/MM/yyyy"
      onChange={(update) => {
        setDateRange(update);
      }}
      isClearable={true}
    />
  );
};

export default DayPicker;
