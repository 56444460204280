import React, { useRef, useState, useContext } from "react";
import AppContext from "components/app_context";
import parse from "html-react-parser";
import ReactStars from "react-rating-stars-component";
import NumberFormat from "../../../components/number_format";
import ShareSocialGroup from "../../../components/ShareSocialGroup/ShareSocialGroup";
import { Link } from "react-router-dom";
import Carousels from "../../../components/Carousels";
import { useTranslation } from "react-i18next";

export default function MainContainer(props) {
  const expand = useRef();
  const { current_user } = useContext(AppContext);
  const { listImage, product, addToCart, actionFavourite, commnetRef } = props;
  const regex = /(<video.*<\/video>)/g;
  const regexVideo = /src="(.*?)"/;
  const productDescriptionParts = product?.description?.split(regex);
  const descText = productDescriptionParts && productDescriptionParts[0];
  const videoString = productDescriptionParts && productDescriptionParts[1];
  const match = videoString?.match(regexVideo);
  const descVideo = match && match[1];
  const [isShow, setIsShow] = useState(false);
  const descriptText = $(".description-text").height();
  const validHeight = descriptText <= 180;
  const avatar_user = product?.shop?.image ?? "/images/avatar.png";
  const { t } = useTranslation();
  const handleShowMore = () => {
    setIsShow(true);
    $(".default-description").addClass("is-showed");
  };
  const scrollToCommentBox = (element, offset) => {
    const elementReact = element.current.getBoundingClientRect().top;
    const bodyReact = document.body.getBoundingClientRect().top;
    const offsetTop = elementReact - bodyReact - offset;
    window.scrollTo({
      top: offsetTop,
      behavior: "smooth",
    });
  };
  return (
    <div className="row">
      <div className="col-md-7 col-sm-12 product-detail">
        {listImage && (
          <Carousels
            infoImageFromHome={null}
            rootUrlImage={listImage}
            images={listImage?.map((url) => ({ original: url }))}
            page={"Product"}
          />
        )}
        <div className=" d-flex justify-content-between align-items-center mt-3">
          <div className="d-flex  align-items-center ">
            {product && (
              <ReactStars
                count={5}
                size={20}
                isHalf={true}
                activeColor="#ffd700"
                value={product.rate}
                edit={false}
              />
            )}
            <span className=" mx-1  mt-1 font-italic">
              {product && product.total_rated} {t("product.reviews")}
            </span>
          </div>

          <span className="font-italic mt-1">
            <img
              src="/images/eye-icon.png"
              style={{ width: "20px", height: "20px" }}
            />{" "}
            &nbsp;
            {product && product.view_count} {t("common.viewed")}{" "}
            {product && product?.qty_buy > 0 && `/ đã bán: ${product?.qty_buy}`}
          </span>
        </div>
        <p className="font-weight-bold my-2">Product infomation </p>
        <div className="text-justify">
          <div className="default-description">
            <div className="description-text">
              {descText && parse(descText.replace(/<\/p\>/g, "<br />"))}
              {descVideo && (
                <video
                  width="350"
                  height="200"
                  src={descVideo}
                  controls
                  preload
                  type="video/mp4"
                ></video>
              )}
            </div>
          </div>
          <div
            className="post-more border-0 mt-0 w-100 mb-3"
            onClick={() => handleShowMore()}
            hidden={(!isShow && validHeight) || isShow}
          >
            <u className="hover txt fw-500">{t("button.more")}</u>
          </div>
        </div>
      </div>
      <div className="col-md-5 col-sm-12">
        <h3>{product?.title}</h3>
        <div>
          <span>{t("product.retail")}: </span>
          {product?.retail_price > 0 ? (
            <span className="price-clr mr-1">
              <NumberFormat>{product?.retail_price}</NumberFormat> $/
              {product?.unit_name}
            </span>
          ) : (
            <span className="price-clr ml-1">{t("product.contact")}</span>
          )}
        </div>
        <div>
          <span>{t("product.wholesale")}: </span>
          {product?.wholesale_price > 0 ? (
            <span className="price-clr">
              <NumberFormat>{product?.wholesale_price}</NumberFormat> $/
              {product?.unit_name}
            </span>
          ) : (
            <span className="price-clr">&nbsp;{t("product.contact")}</span>
          )}
        </div>
        {product?.shop?.district_name !== null ||
        product?.shop?.province_name !== null ? (
          <div>
            <div>
              <span className="icon-location01" />{" "}
              <span>
                {product?.shop?.district_name} {product?.shop?.province_name}
              </span>
            </div>
          </div>
        ) : (
          <div>
            <span className="icon-location01" />
            <span className="ml-1">Unknown</span>
          </div>
        )}
        <div>
          <h6 className="fw-bold  mb-0 mt-3">{t("product.status")}</h6>
          <div className="primary-clr mb-3">
            {product?.quantity === 0.0
              ? t("product.out-of-stock")
              : t("product.in_stock")}
          </div>
        </div>
        <div className="d-flex">
          <div
            onClick={() => scrollToCommentBox(commnetRef, 200)}
            className="interve-btn mr-2"
          >
            <span className="icon-msgs" />
          </div>
          <div
            onClick={actionFavourite}
            className={`fav-btn mr-2 ${
              product?.is_favourite ? "" : "faved-btn"
            }`}
          >
            <span className="icon-tag" />
          </div>
          <ShareSocialGroup share2Nong={false} />
        </div>
        <div>
          <h6 className="text-uppercase fw-bold mb-0 mt-3">
            Seller infomation
          </h6>
          <div className="user-inf d-flex py-2">
            <Link to={`/nguoi-dung/${product?.user_id}`}>
              <img src={avatar_user} alt="avatar-user" />
              <span className="name">{product?.shop?.name}</span>
            </Link>
          </div>
          <div className="msg-box">
            <div className="d-flex align-items-center">
              <div
                className="mr-2"
                style={{
                  backgroundColor: "#009A59",
                  padding: "5px 8px",
                  borderRadius: "5px",
                }}
              >
                <img
                  src="../images/call.png"
                  alt=""
                  style={{ witdh: "22px", height: "22px" }}
                />
              </div>
              <span>{t("product.contact")} with seller</span>
            </div>
            <div className="row">
              <div className="group-btn-buy col-md-6 d-flex w-100">
                <a
                  type="button"
                  className="btn w-100 mt-4"
                  href={"tel:" + `${product?.shop?.phone}`}
                  ref={expand}
                >
                  {t("product.contact")}
                </a>
                {current_user && product?.user_id !== current_user?.id && (
                  <a
                    onClick={() => addToCart(product)}
                    type="button"
                    className="btn w-100 mt-4 ml-2"
                  >
                    {t("product.buy")}
                  </a>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
