import axiosService from "services/axiosService";
import { DOMAIN } from "constants";

const url = "api/v2/app_modules";

export const getAppModules = () => {
  return axiosService.get(`${DOMAIN}/${url}`);
};

const url_config_lucky_wheel = "api/v2/mini_events/event_lucky_wheel/config_lucky_wheel";
export const getConfigWheelData = () => {
  return axiosService.get(`${DOMAIN}/${url_config_lucky_wheel}`);
};
