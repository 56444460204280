import * as notificationConst from 'constants/notification';
import { toastError } from 'helpers/toastHelper'

const initialState = {
  listNotifications: {
    data: [],
    error: null,
    isLoading: false,
    isLoadMore: false
  },
  countNoti: 0
}

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case notificationConst.FETCH_NOTIFICATION: {
      return {
        ...state,
        listNotifications: {
          data: initialState.listNotifications.data,
          isLoading: true,
          isLoadMore: false,
        }
      };
    }
    case notificationConst.FETCH_NOTIFICATION_SUCCESS: {
      const { data } = action.payload;
      return {
        ...state,
        listNotifications: {
          data: data.data,
          isLoading: false,
          isLoadMore: true,
        },
      };
    }
    case notificationConst.FETCH_NOTIFICATION_FAILED: {
      const { error } = action.payload;
      toastError(error);
      return {
        ...state,
        listNotifications: {
          data: initialState.listNotifications.data,
          isLoading: false
        }
      };
    }

    case notificationConst.DELETE_NOTIFICATION_SUCCESS: {
      const { data } = action.payload;
      const {id} = data;
      return {
        ...state,
        listNotifications: {
          data: state.listNotifications.data.filter(noti => noti.id !== id),
          isLoading: false,
          error: null,
          isLoadMore: true
        }
      }
    }
    
    case notificationConst.COUNT_NOTIFICATION_SUCCESS: {
      const { data } = action.payload;
      return {
        ...state,
        countNoti: data
      }
    }


    case notificationConst.LOAD_MORE_NOTIFICATION: {
      return {
        ...state,
        listNotifications: {
          data: state.listNotifications.data,
          isLoadMore: false,
          isLoading: true,
          error: null
        }
      };
    }
    case notificationConst.LOAD_MORE_NOTIFICATION_SUCCESS: {
      const { data } = action.payload.data;
      return {
        ...state,
        listNotifications: {
          data: [...state.listNotifications.data, ...data],
          isLoadMore: true,
          isLoading: false,
          error: null
        }
      };
    }
    case notificationConst.LOAD_MORE_NOTIFICATION_FAILED: {
      const { error } = action.payload;
      toastError(error);
      return {
        ...state,
        listNotifications: {
          data: initialState.listNotifications.data,
          isLoadMore: false,
          isLoading: false,
          error: error
        }
      };
    }
    case notificationConst.VIEW_NOTIFICATION_SUCCESS: {
      const { id } = action.payload;
      return {
        ...state,
        listNotifications: {
          data: state.listNotifications.data.map(notice => {
            if (notice.id === id) {
              notice.status = "seen"
            }
            return notice;
          }),
          isLoading: false,
          error: null,
          isLoadMore: true
        }
      }
    }
    case notificationConst.VIEW_NOTIFICATION_FAILED: {
      const { error } = action.payload;
      toastError(error);
      return { ...state }
    }
    default:
      return state;
  }
}

export default reducer
