import React, { useEffect, useState, useContext, useRef } from "react";
import { bindActionCreators } from "redux";
import { connect, useSelector } from "react-redux";
import { Link, useParams, useLocation } from "react-router-dom";
import * as productActions from "actions/product";
import { PAGE, PER_PAGE } from "constants";
import FadeIn from "react-fade-in";
import Loader from "components/loader";
import AppContext from "components/app_context";
import LeftSidebar from "components/left_sidebar";
import SidebarCatalogueOfProduct from "components/sidebar_catalogueOfProduct";
import NumberFormat from "components/number_format";
import AddMetaTags from "components/add_meta_tags";
import { imageUrl } from "lib/index";
import { getCataloguesProductsDetail } from "apis/catalogue";
import _ from "lodash";
function ProductCatalogues(props) {
  const { keyword } = useContext(AppContext);
  const componentRef = useRef(null);
  const location = useLocation();
  const screenWidth = window.innerWidth;
  const catalogdata = location.state;
  let { productActionsCreator } = props;
  let { fetchProduct, fetchLoadMoreProduct, fetchChildCatalogue, fetchParentCatalogue } = productActionsCreator;
  let { data, isLoading, isLoadMore } = props.listProducts;
  let [page, setPage] = useState(PAGE);
  const [detailCatalogues, setDetailCatalogues] = useState(null);
  const childCatalogueOfProduct = useSelector((state) => state.product.childCatalogueOfProduct);
  const listener = (e) => {
    setPage(page + 1);
    fetchLoadMoreProduct({
      page: page + 1,
      limit: PER_PAGE,
      catalogue_ids: catalogdata?.id,
    });
  };
  const renderBreadcumb = (string) => {
    const newString = string?.split(">"); // remove ">" letter
    const items = newString?.map((item) => {
      return (
        <li className="breadcrumb-item active d-flex align-items-center">
          <span className="icon-arrow-black"></span>
          <span>{item}</span>
        </li>
      );
    });
    return items;
  };
  const scollToComponent = () => {
    if (screenWidth < 768) {
      componentRef.current.scrollIntoView({ behavior: "smooth" });
    }
  };
  useEffect(() => {
    fetchProduct({
      page: page,
      limit: PER_PAGE,
      keyword: keyword,
      catalogue_ids: catalogdata?.id,
    });
  }, [fetchProduct, keyword, catalogdata]);

  useEffect(() => {
    (async () => {
      const { data } = await getCataloguesProductsDetail(catalogdata?.id);
      setDetailCatalogues(data.data);
    })();
  }, [catalogdata]);

  useEffect(() => {
    if (catalogdata?.parentId !== null) {
      fetchParentCatalogue(catalogdata?.parentId);
      renderBreadcumb(catalogdata?.fullName);
    }
    scollToComponent();
  }, [catalogdata, data]);

  const renderWithoutParentId = () => {
    return (
      <nav aria-label="breadcrumb">
        <ol className="breadcrumb">
          <li className="breadcrumb-item">
            <Link to={`/cho-2nong`}>
              <span>Chợ 2 Nông</span>
            </Link>
          </li>
          {!catalogdata.parentId ? (
            <li className="breadcrumb-item active d-flex align-items-center">
              <span className="icon-arrow-black"></span>
              <span>{catalogdata?.name}</span>
            </li>
          ) : (
            renderBreadcumb(catalogdata?.fullName)
          )}
        </ol>
      </nav>
    );
  };

  return (
    <div className="products">
      <AddMetaTags title={"Danh sách sản phẩm theo danh mục"} />
      <div className="row">
        <LeftSidebar />
        <div className="col-md-6 col-sm-12 order-md-1 order-xs-2" ref={componentRef}>
          <div className="cont-center">
            <Loader isLoading={childCatalogueOfProduct.isLoading} />
            <div className="cate-lst mb-4">
              <h6 className="mb-2">{catalogdata && renderWithoutParentId()}</h6>
              <div className="row">
                {detailCatalogues?.map(function (item, index) {
                  let data = {
                    id: item.id,
                    name: item.name,
                    parentId: item.parent_id,
                    fullName: item.fullname,
                    slug: item.slug,
                    parent_slug: catalogdata?.slug,
                  };
                  return (
                    <div className="col-md-6 col-sm-6 col-xs-12 mb-2" key={index}>
                      <Link to={`/san-pham/danh-muc/${item.slug}`} state={data} className="cate-item position-relative">
                        <div className="img-box w-100 h-100 position-absolute">
                          <img src={item.image} alt="product cate" title="product cate" className="img-cover" />
                        </div>
                        <div className="prod-inf position-absolute d-flex align-items-center">
                          <div className="line-clamp-01 fw-bold text-uppercase">{item.name}</div>
                        </div>
                      </Link>
                    </div>
                  );
                })}
              </div>
            </div>
            <h5 className="title-line-lt text-uppercase fw-bold mb-3">
              <span className="d-block">{catalogdata?.name}</span>
            </h5>
            <FadeIn className="row prod-list" childClassName="col-md-3 col-sm-6 col-xs-12">
              {data.map(function (product, index) {
                let product_image =
                  product.images[0] !== undefined
                    ? imageUrl(product.images[0]?.name)
                    : "/images/product-image-default.png";
                return (
                  <div key={index} className="item-lst">
                    <Link to={`/san-pham/${product?.slug}`}>
                      <div className="bg-img-orgnal" style={{ background: `url(${product_image})` }} />
                      <div className="prod-inf">
                        <div className="txt-name">{product.title}</div>
                        {product.province_name ? (
                          <div className="locatn">
                            <span className="icon-location01" />
                            <span className="txt-city">{product?.shop?.province_name}</span>
                          </div>
                        ) : (
                          <div className="locatn">
                            {" "}
                            <span className="icon-location01" />
                            <span className="txt-city">Unknown</span>
                          </div>
                        )}
                        <div className="price-item">
                          <div className="price01">
                            <span>Retail price:</span>
                            <span className="price-clr">
                              &nbsp;
                              <NumberFormat>{product.retail_price}</NumberFormat> đ
                            </span>
                          </div>
                          <div className="price02">
                            <span>Wholesale price:</span>
                            <span className="price-clr">
                              &nbsp;
                              <NumberFormat>{product.wholesale_price}</NumberFormat> đ
                            </span>
                          </div>
                        </div>
                      </div>
                    </Link>
                  </div>
                );
              })}
            </FadeIn>
          </div>
          <Loader isLoading={isLoading} />
          {isLoadMore && (
            <div className="post-more background-clr-f5  hover" onClick={listener}>
              <span className="txt fw-500">More</span>
              <span className="icon-arrow-solid"></span>
            </div>
          )}
        </div>
        <SidebarCatalogueOfProduct />
      </div>
    </div>
  );
}

const mapStateToProps = (state) => {
  return {
    listProducts: state.product.listProducts,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    productActionsCreator: bindActionCreators(productActions, dispatch),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(ProductCatalogues);
