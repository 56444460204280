import React, { useEffect, useState } from "react";
import { getAnswersComment } from "apis/comment";
import { toastError } from "helpers/toastHelper";
import Modal from "react-bootstrap/Modal";
import ReactTimeAgo from "react-time-ago";
import { bindActionCreators } from "redux";
import { connect, useDispatch, useSelector } from "react-redux";
import * as postActions from "actions/post";
import * as authActions from "actions/auth";
import ModalDeleteConfirmComment from "components/shared/modalDeleteConfirmComment/modal";
import * as managerActions from "actions/manager";
import Dropdown from "react-bootstrap/Dropdown";
import parse from "html-react-parser";
import InputSubCommentBox from "../../../pages/technical_process/partials/inputSubComment";
import { replyCommentPost, updateAnswerComment, fetchAnswerComment } from "../../../redux/actions/post";
import { managerUpdateAnswerComment } from "../../../redux/actions/manager";
import { useTranslation } from "react-i18next";

function SubComment(props) {
  const {
    dataUpdateComment,
    id,
    actionReportComment,
    comment,
    LikeComment,
    current_user,
    deleteComment,
    dataUpdateAnswerComment,
    dataReplyComment,
    actionUpdateComment,
  } = props;
  const dispatch = useDispatch();
  const listCommentAnswers = useSelector((state) => state.post.listCommentsAnswer.data);
  const [showReportNestComment, setShowReportNestComment] = useState(false);
  const [reportContent, setReportContent] = useState("");
  const [isLoadMore, setIsLoadMore] = useState(false);
  const [page] = useState(1);
  const [reportId, setReportId] = useState(null);
  const [reportType, setReportType] = useState("");
  const [subCommentId, setSubCommentId] = useState(null);
  const [subComment, setSubComment] = useState("");
  const [typeInput, setTypeInput] = useState("");
  const [idSubComment, setIdSubComment] = useState(null);
  const { t } = useTranslation("translation");

  useEffect(() => {
    dispatch(fetchAnswerComment({ id: id, page: 1, limit: 5 }));
  }, [comment, dataUpdateComment, dataUpdateAnswerComment, dataReplyComment]);

  const submitReply = (content, type, replier_id) => {
    if (content === "") {
      toastError("Vui lòng nhập bình luận");
    } else if (content.length > 1000) {
      toastError("Do not more than  one thousand letters");
    } else {
      if (type === "reply") {
        dispatch(
          replyCommentPost({
            id: comment.id,
            parent_id: comment.id,
            content: content,
            replier_id: replier_id,
          })
        );
      } else if (type === "edit") {
        actionUpdateComment("SubComment", idSubComment, content);
      } else {
        if (current_user?.manager_type !== "member") {
          dispatch(managerUpdateAnswerComment({ id: idSubComment, content: subComment }));
        } else {
          updateAnswerComment({ id: idSubComment, content: subComment });
        }
      }

      setIdSubComment(null);
      setSubComment("");
    }
  };

  const showReportNestCommentModal = (id, type) => {
    setShowReportNestComment(true);
    setReportId(id);
    setReportType(type);
  };

  const handleClose = () => {
    setShowReportNestComment(false);
    setReportContent("");
  };
  const submitReportNestComment = (user_id, reason, reportId, reportType) => {
    if (reason === "") {
      toastError("Type your content");
    } else {
      actionReportComment(user_id, reason, reportId, reportType);
      setShowReportNestComment(false);
      setReportContent("");
    }
  };

  const handleLoadSubComment = (id) => {
    dispatch(fetchAnswerComment({ id: id, page: page + 1, limit: 5 }));
  };

  const showInputBox = (id) => setIdSubComment(id);
  return (
    <>
      {listCommentAnswers.length > 0 && (
        <>
          {listCommentAnswers.map(function (ans, index) {
            return (
              <>
                <div className="ml-5 mt-1 position-relative" key={ans.id}>
                  <div className="user-inf border-0">
                    <div className="">
                      <a href={`/nguoi-dung/${ans.user_id}`}>
                        {!ans.user.image ? (
                          <div
                            className="bg-img-orgnal avtar40"
                            style={{
                              backgroundImage: "url(/images/avatar.png)",
                              width: "35px",
                              height: "35px",
                              marginRight: "5px",
                              padding: "0",
                            }}
                          />
                        ) : (
                          <div
                            className="bg-img-orgnal avtar45"
                            style={{
                              backgroundImage: `url(${ans.user.image})`,
                              width: "35px",
                              height: "35px",
                              marginRight: "5px",
                              padding: "0",
                            }}
                          />
                        )}
                      </a>
                      <div className="d-inline-block">
                        <a href={`/nguoi-dung/${ans.user_id}`}>
                          <p className="user-name">{ans.user.name}</p>
                        </a>
                        <p className="post-time">
                          <span className="icon-time align-middle"></span>{" "}
                          <ReactTimeAgo date={Date.parse(ans.created_at)} />
                        </p>
                      </div>

                      <ModalDeleteConfirmComment
                        onDelete={() => deleteComment(ans.id, current_user?.manager_type, ans.classable_type)}
                        isOpen={subCommentId}
                        onToggle={() => setSubCommentId(null)}
                      />
                    </div>
                    <div>
                      {ans.parent_id !== null && ans.replier_id !== null ? (
                        <p className="txt-cmt">
                          <span style={{ color: "blue" }}>@{ans?.replier?.name}</span>
                          &nbsp;
                          {ans.content && parse(ans.content.replace(/\n/g, "<br />"))}
                        </p>
                      ) : (
                        <p className="txt-cmt"> {ans.content && parse(ans.content.replace(/\n/g, "<br />"))} </p>
                      )}
                      <div className="mt-2 actions d-flex align-items-center">
                        <div className="d-flex align-items-center">
                          <div
                            className={`${ans.user_liked ? "active" : ""} like d-inline-block mr-3`}
                            onClick={() => LikeComment(ans.classable_id, ans.classable_type, ans.user_liked)}>
                            {ans.user_liked === true ? (
                              <div className="d-inline-block">
                                <span className="icon-like01"></span>
                                <span>{t("button.dislike")}</span>
                              </div>
                            ) : (
                              <div className="d-inline-block">
                                <span className="icon-like01"></span>
                                <span>{t("button.like")}</span>
                              </div>
                            )}
                            <small>({ans.total_likes === null ? 0 : ans.total_likes})</small>
                          </div>
                          <div
                            className="d-inline-block"
                            onClick={() => {
                              showInputBox(ans.id);
                              setTypeInput("reply");
                            }}>
                            <span>{t('button.reply')}</span>
                          </div>
                        </div>
                        <div className="dropdown post-report ms-auto">
                          <Dropdown>
                            <Dropdown.Toggle as="div" className="dropdown post-report ms-auto">
                              <button
                                className="btn dropdown-toggle h-23px"
                                type="button"
                                data-bs-toggle="dropdown"
                                aria-expanded="false">
                                <span className="icon-menu-dot"></span>
                              </button>
                            </Dropdown.Toggle>
                            <Dropdown.Menu as="ul" className="dropdown-menu dropdown-menu-end">
                              <Dropdown.Item as="li" className="dropdown-item">
                                <div
                                  className=""
                                  onClick={() => showReportNestCommentModal(ans.id, ans.classable_type)}>
                                  <a role="button">{t("dropdown.report")}</a>
                                </div>
                              </Dropdown.Item>
                              {current_user?.id == ans.user_id ||
                              current_user?.manager_type == "admin" ||
                              current_user?.manager_type == "smod" ||
                              current_user?.manager_type == "mod" ? (
                                <Dropdown.Item className="dropdown-item" as="li">
                                  <div
                                    className=""
                                    onClick={() => {
                                      showInputBox(ans.id);
                                      setTypeInput("edit");
                                    }}>
                                    <span>{t("dropdown.update")}</span>
                                  </div>
                                </Dropdown.Item>
                              ) : (
                                ""
                              )}
                              {current_user?.id === ans.user_id ||
                              current_user?.manager_type == "smod" ||
                              current_user?.manager_type == "admin" ? (
                                <Dropdown.Item className="dropdown-item" as="li">
                                  <div className="" onClick={() => setSubCommentId(ans.id)}>
                                    <a>{t("dropdown.delete")}</a>
                                  </div>
                                </Dropdown.Item>
                              ) : (
                                ""
                              )}
                            </Dropdown.Menu>
                          </Dropdown>
                        </div>
                      </div>
                      {idSubComment === ans.id && (
                        <InputSubCommentBox
                          current_user={current_user}
                          ans={ans}
                          setSubContent={setSubComment}
                          subContent={subComment}
                          typeInput={typeInput}
                          submitReplyChildComment={submitReply}
                        />
                      )}
                    </div>
                  </div>
                </div>
                <Modal
                  show={showReportNestComment}
                  onHide={handleClose}
                  aria-labelledby="contained-modal-title-vcenter"
                  centered>
                  <Modal.Header closeButton>
                    <Modal.Title>{t("dropdown.report")} this comment</Modal.Title>
                  </Modal.Header>
                  <Modal.Body>
                    <input
                      className="form-control"
                      name="message"
                      placeholder="Type your reason"
                      value={reportContent}
                      onChange={(e) => setReportContent(e.target.value)}
                    />
                  </Modal.Body>
                  <Modal.Footer>
                    <button
                      type="button"
                      className="btn btn-primary"
                      onClick={() => submitReportNestComment(ans.user_id, reportContent, reportId, reportType)}>
                      {" "}
                      {t("button.submit")}
                    </button>
                  </Modal.Footer>
                </Modal>
              </>
            );
          })}
          {isLoadMore && (
            <span className="load-more-sub-comment-post" onClick={() => handleLoadSubComment(id)}>
             {t("button.more")}
            </span>
          )}
        </>
      )}
    </>
  );
}
const mapStateToProps = (state) => {
  return {
    listPosts: state.post.listPosts,
    listCommentPosts: state.post.listCommentsPost,
    listCommentAnswers: state.post.listCommentsAnswer,
    updateComment: state.post.updateComment,
    managerUpdateComment: state.manager.dataUpdateComment,
    managerUpdateAnswerComment: state.manager.dataUpdateAnswerComment,
    dataCreateComment: state.post.createComment,
    dataReplyComment: state.post.createReplyComment,
  };
};

const mapDispatchToProps = (dispatch, ownProps) => {
  return {
    postActionsCreator: bindActionCreators(postActions, dispatch),
    authActionsCreator: bindActionCreators(authActions, dispatch),
    managerActionsCreator: bindActionCreators(managerActions, dispatch),
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(SubComment);
