import React, { useState, useRef, useContext } from "react";
import { DOMAIN } from "constants";
import _ from "lodash";
import YouTube from "react-youtube";
import {
  youtubeGetID,
  convertString,
  validURL,
  validateStringEditor,
} from "lib";
import FbImageLibrary from "lib/react-fb-image-grid";
import MetaPost from "components/meta_post";
import * as linkify from "linkifyjs";
import parse, { domToReact } from "html-react-parser";
import ShowSharePost from "components/show_share_post";
import { toastError, toastErrorLogin } from "helpers/toastHelper";
import ReactTimeAgo from "react-time-ago";
import { Link } from "react-router-dom";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import Modal from "react-bootstrap/Modal";
import AppContext from "components/app_context";
import Comment from "./comment";
import ButtonShareFacebook from "../../../components/ButtonShareFacebook";
import Editor from "../../../components/simple_editor";
import CopyUrl from "../../../components/CopyURL";
import FollowUser from "components/shared/FollowUser";

const Post = React.memo(function Post(props) {
  let {
    post,
    postFilter,
    actionLike,
    isOwner,
    actionDelete,
    addComment,
    deleteComment,
    current_user,
    actionSharePost,
    actionReportPost,
    actionSharePoint,
    actionFetchCommentPost,
    actionReportComment,
    comments,
    actionLikeComment,
    actionReplyComment,
    actionUpdateComment,
    dataUpdateComment,
    dataUpdateAnswerComment,
    isLoadMore,
    actionLoadMoreCommentsPost,
    actionSortComment,
    setPostId,
    postId,
    setPageMore,
    setCurrentPost,
  } = props;
  let [content, setContent] = useState("");
  const [isToggle, setIsToggle] = useState(false);
  let hei_post = 286;
  const wid_post = $(".post-box").width() - 10;
  let [report, setReport] = useState("");
  const dataUser = useSelector((state) => state.login.user_data.current_user);
  const { t } = useTranslation();
  let inputSharePoint = useRef(null);
  let [showTitle, setShowTitle] = useState(false);
  let [showBox, setShowBox] = useState({
    type: "",
    placeholder: "",
    active: false,
    btnName: "",
  });
  const [show, setShow] = useState(false);
  const [hover, setHover] = useState(false);
  let [contentRoot, setContentRoot] = useState("");

  const [showReportComment, setShowReportComment] = useState(false);
  const [showModalSharePoint, setShowModalSharePoint] = useState(false);
  const { isMobile, navigate } = useContext(AppContext);
  let [commentId, setCommentId] = useState(null);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  let [reportId, setReportId] = useState(null);
  let [reportType, setReportType] = useState("");
  const handleShowReportComment = (id, type) => {
    setReportType(type);
    setReportId(id);
    setShowReportComment(true);
  };
  const handleCloseReportComment = () => setShowReportComment(false);
  const [reportComment, setReportComment] = useState("");
  const [activeSort, setActiveSort] = useState("asc");

  const submitReportComment = (user_id, reason) => {
    if (reason === "") {
      toastError("Type your content");
    } else {
      actionReportComment(user_id, reason, reportId, reportType);
      setShowReportComment(false);
      setReportComment("");
    }
  };
  const [type, setType] = useState("");
  let [showPicker, setShowPicker] = useState(false);
  let [innerString, setInnerString] = useState("");
  const onReady = (event) => {
    event.target.pauseVideo();
  };

  const submitBox = () => {
    if (validateStringEditor(contentRoot) === "too long") {
      return toastError("Do not more than  one thousand letters!");
    }
    if (!validateStringEditor(contentRoot)) {
      return toastError("Type your content");
    }
    if (
      contentRoot.match(/^(?=.*[a-zA-Z0-9])/) ||
      contentRoot.match(/\p{Emoji}/u)
    ) {
      if (type === "comment") {
        addComment(commentId, "Post", contentRoot), resetStateEditor();
      } else {
        actionSharePost(post.id, "Post", contentRoot), resetStateEditor();
      }
    } else {
      return toastError("Invalid content");
    }
  };
  const resetStateEditor = () => {
    $("#editor").html("");
    setContentRoot("");
    setShowPicker(false);
    setInnerString("");
  };

  const submitReport = (id, reason) => {
    if (reason === "") {
      toastError("Type your content");
    } else {
      actionReportPost(id, reason);
      setShow(false);
      setReport("");
    }
  };
  const actionClick = (type) => {
    setIsToggle(true);
    if (current_user === null) {
      toastErrorLogin(isMobile);
    }
    setContent("");
    let isActive = type === showBox.type ? !showBox.active : true;
    if (type === "comment") {
      setShowBox({
        type: "comment",
        placeholder: "Type your comment",
        active: isActive,
        btnName: (
          <div
            className="position-absolute btn-send-cmt"
            style={{
              backgroundImage: 'url("/images/send-message.png")',
              width: "24px",
              height: "24px",
            }}
          ></div>
        ),
      });
    } else if (type === "edit") {
      setShowBox({
        type: "edit",
        placeholder: "Edit bình luận",
        active: isActive,
        btnName: (
          <div
            className="position-absolute btn-send-cmt"
            style={{
              backgroundImage: 'url("/images/send-message.png")',
              width: "24px",
              height: "24px",
            }}
          ></div>
        ),
      });
    } else {
      setShowBox({
        type: "share",
        placeholder: "Nội dung chia sẻ của bạn",
        active: isActive,
        btnName: (
          <div
            className="position-absolute btn-send-cmt"
            style={{
              backgroundImage: 'url("/images/send-message.png")',
              width: "24px",
              height: "24px",
            }}
          ></div>
        ),
      });
    }
  };
  const handleShowModal = (item) => {
    if (current_user) {
      setCurrentPost(item);
    } else {
      toastError("Bạn chưa đăng nhập");
    }
  };

  const handleLoadMoreComment = (postId) => {
    actionLoadMoreCommentsPost(postId);
    setIsToggle(true);
  };

  const acionLikePost = () => {
    if (current_user === null) {
      toastErrorLogin(isMobile);
    } else {
      actionLike(post.classable_id, post.classable_type, post.user_liked);
    }
  };
  const LikeComment = (classable_id, classable_type, user_liked) => {
    if (current_user === null) {
      toastErrorLogin(isMobile);
    } else {
      actionLikeComment(classable_id, classable_type, user_liked);
    }
  };

  const options = {
    replace: ({ attribs, children }) => {
      if (!attribs) {
        return;
      }
      if (attribs.class === "tag") {
        let domReact = domToReact(children, options);
        return <span onClick={(evt) => postFilter(domReact)}>{domReact}</span>;
      }
    },
  };

  const viewAllTitle = (title) => {
    setShowTitle(true);
  };

  const replace_tag = (children) => {
    return children.replace("<a href='https://more'>More</a>", "");
  };

  const submitSharePoint = (receiver_id) => {
    if (current_user.id === receiver_id) {
      toastError("Không thể tặng chính mình");
    } else {
      if (!/^\d+$/.test(inputSharePoint.current.value)) {
        toastError("Vui lòng chỉ nhập số");
      } else {
        actionSharePoint(
          current_user?.token_user,
          receiver_id,
          inputSharePoint.current.value
        );
        setShowModalSharePoint(false);
      }
    }
  };

  const ShowModalSharePoint = () => {
    if (!current_user) {
      toastErrorLogin(isMobile);
      setShowModalSharePoint(false);
    } else {
      setShowModalSharePoint(true);
    }
  };
  const showCommentPost = (isToggle, id) => {
    setIsToggle(!isToggle);
    setActiveSort("asc");
    setPageMore(1);
    if (!isToggle) {
      setPostId(id);
      actionFetchCommentPost(post.id, 1, 5);
    }
  };

  const handleSortComment = (type) => {
    if (type === "asc") {
      actionSortComment(post.id, type);
      setActiveSort(type);
      setIsToggle(true);
    } else {
      actionSortComment(post.id, type);
      setActiveSort(type);
      setIsToggle(true);
    }
  };

  let youtubeID = post.title != null && youtubeGetID(post.title);
  let images = _.map(post.images, "name").map(
    (n) => `${validURL(n) ? n : DOMAIN + n}`
  );
  let shop_image = validURL(post.shop_image)
    ? `${post.shop_image}`
    : `${DOMAIN}${post.shop_image}`;
  let class_like =
    current_user !== null && post.user_liked === true ? "active-post" : "";
  let class_like_comment =
    current_user !== null && comments.user_liked === true ? "active" : "";
  let shared_link_status = post.shared_link_status;
  let links =
    post.title !== null
      ? _.uniqBy(
          _.map(linkify.find(post.title, "url"), (item) =>
            _.extend(
              {
                domain: item.value.match(
                  /^(?:https?:\/\/)?(?:[^@\/\n]+@)?(?:www\.)?([^:\/?\n]+)/
                )[1],
              },
              item
            )
          ),
          "domain"
        )
      : [];
  let description = post.description;
  let title =
    post.shared_post_id !== null
      ? `${description !== null ? description : ""}`
      : `<div className="title">
                        ${post.title !== null ? convertString(post.title) : ""}
                      </div>
                    `;
  let shared_post_id = post.shared_post_id;
  let opts = {
    height: hei_post,
    width: wid_post,
    playerVars: {
      autoplay: 0,
    },
  };

  const handleHover = () => {
    setHover(true);
  };

  const handleHoverMove = () => {
    setHover(false);
  };
  const handleCloseModalShare = () => {
    setShowModalSharePoint(false);
    handleHoverMove();
  };
  const url = window.location.href;

  return (
    <div className="post-box" id={`post-id-${post?.id}`}>
      <div className="p-2 mt-1">
        <div className="row ">
          <div className="col-md-7 col-sm-6 col-xs-12">
            <div className="wrap-flwing">
              <div className="user-name d-flex align-items-center">
                <Link
                  to={`/nguoi-dung/${post?.user_id}`}
                  className="txt-limit fw-semibold fs-6"
                >
                  {post.shop_name === null ? post.user_name : post?.shop_name}
                </Link>{" "}
                <span className="d-inline-block">
                  {current_user && <FollowUser post={post} isOwner={isOwner} />}
                </span>
              </div>
              <Link
                to={`/nguoi-dung/${post?.user_id}`}
                className="user-inf d-flex"
              >
                <div className="d-flex align-items-center">
                  <img
                    src={post.shop_image ? shop_image : "/images/avatar.png"}
                    alt="comment images"
                  />
                  <div className="txt-sectn">
                    <p className="post-time">
                      <span className="icon-location01" />{" "}
                      <span>{post?.province_name ?? "Unknown"}</span>
                    </p>
                    <p className="post-time">
                      <span className="icon-time align-middle"></span>{" "}
                      <ReactTimeAgo date={Date.parse(post.created_at)} />
                    </p>
                  </div>
                </div>
              </Link>
            </div>
          </div>
          <div className="col-md-5 col-sm-6 col-xs-12 d-flex">
            <div className="member_rate_in_month d-inline-block text-end w-100">
              <span className="member_rate icon-start-arrow fs-6 me-1"></span>
              <span className="member_rate_text">Farmer </span>
            </div>
            <div className="dropdown post-report ms-auto">
              <>
                <button
                  className="btn dropdown-toggle"
                  type="button"
                  data-bs-toggle="dropdown"
                  aria-expanded="false"
                >
                  <span className="icon-menu-dot" />
                </button>
                <ul className="dropdown-menu dropdown-menu-end" aria-labelledby>
                  {!isOwner && current_user && (
                    <li className="dropdown-item">
                      <a href="#" onClick={handleShow}>
                      {t("dropdown.report")} this post
                      </a>
                    </li>
                  )}
                  {isOwner || current_user?.manager_type == "admin" ? (
                    <li className="dropdown-item">
                      <span onClick={(evt) => actionDelete(post.id)}> {t("dropdown.delete")}</span>
                    </li>
                  ) : (
                    ""
                  )}
                </ul>
                <Modal
                  show={show}
                  onHide={handleClose}
                  aria-labelledby="contained-modal-title-vcenter"
                  centered
                >
                  <Modal.Header closeButton>
                    <Modal.Title> {t("dropdown.report")} this post</Modal.Title>
                  </Modal.Header>
                  <Modal.Body>
                    <input
                      className="form-control"
                      name="message"
                      placeholder="Type your reason"
                      value={report}
                      onChange={(e) => setReport(e.target.value)}
                    />
                  </Modal.Body>
                  <Modal.Footer>
                    <button
                      type="button"
                      className="btn btn-primary"
                      onClick={() => submitReport(post.id, report)}
                    >
                       {t("button.submit")} this post
                    </button>
                  </Modal.Footer>
                </Modal>
              </>
            </div>
          </div>
        </div>
      </div>
      <div className="posted-inf">
        {post.short_title == "" && (
          <div className="cont">{parse(title, options)}</div>
        )}
        {post.short_title != "" && (
          <div className="cont">
            {showTitle
              ? parse(title, options)  //parse(post?.title.replace(/\n/g, "<br />"), options)
              : parse(replace_tag(post.short_title))}{" "}
            {showTitle ? (
              ""
            ) : (
              <a
                role="button"
                className="btn-more fw-500"
                onClick={() => viewAllTitle(post?.title)}
              >
                {t("button.more")}
              </a>
            )}{" "}
          </div>
        )}
        <div className="media" style={{ display: "block" }}>
          {youtubeID != null && (
            <YouTube
              containerClassName={"video-youtube"}
              videoId={youtubeID}
              opts={opts}
              onReady={onReady}
            />
          )}
          {!shared_post_id && (
            <div key={post.id} className="post_images">
              <FbImageLibrary
                listImage={_.sortBy(post?.images, (o) => o.id)}
                navigate={navigate}
                post={post}
                images={images}
                countFrom={3}
                renderOverlay={() => (
                  <div className="view-image">Xem full hình</div>
                )}
                overlayBackgroundColor={"#0005"}
              />
            </div>
          )}
          {youtubeID === null && links.length > 0 && (
            <MetaPost
              links={links}
              shared_link_status={shared_link_status}
              postId={post.id}
            />
          )}
          {shared_post_id && (
            <ShowSharePost
              navigate={navigate}
              post={post}
              shared_post_id={shared_post_id}
              options={options}
              opts={opts}
              onReady={onReady}
            />
          )}
        </div>
        <div className="fav-inf d-flex align-items-center">
          <div className="like me-auto">
            <span className="icon-like01" />
            <span className="txt ml-1">{post.total_like}</span>
            <div className="ml-3">
              <span className=" txt">{post.viewed || 0}</span>{" "}
              <span className=" txt ml-1"> {t("common.viewed")}</span>
            </div>
          </div>
          <div
            className="cmnt"
            onClick={() => {
              showCommentPost(isToggle, post.id);
              actionClick("comment");
            }}
          >
            <span className="numb">{post.total_comment}</span>
            <span className="txt"> {t("common.comments")}</span>
          </div>
        </div>
        <div className=" d-flex justify-content-around align-items-center">
          <div
            onClick={() =>
              acionLikePost(
                post.classable_id,
                post.classable_type,
                post.user_liked
              )
            }
            className={`${class_like} like d-flex align-items-center`}
          >
            <span className="icon-like01" />
            <span
              className={` ${class_like} txt fw-semibold ml-1 hover color-585858`}
            >
              {t("button.like")}
            </span>
          </div>

          <div
            className="cmnt d-flex align-items-center"
            onClick={ShowModalSharePoint}
          >
            <span className="icon-get-gift" />
            <span className="txt fw-semibold ml-1 hover color-585858">
            {t("button.share_point")}
            </span>
          </div>

          <Modal
            show={showModalSharePoint}
            onHide={() => handleCloseModalShare()}
            aria-labelledby="contained-modal-title-vcenter"
            centered
          >
            <Modal.Body closeButton>
              <div className="d-flex justify-content-between mt-2">
                <div className="d-flex align-items-center">
                  <img
                    src={current_user?.image ?? "/images/avatar.png"}
                    alt=" images"
                    className="avtar-sharepoint"
                  />
                  <div>
                    <div className="txt-limit">
                      {current_user?.name ?? current_user?.shop?.name}
                    </div>
                    <div calssName="mt-2">
                      <img
                        src="../images/rice (1).png"
                        style={{ width: "20px", height: "20px" }}
                      />
                      <span className="ml-1">{current_user?.points}</span>
                    </div>
                  </div>
                </div>
                <div>
                  <img
                    src="../images/gift (1).png"
                    style={{ width: "30px", height: "30px" }}
                  />
                </div>
              </div>
              <div className="position-relative my-3">
                <input
                  className="form-control"
                  name="message"
                  placeholder={`How many point you wanna give for ${post?.user_name} ?`}
                  onKeyPress={(event) => {
                    if (!/^[0-9]*$/.test(event.key)) {
                      event.preventDefault();
                      toastError("Type only number");
                    }
                  }}
                  ref={inputSharePoint}
                />
                <button
                  type="button"
                  className="btn position-absolute  btn-sm btn-primary"
                  onClick={() => submitSharePoint(post?.user_id)}
                  style={{ top: "3px", right: "5px" }}
                >
                  {" "}
                  {t("button.submit")}
                </button>
              </div>
              <div
                className="d-flex justify-content-center fw-semibold mt-4 background-clr-f5 "
                style={{ height: "40px", paddingTop: "5px" }}
              >
                {/*<div
                  className="pointer"
                  onMouseOver={() => handleHover()}
                  onMouseOut={() => handleHoverMove()}
                  onClick={() => handleCloseModalShare()}
                >
                  <img
                    src={`${hover === false ? "../images/cancel (1).png" : "../images/cancel (2).png"} `}
                    style={{ width: "30px", height: "30px" }}
                  />
                </div>*/}
              </div>
            </Modal.Body>
          </Modal>

          <div
            className="cmnt d-flex align-items-center"
            onClick={() => {
              actionClick("comment");
              setCommentId(post.id);
              setType("comment");
              showCommentPost(isToggle, post.id);
            }}
          >
            <span className="icon-comment-line" />
            <span className="txt fw-semibold ml-1  hover color-585858">
            {t("button.comment")}
            </span>
          </div>
          <div className="share  d-flex align-items-center">
            <div className="dropdown post-report">
              <button
                className="btn dropdown-toggle"
                type="button"
                data-bs-toggle="dropdown"
                aria-expanded="false"
                style={{
                  backgroundColor: "transparent",
                  color: "#",
                  height: "100%",
                }}
              >
                <span
                  className="icon-share01"
                  style={{ fontSize: "20px", content: "" }}
                ></span>
                <span className="txt fw-semibold ml-1 hover color-585858">
                {t("button.share")}
                </span>
              </button>
              <ul
                className="dropdown-menu dropdown-menu-end"
                aria-labelledby="true"
                style={{ width: "12rem" }}
              >
                <li className="dropdown-item">
                  <div
                    style={{ height: "32", width: "32", cursor: "pointer" }}
                    className="mr-2"
                  >
                    <ButtonShareFacebook>
                      <span className="ml-1">{t("common.share_with")} Facebook</span>
                    </ButtonShareFacebook>
                  </div>
                </li>
                <li className="dropdown-item">
                  <div
                    style={{ height: "32", width: "32", cursor: "pointer" }}
                    className="mr-2"
                    onClick={() => handleShowModal(post)}
                  >
                    <img
                      src="/images/logo_2nong.png"
                      alt="2Nong-share"
                      style={{
                        height: "32",
                        width: "32",
                        cursor: "pointer",
                        backgroundColor: "#009858",
                        borderRadius: "3px",
                      }}
                    />
                    <span className="hover ml-1">{t("common.share_with")} 2Nong</span>
                  </div>
                </li>
                <li className="dropdown-item">
                  <div
                    style={{ height: "32", width: "32", cursor: "pointer" }}
                    className="mr-2"
                  >
                    <CopyUrl url={url} />
                  </div>
                </li>
              </ul>
            </div>
          </div>
        </div>
        {showBox.active && current_user !== null && (
          <div className="cmt-box">
            <div className="inpt-cntn cmnt-box position-relative ">
              <div className="editor editor-post">
                <Editor
                  component="div"
                  setShowPicker={setShowPicker}
                  showPicker={showPicker}
                  setInnerString={setInnerString}
                  innerString={innerString}
                  onChange={setContentRoot}
                  value={contentRoot}
                  createComment={submitBox}
                  placeholder="Type your comment"
                />
              </div>
            </div>

            <Comment
              isToggle={isToggle}
              post={post}
              postId={postId}
              comments={comments}
              handleSortComment={handleSortComment}
              activeSort={activeSort}
              dataUser={dataUser}
              current_user={dataUser}
              deleteComment={deleteComment}
              options={options}
              class_like_comment={class_like_comment}
              showReportComment={showReportComment}
              LikeComment={LikeComment}
              setContent={setContent}
              handleCloseReportComment={handleCloseReportComment}
              reportComment={reportComment}
              handleShowReportComment={handleShowReportComment}
              content={content}
              submitReportComment={submitReportComment}
              setReportComment={setReportComment}
              dataUpdateComment={dataUpdateComment}
              isLoadMore={isLoadMore}
              handleLoadMoreComment={handleLoadMoreComment}
              actionReplyComment={actionReplyComment}
              dataUpdateAnswerComment={dataUpdateAnswerComment}
              actionUpdateComment={actionUpdateComment}
            />
          </div>
        )}
      </div>
    </div>
  );
});

export default Post;
