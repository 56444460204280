import React from "react";
import { Link } from "react-router-dom";
export default function Breadcumb({ product }) {
  return (
    <>
      {product && (
        <nav aria-label="breadcrumb">
          <ol className="breadcrumb">
            <li className="breadcrumb-item">
              <Link to={`/cho-2nong`}>
                <span>Supermarket</span>
              </Link>
            </li>
            <li className="breadcrumb-item active d-flex align-items-center fw-semibold">
                <span className="icon-arrow-black"></span>
                <span>{product?.catalogue_name}</span>
            </li>
          </ol>
        </nav>
      )}
    </>
  );
}
