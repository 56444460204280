import React, {
  useEffect,
  useState,
  useRef,
  useContext,
  useLayoutEffect,
} from "react";
import { useParams } from "react-router-dom";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import * as productActions from "actions/product";
import { DOMAIN } from "constants";
import LeftSidebar from "components/left_sidebar";
import SidebarCatalogueOfProduct from "components/sidebar_catalogueOfProduct";
import { validateStringEditor } from "lib";
import { toastError } from "helpers/toastHelper";
import AddMetaTags from "components/add_meta_tags";
import ScrollToTop from "components/ScrollToTop";
import AppContext from "components/app_context";
import { toastErrorLogin } from "helpers/toastHelper";
import ReactStars from "react-rating-stars-component";
import { useCart } from "../Cart/Context";
import { useNavigate } from "react-router-dom";
import Swal from "sweetalert2";
import Editor from "../../components/simple_editor";
import Breadcumb from "./components/Breadcumb";
import ProductRelation from "./components/ProductRelation";
import MainContainer from "./components/MainContainer";
import CommentArea from "./components/CommentArea";

function ProductDetail(props) {
  const { productId } = useParams();
  const { productActionsCreator } = props;
  const {
    fetchProductID,
    createCommentProduct,
    createFavoriteProduct,
    deleteFavoriteProduct,
  } = productActionsCreator;
  const [content, setContent] = useState("");
  const commnetRef = useRef();
  const ref = useRef(null);
  const { current_user, isMobile } = useContext(AppContext);
  const { data: product } = props.productDetail;
  const [comment, setComment] = useState("");
  const { data } = props.relationProducts;
  const listImage = product && product.images.map((n) => n.name);
  const [rating, setRating] = useState(null);
  const [hadRated, setHadRated] = useState(false);
  const rate = product?.comment?.rate ?? 0;
  const isCommented = rate > 0;
  const [showPicker, setShowPicker] = useState(false);
  const [innerString, setInnerString] = useState("");
  const navigate = useNavigate();
  const { handleAddToCart } = useCart();
  const screenWidth = window.innerWidth;
  useLayoutEffect(() => {
    fetchProductID(productId);
  }, [fetchProductID, productId]);
  useEffect(() => {
    if (product) {
      if (product.comment === null) {
        setHadRated(false);
        setRating(null);
      }
      if (screenWidth < 628) ref.current.scrollIntoView({ behavior: "smooth" });
    }
  }, [fetchProductID, productId]);
  const clearEditorState = () => {
    $("#editor").html("");
    setContent("");
    setShowPicker(false);
    setInnerString("");
  };
  const submitComment = () => {
    if (!current_user) {
      return toastErrorLogin(isMobile);
    }
    if (!validateStringEditor(comment)) {
      return toastError("Type your content");
    }
    if (validateStringEditor(comment) === "too long") {
      return toastError("Do not more than  one thousand letters");
    }
    if (rating !== null) {
      setHadRated(true);
    }
    const commentData = {
      commentable_id: product?.id,
      commentable_type: "Product",
      content: comment,
    };
    if (!hadRated) {
      commentData.rate = rating | 0;
    }
    createCommentProduct(commentData);
    clearEditorState();
  };
  const actionFavourite = () => {
    if (current_user === null) {
      toastErrorLogin(isMobile);
    } else {
      if (product.is_favourite) {
        deleteFavoriteProduct({
          id: product.favourite_id,
          productId: product?.id,
        });
      } else {
        createFavoriteProduct({
          classable_type: product?.classable_type,
          classable_id: product?.classable_id,
        });
      }
    }
  };

  const addToCart = async (product) => {
    let updatedProducts = {
      id: product?.id,
      name: product?.title,
      image: product?.images[0],
      price: product?.retail_price,
      shop_id: product?.shop_id,
      shop_name: product?.shop?.name,
      shop_image: product?.shop?.image,
      quantity: 1,
      unit: product?.unit_name,
      user_id: current_user?.id,
    };
    await handleAddToCart(updatedProducts);
    Swal.fire(
      "Thank you!",
      "Product have added!",
      "success"
    ).then((result) => {
      if (result.isConfirmed) {
        navigate("/gio-hang");
      }
    });
  };
  return (
    <ScrollToTop>
      <div className="detail-product">
        <AddMetaTags title={product?.title} />
        <div className="row">
          <LeftSidebar />
          <div className="col-md-6 col-sm-12 order-md-1 order-xs-2" ref={ref}>
            <div className="cont-center">
              <Breadcumb product={product} />
              <MainContainer
                listImage={listImage}
                product={product}
                addToCart={addToCart}
                actionFavourite={actionFavourite}
                commnetRef={commnetRef}
              />
              <div className="cmt-box">
                <h2 className="fs-4 font-italic" ref={commnetRef}>
                Product's Reviews
                </h2>
                <div className="inpt-cntn cmnt-box position-relative">
                  <div className="editor">
                    <Editor
                      component="div"
                      setShowPicker={setShowPicker}
                      showPicker={showPicker}
                      onChange={setComment}
                      value={comment}
                      createComment={submitComment}
                      setInnerString={setInnerString}
                      innerString={innerString}
                      placeholder="Type your comment"
                    />
                  </div>
                  {product?.is_bought && (
                    <div className="d-flex justify-content-between mt-4">
                      <div className="d-flex align-items-center">
                        <span className="mr-2 mt-1 fs-6 fw-semibol font-italic">
                         Let's rate
                        </span>
                        <ReactStars
                          key={content}
                          count={5}
                          size={28}
                          activeColor="#ffd700"
                          value={isCommented ? rate : rating}
                          edit={!isCommented && !hadRated}
                          half={false}
                          onChange={(value) => setRating(value)}
                        />
                      </div>
                    </div>
                  )}
                </div>
                <CommentArea product={product} current_user={current_user} />
              </div>
              <ProductRelation data={data} />
            </div>
          </div>
          <SidebarCatalogueOfProduct />
        </div>
      </div>
    </ScrollToTop>
  );
}

const mapStateToProps = (state) => {
  return {
    productDetail: state.product.productDetail,
    relationProducts: state.product.relationProducts,
    commentProducts: state.product.commentProducts,
    listAnswersComment: state.product.listAnswerComment,
  };
};

const mapDispatchToProps = (dispatch) => ({
  productActionsCreator: bindActionCreators(productActions, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(ProductDetail);
