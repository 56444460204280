import * as postConst from 'constants/post'
import * as articleConst from 'constants/article'
import { toastError } from 'helpers/toastHelper'

const initialState = {
  listPostCatalogues: {
    data: [],
    error: null,
    isLoading: false
  }
}

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case postConst.FETCH_POST_CATALOGUE: {
      return {
        ...state,
        listPostCatalogues: {
          data: initialState.listPostCatalogues.data,
          isLoading: true,
          error: null
        }

      };
    }
    case postConst.FETCH_POST_CATALOGUE_SUCCESS: {
      const { data } = action.payload;
      return {
        ...state,
        listPostCatalogues: {
          data: data.data,
          isLoading: false,
          error: null
        },
      };
    }
    case postConst.FETCH_POST_CATALOGUE_FAILED: {
      const { error } = action.payload;
      toastError(error);
      return {
        ...state,
        listPostCatalogues: {
          data: initialState.listPostCatalogues.data,
          isLoading: false,
          error: error
        }

      };
    }
    case articleConst.FETCH_OBJECT_HASHTAG_SUCCESS: {
      const { data } = action.payload;
      return {
        ...state,
        listPostCatalogues: {
          data: data.data,
          isLoading: false,
          error: data
        }
      }
    }
    default:
      return state;
  }
}

export default reducer
