import { call, put } from "redux-saga/effects";
import { toastError, toastSuccess } from "helpers/toastHelper";
import {
  fetchArticleSuccess,
  fetchArticleFailed,
  fetchArticleIDSuccess,
  fetchArticleIDFailed,
  fetchSidebarArticleSuccess,
  fetchSidebarArticleFailed,
  fetchRelationArticle,
  fetchRelationSuccess,
  fetchRelationFailed,
  fetchCommentArticle,
  fetchCommentArticleSuccess,
  fetchCommentArticleFailed,
  fetchFeatureArticleSuccess,
  fetchFeatureArticleFailed,
  fetchFavoriteArticleSuccess,
  fetchFavoriteArticleFailed,
  createFavoriteArticleSuccess,
  createFavoriteArticleFailed,
  fetchObjectHashTagSuccess,
  fetchObjectHashTagFailed,
  deleteFavoriteArticleSuccess,
  deleteFavoriteArticleFailed,
  fetchAnswerCommentArticleSuccess,
  createAnswerCommentArticleSuccess,
  loadMoreCommentsArticlesSuccess,
  reportCommentArticleFailed,
  userReplyCommentSuccess,
  userReplyCommentFailed,
  updateCommentSuccess,
  updateCommentFailed,
  updateChildCommentSuccess,
  updateChildCommentFailed,
  managerUpdateChildCommentSuccess,
  managerUpdateCommentSuccess,
  managerDestroyCommentSuccess,
  userDestroyChildCommentSuccess,
  userDestroyCommentSuccess,
  fetchLoadMoreCommentSuccess,
  fetchArticle,
  createCommentArticleSuccess,
  managerDestroyChildCommentSuccess,
  createLikeCommentArticleSuccess,
  createUnLikeCommentArticleSuccess
} from "actions/article";

import {
  managerUpdateComment,
  managerUpdateAnswerComment,
  managerDeleteComment,
  managerDeleteSubComment,
} from "apis/manager";

import {
  getArticles,
  getArticleId,
  getRelationArticles,
  getFavoritesArticles,
  createFavoriteArticle,
  getObjectHashTag,
  deleteFavoriteArticle,
  likeComment,
  UnlikeComment,
} from "apis/article";
import { STATUS_CODE } from "constants/index";
import {
  getComment,
  createComment,
  getAnswersComment,
  replyComment,
  reportComment,
  updateComment,
  updateCommentAnswer,
  destroyComment,
  destroyChildComment,
} from "apis/comment";
import { track_activities } from "apis/track_activity";
import { setParamsTrackActivity } from "lib";

export function* userDestroyCommentArticle({ payload }) {
  try {
    const { id } = payload.id;
    const resp = yield call(destroyComment, id);
    const { data, status } = resp;
    if (status === STATUS_CODE.SUCCESS) {
      yield put(userDestroyCommentSuccess(id));
    }
  } catch (error) {}
}
export function* userDestroyChildCommentArticle({ payload }) {
  try {
    const { id } = payload.id;
    const resp = yield call(destroyChildComment, id);
    const { status } = resp;
    if (status === STATUS_CODE.SUCCESS) {
      yield put(userDestroyChildCommentSuccess({ id: id, data: "success" }));
    }
  } catch (error) {}
}
export function* managerDestroyCommentArticle({ payload }) {
  try {
    const { id } = payload.id;
    const resp = yield call(managerDeleteComment, id);
    const { data, status } = resp;
    if (status === STATUS_CODE.SUCCESS) {
      yield put(managerDestroyCommentSuccess(id));
    }
  } catch (error) {}
}
export function* managerDestroyChildCommentArticle({ payload }) {
  try {
    const { id } = payload.id;
    const resp = yield call(managerDeleteSubComment, id);
    const { status } = resp;
    if (status === STATUS_CODE.SUCCESS) {
      yield put(managerDestroyChildCommentSuccess({ id: id, data: "success" }));
    }
  } catch (error) {}
}
export function* managerUpdateCommentArticle({ payload }) {
  try {
    const { params } = payload;
    const resp = yield call(managerUpdateComment, params);
    const { data, status } = resp;
    if (status === STATUS_CODE.SUCCESS) {
      yield put(managerUpdateCommentSuccess(data));
    }
  } catch (error) {
    toastError(error);
  }
}
export function* managerUpdateChildCommentArticle({ payload }) {
  try {
    const { params } = payload;
    const resp = yield call(managerUpdateAnswerComment, params);
    const { data, status } = resp;
    if (status === STATUS_CODE.SUCCESS) {
      yield put(managerUpdateChildCommentSuccess(data));
    }
  } catch (error) {
    toastError(error);
  }
}
export function* UpdateCommentArticle({ payload }) {
  try {
    const { params } = payload;
    const resp = yield call(updateComment, params);
    const { data, status } = resp;
    if (status === STATUS_CODE.SUCCESS) {
      yield put(updateCommentSuccess(data));
    } else {
      yield put(updateCommentFailed(data));
    }
  } catch (error) {}
}
export function* UpdateChildComment({ payload }) {
  try {
    const { params } = payload;
    const resp = yield call(updateCommentAnswer, params);
    const { data, status } = resp;
    if (status === STATUS_CODE.SUCCESS) {
      yield put(updateChildCommentSuccess(data));
    } else {
      yield put(updateChildCommentFailed(data));
    }
  } catch (error) {
    toastError(error);
  }
}

export function* userReplyComment({ payload }) {
  try {
    const { params } = payload;
    const resp = yield call(replyComment, params);
    const { data, status } = resp;
    if (status === STATUS_CODE.SUCCESS) {
      yield put(userReplyCommentSuccess(data));
    } else {
      yield put(userReplyCommentFailed(data));
    }
  } catch (error) {
    toastError(error);
  }
}

export function* userReportCommentArticle({ payload }) {
  try {
    const { params } = payload;
    const resp = yield call(reportComment, params);
    const { data, status } = resp;
    if (status === STATUS_CODE.SUCCESS) {
      toastSuccess("Báo cáo bình luận thành công!");
    } else {
      yield put(reportCommentArticleFailed(data));
    }
  } catch (error) {}
}

export function* fetchArticles({ payload }) {
  try {
    const { params } = payload;
    const resp = yield call(getArticles, params);
    const { data, status } = resp;
    if (status === STATUS_CODE.SUCCESS) {
      yield put(fetchArticleSuccess(data));
    } else {
      yield put(fetchArticleFailed(data));
    }
  } catch (error) {}
}

export function* fetchSidebarArticles({ payload }) {
  try {
    const { params } = payload;
    const resp = yield call(getArticles, params);
    const { data, status } = resp;
    if (status === STATUS_CODE.SUCCESS) {
      yield put(fetchSidebarArticleSuccess(data));
    } else {
      yield put(fetchSidebarArticleFailed(data));
    }
  } catch (error) {}
}

export function* fetchArticleDetail({ payload }) {
  try {
    const { id } = payload;
    const resp = yield call(getArticleId, id);
    var checkVideoOrArticle = window.location.pathname.split("/")[1]; //tách chuỗi để lấy tham số của pathname
    const { data, status } = resp;
    if (status === STATUS_CODE.SUCCESS) {
      yield put(fetchArticleIDSuccess(data));
      yield put(
        fetchArticle({
          catalogue_id: data.data.article_catalogue_id,
          article_type: checkVideoOrArticle === "videos" ? "Video" : "Article",
        })
      );
      yield put(
        fetchRelationArticle({
          page: 1,
          limit: 5,
          id: data.data.id,
          article_type: checkVideoOrArticle === "videos" ? "Video" : "Article",
        })
      );
      yield put(
        fetchCommentArticle({
          classable_type: data.data.classable_type,
          classable_id: data.data.id,

          sort: "total_likes",
        })
      );
    } else {
      yield put(fetchArticleIDFailed(data));
    }
  } catch (error) {
    toastError(error);
  }
}

export function* fetchRelationArticles({ payload }) {
  try {
    const { params } = payload;
    const resp = yield call(getRelationArticles, params);
    const { data, status } = resp;
    if (status === STATUS_CODE.SUCCESS) {
      yield put(fetchRelationSuccess(data));
    } else {
      yield put(fetchRelationFailed(data));
    }
  } catch (error) {}
}

export function* fetchUserCommentArticles({ payload }) {
  try {
    const { params } = payload;
    const resp = yield call(getComment, params);
    const { data, status } = resp;
    if (status === STATUS_CODE.SUCCESS) {
      yield put(fetchCommentArticleSuccess(data));
    } else {
      yield put(fetchCommentArticleFailed(data));
    }
  } catch (error) {
    toastError(error);
  }
}

export function* fetchUserAnswerCommentArticles({ payload }) {
  try {
    const { params } = payload;
    const resp = yield call(getAnswersComment, params);
    const { data, status } = resp;
    if (status === STATUS_CODE.SUCCESS) {
      yield put(fetchAnswerCommentArticleSuccess(data));
    }
  } catch (err) {}
}

export function* userCreateCommentArticles({ payload }) {
  try {
    const { params } = payload;
    const resp = yield call(createComment, params);
    const { data, status } = resp;
    if (status === STATUS_CODE.SUCCESS) {
      yield put(createCommentArticleSuccess(data));
    } else {
      yield put(fetchCommentArticleFailed(data));
    }
  } catch (error) {
    toastError(error);
  }
}

export function* fetchFeatureArticles({ payload }) {
  try {
    const { params } = payload;
    const resp = yield call(getArticles, params);
    const { data, status } = resp;
    if (status === STATUS_CODE.SUCCESS) {
      yield put(fetchFeatureArticleSuccess(data));
      if (data.data.length > 0) {
        yield put(
          fetchCommentArticle({
            classable_type: "Article",
            classable_id: data.data[0]["classable_id"],
          })
        );
      }
    } else {
      yield put(fetchFeatureArticleFailed(data));
    }
  } catch (error) {}
}

export function* fetchFavoriteArtiles() {
  try {
    const resp = yield call(getFavoritesArticles);
    const { data, status } = resp;

    if (status === STATUS_CODE.SUCCESS) {
      yield put(fetchFavoriteArticleSuccess(data));
    } else {
      yield put(fetchFavoriteArticleFailed(data));
    }
  } catch (error) {}
}

export function* createFavoriteArticles({ payload }) {
  try {
    const { params } = payload;
    const resp = yield call(createFavoriteArticle, params);
    const response = yield call(getFavoritesArticles);
    let { data: dataFavourite, status: statusArticle } = response;
    const { data, status } = resp;
    if (status === STATUS_CODE.SUCCESS && statusArticle === STATUS_CODE.SUCCESS) {
      yield put(fetchFavoriteArticleSuccess(dataFavourite));
      yield put(createFavoriteArticleSuccess(data));
    } else {
      yield put(createFavoriteArticleFailed(data));
    }
  } catch (error) {}
}

export function* deleteFavoriteArticles({ payload }) {
  try {
    const { params } = payload;
    const resp = yield call(deleteFavoriteArticle, params);
    const response = yield call(getFavoritesArticles);
    let { data: dataFavourite, status: statusArticle } = response;
    let { data, status } = resp;
    if (status === STATUS_CODE.SUCCESS && statusArticle === STATUS_CODE.SUCCESS) {
      yield put(fetchFavoriteArticleSuccess(dataFavourite));
      yield put(deleteFavoriteArticleSuccess(data));
    } else {
      yield put(deleteFavoriteArticleFailed(data));
    }
  } catch (error) {}
}

export function* fetchObjectHashTags({ payload }) {
  try {
    const { params } = payload;
    const resp = yield call(getObjectHashTag, params);
    const { data, status } = resp;
    if (status === STATUS_CODE.SUCCESS) {
      yield put(fetchObjectHashTagSuccess(data));
    } else {
      yield put(fetchObjectHashTagFailed(data));
    }
  } catch (error) {}
}

export function* UserReplyCommentArticle({ payload }) {
  try {
    const { params } = payload;
    const resp = yield call(replyComment, params);
    const { data, status } = resp;
    if (status === STATUS_CODE.SUCCESS) {
      yield put(createAnswerCommentArticleSuccess(data));
    }
  } catch (error) {}
}

export function* UserCreateLikeComment({ payload }) {
  try {
    const { params } = payload;
    const resp = yield call(likeComment, params);
    const { data, status } = resp;
    if (status === STATUS_CODE.SUCCESS) {
      yield put(createLikeCommentArticleSuccess(data))
      // yield put(
      //   fetchCommentArticle({
      //     classable_type: params.type,
      //     classable_id: params.id,
      //   })
      // );
    }
  } catch (error) {}
}

export function* UserCreateUnLikeComment({ payload }) {
  try {
    const { params } = payload;
    const resp = yield call(UnlikeComment, params);
    const { data, status } = resp;
    if (status === STATUS_CODE.SUCCESS) {
      yield put(createUnLikeCommentArticleSuccess(params))
      // yield put(
      //   fetchCommentArticle({
      //     classable_type: params.type,
      //     classable_id: params.id,
      //   })
      // );
    }
  } catch (error) {}
}

export function* UserLoadMoreCommentsArticles({ payload }) {
  try {
    const { params } = payload;
    const resp = yield call(getComment, params);
    const { data, status } = resp;

    if (status === STATUS_CODE.SUCCESS) {
      yield put(loadMoreCommentsArticlesSuccess(data));
    }
  } catch (error) {}
}

export function* fetchLoadMoreCommentsArticles({ payload }) {
  try {
    const { params } = payload;

    const resp = yield call(getComment, params);
    const { data, status } = resp;
    if (status === STATUS_CODE.SUCCESS) {
      yield put(fetchLoadMoreCommentSuccess(data));
    }
  } catch (error) {}
}
