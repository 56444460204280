import { call, put, select } from "redux-saga/effects";
import { getPostDetail, likePost, unlikePost } from 'apis/post'
import {
	commentPostDetailSuccess,
	fetchCommentPostSuccess,
	fetchCommentPostFail,
	fetchSubCommentPostSuccess, likeCommentPostDetailSuccess,
	likePostDetailSuccess,
	updateCommentWithPattern, modifyReplyData, fetchCommentPost,
	editCommentPostDetailSuccess, 
	modifyEditData, 
	deleteCommentPostDetailSuccess,
	deleteSubCommentPostDetailSuccess,
	editSubCommmentPostDetailSuccess,
	fastFollowUserSuccess,
	fastUnFollowUserSuccess
} from "../../redux/actions/postDetailActions";
import {
	getComment,
	getAnswersComment,
	createComment,
	replyComment,
	updateComment,
	destroyComment,
	destroyChildComment,
	updateCommentAnswer
} from "apis/comment";
import {followItem, unFollow} from 'apis/auth';
import { get, merge, omit, mapValues, mapKeys} from "lodash";
import {STATUS_CODE} from "constants";

export function* fetchInitialDataDetailPage({ payload }) {
	const { postId, imageId } = payload;
	try {
		const { meta } = yield select(state => state.postDetail.comments)
		const resp = yield call(getPostDetail, postId);
		const post = resp.data.data;

		if (imageId) {
			yield put({
				type: 'FETCH_POST_DETAIL_SUCCESS',
				payload: {
					data: post,
					postDisplay: post.images.find(img => `${img.id}` === imageId)
				}
			})
			yield put({
				type: "MODIFY_META_COMMENT", payload: {
					...meta,
					classable_id: imageId,
					classable_type: "Image"
				}
			})
		} else {
			yield put({
				type: 'FETCH_POST_DETAIL_SUCCESS',
				payload: {
					data: post,
					postDisplay: post
				}
			})

			yield put({
				type: "MODIFY_META_COMMENT", payload: {
					...meta,
					classable_id: postId,
					classable_type: "Post"
				}
			})
		}
		yield put(fetchCommentPost({
			page: 1
		}))
	} catch (error) {

	}
}

export function* fetchCommentPostDetail({ payload }) {
	const { page } = payload
	try {
		const { meta } = yield select(state => state.postDetail.comments)
		const { nextPage, classable_id, classable_type } = meta

		const resp = yield call(getComment, {
			classable_id,
			classable_type,
			page: page ? page : nextPage,
			limit: 5
		});

		const { data } = resp.data;
		if (data.length > 0) {
			const objectComments = data.reduce((result, item) => (
				{
					...result,
					[`comment-${item['id']}`]: {
						...item,
						children: {},
						extraData: {
							loading: false,
							page: 1,

						}
					}
				}), {})
			yield put(fetchCommentPostSuccess({
				entity: objectComments,
			}));
		} else {
			yield put(fetchCommentPostFail())
		}
	} catch (error) {
		console.log(error)
	}
}


export function* userLikePostDetail({ payload }) {
	const liked = payload.liked

	delete payload.liked

	try {
		if (!liked) {
			yield call(likePost, { ...payload });
		} else {
			yield call(unlikePost, { ...payload });
		}
		yield put(likePostDetailSuccess())
	} catch (error) {
		console.log(error)
	}
}

export function* likeCommentPostDetail({ payload }) {
	const liked = payload.liked
	const pattern = payload.pattern

	delete payload.liked
	delete payload.pattern

	try {
		if (!liked) {
			yield call(likePost, { ...payload });
		} else {
			yield call(unlikePost, { ...payload });
		}
		yield put(likeCommentPostDetailSuccess({ pattern }))

	} catch (error) {
		console.log(error)
	}
}
export function* fetchSubCommentPostDetail({ payload }) {
	const { id, page } = payload
	try {
		const response = yield call(getAnswersComment, { id, page, limit: 5 })
		const { data } = response.data
		const objectComments = data.reduce((result, item) => (
			{
				...result,
				[`comment-${item['id']}`]: {
					...item,
					children: {},
					extraData: {
						loading: false,
						page: 1,

					}
				}
			}), {})
		yield put(fetchSubCommentPostSuccess({ data: objectComments, id }))

	} catch (error) {
		console.log(error)
	}
}

export function* userCommentPostDetail({ payload }) {
	try {
		const { meta } = yield select(state => state.postDetail.comments)
		const { classable_id, classable_type } = meta

		const response = yield call(createComment, {
			content: payload.content,
			commentable_id: classable_id,
			commentable_type: classable_type,
		})
		yield put(commentPostDetailSuccess(response.data))

	} catch (error) {
		console.log(error)
	}
}
export function* userReplyCommentPostDetail({ payload }) {
	const { params, pattern } = payload
	try {
		const { replyData, entity } = yield select(state => state.postDetail.comments)
		const response = yield call(replyComment, {
			...params,
			parent_id: replyData.parentId,
			replier_id: replyData.replierId,
			id: replyData.id,
		})
		const currentComment = get(entity, pattern)

		yield put(updateCommentWithPattern({
			pattern,
			data: merge(currentComment, {
				[`comment-${response.data.data.id}`]: {
					...response.data.data,
					children: {}
				}
			})
		}))
		yield put(modifyReplyData({
			replierId: null,
			parentId: null,
			id: null
		}))
	} catch (error) {

	}
}

export function* userEditCommentPostDetail({payload}){
	const { params } = payload;
	try {
		const { editData} = yield select(state => state.postDetail.comments);
			const response = yield call(updateComment, {
				id: editData.id,
				...params
			});
			yield put(editCommentPostDetailSuccess(merge({}, {
				...response.data.data,
				children: {},
				extraData: {
					loading: false,
					page: 1,
				}
			})))
			yield put(modifyEditData({
				replierId: null,
				parentId: null,
				id: null
			}))
	} catch (error) {
		console.log(error);
	}
}

export function* userDeleteCommentPostDetail({payload}){
	const {params} = payload;
	try {
		const response = yield call(destroyComment, params.id);
		const {status} = response;
		if (STATUS_CODE.SUCCESS === status) {
			yield put(deleteCommentPostDetailSuccess({id: params.id}))
		}
	} catch (error) {
		console.log(error);
	}
}

export function* userDeleteSubCommentPostDetail({payload}){
	const {params, pattern}= payload;
	try {
		const {id, parentId} = params;
		const { entity } = yield select(state => state.postDetail.comments);
		const response = yield call(destroyChildComment, params.id);
		const currentComment = get(entity, pattern);
		const commentIndex = `comment-${id}`;
		const data = omit(currentComment, commentIndex);
		const {status} = response;
		if (STATUS_CODE.SUCCESS === status) {
			yield put(deleteSubCommentPostDetailSuccess({data: data, parentId: parentId}))
		}
	} catch (error) {
		console.log(error);
	}
}

export function* userEditSubCommentPostDetail({payload}){
	const {params, pattern}= payload;
	try {
		const {parentId} = params;
		const { editData, entity} = yield select(state => state.postDetail.comments);
		const response = yield call(updateCommentAnswer, {id: editData.id, ...params});
		const {data, status} = response;
		const currentComment = get(entity, pattern);
		const commentIndex = `comment-${editData.id}`; // index của object children comment

		const updatedObj = mapValues(mapKeys(currentComment, (value, key) => (key === commentIndex ? commentIndex : key)), (value, key) => {
			if (key === commentIndex) {
				return {...data.data, 
					children: {},
					extraData: {
						loading: false,
						page: 1,
				}};
			}
			return value;
		});
		if(status === STATUS_CODE.SUCCESS){
			yield put(editSubCommmentPostDetailSuccess({data: updatedObj, parentId: parentId}));
		}
		yield put(modifyEditData({
			replierId: null,
			parentId: null,
			id: null
		}))
	} catch (error) {
		
	}
}

export function* userFastFollowUserInPost({payload}){
	const {params} = payload;
	const {shop_id} = params;
	const { data} = yield select(state => state.postDetail.post);

  try {
    const response = yield call(followItem, {
      classable_type: "Shop",
      classable_id: shop_id,
    });
    const {status} = response;
    if (STATUS_CODE.SUCCESS === status) {
			const dataModified = {...data, user_followed_shop: true};
			yield put(fastFollowUserSuccess(dataModified));
    }
  } catch (error) {
    console.log(error);
  }
}

export function* userFastUnFollowUserInPost({payload}){
	const {params} = payload;
	const {shop_id} = params;
	const { data} = yield select(state => state.postDetail.post);

  try {
    const response = yield call(unFollow, {
      classable_type: "Shop",
      classable_id: shop_id,
    });
    const {status} = response;
    if (STATUS_CODE.SUCCESS === status) {
			const dataModified = {...data, user_followed_shop: false};
			yield put(fastUnFollowUserSuccess(dataModified));
    }
  } catch (error) {
    console.log(error);
  }
}