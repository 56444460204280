function Loader({ isLoading }) {
  return (
    <>
      {isLoading && (
        <div className="position-relative">
          <div className="loading-style position-absolute">
            <span></span>
            <span></span>
            <span></span>
          </div>
        </div>  
      )}
    </>
  );
}

export default Loader;
