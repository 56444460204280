import React from "react";
import AddMetaTags from "components/add_meta_tags";
import LeftSidebar from "components/left_sidebar";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import * as fertilizerActions from "actions/fertilizer";
import TabCalculatorReverse from "./components/tabCalculatorReverse";
import TabCalculatorNPK from './components/tabCalculatorNPK';
import TabChangeNPK from "./components/tabChangeNPK";
function NPK() {
  const resetDisable = () => {
    for (let  i = 0; i < $('input:disabled').length; i++) {
      $('input:disabled').removeAttr("disabled");
    }
    for (let  i = 0; i < $('input:checked').length; i++) {
     $('input:checked').prop( "checked", false );
    }
  }

  return (
    <>
      <AddMetaTags title={"NPK"} />
      <div className="npk">
        <div className="row">
          <LeftSidebar />
          <div className="col-md-9 col-sm-12">
            <div className="cont-center saved-items">
              <ul
                className="nav nav-tabs justify-content-evenly"
                id="myTab"
                role="tablist"
              >
                <li className="nav-item" role="presentation">
                  <button
                  onClick={() => resetDisable()}
                    className="nav-link active"
                    id="news-tab"
                    data-bs-toggle="tab"
                    data-bs-target="#news-saved"
                    type="button"
                    role="tab"
                    aria-controls="news-saved"
                    aria-selected="true"
                  >
                    Phối trộn NPK
                    
                  </button>
                </li>
                <li className="nav-item" role="presentation">
                  <button
                    onClick={() => resetDisable()}
                    className="nav-link"
                    id="prod-tab"
                    data-bs-toggle="tab"
                    data-bs-target="#prod-saved"
                    type="button"
                    role="tab"
                    aria-controls="prod-saved"
                    aria-selected="false"
                  >
                    Xác định NPK thừa/thiếu
                  </button>
                </li>
                <li className="nav-item" role="presentation">
                  <button
                    onClick={() => resetDisable()}
                    className="nav-link"
                    id="change-tab"
                    data-bs-toggle="tab"
                    data-bs-target="#change-npk"
                    type="button"
                    role="tab"
                    aria-controls="change-npk"
                    aria-selected="false"
                  >
                   Trộn NPK loại này sang loại khác 
                  </button>
                </li>
              </ul>
              <div className="tab-content mt-3">
                <div
                  className="tab-pane fade show active"
                  id="news-saved"
                  role="tabpanel"
                  aria-labelledby="news-tab"
                >
                  <TabCalculatorNPK />
                </div>
                <div
                  className="tab-pane fade"
                  id="prod-saved"
                  role="tabpanel"
                  aria-labelledby="prod-tab"
                >
                  <TabCalculatorReverse />
                </div>
                <div
                  className="tab-pane fade"
                  id="change-npk"
                  role="tabpanel"
                  aria-labelledby="change-tab"
                >
                  <TabChangeNPK />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

const mapStateToProps = (state) => {
  return {
    fertilizer_list: state.fertilizer.list_fertilizer,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    fertilizerActions: bindActionCreators(fertilizerActions, dispatch),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(NPK);
