import * as postConst from "constants/post";
import * as managerConst from "constants/manager";
import * as authConst from "constants/auth";
import { toastError, toastSuccess } from "helpers/toastHelper";
import { checkLoadMore } from "lib";

const initialState = {
  post: {
    data: {},
    meta: {
      isLoading: true,
      deleteData: null,
    },
    comments: {
      replyData: {
        replierId: null,
        parentId: null,
        id: null,
      },
      editData: {
        replierId: null,
        parentId: null,
        id: null,
      },
      isLoading: false,
      meta: {
        nextPage: 1,
        classable_id: null,
        classable_type: null,
        displayLoadmore: true,
      },
      entity: {},
    },
  },

  listPosts: {
    data: [],
    error: null,
    isLoading: false,
    isLoadMore: false,
    meta: {
      deleteData: null,
    },
  },
  listHighlightPosts: {
    data: [],
    error: null,
    isLoading: false,
  },
  listCommentsPost: {
    data: [],
    error: null,
    isLoading: false,
    isLoadMore: false,
  },
  listCommentsAnswer: {
    data: [],
    error: null,
    isLoading: false,
    isLoadMore: false,
  },
  updateComment: {
    data: null,
    error: null,
    isLoading: false,
  },
  createComment: {
    data: null,
    error: null,
    isLoading: false,
  },
  postDetail: {
    data: null,
    error: null,
    isLoading: false,
  },
  createReplyComment: {
    data: null,
    error: null,
    isLoading: false,
  },
  listPickTops: {
    data: [],
    isLoading: false,
    error: null,
  },
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case postConst.FETCH_COMMENT_POST: {
      return {
        ...state,
        listCommentsPost: {
          data: initialState.listCommentsPost.data,
          isLoading: true,
          error: null,
          isLoadMore: true,
        },
      };
    }

    case postConst.FETCH_COMMENT_POST_SUCCESS: {
      const { data } = action.payload;
      const checkLoadMore = data.data.length <= 5 ? false : true;
      return {
        ...state,
        listCommentsPost: {
          data: data.data.slice(0, 5),
          isLoading: false,
          error: null,
          isLoadMore: checkLoadMore,
        },
      };
    }

    case postConst.FETCH_COMMENT_POST_FAILED: {
      const { error } = action.payload;
      return {
        ...state,
        listCommentsPost: {
          data: initialState.listCommentsPost.data,
          isLoading: false,
          error: error,
          isLoadMore: false,
        },
      };
    }

    case postConst.FETCH_POST: {
      return {
        ...state,
        listPosts: {
          data: initialState.listPosts.data,
          isLoading: true,
          error: null,
          meta: {
            deleteData: initialState.listPosts.meta.deleteData,
          },
        },
      };
    }
    case postConst.FETCH_POST_SUCCESS: {
      const { data } = action.payload;
      return {
        ...state,
        listPosts: {
          data: data.data,
          isLoading: false,
          error: null,
          isLoadMore: checkLoadMore(data),
          meta: {
            deleteData: null,
          },
        },
      };
    }
    case postConst.FETCH_POST_FAILED: {
      const { error } = action.payload;
      toastError(error);
      return {
        ...state,
        listPosts: {
          data: initialState.listPosts.data,
          isLoading: false,
          error: error,
          isLoadMore: false,
        },
      };
    }

    case postConst.ADD_POST_SUCCESS: {
      const { data } = action.payload.data;
      state.listPosts.data.unshift(data);
      toastSuccess("Tạo bài đăng thành công");
      return {
        ...state,
        listPosts: {
          data: state.listPosts.data,
          isLoading: false,
          error: null,
          isLoadMore: true,
        },
      };
    }

    case postConst.ADD_POST_FAILED: {
      const { error } = action.payload;
      toastError(error);
      return {
        ...state,
        listPosts: {
          data: initialState.listPosts.data,
          isLoading: false,
          error: error,
          isLoadMore: false,
        },
      };
    }
    case postConst.LIKE_POST_SUCCESS: {
      const { data } = action.payload.data;
      return {
        ...state,
        listPosts: {
          data: state.listPosts.data.map((post) => {
            if (post.id === data.likeable_id) {
              post.total_like = post.total_like + 1;
              post.user_liked = true;
            }
            return post;
          }),
          isLoading: false,
          error: null,
          isLoadMore: true,
        },
      };
    }
    case postConst.LIKE_POST_DETAIL_PAGE_SUCCESS: {
      const { data } = action.payload.data;
      return {
        ...state,
        postDetail: {
          ...state.postDetail,
          data: {
            ...state.postDetail.data,
            user_liked: true,
            total_like: state.postDetail.data.total_like + 1,
          },
          isLoading: false,
          error: null,
          isLoadMore: true,
        },
      };
    }
    case postConst.UNLIKE_POST_DETAIL_PAGE_SUCCESS: {
      const { data } = action.payload.data;
      return {
        ...state,
        postDetail: {
          ...state.postDetail,
          data: {
            ...state.postDetail.data,
            user_liked: false,
            total_like: state.postDetail.data.total_like - 1,
          },
          isLoading: false,
          error: null,
          isLoadMore: true,
        },
      };
    }

    case postConst.LIKE_COMMENT_SUCCESS: {
      const { data } = action.payload.data;
      if (data.likeable_type !== "SubComment") {
        return {
          ...state,
          listCommentsPost: {
            data: state.listCommentsPost.data.map((comment) => {
              if (comment.id === data.likeable_id) {
                comment.total_likes = comment.total_likes + 1;
                comment.user_liked = true;
              }
              return comment;
            }),
            isLoading: false,
            error: null,
            isLoadMore: state.listCommentsPost.isLoadMore,
          },
        };
      } else {
        return {
          ...state,
          listCommentsAnswer: {
            data: state.listCommentsAnswer.data.map((answer) => {
              if (answer.id == data.likeable_id) {
                answer.total_likes += 1;
                answer.user_liked = true;
              }
              return answer;
            }),
            isLoading: false,
            error: null,
            isLoadMore: state.listCommentsAnswer.isLoadMore,
          },
        };
      }
    }

    case postConst.UNLIKE_COMMENT_SUCCESS: {
      const { data } = action.payload;
      if (data.classable_type !== "SubComment") {
        return {
          ...state,
          listCommentsPost: {
            data: state.listCommentsPost.data.map((comment) => {
              if (comment.id == data.classable_id) {
                comment.total_likes = comment.total_likes - 1;
                comment.user_liked = false;
              }
              return comment;
            }),
            isLoading: false,
            error: null,
            isLoadMore: state.listCommentsPost.isLoadMore,
          },
        };
      } else {
        return {
          ...state,
          listCommentsAnswer: {
            data: state.listCommentsAnswer.data.map((ans) => {
              if (ans.id == data.classable_id) {
                ans.total_likes = ans.total_likes - 1;
                ans.user_liked = false;
              }
              return ans;
            }),
            isLoading: false,
            error: null,
            isLoadMore: state.listCommentsAnswer.isLoadMore,
          },
        };
      }
    }
    case postConst.UNLIKE_POST_SUCCESS: {
      const { data } = action.payload;
      return {
        ...state,
        listPosts: {
          data: state.listPosts.data.map((post) => {
            if (post.id === data.classable_id) {
              post.total_like = post.total_like - 1;
              post.user_liked = false;
            }
            return post;
          }),
          isLoading: false,
          error: null,
          isLoadMore: true,
        },
      };
    }
    case postConst.LIKE_POST_FAILED: {
      return { ...state };
    }

    case postConst.LOAD_MORE_POST: {
      return {
        ...state,
        listPosts: {
          data: state.listPosts.data,
          isLoadMore: false,
          isLoading: true,
          error: null,
        },
      };
    }
    case postConst.LOAD_MORE_POST_SUCCESS: {
      const { data } = action.payload.data;
      const isLoadMore = data.length > 0;
      return {
        ...state,
        listPosts: {
          data: [...state.listPosts.data, ...data],
          isLoadMore: isLoadMore,
          isLoading: false,
          error: null,
        },
      };
    }
    case postConst.LOAD_MORE_POST_FAILED: {
      const { error } = action.payload;
      toastError(error);
      return {
        ...state,
        listPosts: {
          data: initialState.listPosts.data,
          isLoadMore: false,
          isLoading: false,
          error: error,
        },
      };
    }
    case postConst.FETCH_HIGHLIGHT_POST: {
      return {
        ...state,
        listHighlightPosts: {
          data: initialState.listHighlightPosts.data,
          isLoading: true,
          error: null,
        },
      };
    }
    case postConst.FETCH_HIGHLIGHT_POST_SUCCESS: {
      const { data } = action.payload;
      return {
        ...state,
        listHighlightPosts: {
          data: data.data,
          isLoading: false,
          error: null,
        },
      };
    }

    case postConst.LOAD_MORE_COMMENT_POST_SUCCESS: {
      const { data } = action.payload;
      return {
        ...state,
        listCommentsPost: {
          // data: [...state.listCommentsPost.data, ...data.data],
          data: data.data,
          isLoading: false,
          isLoadMore: false,
          error: null,
        },
      };
    }

    case postConst.FETCH_HIGHLIGHT_POST_FAILED: {
      const { error } = action.payload;
      toastError(error);
      return {
        ...state,
        listHighlightPosts: {
          data: initialState.listHighlightPosts.data,
          isLoading: false,
          error: error,
        },
      };
    }
    case postConst.FETCH_USER_POST: {
      return {
        ...state,
        listPosts: {
          data: initialState.listPosts.data,
          isLoading: true,
        },
      };
    }
    case postConst.FETCH_USER_POST_SUCCESS: {
      const { data } = action.payload;
      const isLoadMore = data.length > 0;
      return {
        ...state,
        listPosts: {
          data: data.data,
          isLoading: false,
          isLoadMore: isLoadMore,
          error: null,
        },
      };
    }
    case postConst.FETCH_USER_POST_FAILED: {
      const { error } = action.payload;
      toastError(error);
      return {
        ...state,
        listPosts: {
          data: initialState.listPosts.data,
          isLoading: false,
          isLoadMore: false,
          error: error,
        },
      };
    }
    case postConst.FETCH_FAVORITES_POST: {
      return {
        ...state,
        listPosts: {
          data: initialState.listPosts.data,
          isLoading: true,
          error: null,
        },
      };
    }
    case postConst.FETCH_FAVORITES_POST_SUCCESS: {
      const { data } = action.payload;
      return {
        ...state,
        listPosts: {
          data: data.data,
          isLoading: false,
          error: null,
          isLoadMore: true,
        },
      };
    }
    case postConst.FETCH_FAVORITES_POST_FAILED: {
      const { error } = action.payload;
      toastError(error);
      return {
        ...state,
        listPosts: {
          data: initialState.listPosts.data,
          isLoading: false,
          error: error,
          isLoadMore: false,
        },
      };
    }
    case postConst.DELETE_POST_SUCCESS: {
      const { id } = action.payload;
      toastSuccess("Xóa bài viết thành công");
      return {
        ...state,
        listPosts: {
          data: state.listPosts.data.filter((post) => post.id != id),
          isLoading: false,
          error: null,
          isLoadMore: true,
        },
      };
    }
    case postConst.DELETE_COMMENT_POST_SUCCESS: {
      const { id } = action.payload;
      toastSuccess("Xóa bình luận thành công");
      return {
        ...state,
        listPosts: {
          data: state.listPosts.data,
          isLoading: false,
          error: null,
          isLoadMore: true,
        },
      };
    }
    case postConst.DELETE_POST_FAILED: {
      const { error } = action.payload;
      toastError(error);
      return { ...state };
    }
    case postConst.CREATE_COMMENT_POST_SUCCESS: {
      const { data } = action.payload;
      return {
        ...state,
        createComment: {
          data: data.data,
          isLoading: false,
          error: null,
        },
      };
    }
    case postConst.CREATE_COMMENT_POST_FAILED: {
      const { error } = action.payload;
      toastError(error);
      return { ...state };
    }
    case postConst.SHARE_POST_FAILED: {
      const { error } = action.payload;
      toastError(error);
      return { ...state };
    }
    case postConst.REPORT_POST_FAILED: {
      const { error } = action.payload;
      toastError(error);
      return { ...state };
    }
    case postConst.REPORT_COMMENT_FAILED: {
      const { error } = action.payload;
      toastError(error);
      return { ...state };
    }
    case postConst.REPLACE_POST_ID_SUCCESS: {
      const { data } = action.payload.data;
      const listPosts = state.listPosts.data;
      const index = listPosts.findIndex((post) => post.id === data.id);
      if (index !== -1) {
        const newList = [
          ...listPosts.slice(0, index),
          data,
          ...listPosts.slice(index + 1),
        ];
        return {
          ...state,
          listPosts: {
            data: newList,
            isLoading: false,
            error: null,
            isLoadMore: true,
          },
        };
      }
      return { ...state };
    }

    case postConst.FETCH_COMMENT_ANSWERS: {
      return {
        ...state,
        listCommentsAnswer: {
          data: initialState.listCommentsAnswer.data,
          isLoading: true,
          error: null,
        },
      };
    }

    case postConst.FETCH_COMMENT_ANSWERS_SUCCESS: {
      const { data } = action.payload;
      return {
        ...state,
        listCommentsAnswer: {
          data: data.data,
          isLoading: false,
          error: null,
        },
      };
    }

    case postConst.UPDATE_COMMENT: {
      return {
        ...state,
      };
    }

    case postConst.UPDATE_COMMENT_SUCCESS: {
      const { data } = action.payload;
      return {
        ...state,
        listCommentsPost: {
          data: state.listCommentsPost.data.map((comment) => {
            if (comment.id === data.data.id) {
              comment.content = data.data.content;
            }
            return comment;
          }),
          isLoading: false,
          error: null,
        },
      };
    }

    case postConst.UPDATE_ANSWER_COMMENT: {
      return {
        ...state,
        updateComment: {
          data: initialState.updateComment.data,
          isLoading: true,
          error: null,
        },
      };
    }

    case postConst.UPDATE_ANSWER_COMMENT_SUCCESS: {
      const { data } = action.payload;
      return {
        ...state,
        updateComment: {
          data: data.data,
          isLoading: false,
          error: null,
        },
      };
    }
    case postConst.USER_SHARE_ARTICLE: {
      return {
        ...state,
        listPosts: {
          data: initialState.listPosts.data,
          isLoading: true,
          error: null,
        },
      };
    }
    case postConst.USER_SHARE_ARTICLE_SUCCESS: {
      const { data } = action.payload.data;

      return {
        ...state,
        listPosts: {
          data: data.data,
          isLoading: false,
          error: null,
          isLoadMore: checkLoadMore(data),
        },
      };
    }
    case postConst.USER_SHARE_ARTICLE_FAILED: {
      const { error } = action.payload;
      toastError(error);
      return {
        ...state,
        listPosts: {
          data: initialState.listPosts.data,
          isLoading: false,
          error: error,
          isLoadMore: false,
        },
      };
    }
    case postConst.REPLY_COMMENT_POST_SUCCESS: {
      const { data } = action.payload;
      return {
        ...state,
        listCommentsPost: {
          data: state.listCommentsPost.data.map((comment) => {
            if (comment.id == data.data.comment_id) {
              comment.total_answers = comment.total_answers + 1;
            }
            return comment;
          }),
          error: null,
          isLoading: false,
        },
        listCommentsAnswer: {
          data: [data.data, ...state.listCommentsAnswer.data],
          isLoading: false,
          error: null,
        },
      };
    }

    case postConst.FETCH_DETAIL_POST_SUCCESS: {
      const { data } = action.payload.data;
      return {
        ...state,
        postDetail: {
          data: data,
          isLoading: false,
          error: null,
          isLoadMore: false,
        },
      };
    }

    case postConst.FETCH_DETAIL_POST_FAILED: {
      const { error } = action.payload;
      return {
        ...state,
        postDetail: {
          data: state.postDetail.data,
          error: error,
        },
      };
    }

    case managerConst.MANAGER_DELETE_COMMENT_POST_DETAIL_SUCCESS: {
      const { data } = action.payload;
      return {
        ...state,
        listCommentsPost: {
          data: state.listCommentsPost.data.filter(
            (comment) => comment.id !== data.id
          ),
          isLoading: false,
        },
      };
    }

    case authConst.FOLLOW_POST: {
      return {
        ...state,
      };
    }

    case authConst.FOLLOW_POST_SUCCESS: {
      const { data } = action.payload;
      return {
        ...state,
        listPosts: {
          data: state.listPosts.data.map((post) => {
            if (post.id === data.data.followable_id) {
              post.user_followed = true;
            }
            return post;
          }),
        },
      };
    }
    case authConst.UNFOLLOW_POST_SUCCESS: {
      const { data } = action.payload;
      return {
        ...state,
        listPosts: {
          data: state.listPosts.data.map((post) => {
            if (post.id === data.data.followable_id) {
              post.user_followed = false;
            }
            return post;
          }),
        },
      };
    }
    case postConst.FETCH_PICK_TOP: {
      return {
        ...state,
        listPickTops: {
          data: initialState.listPickTops.data,
          isLoading: true,
          error: null,
        },
      };
    }
    case postConst.FETCH_PICK_TOP_SUCCESS: {
      const { data } = action.payload;
      return {
        ...state,
        listPickTops: {
          data: data.data,
          isLoading: false,
          error: null,
        },
      };
    }
    case postConst.REPORT_IMAGE_POST_SUCCESS: {
      const { data } = action.payload;
      return {
        ...state,
        listCommentsPost: {
          data: data.data,
          isLoading: false,
          error: null,
          isLoadMore: false,
        },
      };
    }

    case postConst.FAST_FOLLOW_USER_IN_POST: {
      const { shopId } = action.payload;
      return {
        ...state,
        listPosts: {
          data: state.listPosts.data.map((post) => {
            if (post.shop_id === shopId) {
              post.user_followed_shop = true;
            }
            return post;
          }),
        },
      };
    }
    case postConst.FAST_UNFOLLOW_USER_IN_POST: {
      const { shopId } = action.payload;
      return {
        ...state,
        listPosts: {
          data: state.listPosts.data.map((post) => {
            if (post.shop_id === shopId) {
              post.user_followed_shop = false;
            }
            return post;
          }),
        },
      };
    }
    case postConst.MODIFY_DATA_DELETE: {
      const { params } = action.payload;
      return {
        ...state,
        listPosts: {
          data: state.listPosts.data,
          error: null,
          isLoading: false,
          isLoadMore: false,
          meta: {
            deleteData: params,
          },
        },
      };
    }

    case postConst.RESET_DATA: {
      return {
        ...state,
        ...initialState,
      };
    }

    case postConst.FETCH_ANSWER_COMMENT: {
      return {
        ...state,
        listCommentsAnswer: {
          data: initialState.listCommentsAnswer.data,
          isLoading: true,
          error: null,
        },
      };
    }
    case postConst.FETCH_ANSWER_COMMENT_SUCCESS: {
      const { data } = action.payload.data;
      return {
        ...state,
        listCommentsAnswer: {
          data: data,
          isLoading: false,
          error: null,
        },
      };
    }

    default:
      return state;
  }
};

export default reducer;
