import React, { useEffect, useState, useRef } from "react";
import { getCataloguesVideos } from "apis/catalogue";
import moment from "moment";
import "moment/locale/vi";
import VideoOfCatalogues from "./videoOfCatalogues";
import _ from "lodash";
moment.locale("vi");
function Videos(props) {
  const isMounted = useRef(true);
  let { setSearch, keyword } = props;
  let [catalogue, setCatalogue] = useState([]);
  let [activeLinkTagvideo, setActiveLinkTagVideo] = useState(null);

  useEffect(() => {
    getCataloguesVideos().then((res) => {
      if (isMounted.current) {
        let result = res["data"]["data"];
        if (result) {
          setCatalogue(result);
        }
      }
    });

    return () => {
      setSearch("");
      isMounted.current = false;
    };
  }, []);

  useEffect(() => {
    if (isMounted.current) {
      if (!keyword) {
        setActiveLinkTagVideo(null);
      }
    }
  }, [keyword]);

  return (
    <div className="col-md-6 col-sm-12">
      <div className="cont-center">
        <div className="mt-1">
          <p className="fs-3 d-flex justify-content-center text-uppercase fw-bold mb-0">
            Videos
          </p>
          {/* {featureArticle && renderFirstArticle(featureArticle)}
          <div className="row">
            {_.slice(data, 1, 5).map(function (article, index) {
              let image = validURL(article.image) ? `${article.image}` : `${DOMAIN}${article.image}`;
              return (
                <div className="col-sm-4 col-xs-6 col-md-6 col-lg-3">
                  <div className="item-lst">
                    <Link
                      key={article.id}
                      to={`/videos/${article?.slug}`}
                      className="">
                      <div className="sub_vid position-relative">
                        <div className="bg-img article-bg" style={{ backgroundImage: `url(${image})` }} />
                        <span className="video-play-icon icon-arrow-solid text-center xs-size position-absolute"></span>
                      </div>
                      <div className="prod-inf">
                        <div className="ctnt txt-name artile-title">{article.title}</div>
                      </div>
                    </Link>
                  </div>
                </div>
              );
            })}
          </div> */}
          {catalogue &&
            catalogue.map(function (group, index) {
              return (
                <div className="mt-3 video-sectn" key={index}>
                  <h5 className="title-line-lt text-uppercase fw-bold mb-0">
                    <span className="d-block">{group.name}</span>
                  </h5>
                  <div className="row">
                    <VideoOfCatalogues id={group.id} />
                  </div>
                </div>
              );
            })}
        </div>
      </div>
    </div>
  );
}
export default Videos;
