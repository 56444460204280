import React from "react";
import Modal from "react-bootstrap/Modal";
import { useFormik } from "formik";
import * as Yup from "yup";
import { useDispatch } from "react-redux";
import { editUserAlbums } from "actions/albums";
import {  Form } from "react-bootstrap";

function ModalAddAlbums({
  isOpen,
  onToggle,
  onEdit,
}) {
  const dispatch = useDispatch();

  const validationSchema = Yup.object().shape({
    name: Yup.string().required("Không thể bỏ trống tên albums"),
  });

  const formik = useFormik({
    initialValues: {
      name: "",
    },
    validationSchema: validationSchema,
    onSubmit: (values, { resetForm }) => {
      dispatch(editUserAlbums({name: formik.values.name, albumsId: isOpen}))
      resetForm();
      onToggle();
    },
  });
  return (
    <>
      <Modal
        show={isOpen}
        onHide={onToggle}
        aria-labelledby="contained-modal-title-vcenter"
        centered
        style={{zIndex: 1000000}}
      >
        <Modal.Header closeButton>
          <Modal.Title>Sửa albums</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form onSubmit={formik.handleSubmit}>
            <input
              className={`form-control ${
                formik.errors.name && "border border-danger"
              }`}
              name="name"
              placeholder="Nhập tên albums"
              value={formik.values.name}
              onChange={formik.handleChange}
            />
            {formik.touched.name && formik.errors.name && (
              <label className="alert-invalid ">{formik.errors.name}</label>
            )}
            <button type="submit" className="btn btn-secondary float-right mt-2 fw-semibold">
              Đồng ý
            </button>
          </Form>
        </Modal.Body>
        {/* <Modal.Footer>
       
          </Modal.Footer> */}
      </Modal>
    </>
  );
}

export default ModalAddAlbums;
