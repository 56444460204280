import React, { useContext, useRef } from "react";
import { CloseButton, Col, Row } from "react-bootstrap";
import Modal from "react-bootstrap/Modal";
import { useNavigate, Link, useLocation } from "react-router-dom";
import { PostDetailContext } from "../../containers/PostDetailProvider";
import {
  NewFeedActions,
  NewsFeedHeader,
  NewFeedComments,
  CommentInput,
  PreviewAttachment,
} from "components/NewsFeed";
import AppContext from "components/app_context";
import "./index.scss";
import { useTranslation } from "react-i18next";


const ModalViewImagePage = () => {
  let navigate = useNavigate();
  const { setPostIdForScroll } = useContext(AppContext);
  const { t } = useTranslation();
  const scrollToTop = () => {
    window.scrollTo(0, 0);
  };
  const {
    onClickActionsPost,
    onClickActionsComment,
    onVisisibleReplyForm,
    onUserComment,
    replyData,
    editData,
    listComments,
    post,
    isDisplayLoadmore,
    loadMoreComment,
    onNavigateFile,
    showNextButton,
    showPrevButton,
    onVisisibleEditForm,
    currentUser,
    isOwner,
    handleFastFollowUser,
  } = useContext(PostDetailContext);
  const inputRef = useRef(null);
  const location = useLocation();
  const state = location.state;
  const closeModal = () => {
    setPostIdForScroll(post.post_id);
    if (state) navigate(-1);
    else {
      navigate("/");
    }
  };

  return (
    <Modal show={true} fullscreen={true} onHide={closeModal}>
      <CloseButton
        className="close-button-modal-prevew-file text-white"
        onClick={closeModal}
      />

      {post?.id && (
        <Row className="h-100 no-gutters m-0 ModalViewContainer">
          <Col md={12} xl={9} className="bg-dark ">
            <PreviewAttachment
              type="image"
              data={post.content}
              showNextButton={showNextButton}
              navigateFile={onNavigateFile}
              showPrevButton={showPrevButton}
              isOwner={isOwner}
            />
          </Col>
          <Col xl={3} md={12}>
            <div className="h-100">
              <div className="h-100 d-flex flex-column bg-white">
                <div className="d-flex flex-column">
                  <div className="p-3">
                    <div className="align-items-center">
                      <NewsFeedHeader
                        memberRate={post.user.member_rate}
                        avatarUrl={post.user.shop_image}
                        userLevel={post.user.user_level}
                        userName={
                          post.user.shop_name === null
                            ? post.user.user_name
                            : post?.user.shop_name
                        }
                        createdAt={Date.parse(post.created_at)}
                        isOwner={isOwner}
                        post={post}
                        handleUserInteractionComment={onClickActionsComment}
                        handleFastFollowUser={handleFastFollowUser}
                      />
                    </div>
                  </div>
                  <NewFeedActions
                    onClick={onClickActionsPost}
                    liked={post.user_liked}
                  />
                </div>
                <CommentInput ref={inputRef} onSubmit={onUserComment} />
                <div className="CommentWrapper">
                  <div className="p-2">
                    <NewFeedComments
                      parent={""}
                      comments={listComments}
                      setVisibilityFormReply={onVisisibleReplyForm}
                      setVisibilityFormEdit={onVisisibleEditForm}
                      visibilityFormReply={replyData.id}
                      visibilityFormEdit={editData.id}
                      handleUserInteractionComment={onClickActionsComment}
                      currentUser={currentUser}
                    />
                  </div>
                  {isDisplayLoadmore && listComments.length >= 5 && (
                    <div className="px-3 pointer" onClick={loadMoreComment}>
                    {t("button.more")}
                    </div>
                  )}
                </div>
              </div>
            </div>
          </Col>
        </Row>
      )}
    </Modal>
  );
};

export default ModalViewImagePage;
