import React from "react";

function LoadMore({ handleLoadMore }) {
  return (
    <div>
      <div className="post-more hover  background-clr-f5 pointer  mt-3 w-100" onClick={handleLoadMore}>
        <span className="txt  fw-500">
          <u>More</u>
        </span>
        <span className="icon-arrow-solid"></span>
      </div>
    </div>
  );
}

export default LoadMore;
