import React, { useState, useEffect } from "react";
import { TabContainer, TabContent, TabPane, Nav } from "react-bootstrap";
import SearchInvoiceBox from "./searchBox";
import BuyingPage from "./buyingPage";
import SellingPage from "./sellingPage";
import { useDispatch, useSelector } from "react-redux";
import { fetchBuyingInvoices, fetchSellingInvoices } from "redux/actions/invoice";
const Tabs = () => {
  const [tabType, setTabType] = useState("buying");
  const dispatch = useDispatch();
  const listBuyingInvoices = useSelector((state) => state.invoices.listBuyingInvoices.data);
  const listSellingInvoices = useSelector((state) => state.invoices.listSellingInvoices.data);
  const [statusBox, setStatusBox] = useState('pending');
  const handleChangeTab = (type) => {
    setTabType(type);
    if (type === "selling") {
      dispatch(fetchSellingInvoices({ page: 1, limit: 24, status: "pending"  }));
    }else{
      dispatch(fetchBuyingInvoices({ page: 1, limit: 24, status: "pending" }));
    }
    setStatusBox("pending");
  };
  useEffect(() => {
    dispatch(fetchBuyingInvoices({ page: 1, limit: 24, status: "pending" }));
  }, []);
  const filterInvoices = (tab) => {
    setStatusBox(tab);
    if (tabType === "buying") {
      dispatch(fetchBuyingInvoices({ page: 1, limit: 24, status: tab }));
    } else {
      dispatch(fetchSellingInvoices({ page: 1, limit: 24, status: tab }));
    }
  };
  const statusCheck = (string) => {
    switch (string) {
      case "done":
        return { text: "Hoàn thành", color: "success" };
      case "pending":
        return { text: "Đang chờ", color: "warning" };
      case "cancelled":
        return { text: "Đã hủy", color: "danger" };
      default:
        return { text: "", color: "primary" };
    }
  };
  return (
    <>
      <TabContainer defaultActiveKey="buying" onSelect={(type) => handleChangeTab(type)}>
        <Nav as="ul" className="nav nav-mkt-price" id="mkt-price-tab" justify style={{ backgroundColor: "#fff" }}>
          <Nav.Item as="li" className="col-md-4 col-sm-4 col-xs-4">
            <Nav.Link as="button" eventKey="buying">
              Đơn Mua
            </Nav.Link>
          </Nav.Item>
          <Nav.Item as="li" className="col-md-4 col-sm-4 col-xs-4">
            <Nav.Link as="button" eventKey="selling">
              Đơn Bán
            </Nav.Link>
          </Nav.Item>
        </Nav>
        <SearchInvoiceBox filter={filterInvoices} statusBox={statusBox} />
        <TabContent>
          <TabPane eventKey="buying" title="Đơn Mua">
            <BuyingPage data={listBuyingInvoices} statusCheck={statusCheck} />
          </TabPane>
          <TabPane eventKey="selling" title="Đơn Bán">
            <SellingPage data={listSellingInvoices} statusCheck={statusCheck} />
          </TabPane>
        </TabContent>
      </TabContainer>
    </>
  );
};

export default Tabs;
