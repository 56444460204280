import { Link, NavLink, useLocation } from "react-router-dom";
import React, { useEffect, useState, useContext, useRef } from "react";
import * as authActions from "actions/auth";
import { bindActionCreators } from "redux";
import * as notificationActions from "actions/notification";
import { connect, useDispatch, useSelector } from "react-redux";
import { validURL } from "lib";
import { DOMAIN, CALLBACK_URL, PAGE, PER_PAGE } from "constants";
import AppContext from "components/app_context";
import PostCatalogues from "components/post_catalogues";
import { toastErrorLogin } from "helpers/toastHelper";
import Notifications from "components/notifications";
import { getProfile } from "apis/auth";
import Dropdown from "react-bootstrap/Dropdown";
import DropdownMenu from "react-bootstrap/esm/DropdownMenu";
import { subscribe, unsubscribe } from "../utils/customEvent";
import { useCart } from "../pages/Cart/Context";
import BoxSearchHomePage from "pages/home/component/BoxSearch";
import { fetchListBusiness } from "actions/business";
import _ from "lodash";
import { useTranslation } from "react-i18next";
function Navigation(props) {
  const {
    keyword,
    setSearch,
    setUser,
    navigate,
    hashtag,
    setDataFilter,
    isMobile,
    isLoadingNav,
    setDataUser,
    dataUser,
  } = useContext(AppContext);

  const [showMenu, setShowMenu] = useState(false);
  const { authActionsCreator } = props;
  const { fetchLogout } = authActionsCreator;
  const { notiActionsCreator } = props;
  const {
    fetchNotifications,
    deleteNotification,
    viewNotification,
    fetchLoadMoreNotifications,
  } = notiActionsCreator;

  const { current_user } = props.user_data;
  const {
    data: listNotification,
    isLoading,
    isLoadMore,
  } = props.listNotifications;

  const { data: listBusiness } = useSelector(
    (state) => state.business.list_business_association
  );
  const [page, setPage] = useState(PAGE);
  const [isFixed, setIsFixed] = useState(false);
  const { t } = useTranslation();
  let [loading, setLoading] = useState(false);
  const { path } = useSelector((state) => state.login.current_url);
  const { numberProductInCart, listProductInCarts } = useCart();
  const dispatch = useDispatch();
  let navRefMobile = useRef(null);

  useEffect(() => {
    setUser(current_user);
    if (current_user) {
      fetchNotifications({ page: page, limit: PER_PAGE });
      getProfile(current_user.id).then((resp) => {
        setLoading(true);
        setDataUser(resp?.data?.data);
        setLoading(false);
      });
      dispatch(fetchListBusiness({ page: 1, limit: 1 }));
    }
  }, [current_user]);

  const handleOutsideClick = (event) => {
    if (navRefMobile.current && !navRefMobile.current.contains(event.target)) {
      setShowMenu(false);
    }
  };

  useEffect(() => {
    document.addEventListener("mousedown", handleOutsideClick);
    return () => {
      document.removeEventListener("mousedown", handleOutsideClick);
    };
  }, []);

  const logOut = () => {
    navigate(path);
    fetchLogout(current_user["token_user"]);
    setUser(null);
    setDataUser(null);
    resetChat();
    setTimeout(() => {
      window.location.reload();
    }, 1000);
  };

  function resetChat() {
    localStorage.removeItem("chat_session");
  }

  const onClick = (e) => {
    if (current_user === null) {
      toastErrorLogin(isMobile);
      e.preventDefault();
    }
    setShowMenu(false);
  };

  const logIn = () => {
    TNong.createLoginButton({
      clientKey: "54e623ef2dc6bf0a281445fc8e3be054531b2fee",
      callbackUri: `${CALLBACK_URL}`,
      redirectUri: `${path}`,
      mode: "popup",
    });
  };

  useEffect(() => {
    if (!current_user) {
      logIn();
    }
  }, [logIn, current_user]);

  const removeNoti = (id) => {
    deleteNotification(id);
  };

  const viewNoti = (id) => {
    viewNotification({ id: id });
  };

  const loadMoreNoti = () => {
    setPage(page + 1);
    fetchLoadMoreNotifications({ page: page + 1 });
  };

  const showPopupLogin = () => {
    const body = document.querySelector("body");
    const a = <div className="login-with-two-nong"></div>;
    body.insertAdjacentHTML(
      "afterbegin",
      <div className="login-with-two-nong"></div>
    );
  };

  useEffect(() => {
    window.addEventListener("scroll", onScroll);
    return () => {
      window.removeEventListener("scroll", onScroll);
    };
  }, []);

  useEffect(() => {
    subscribe("showPopupLogin", showPopupLogin);
    return () => {
      unsubscribe("showPopupLogin", showPopupLogin);
    };
  }, []);

  const onScroll = () => {
    window.pageYOffset > 140 ? setIsFixed(true) : setIsFixed(false);
  };

  const linkImage = () => {
    if (current_user?.background_image === null) {
      return "/images/avatar.png";
    }

    if (validURL(current_user?.background_image)) {
      return current_user?.background_image;
    } else if (!validURL(current_user?.image)) {
      return DOMAIN + current_user?.image;
    }
  };
  if (isMobile) {
    $("#apply-login-modal").detach().appendTo($(".popup-login"));
  }

  const pathnameMap = {
    // "/dien-dan": "post",
    "/": "post",
    "/tin-tuc": "articles",
  };
  const location = useLocation();
  const currentPathname = window.location.pathname;
  const whichPage = pathnameMap[currentPathname];
  return (
    <header>
      <div className="top-sectn">
        <div className="container">
          <div className="row d-flex align-items-center">
            <div className="col-md-3 col-xs-3 col-3">
              <Link to="/" className="logo" onClick={() => setShowMenu(false)}>
                <img
                  src="/images/logo.png"
                  alt="logo images"
                  title="Go to Homepage"
                />
              </Link>
            </div>
            <div className="col-md-6 col-xs-6 col-6">
              <BoxSearchHomePage isLoadingNav={isLoadingNav} />
            </div>
            <div className="col-md-3 col-xs-3 col-3 side-r">
              <div className="d-flex justify-content-end">
                {listProductInCarts.filter(
                  (product) => product.user_id === current_user?.id
                ).length > 0 &&
                  numberProductInCart > 0 && (
                    <div className="cart-box">
                      <div type="button">
                        <Link to="/gio-hang">
                          <span className="icon-cart" />
                          <span className="item-numb">
                            {numberProductInCart}
                          </span>
                        </Link>
                      </div>
                    </div>
                  )}
                {dataUser ? (
                  <div className="notify-box">
                    <div
                      type="button"
                      id="dropdownNotify"
                      data-bs-toggle="dropdown"
                      aria-expanded="false"
                    >
                      <span className="icon-bell" />
                      {props.countNoti.data > 0 ? (
                        <span className="item-numb">
                          {props.countNoti.data}
                        </span>
                      ) : (
                        ""
                      )}
                    </div>
                    <Notifications
                      current_user={current_user}
                      data={listNotification}
                      removeNoti={removeNoti}
                      viewNoti={viewNoti}
                      loadMoreNoti={loadMoreNoti}
                      isLoadMore={isLoadMore}
                      isLoading={isLoading}
                    />
                  </div>
                ) : (
                  <div className="notify-box">
                    <div type="button">
                      <span className="icon-bell" onClick={onClick} />
                    </div>
                  </div>
                )}
                <div className="dropdown acct-box">
                  <div className="d-desktop contain-prof">
                    {current_user ? (
                      <Dropdown>
                        <Dropdown.Toggle
                          as="a"
                          className="btn btn-secondary dropdown-toggle d-flex align-items-center"
                          role="button"
                        >
                          <div
                            className="bg-img-orgnal avtar36"
                            style={{
                              backgroundImage: `url(${
                                current_user?.shop?.image ??
                                "/images/avatar.png"
                              })`,
                            }}
                          />
                          <span className="user-name">
                            {current_user["name"]}
                          </span>
                        </Dropdown.Toggle>
                        <DropdownMenu
                          as="div"
                          className="dropdown-menu dropdown-menu-end"
                          style={{ zIndex: 9999 }}
                        >
                          <ul>
                            <li className="logout">
                              <a role="button" onClick={logOut}>
                                <span className="icon-logout" />
                                <span>Logout</span>
                              </a>
                            </li>
                          </ul>
                        </DropdownMenu>
                      </Dropdown>
                    ) : (
                      <a className="btn btn-login" role="button">
                        <div className="login-with-two-nong user-name btn-login"></div>
                      </a>
                    )}
                  </div>
                  <div className="d-mobile contain-prof">
                    <a
                      className="btn btn-secondary dropdown-toggle"
                      href="#"
                      role="button"
                      id="dropdownProfileMobile"
                      data-bs-toggle="dropdown"
                      aria-expanded="false"
                    >
                      <span className="icon-user" />
                    </a>
                    <div
                      className="dropdown-menu dropdown-menu-end"
                      aria-labelledby="dropdownProfileMobile"
                    >
                      {dataUser ? (
                        <ul>
                          <li>
                            <Link to="/nguoi-dung/trang-ca-nhan">
                              <img
                                src={`${linkImage()}`}
                                alt="avatar"
                                className="avatar"
                              />
                              <span className="user-name">
                                {dataUser["name"]}
                              </span>
                            </Link>
                          </li>
                          <li className="logout">
                            <a href="#" onClick={logOut}>
                              <span className="icon-logout" />
                              <span>Logout</span>
                            </a>
                          </li>
                        </ul>
                      ) : (
                        <ul>
                          <li className="logout">
                            <a
                              href="#"
                              id="login-with-two-nong"
                              className="btn login-modal-button fw-bold"
                              data-toggle="modal"
                              data-target="#loginModal"
                            >
                              Login
                            </a>
                          </li>
                        </ul>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div
        className="cate-sectn sticky-top"
        style={
          isFixed
            ? {
                top: 0,
                left: 0,
                right: 0,
                position: "fixed",
                transition: "top 0.2s ease-in-out",
              }
            : {}
        }
      >
        <div className="container">
          <div className="d-flex justify-content-around align-items-center position-relative">
            {/* <NavLink to="/" title="" end>
              <div className="d-flex justify-content-center position-relative">
                <span
                  className="icon-home"
                  onClick={() => setShowMenu(false)}
                />
                <span className="mn-tooltip">Trang chủ</span>
              </div>
            </NavLink> */}
            {/* redirect to post module  */}
            <NavLink to="/" title="" end>
              <div className="d-flex justify-content-center position-relative">
                <span
                  className="icon-news02"
                  onClick={() => setShowMenu(false)}
                />
                <span className="mn-tooltip">2Nong forum</span>
              </div>
            </NavLink>

            {/* <NavLink to="/dien-dan" title="" end className="d-none">
              <div className="d-flex justify-content-center position-relative">
                <span
                  className="icon-news02"
                  onClick={() => setShowMenu(false)}
                />
                <span className="mn-tooltip">Diễn đàn nông nghiệp</span>
              </div>
            </NavLink> */}
            <NavLink to="/videos" title="">
              <div className="d-flex justify-content-center position-relative">
                <span
                  className="icon-triangle-line"
                  onClick={() => setShowMenu(false)}
                />
                <span className="mn-tooltip">Videos</span>
              </div>
            </NavLink>
            <NavLink to="/tin-tuc" title="">
              <div className="d-flex justify-content-center position-relative">
                <span
                  className="icon-news01"
                  onClick={() => setShowMenu(false)}
                />
                <span className="mn-tooltip">News</span>
              </div>
            </NavLink>
            <NavLink to="/modules/gia-ca-thi-truong" exact="true">
              <span className="icon-price-menu"></span>
              <span className="mn-tooltip">Market Price</span>
            </NavLink>
            <a
              href="#"
              className="d-mobile"
              onClick={() => setShowMenu(!showMenu)}
              role="button"
            >
              <div className="d-flex justify-content-center position-relative">
                <span className="icon-more-menu" />
                <span className="mn-tooltip">Shortcut</span>
              </div>
            </a>
            {/* mobile */}
            <div
              className={`contain-sidebar-l ${showMenu ? "show" : "disable"}`}
              ref={navRefMobile}
            >
              <div className="row">
                <p className="title-txt text-uppercase fw-bold text-center mb-2">
                Shortcut
                </p>
                <div className="col-6">
                  <ul>
                    <li>
                      <Link
                        to="/nguoi-dung/trang-ca-nhan"
                        onClick={onClick}
                        className="d-flex align-items-center"
                      >
                        <span className="icon-user"></span>
                        <span className="txt">{t("user.profile")}</span>
                      </Link>
                    </li>
                    <li>
                      <Link
                        to="/nguoi-dung/san-pham"
                        onClick={onClick}
                        className="d-flex align-items-center"
                      >
                        <span className="icon-prod"></span>
                        <span className="txt">{t("user.product")}</span>
                      </Link>
                    </li>
                    <li>
                      <Link
                        to="/nguoi-dung/don-hang-cua-toi"
                        onClick={onClick}
                        className="d-flex align-items-center"
                      >
                        <span className="icon-order"></span>
                        <span className="txt">{t("user.invoice")}</span>
                      </Link>
                    </li>
                    <li>
                      <Link
                        to="/nguoi-dung/san-pham-yeu-thich"
                        onClick={onClick}
                        className="d-flex align-items-center"
                      >
                        <span className="icon-tag"></span>
                        <span className="txt">{t("user.saved")}</span>
                      </Link>
                    </li>
                    <li>
                      <Link
                        to="/nguoi-dung/bai-viet"
                        onClick={onClick}
                        className="d-flex align-items-center"
                      >
                        <span className="icon-news-solid"></span>
                        <span className="txt">{t("user.article")}</span>
                      </Link>
                    </li>
                    {current_user &&
                      current_user?.manager_type !== "member" &&
                      current_user?.manager_type !== null && (
                        <li>
                          <Link
                            to="/nguoi-dung/quan-ly-binh-luan"
                            onClick={onClick}
                            className="d-flex align-items-center"
                          >
                            <span className="icon-news-solid"></span>
                            <span className="txt">{t("admin.report")}</span>
                          </Link>
                        </li>
                      )}
                    {current_user &&
                      current_user?.manager_type !== "member" &&
                      current_user?.manager_type !== null && (
                        <li>
                          <Link
                            to="/nguoi-dung/quan-ly-dong-gop"
                            onClick={onClick}
                            className="d-flex align-items-center"
                          >
                            <span className="icon-news-solid"></span>
                            <span className="txt">{t("admin.contributes")}</span>
                          </Link>
                        </li>
                      )}

                    <li>
                      <Link
                        to={`/lien-ket-danh-nghiep/chuc-nang/${
                          _.first(listBusiness)?.id
                        }`}
                        onClick={onClick}
                        className="d-flex align-items-center"
                      >
                        <span className="txt">
                          {_.first(listBusiness)?.name}
                        </span>
                      </Link>
                    </li>
                  </ul>
                </div>
                <div className="col-6">
                  <ul>
                    <li>
                      <Link
                        to="/cho-2nong"
                        onClick={() => setShowMenu(false)}
                        className="d-flex align-items-center"
                      >
                        <span className="icon-farmer-market" />
                        <span className="txt">{t("label.market")}</span>
                      </Link>
                    </li>
                    {/*<li>
                      <Link to="/" onClick={() => setShowMenu(false)} className="d-flex align-items-center">
                        <span className="txt">Diễn đàn nông nghiệp</span>
                      </Link>
                    </li>*/}
                    <li>
                      <a
                        href="/modules/thoi-tiet"
                        onClick={() => setShowMenu(false)}
                        className="d-flex align-items-center"
                      >
                        <span className="icon-weather02"></span>
                        <span className="txt">{t("label.weather")}</span>
                      </a>
                    </li>
                    {/*<li>
                      <Link to="/modules/gia-ca-thi-truong" onClick={() => setShowMenu(false)}>
                        <span className="txt">Giá cả thị trường</span>
                      </Link>
                    </li>*/}

                    <li>
                      <Link
                        to="/modules/npk"
                        onClick={() => setShowMenu(false)}
                        className="d-flex align-items-center"
                      >
                        <span className="icon-mix-npk"></span>
                        <span className="txt">{t("label.npk_calcutalter")}</span>
                      </Link>
                    </li>
                    <li>
                      <Link
                        to="/modules/truy-xuat-nguon-goc"
                        onClick={() => setShowMenu(false)}
                        className="d-flex align-items-center"
                      >
                        <span className="icon-find-origin"></span>
                        <span className="txt">{t("label.traceability")}</span>
                      </Link>
                    </li>
                    <li>
                      <Link
                        to="/quy-trinh-ky-thuat"
                        onClick={() => setShowMenu(false)}
                        className="d-flex align-items-center"
                      >
                        <span className="icon-tech-process"></span>
                        <span className="txt">{t("label.technical_process")}</span>
                      </Link>
                    </li>
                    <li>
                      <Link
                        to="/shop-qua-tang"
                        onClick={() => setShowMenu(false)}
                        className="d-flex align-items-center"
                      >
                        <span className="icon-gift"></span>
                        <span className="txt">{t("label.gift")}</span>
                      </Link>
                    </li>
                    {/*
                      <li>
                        <a href={`${event_url}/?access_token=${current_user?.token_user}`}>
                          <span className="txt">Vòng quay may mắn</span>
                        </a>
                      </li>
                    */}
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {whichPage && (
        <PostCatalogues
          setDataFilter={setDataFilter}
          hashtag={hashtag}
          page={whichPage}
        />
      )}
    </header>
  );
}

const mapStateToProps = (state) => {
  return {
    user_data: state.login.user_data,
    listNotifications: state.notification.listNotifications,
    countNoti: state.notification.countNoti,
  };
};

const mapDispatchToProps = (dispatch) => ({
  authActionsCreator: bindActionCreators(authActions, dispatch),
  notiActionsCreator: bindActionCreators(notificationActions, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(Navigation);
