import * as weatherConst from "constants/weather";
import { toastError } from "helpers/toastHelper";

const initialState = {
  listWeathers: {
    data: [],
    error: null,
    isLoading: false,
  },
  listWeatherPlaces: {
    data: [],
    error: null,
    isLoading: false,
  },
  weatherAudio: {
    data: null,
    error: null,
    isLoading: false,
  },
  listWeatherSchedule: {
    data: [],
    error: null,
    isLoading: false,
  },
  weatherScheduleId: {
    data: {},
    error: null,
    isLoading: false,
  },
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case weatherConst.FETCH_WEATHER: {
      return {
        ...state,
        listWeathers: {
          data: initialState.listWeathers.data,
          isLoading: true,
          error: null,
        },
      };
    }

    case weatherConst.FETCH_WEATHER_SUCCESS: {
      const { data } = action.payload;
      return {
        ...state,
        listWeathers: {
          data: data.data,
          isLoading: false,
          error: null,
        },
      };
    }

    case weatherConst.FETCH_WEATHER_FAILED: {
      const { error } = action.payload;
      return {
        ...state,
        listWeathers: {
          data: initialState.listWeathers.data,
          isLoading: false,
          error: error,
        },
      };
    }

    case weatherConst.FETCH_WEATHER_PLACES: {
      return {
        ...state,
        listWeatherPlaces: {
          data: initialState.listWeatherPlaces.data,
          isLoading: true,
          error: null,
        },
      };
    }
    case weatherConst.FETCH_WEATHER_PLACES_SUCCESS: {
      const { data } = action.payload;
      return {
        ...state,
        listWeatherPlaces: {
          data: data.data,
          isLoading: false,
          error: null,
        },
      };
    }

    case weatherConst.FETCH_WEATHER_PLACES_FAILED: {
      const { error } = action.payload;
      toastError(error);
      return {
        ...state
      };
    }

    case weatherConst.FETCH_WEATHER_ADDRESS: {
      return {
        ...state,
        listWeathers: {
          data: initialState.listWeathers.data,
          isLoading: true,
          error: null,
        },
      };
    }
    case weatherConst.FETCH_WEATHER_ADDRESS_SUCCESS: {
      const { data } = action.payload;

      return {
        ...state,
        listWeathers: {
          data: data.data,
          isLoading: false,
          error: null,
        },
      };
    }

    case weatherConst.FETCH_WEATHER_ADDRESS_FAILED: {
      const { error } = action.payload;
      toastError(error);
      return {
        ...state,
        listWeathers: {
          data: initialState.listWeathers.data,
          isLoading: false,
          error: error,
        },
      };
    }

    case weatherConst.FETCH_WEATHER_AUDIO: {
      return {
        ...state,
        weatherAudio: {
          data: initialState.weatherAudio.data,
          error: null,
          isLoading: true,
        },
      };
    }

    case weatherConst.FETCH_WEATHER_AUDIO_SUCCESS: {
      const { data } = action.payload;

      return {
        ...state,
        weatherAudio: {
          data: data.data,
          error: null,
          isLoading: false,
        },
      };
    }
    case weatherConst.FETCH_WEATHER_SCHEDULE: {
      return {
        ...state,
        listWeatherSchedule: {
          data: initialState.listWeatherSchedule.data,
          error: null,
          isLoading: true,
        },
      };
    }
    case weatherConst.FETCH_WEATHER_SCHEDULE_SUCCESS: {
      const { data } = action.payload;
      return {
        ...state,
        listWeatherSchedule: {
          data: data.data,
          error: null,
          isLoading: false,
        },
      };
    }
    case weatherConst.DELETE_WEATHER_SCHEDULE: {
      return {
        ...state,
        listWeatherSchedule: {
          data: state.listWeatherSchedule.data,
          isLoading: true,
          error: null,
        },
      };
    }
    case weatherConst.DELETE_WEATHER_SCHEDULE_SUCCESS: {
      const { data } = action.payload;
      return {
        ...state,
        listWeatherSchedule: {
          data: state.listWeatherSchedule.data.filter((weather) => weather.id !== data.data.id),
          isLoading: false,
          error: null,
        },
      };
    }
    case weatherConst.FETCH_WEATHER_SCHEDULE_BY_ID: {
      return {
        ...state,
        weatherScheduleId: {
          data: initialState.weatherScheduleId.data,
          isLoading: true,
          error: null,
        },
      };
    }
    case weatherConst.FETCH_WEATHER_SCHEDULE_BY_ID_SUCCESS: {
      const { data } = action.payload;
      return {
        ...state,
        weatherScheduleId: {
          data: data.data,
          isLoading: false,
          error: null,
        },
      };
    }
    default:
      return state;
  }
};

export default reducer;
