const  DetectAction = ({children, status}) => {
  switch(status){
    case "pending": {
      return children;
    }
    case "cancelled": {
      return null;
    }
    case "done": {
      return null;
    }
    default: {
      return children;
    }
  }
}
export default DetectAction;