import React, { useEffect } from "react";
import { Card, Table } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { Link, useParams } from "react-router-dom";
import { imageUrl } from "lib/index";
import NumberFormat from "components/number_format";
import { fetchBusinessProduct, deleteBusinessProduct } from "actions/business";
import LeftSidebar from "components/left_sidebar";
import AddMetaTags from "components/add_meta_tags";
import Loader from "components/loader";
import Swal from "sweetalert2";
import ImportDataGroup from "./ImportData";
import ErrorsRendered from "./ErrorsRendered";

const BusinessProduct = () => {
  const { businessId } = useParams();
  const dispatch = useDispatch();
  let { data, error, isLoading } = useSelector((state) => state.business.business_product);

  useEffect(() => {
    dispatch(fetchBusinessProduct({ business_association_id: businessId }));
  }, [businessId]);

  const deleteProduct = (productId) => {
    Swal.fire({
      title: "Bạn có chắc chắn?",
      text: "Xóa sản phẩm này?",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "OK",
      cancelButtonText: "Đóng",
    }).then((result) => {
      if (result.isConfirmed) {
        dispatch(
          deleteBusinessProduct({
            businessId: businessId,
            productId: productId,
          })
        );
      }
    });
  };
  return (
    <div className="products">
      <AddMetaTags title={"Danh sách sản phẩm của danh nghiệp"} />
      <div className="row">
        <LeftSidebar />
        <div className="col-md-9 col-sm-12">
          <div className="cont-center">
            <div className="row mt-1">
              <div className="d-flex justify-content-between align-items-center">
                <h5 className="title-line-lt text-uppercase fw-bold">
                  <Link to={`/lien-ket-danh-nghiep/chuc-nang/${businessId}`}>
                    <span className="d-block">Danh sách sản phẩm</span>
                  </Link>
                </h5>
                <Link to={`/lien-ket-danh-nghiep/san-pham/${businessId}/tao-moi`}>
                  <div className="btn btn-primary">Tạo mới</div>
                </Link>
              </div>
              <div className="d-flex justify-content-end align-items-center">
                <ImportDataGroup businessId={businessId} errors={error} isLoading={isLoading} />
              </div>
              <ErrorsRendered errors={error} />
              <Card className="p-0 mt-3">
                <Card.Body>
                  <Table bordered className="m-0">
                    <thead>
                      <tr>
                        <th>
                          <div className="d-flex justify-content-center align-items-center line-clamp-01">
                            <span>Hình ảnh</span>
                          </div>
                        </th>
                        <th>
                          <div className="d-flex justify-content-center align-items-center  line-clamp-01">
                            <span>Tên sản phẩm</span>
                          </div>
                        </th>
                        <th>
                          <div className="d-flex justify-content-center align-items-center mx-3 line-clamp-01">
                            <span>Thông tin </span>
                          </div>
                        </th>
                        <th>
                          <div className="d-flex justify-content-center align-items-center  line-clamp-01">
                            <span>Tùy chọn</span>
                          </div>
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      {data.map((product) => {
                        let product_image =
                          product?.images && product?.images[0]
                            ? imageUrl(product?.images[0]["name"])
                            : "/images/product-image-default.png";
                        return (
                          <tr key={product.id}>
                            <td>
                              <div
                                style={{
                                  maxWidth: "110px",
                                  margin: "auto",
                                  minHeight: "100px",
                                }}>
                                <img className="d-block w-100" src={product_image} alt={`product-${product.title}`} />
                              </div>
                            </td>
                            <td>
                              <div className="d-flex justify-content-center align-items-center">
                                <Link to={`/san-pham/${product?.slug}`}>
                                  <div className="btn btn-link fw-bold">{product.title}</div>
                                </Link>
                              </div>
                            </td>
                            <td>
                              <div
                                className="price01 d-flex justify-content-center align-items-center fw-bold"
                                style={{
                                  marginTop: "8px",
                                }}>
                                <span>Retail price:</span>
                                <span className="price-clr">
                                  &nbsp;
                                  <NumberFormat>{product.retail_price}</NumberFormat>
                                </span>
                              </div>
                            </td>
                            <td>
                              <div className="d-flex justify-content-center align-items-center">
                                <Link
                                  to={`/lien-ket-danh-nghiep/san-pham/chinh-sua/${product.id}/business/${businessId}`}>
                                  <span className="btn btn-link">Sửa</span>
                                </Link>
                                <span className="btn btn-link" onClick={() => deleteProduct(product.id)}>
                                  Xoá
                                </span>
                              </div>
                            </td>
                          </tr>
                        );
                      })}
                    </tbody>
                  </Table>
                </Card.Body>
              </Card>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default BusinessProduct;
