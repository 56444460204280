import * as invoiceConst from "constants/invoice";
import {toastError} from "helpers/toastHelper";

const initialState = {
  listBuyingInvoices: {
    data: [],
    error: null,
    isLoading: false,
  },
  detailInvoice: {
    type: "",
    data: [],
    isLoading: false,
    error: null,
  },
  listSellingInvoices: {
    data: [],
    isLoading: false,
  },
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case invoiceConst.FETCH_BUYING_INVOICES: {
      return {
        ...state,
        listBuyingInvoices: {
          data: initialState.listBuyingInvoices.data,
          isLoading: true,
          error: null,
        },
      };
    }
    case invoiceConst.FETCH_BUYING_INVOICES_SUCCESS: {
      const { data } = action.payload;
      return {
        ...state,
        listBuyingInvoices: {
          data: data.data,
          isLoading: false,
          error: null,
        },
      };
    }
    case invoiceConst.FETCH_BUYING_INVOICES_FAILED: {
      const { error } = action.payload;
      return {
        ...state,
        listBuyingInvoices: {
          data: initialState.listBuyingInvoices.data,
          isLoading: true,
          error: error,
        },
      };
    }
    case invoiceConst.FETCH_SELLING_INVOICES: {
      return {
        ...state,
        listSellingInvoices: {
          data: initialState.listSellingInvoices.data,
          isLoading: true,
          error: null,
        },
      };
    }
    case invoiceConst.FETCH_SELLING_INVOICES_SUCCESS: {
      const { data } = action.payload;
      return {
        ...state,
        listSellingInvoices: {
          data: data.data,
          isLoading: false,
          error: null,
        },
      };
    }
    case invoiceConst.FETCH_SELLING_INVOICES_FAILED: {
      const { error } = action.payload;
      return {
        ...state,
        listSellingInvoices: {
          data: initialState.listSellingInvoices.data,
          isLoading: true,
          error: error,
        },
      };
    }
    case invoiceConst.FETCH_DETAIL_INVOICE: {
      return {
        ...state,
        detailInvoice: {
          data: initialState.detailInvoice.data,
          isLoading: true,
          type: initialState.detailInvoice.type,
          error: null,
        },
      };
    }
    case invoiceConst.FETCH_DETAIL_INVOICE_SUCCESS: {
      const { data } = action.payload;
      const type = data.type;
      const detail = data.detail;
      return {
        ...state,
        detailInvoice: {
          data: detail.data,
          isLoading: false,
          type: type,
          error: null,
        },
      };
    }

    case invoiceConst.UPDATE_STATUS_INVOICE: {
      return {
        ...state,
        detailInvoice: {
          data: initialState.detailInvoice.data,
          isLoading: true,
          type: initialState.detailInvoice.type,
          error: null,
        },
      };
    }

    case invoiceConst.UPDATE_STATUS_INVOICE_SUCCESS: {
      const {data} = action.payload;
      return {
        ...state,
        detailInvoice: {
          data: data.data,
          isLoading: false,
          type: initialState.detailInvoice.type,
          error: null,
        },
      };
    }

    case invoiceConst.UPDATE_STATUS_INVOICE_FAILED: {
      const {error} = action.payload;
      const {errorText, data} = error;
      toastError(errorText.data);
      return {
        ...state,
        detailInvoice: {
          data: data.data,
          isLoading: false,
        }
      };
    }

    default:
      return state;
  }
};
export default reducer;
