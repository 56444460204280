import React, { useEffect, useContext, useCallback } from "react";
import { bindActionCreators } from "redux";
import { connect, useDispatch } from "react-redux";
import * as articleActions from "actions/article";
import ListVideos from "./components/listVideos";
import LeftSidebar from "components/left_sidebar";
import RightSidebar from "components/right_sidebar";
import AddMetaTags from "components/add_meta_tags";
import AppContext from "components/app_context";
import ScrollToTop from "components/ScrollToTop";
import _ from "lodash";
import {setParamsTrackActivity} from 'lib';
import {createTrackActivity} from 'actions/track_activity';

function Videos(props) {
  let { articleActionsCreator } = props;
  let { fetchArticle, createCommentArticle, fetchFeatureArticle } = articleActionsCreator;

  let { data, isLoading } = props.listArticles;
  const { keyword, current_user, setSearch, setIsLoadingNav } = useContext(AppContext);
  const { featureArticle, commentArticles } = props;
  const dispatch = useDispatch();

  const searchVideo = useCallback(
    _.debounce(
      (keyword) =>
        fetchArticle({
          keyword: keyword,
          article_type: "Video",
        }),
      3000
    ),
    // setIsLoadingNav(isLoading)
  );

  useEffect(() => {
    if (keyword !== "") {
      searchVideo(keyword);
    } else {
      fetchArticle({ keyword: keyword, article_type: "Video" });
    }

  }, [fetchArticle, keyword]);

  useEffect(() => {
    fetchFeatureArticle({ is_feature: 1, article_type: "Video", limit: 1 });
    dispatch(createTrackActivity(setParamsTrackActivity("List Videos", "GET", {}, "videos")));
  }, [fetchFeatureArticle]);

  return (
    <ScrollToTop>
      <div className="articles">
        <AddMetaTags title={"Danh sách video"} />
        <div className="row">
          <LeftSidebar />
          <ListVideos
            data={data}
            isLoading={isLoading}
            createCommentArticle={createCommentArticle}
            featureArticle={featureArticle}
            commentArticles={commentArticles?.data}
            current_user={current_user}
            setSearch={setSearch}
            keyword={keyword}
          />
          <RightSidebar />
        </div>
      </div>
    </ScrollToTop>
  );
}

const mapStateToProps = (state) => {
  return {
    listArticles: state.article.listArticles,

    featureArticle: state.article.featureArticle,
    commentArticles: state.article.commentArticles,
  };
};

const mapDispatchToProps = (dispatch, ownProps) => {
  return {
    articleActionsCreator: bindActionCreators(articleActions, dispatch),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Videos);
