import React, { useState } from "react";
import FadeIn from "react-fade-in";
import { Link } from "react-router-dom";
import SidebarArticlesPlaceholder from "./placeholder/sidebar_articles";
import { useTranslation } from "react-i18next";

const SidebarPosts = React.memo(function SidebarPosts(props) {
  const { t } = useTranslation("translation");
  const { data, isLoading, currentPathname } = props;
  const [numItems, setNumItems] = useState(4);
  const [loadMore, setLoadMore] = useState(false); //
  const handleClick = () => {
    setNumItems(data.length);
    setLoadMore(true);
  };

  return (
    <FadeIn>
      {isLoading ? (
        <FadeIn>
          <SidebarArticlesPlaceholder />
        </FadeIn>
      ) : (
        <div className="box-bd hotnews position-relative">
          <div className="title text-center mt-2 align-items-center">
            <span className="txt text-uppercase primary-clr">
           {t("label.hot_post")}
            </span>
          </div>
          {data
            .filter((post) => `/p/${post.id}` != currentPathname)
            .slice(0, numItems)
            .map(function (item, index) {
              return (
                <div key={index}>
                  <Link
                    to={`/p/${item.id}`}
                    key={item.id}
                    className="hot-videos p-2 hover"
                  >
                    <div className="row d-flex align-items-center">
                      <div className="col-md-12 col-sm-4 col-xs-4">
                        <div
                          className="bg-img position-relative"
                          style={{
                            backgroundImage: `url(${
                              item.images[0]["name"] ?? "/images/logo_2nong.png"
                            })`,
                          }}
                        ></div>
                      </div>
                      <div className="col-md-12 col-sm-8 col-xs-8">
                        <small className="txt line-clamp-02">
                          {item.user_name}
                        </small>
                        <span className="txt line-clamp-02 fw-bold">
                          {item.title}
                        </span>
                        <div className="d-inline-block ">
                          <span className="icon-like01" />
                          <small className="txt ml-1">{item.total_like}</small>
                        </div>
                        <div className="d-inline-block ml-3">
                          <span className="icon-comment-line align-middle mr-1"></span>
                          <small>{item.total_comment}</small>
                        </div>
                      </div>
                    </div>
                  </Link>
                </div>
              );
            })}
          {!loadMore && (
            <div
              onClick={() => handleClick()}
              className="product-more  hover border-0 w-100 "
              style={{
                backgroundColor: "f5f5f5",
                borderRadius: "5px",
                marginBottom: "10px",
              }}
            >
              <span className="txt fw-500 hover">
                <u>{t("button.more")}</u>
              </span>
              <span
                className="icon-arrow-solid"
                style={{ fontSize: "5px", marginLeft: "4px", color: "grey" }}
              ></span>
            </div>
          )}
        </div>
      )}
    </FadeIn>
  );
});

export default SidebarPosts;
