import React, { useState, useCallback, useEffect } from "react";
import Navigation from "components/navigation";
import { useNavigate } from "react-router-dom";
import Main from "routers/routers";
import DeepLink from "components/deeplink";
import "assets/css/main.scss";
import "bootstrap/dist/js/bootstrap";
import "sweetalert2/src/sweetalert2.scss";
import "i18n";
import { AppProvider } from "components/app_context";
import TimeAgo from "javascript-time-ago";
import vi from "javascript-time-ago/locale/vi.json";
import Notification from "components/firebaseNotifications/notification";
import PopupLogin from "./loginForm";
import CurrentURL from "components/CurrentURL";
import Footer from "components/footer";
import ScrollButton from "./ScrollButton";

TimeAgo.addDefaultLocale(vi);

export default function App() {
  let [value, setValue] = useState("");
  let [keyword, setKeyword] = useState("");
  let [user, setUser] = useState(null);
  let navigate = useNavigate();
  const [dataUser, setDataUser] = useState(null);
  let [isMobile, setIsMobile] = useState(true);
  let [isLoadingNav, setIsLoadingNav] = useState(false);
  const [postIdForScroll, setPostIdForScroll] = useState(null);
  const handleResize = async () => {
    if (window.innerWidth < 720) {
      setIsMobile(true);
    } else {
      setIsMobile(false);
    }
  };
  // thay đổi kích thước mới set giá trị
  useEffect(() => {
    handleResize();
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  });
  const setDataFilter = useCallback((value) => {
    setValue(value);
  });

  const setSearch = useCallback((value) => {
    setKeyword(value);
  });

  const initData = {
    hashtag: value,
    setDataFilter: setDataFilter,
    current_user: user,
    setUser: setUser,
    navigate: navigate,
    setSearch: setSearch,
    keyword: keyword,
    isMobile: isMobile,
    isLoadingNav: isLoadingNav,
    setIsLoadingNav: setIsLoadingNav,
    dataUser: dataUser,
    setDataUser: setDataUser,
    setPostIdForScroll: setPostIdForScroll,
    postIdForScroll: postIdForScroll,
  };

  function setupSlots(sessionId) {
    let user_storage = localStorage.getItem("user_storage") || "{}";
    let user_data = user_storage ? JSON.parse(user_storage).user_data : null;
    let user_token = user_data ? user_data.authToken : null;
    let user_name = user_data?.current_user?.name;
    let access_token = localStorage.getItem("access_token");

    if (!user_token) {
      return;
    }

    const xhr = new XMLHttpRequest();
    var url = `https://core.chatbot.hainong.vn/conversations/${sessionId}/tracker/events`
    var data = [
      {
        "event": "slot",
        "name": "user_token",
        "value": access_token
      },
      {
        "event": "slot",
        "name": "user_name",
        "value": user_name
      }
    ]
    xhr.open('POST', url);
    xhr.setRequestHeader('Content-Type', 'application/json');
    const jsonData = JSON.stringify(data);

    xhr.onload = function () {
      if (xhr.status === 200) {
        console.info("Success");
      } else {
        console.info("False");
        console.error('Error:', xhr.statusText);
      }
    };

    xhr.send(jsonData);
  }

  function defaultChat(isMobile) {
    let chat_session = localStorage.getItem("chat_session") || "{}";
    let sessionId = JSON.parse(chat_session)?.session_id;
    window.WebChat.default(
      {
        customData: { language: "vi" },
        socketUrl: "https://core.chatbot.hainong.vn",
        initPayload: "/start_conversation",
        title: "Trợ lý Hai Nông",
        inputTextFieldHint: "Nội dung",
        mainColor: "#009a59",
        userBackgroundColor: "#009a59",
        closeImage: isMobile ?  'close-img.png' : "https://chatbot.hainong.vn/chatbot_1.png",
        openLauncherImage: "https://chatbot.hainong.vn/chatbot_1.png",
        speechToTextUrl: "https://dev.panel.hainong.vn/api/v2/speech_to_texts",
        textToSpeechUrl: "https://dev.panel.hainong.vn/api/v2/text_to_speeds",
        sessionConfirmCallback: setupSlots(sessionId),
        autoClearCacheTimeout: 3 * 60 * 1000,
        speechToTextRecordTimeout: 30000,
        speechToTextVoiceAutoStopTimeout: 3 * 1000,
        messagesOnMicroErrors: {
          onConvertSTT: 'Có lỗi khi chuyển giọng nói thành tin nhắn. Bạn vui lòng thử lại!',
          onStartRecord: 'Vui lòng cấp quyền ghi âm!',
          onConvertMp3: 'Có lỗi khi ghi âm, vui lòng thử lại!'
        },
      }
    );
  }

  useEffect(() => {
    defaultChat(isMobile);
  }, [isMobile]);

  console.log(process.env.NODE_ENV);

  return (
    <AppProvider value={initData}>
      <div className="App">
        <DeepLink />
        <Navigation />
        <div id="page-wrapper">
          <div className="container">
            <CurrentURL>
              <Main />
            </CurrentURL>
          </div>
          <ScrollButton />
          <Footer />
        </div>
        <Notification />
        <PopupLogin />
      </div>
    </AppProvider>
  );
}
