import React, { useState } from "react";
import Dropdown from "react-bootstrap/Dropdown";
import Modal from "react-bootstrap/Modal";
import ModalDeleteConfirmComment from "components/shared/modalDeleteConfirmComment/modal";
import InputComment from "./InputComment";
import CommentBody from "../../../components/CommentBody/index";
export default function Comment(props) {
  const {
    children,
    comment,
    current_user,
    actionLikeComment,
    setIdShowChildComment,
    handleShowReplyBox,
    showReportModal,
    setContent,
    content,
    showModal,
    hideModal,
    reportContent,
    setReportContent,
    submitReplyComment,
    idShowChildComment,
    showInputBox,
    submitReport,
    deleteComment,
  } = props;
  const [typeInput, setTypeInput] = useState("");
  const [open, setOpen] = useState(null);
  const showConfirm = () => setOpen(true);
  return (
    <>
      <div key={comment.id} className="mt-3">
        <CommentBody comment={comment} />
        <div className="actions d-flex align-items-center my-2">
          <div className="d-flex align-items-center">
            <div className="d-inline-block mr-3">
              <div
                className={`d-flex align-items-center like ${comment?.user_liked ? "active" : ""}`}
                onClick={() => actionLikeComment(comment.id, comment.user_liked, comment.classable_type)}>
                <span className={`icon-like01 ${comment?.user_liked ? "active" : ""}`}></span>
                <div>
                  <span> {comment.user_liked ? "Dislike" : "Like"}</span>
                  <small>({comment.total_likes === null ? 0 : comment.total_likes})</small>
                </div>
              </div>
            </div>
            {comment?.total_answers !== 0 && (
              <div className="d-inline-block cmnt mr-3" onClick={() => setIdShowChildComment(comment.id)}>
                <a className="d-flex align-items-center">
                  <span className="icon-comment-line"></span>
                  <span>Comment</span>
                  <small>({comment?.total_answers})</small>
                </a>
              </div>
            )}
            <div className="d-inline-block">
              <div className="d-flex align-items-center">
                <span
                  onClick={() => {
                    handleShowReplyBox(comment.id, comment.user_id);
                    setTypeInput("reply");
                  }}>
                  Reply
                </span>
              </div>
            </div>
          </div>
          <div className="dropdown post-report ms-auto">
            <Dropdown>
              <Dropdown.Toggle as="div" className="dropdown post-report ms-auto">
                <button className="btn dropdown-toggle">
                  <span className="icon-menu-dot"></span>
                </button>
              </Dropdown.Toggle>
              <Dropdown.Menu as="ul" className="dropdown-menu dropdown-menu-end">
                {current_user?.id !== comment.user_id && (
                  <Dropdown.Item as="li" className="dropdown-item">
                    <div className="" onClick={() => showReportModal(comment.id, comment.classable_type)}>
                      <a>Vi phạm</a>
                    </div>
                  </Dropdown.Item>
                )}
                {current_user?.id == comment.user_id ||
                current_user?.manager_type == "admin" ||
                current_user?.manager_type == "smod" ? (
                  <Dropdown.Item as="li" className="dropdown-item">
                    <div
                      className=""
                      onClick={() => {
                        handleShowReplyBox(comment.id, comment.user_id);
                        setTypeInput("edit");
                      }}>
                      <a>Edit</a>
                    </div>
                  </Dropdown.Item>
                ) : (
                  ""
                )}
                {current_user?.id === comment.user_id ||
                current_user?.manager_type == "smod" ||
                current_user?.manager_type == "admin" ? (
                  <Dropdown.Item className="dropdown-item" as="li">
                    <div
                      className=""
                      onClick={() => {
                        showConfirm();
                      }}>
                      <a>Delete</a>
                    </div>
                  </Dropdown.Item>
                ) : (
                  ""
                )}
                {open && (
                  <ModalDeleteConfirmComment
                    isOpen={open}
                    onDelete={() => deleteComment(comment.id, "comment", current_user?.manager_type)}
                    onToggle={() => setOpen(null)}
                  />
                )}
              </Dropdown.Menu>
            </Dropdown>
          </div>
          <Modal show={showModal} onHide={hideModal} aria-labelledby="contained-modal-title-vcenter" centered>
            <Modal.Header closeButton>
              <Modal.Title>Báo cáo bình luận vi phạm</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <input
                className="form-control"
                name="message"
                placeHolder="Nhập lý do"
                value={reportContent}
                onChange={(e) => setReportContent(e.target.value)}
              />
            </Modal.Body>
            <Modal.Footer>
              <button
                type="button"
                className="btn btn-primary"
                onClick={() => submitReport(reportContent, comment.id, comment.classable_type)}>
                {" "}
                gửi
              </button>
            </Modal.Footer>
          </Modal>
        </div>
        {showInputBox.id == comment.id && (
          <InputComment
            current_user={current_user}
            setContent={setContent}
            content={content}
            submitReplyComment={submitReplyComment}
            typeInput={typeInput}
            comment={comment}
          />
        )}
      </div>
      {idShowChildComment == comment.id && children}
    </>
  );
}
