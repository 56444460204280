import React, { useRef } from "react";
import Picker from "react-emojipicker";
function Editor(props) {
  const { setShowPicker, showPicker, onChange, value, createComment, setInnerString, innerString } = props;
  const editorRef = useRef(value);
  function applyStyle(style) {
    document.execCommand(style, false, null);
  }
  function handleInput(e, type) {
    if (type === "text") {
      onChange(editorRef.current.innerHTML);
    } else {
      const newEmoji = e.unicode;
      setInnerString(editorRef.current.innerHTML + newEmoji);
      onChange(value + newEmoji);
    }
    updateButtonStates();
  }
  function updateButtonStates() {
    const buttons = [
      { command: "bold", buttonId: "bold-button" },
      { command: "italic", buttonId: "italic-button" },
      { command: "underline", buttonId: "underline-button" },
      { command: "emoji", buttonId: "emoji-button" },
    ];

    buttons.forEach(({ command, buttonId }) => {
      const button = document.getElementById(buttonId);
      if (document.queryCommandState(command)) {
        button.classList.add("bg-success");
      } else {
        button.classList.remove("bg-success");
      }
    });
  }

  return (
    <>
      <div
        contentEditable
        ref={editorRef}
        id="editor"
        style={{
          border: "1px solid #bbbbbb",
          backgroundColor: "white",
          padding: "20px 20px",
        }}
        {...props}
        onInput={(e) => handleInput(e, "text")}
        dangerouslySetInnerHTML={{ __html: innerString }}
      ></div>

      <div id="toolbar">
        <div>
          <button
            id="bold-button"
            onClick={(e) => {
              applyStyle("bold");
              updateButtonStates();
            }}
          >
            <b>B</b>
          </button>
          <button
            id="italic-button"
            onClick={() => {
              applyStyle("italic");
              updateButtonStates();
            }}
          >
            <i>I</i>
          </button>
          <button
            id="underline-button"
            onClick={() => {
              applyStyle("underline");
              updateButtonStates();
            }}
          >
            <u>U</u>
          </button>
          <button id="emoji-button" onClick={() => applyStyle("emoji")}>
            <img src="/images/emoji.png" style={{ height: "20px", width: "20px", marginBottom: "5px" }} onClick={() => setShowPicker(!showPicker)} />
          </button>
          {showPicker && (
            <div>
              <Picker onEmojiSelected={(e) => handleInput(e, "emoji")} visible={showPicker} />
            </div>
          )}
        </div>
        <div
          className=" btn-send-trumb position-absolute"
          style={{ backgroundImage: 'url("/images/send-message.png")', right: "3px" }}
          onClick={() => createComment(editorRef)}
        ></div>
      </div>
    </>
  );
}

export default Editor;
