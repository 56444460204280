import axiosService from "services/axiosService";
import { DOMAIN } from "constants";
import qs from "query-string";

const url = "api/v2/technical_processes";
export const getTechnicalProcess = (params) => {
  let query = "";

  if (Object.keys(params).length > 0) {
    query = `?${qs.stringify(params)}`;
  }
  return axiosService.get(`${DOMAIN}/${url}${query}`);
};
export const getTechnicalProcessId = (id) => {
  return axiosService.get(`${DOMAIN}/${url}/${id}`, true);
};

export const contributeTechnical = (params) => {
  return axiosService.post(`${DOMAIN}/${url}`, params.formData);
};

export const getCataloguesTechnicalProcess = (params = {limit: '', page: ''}) => {
  let query = '';
  if(Object.keys(params).length > 0){
    query= `?${qs.stringify(params)}`;
  }

  return axiosService.get(`${DOMAIN}/${url}/catalogues${query}`);
}

export const getCataloguesChildren  = (parent_id) => {
  return axiosService.get(`${DOMAIN}/${url}/child_catalogues/${parent_id}`);
}

export const getCataloguesDetail = (cataloguesID) => {
  return axiosService.get(`${DOMAIN}/${url}/catalogues_detail/${cataloguesID}`);
}
