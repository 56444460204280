import React, { useEffect } from "react";
import AddMetaTags from "components/add_meta_tags";
import LeftSidebar from "components/left_sidebar";
import { Card } from "react-bootstrap";
import { Link, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {
  fetchBusinessInvoiceDetail,
  updateBusinessInvoice,
} from "actions/business";
import UseInvoice from "../../../context/Inovice/useInvoice";
import NumberFormat from "components/number_format";
import moment from "moment";
import DetectAction from "../../InvoiceDetail/Components/DetectActionByStatus";
import { imageUrl } from "lib";
import Swal from "sweetalert2";

function BusinessInvoiceDetail() {
  const { invoiceId, businessId } = useParams();
  const { data, isLoading, error } = useSelector(
    (state) => state.business.business_invoice_detail
  );
  const dispatch = useDispatch();
  const { invoiceStatus } = UseInvoice();

  useEffect(() => { 
    dispatch(
      fetchBusinessInvoiceDetail({
        businessId: businessId,
        invoiceId: invoiceId,
      })
    );
  }, [invoiceId, businessId]);

  const handleUpdateBusinessInvoice = (status) => {
    if (status === "cancelled") {
      Swal.fire({
        title: "Bạn có chắc chắn?",
        text: "Hủy đơn hàng",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "OK",
        cancelButtonText: "Đóng",
      }).then((result) => {
        if (result.isConfirmed) {
          dispatch(
            updateBusinessInvoice({
              businessId: businessId,
              invoiceId: invoiceId,
              status: status,
            })
          );
        }
      });
    } else {
      Swal.fire({
        title: "Bạn có chắc chắn?",
        text: "Xác nhận đơn hàng",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "OK",
        cancelButtonText: "Đóng",
      }).then((result) => {
        if (result.isConfirmed) {
          dispatch(
            updateBusinessInvoice({
              businessId: businessId,
              invoiceId: invoiceId,
              status: status,
            })
          );
        }
      });
    }
  };

  return (
    <>
      <AddMetaTags title={`Thông tin đơn hàng - ${invoiceId}`} />
      <div className="detail-invoice">
        <div className="row">
          <LeftSidebar />
          <div className="col-md-9 col-sm-12">
            {error ? (
              <p className="fw-bold"> Không tìm thấy thông tin đơn hàng!</p>
            ) : (
              <>
                <section>
                  <Card>
                    <Card.Header>Thông tin đơn hàng</Card.Header>
                    <Card.Body>
                      <div className="row">
                        <div className="col-md-6">
                          <p>
                            <strong>Người nhận: </strong>
                            <span>&nbsp;{data?.name}</span>
                          </p>
                          <p>
                            <strong>Số điện thoại: </strong>
                            <span>{data?.phone_number}</span>
                          </p>
                          <p>
                            <strong>Email: </strong>
                            <span>{data?.email}</span>
                          </p>
                          <p>
                            <strong>Địa chỉ giao hàng: </strong>
                            <span>{data?.shipping_address}</span>
                          </p>
                          <p>
                            <strong>Trạng thái: </strong>
                            <span
                              className={`badge badge-pill ${
                                invoiceStatus[data?.status]?.className
                              }`}
                            >
                              {invoiceStatus[data?.status]?.text}
                            </span>
                          </p>
                        </div>
                        <div className="col-md-6">
                          <p>
                            <strong>Người bán: </strong>
                            <span>{data?.business_association_name}</span>
                          </p>
                          <p>
                            <strong>Hình thức giao dịch: </strong>
                            <span>Tiền mặt</span>
                          </p>
                          <p>
                            <strong>Tổng tiền: </strong>
                            <span>
                              <NumberFormat>{data?.price_total}</NumberFormat>đ
                            </span>
                          </p>
                          <p>
                            <strong>Thời gian đặt: </strong>
                            <span>
                              {moment(data?.created_at).format(
                                "YYYY-MM-DD HH:mm:ss"
                              )}
                            </span>
                          </p>
                          <p>
                            <strong>Số lượng mặt hàng: </strong>
                            <span>{data?.quantity}</span>
                          </p>
                        </div>
                      </div>
                    </Card.Body>
                  </Card>
                </section>
                <section className="mb-3">
                  <div className="row">
                    <div className="col-md-12">
                      <Card className="mt-3">
                        <Card.Header>
                          <p className="fw-bold">Danh sách mặt hàng</p>
                        </Card.Header>
                        <Card.Body>
                          <table className="table align-middle mb-0 bg-white ">
                            <thead className="bg-light">
                              <tr>
                                <th>Tên sản phẩm</th>
                                <th>Giá tiền</th>
                                <th>số lượng</th>
                                <th></th>
                              </tr>
                            </thead>
                            <tbody>
                              {data &&
                                data.invoice_items?.map((product) => {
                                  return (
                                    <tr key={product?.id}>
                                      <td>
                                        <div className="d-flex align-items-center">
                                          <Link
                                            to={`/san-pham/${product?.product_id}`}
                                          >
                                            <img
                                              src={
                                                product?.product_images ===
                                                  null ||
                                                product?.product_images ===
                                                  undefined
                                                  ? "/images/photos/no_image.png"
                                                  : imageUrl(
                                                      product?.product_images[0]
                                                        ?.name
                                                    )
                                              }
                                              className="rounded-circle"
                                              alt=""
                                              style={{
                                                width: "45px",
                                                height: "45px",
                                              }}
                                            />
                                          </Link>
                                          <div className="ms-3">
                                            <Link
                                              to={`/san-pham/${product?.product_id}`}
                                            >
                                              <p className="fw-bold mb-1">
                                                {product?.product_name}
                                              </p>
                                            </Link>
                                          </div>
                                        </div>
                                      </td>
                                      <td>
                                        <p className="fw-bold mb-1">
                                          <NumberFormat>
                                            {product?.price}
                                          </NumberFormat>
                                          ₫
                                        </p>
                                      </td>
                                      <td>
                                        <div className="cart-quantity">
                                          {product?.quantity}
                                        </div>
                                      </td>
                                      <td></td>
                                    </tr>
                                  );
                                })}
                              <tr className="total-price">
                                <td className="fw-bold mb-1 mt-2 border-0">
                                  Tổng tiền:{" "}
                                  <NumberFormat>
                                    {data?.price_total}
                                  </NumberFormat>
                                  ₫
                                </td>
                              </tr>
                            </tbody>
                          </table>
                        </Card.Body>
                      </Card>
                    </div>
                  </div>
                </section>
                <DetectAction status={data?.status}>
                  <div className="mt-2 d-flex">
                    <button
                      className="btn btn-danger"
                      onClick={() => handleUpdateBusinessInvoice("cancelled")}
                    >
                      Hủy
                    </button>
                    <button
                      className="btn btn-success ml-2"
                      onClick={() => handleUpdateBusinessInvoice("done")}
                    >
                      Hoàn thành
                    </button>
                  </div>
                </DetectAction>
              </>
            )}
          </div>
        </div>
      </div>
    </>
  );
}

export default BusinessInvoiceDetail;
