import React, { useEffect, useState, useRef } from "react";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import * as postActions from "actions/post";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { fetchObjectHashTag } from "actions/article";
function PostCatalogues(props) {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const ref = useRef(null);
  const [scrollX, setscrollX] = useState(0);
  const [scrollEnd, setscrollEnd] = useState(false);
  const { setDataFilter, hashtag, page } = props;
  const { postActionsCreator } = props;
  const { fetchPostCatalogue } = postActionsCreator;
  const { data } = props.listPostCatalogues;
  const objectHashtags = useSelector((state) => state.article.object_hashtags.data);
  const checkscrollEnd = () => {
    if (Math.floor(ref.current.scrollWidth - ref.current.scrollLeft) <= ref.current.offsetWidth) {
      setscrollEnd(true);
    } else {
      setscrollEnd(false);
    }
  };

  const slide = (shift) => {
    ref.current.scrollLeft += shift;
    setscrollX(scrollX + shift);
    checkscrollEnd();
  };

  const scrollCheck = () => {
    setscrollX(ref.current.scrollLeft);
    checkscrollEnd();
  };

  const resetFilter = () => {
    setDataFilter("");
  };

  const onClickTag = (e) => {
    setDataFilter(e.target.innerText);
    if (window.location.pathname == "/") {
      navigate("/");
    } else if (window.location.pathname == "/tin-tuc") {
      navigate("/tin-tuc");
    }
  };

  useEffect(() => {
    if (page === "post") {
      fetchPostCatalogue();
    } else if (page === "articles") {
      dispatch(fetchObjectHashTag({ articleable_type: page }));
    }
  }, [page]);

  return (
    <div className="hashtag-sectn">
      <div className="container position-relative">
        <div className="position-absolute mt-1 mr-2">
          <img src="/images/trend (2).png" style={{ width: "40px", height: "40px", transform: "translateX(-50px)" }} />
        </div>
        <div className="d-flex align-items-center justify-content-center">
          {scrollX !== 0 && (
            <div onClick={() => slide(-50)}>
              <span className="icon-arrow-black prev-btn"></span>
            </div>
          )}
          <ul ref={ref} onScroll={scrollCheck} className="list-post-catalogues ">
            {data &&
              data.map(function (catalogue, index) {
                return (
                  <li key={index} className={`${catalogue.name === hashtag.replace("#", "") ? "active" : "unactive"} `}>
                    <a role="button" onClick={onClickTag}>
                      <span>#{catalogue.name}</span>
                    </a>
                  </li>
                );
              })}
          </ul>
          {!scrollEnd && (
            <div onClick={() => slide(+50)}>
              <span className="icon-arrow-black next-btn"></span>
            </div>
          )}
          <ul className="contr-btn ml-2 position-absolute" style={{ right: "0" }}>
            <span className="reset-btn">
              <a href="#" onClick={resetFilter} className="align-items-center">
                <span className="icon-refresh"></span>
              </a>
            </span>
          </ul>
        </div>
      </div>
    </div>
  );
}
const mapStateToProps = (state) => {
  return {
    listPostCatalogues: state.post_catalogue.listPostCatalogues,
  };
};

const mapDispatchToProps = (dispatch) => ({
  postActionsCreator: bindActionCreators(postActions, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(PostCatalogues);
