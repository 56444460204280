import * as articleConst from "constants/article";
import { toastError, toastSuccess } from "helpers/toastHelper";
import { checkLoadMore } from "lib";

const initialState = {
  listArticles: {
    data: [],
    error: null,
    isLoading: false,
  },
  articleDetail: null,
  sidebarArticles: {
    data: [],
    error: null,
    isLoading: false,
  },
  relationArticles: {
    data: [],
    error: null,
    isLoading: false,
  },
  commentArticles: {
    data: [],
    error: "",
    isLoading: false,
    isLoadMore: false,
  },
  featureArticle: null,
  favorite_articles: {
    data: [],
    error: null,
    isLoading: false,
    isLoadMore: false,
  },
  object_hashtags: {
    data: [],
    error: null,
    isLoading: false,
    isLoadMore: false,
  },
  answerCommentArticles: {
    data: [],
    error: null,
    isLoading: false,
    isLoadMore: false,
  },
  replyCommentArticle: {
    data: null,
    error: null,
    isLoading: false,
  },
  deleteChildComment: {
    data: null,
    error: null,
    isLoading: false,
  },
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case articleConst.FETCH_ARTICLE: {
      return {
        ...state,
        listArticles: {
          data: initialState.listArticles.data,
          isLoading: true,
        },
      };
    }
    case articleConst.FETCH_ARTICLE_SUCCESS: {
      const { data } = action.payload;
      return {
        ...state,
        listArticles: {
          data: data.data,
          isLoading: false,
        },
      };
    }
    case articleConst.FETCH_ARTICLE_FAILED: {
      const { error } = action.payload;
      toastError(error);
      return {
        ...state,
        listArticles: {
          data: initialState.listArticles.data,
          isLoading: false,
        },
      };
    }
    case articleConst.FETCH_SIDEBAR_ARTICLE: {
      return {
        ...state,
        sidebarArticles: {
          data: initialState.sidebarArticles.data,
          isLoading: true,
        },
      };
    }
    case articleConst.FETCH_SIDEBAR_ARTICLE_SUCCESS: {
      const { data } = action.payload;
      return {
        ...state,
        sidebarArticles: {
          data: data.data,
          isLoading: false,
        },
      };
    }
    case articleConst.FETCH_SIDEBAR_ARTICLE_FAILED: {
      const { error } = action.payload;
      toastError(error);
      return {
        ...state,
        sidebarArticles: {
          data: initialState.sidebarArticles.data,
          isLoading: false,
        },
      };
    }
    case articleConst.FETCH_ARTICLE_DETAIL_ID: {
      return { ...state };
    }
    case articleConst.FETCH_ARTICLE_DETAIL_ID_SUCCESS: {
      const { data } = action.payload;
      return {
        ...state,
        articleDetail: data.data,
      };
    }
    case articleConst.FETCH_ARTICLE_DETAIL_ID_FAILED: {
      const { error } = action.payload;
      toastError(error);
      return { ...state };
    }
    case articleConst.FETCH_RELATION_ARTICLE: {
      return {
        ...state,
        listArticles: {
          data: initialState.relationArticles.data,
          isLoading: true,
        },
      };
    }
    case articleConst.FETCH_RELATION_ARTICLE_SUCCESS: {
      const { data } = action.payload;
      return {
        ...state,
        relationArticles: {
          data: data.data,
          isLoading: false,
        },
      };
    }
    case articleConst.FETCH_RELATION_ARTICLE_FAILED: {
      const { error } = action.payload;
      toastError(error);
      return {
        ...state,
        relationArticles: {
          data: initialState.relationArticles.data,
          isLoading: false,
        },
      };
    }
    case articleConst.FETCH_ARTICLE_COMMENT: {
      return {
        ...state,
        commentArticles: {
          data: initialState.commentArticles.data,
          isLoading: false,
          isLoadMore: true,
          error: null,
        },
      };
    }
    case articleConst.FETCH_ARTICLE_COMMENT_SUCCESS: {
      // no
      const { data } = action.payload;
      const checkLoadMore = data.data.length <= 5 ? false : true;
      return {
        ...state,
        commentArticles: {
          data: data.data,
          isLoading: false,
          isLoadMore: false,
          error: null,
        },
      };
    }
    case articleConst.FETCH_ARTICLE_COMMENT_FAILED: {
      const { error } = action.payload;
      toastError(error);
      return {
        ...state,
        commentArticles: {
          data: initialState.commentArticles.data,
          isLoading: false,
          isLoadMore: false,
          error: error,
        },
      };
    }

    case articleConst.FETCH_COMMENT_ANSWER_ARTICLE: {
      return {
        ...state,
        answerCommentArticles: {
          data: initialState.answerCommentArticles.data,
          isLoading: true,
          isLoadMore: false,
          error: null,
        },
      };
    }

    case articleConst.FETCH_COMMENT_ANSWER_ARTICLE_SUCCESS: {
      const { data } = action.payload;
      return {
        ...state,
        answerCommentArticles: {
          data: data.data,
          isLoading: false,
          isLoadMore: false,
          error: null,
        },
      };
    }
    case articleConst.FETCH_LOAD_MORE_COMMENT_ARTICLE_SUCCESS: {
      const { data } = action.payload.data;
      return {
        ...state,
        commentArticles: {
          data: [...state.commentArticles.data, ...data],
          isLoading: false,
          isLoadMore: data.length <= 5 ? false : true,
          error: null,
        },
      };
    }
    case articleConst.CREATE_LIKE_COMMENT_ARTICLE_SUCCESS: {
      const { data } = action.payload.data;
      if (data.likeable_type !== "SubComment") {
        return {
          ...state,
          commentArticles: {
            data: state.commentArticles.data.map((comment) => {
              if (data.likeable_id === comment.id) {
                comment.user_liked = true;
                comment.total_likes = comment.total_likes + 1;
              }
              return comment;
            }),
            isLoading: false,
            isLoadMore: false,
            error: null,
          },
        };
      } else {
        return {
          ...state,
          answerCommentArticles: {
            data: state.answerCommentArticles.data.map((comment) => {
              if (data.likeable_id === comment.id) {
                comment.user_liked = true;
                comment.total_likes = comment.total_likes + 1;
              }
              return comment;
            }),
            isLoading: false,
            isLoadMore: false,
            error: null,
          },
        };
      }
    }
    case articleConst.CREATE_UNLIKE_COMMENT_ARTICLE_SUCCESS: {
      const { data } = action.payload;
      if (data.classable_type !== "SubComment") {
        return {
          ...state,
          commentArticles: {
            data: state.commentArticles.data.map((comment) => {
              if (data.classable_id == comment.id) {
                comment.user_liked = false;
                comment.total_likes = comment.total_likes - 1;
              }
              return comment;
            }),
            isLoading: false,
            isLoadMore: false,
            error: null,
          },
        };
      } else {
        return {
          ...state,
          answerCommentArticles: {
            data: state.answerCommentArticles.data.map((comment) => {
              if (data.classable_id === comment.id) {
                comment.user_liked = false;
                comment.total_likes = comment.total_likes - 1;
              }
              return comment;
            }),
            isLoading: false,
            isLoadMore: false,
            error: null,
          },
        };
      }
    }

    case articleConst.FETCH_FEATURE_ARTICLE_SUCCESS: {
      const { data } = action.payload;
      return {
        ...state,
        featureArticle: data.data[0],
      };
    }
    case articleConst.FETCH_FEATURE_ARTICLE_FAILED: {
      const { error } = action.payload;
      toastError(error);
      return { ...state };
    }
    case articleConst.FETCH_FAVORITES_ARTICLE: {
      return {
        ...state,
        favorite_articles: {
          data: initialState.favorite_articles.data,
          error: null,
          isLoading: true,
          isLoadMore: false,
        },
      };
    }
    case articleConst.FETCH_FAVORITES_ARTICLE_SUCCESS: {
      const { data } = action.payload;
      return {
        ...state,
        favorite_articles: {
          data: data.data,
          error: null,
          isLoading: false,
          isLoadMore: false,
        },
      };
    }

    case articleConst.FETCH_FAVORITES_ARTICLE_FAILED: {
      const { error } = action.payload;
      return {
        ...state,
        favorite_articles: {
          error: error,
          isLoading: false,
          isLoadMore: false,
        },
      };
    }

    case articleConst.CREATE_FAVORITE_ARTICLE_SUCCESS: {
      toastSuccess("Thêm yêu thích bài viết thành công");
      return { ...state };
    }

    case articleConst.FETCH_OBJECT_HASHTAG: {
      return {
        ...state,
        object_hashtags: {
          data: initialState.object_hashtags.data,
          error: null,
          isLoading: true,
          isLoadMore: true,
        },
      };
    }

    case articleConst.REPLY_COMMENT_SUCCESS: {
      const { data } = action.payload;
      return {
        ...state,
        commentArticles: {
          data: state.commentArticles.data.map((comment) => {
            if (comment.id === data.data.comment_id) {
              comment.total_answers = comment.total_answers + 1;
            }
            return comment;
          }),
          error: null,
          isLoading: false,
        },
        answerCommentArticles: {
          data: [data.data, ...state.answerCommentArticles.data],
          isLoading: false,
          isLoadMore: false,
          error: null,
        },
      };
    }
    case articleConst.REPLY_COMMENT_FAILED: {
      const { error } = action.payload;
      return {
        ...state,
        replyCommentArticle: {
          error: error,
          isLoading: false,
          isLoadMore: false,
          data: initialState.replyCommentArticle.data,
        },
      };
    }
    case articleConst.FETCH_FAVORITES_ARTICLE_FAILED: {
      const { error } = action.payload;
      return {
        ...state,
        favorite_articles: {
          error: error,
          isLoading: false,
          isLoadMore: false,
        },
      };
    }

    case articleConst.LOAD_MORE_COMMENT_ARTICLE: {
      return {
        ...state,
        commentArticles: {
          data: state.commentArticles.data,
          error: null,
          isLoading: true,
          isLoadMore: true,
        },
      };
    }

    case articleConst.LOAD_MORE_COMMENT_ARTICLE_SUCCESS: {
      const { data } = action.payload.data;
      return {
        ...state,
        commentArticles: {
          data: [...state.commentArticles.data, ...data.data],
          error: null,
          isLoading: false,
          isLoadMore: data.length <= 5 ? false : true,
        },
      };
    }
    case articleConst.UPDATE_COMMENT_SUCCESS: {
      const { data } = action.payload;
      return {
        ...state,
        commentArticles: {
          data: state.commentArticles.data.map((comment) => {
            if (comment.id === data.data.id) {
              comment.content = data.data.content;
            }
            return comment;
          }),
          isLoading: false,
          error: null,
        },
      };
    }
    case articleConst.MANAGER_UPDATE_COMMENT_SUCCESS: {
      const { data } = action.payload;
      return {
        ...state,
        commentArticles: {
          data: state.commentArticles.data.map((comment) => {
            if (comment.id === data.data.id) {
              comment.content = data.data.content;
            }
            return comment;
          }),
          isLoading: false,
          error: null,
        },
      };
    }
    case articleConst.UPDATE_COMMENT_FAILED: {
      const { error } = action.payload;
      toastError(error);
      return {
        ...state,
        commentArticles: {
          data: initialState.commentArticles.data,
          isLoading: false,
          error: error,
        },
      };
    }
    case articleConst.UPDATE_CHILD_COMMENT: {
      return {
        ...state,
        replyCommentArticle: {
          data: initialState.replyCommentArticle.data,
          isLoading: true,
          error: null,
        },
      };
    }
    case articleConst.UPDATE_CHILD_COMMENT_SUCCESS: {
      const { data } = action.payload;
      return {
        ...state,
        replyCommentArticle: {
          data: data.data,
          isLoading: false,
          error: null,
        },
      };
    }

    case articleConst.MANAGER_UPDATE_CHILD_COMMENT_SUCCESS: {
      const { data } = action.payload;
      return {
        ...state,
        replyCommentArticle: {
          data: data.data,
          isLoading: false,
          error: null,
        },
      };
    }
    case articleConst.MANAGER_DESTROY_COMMENT_SUCCESS: {
      const { id } = action.payload;
      toastSuccess("Xóa bình luận thành công");
      return {
        ...state,
        commentArticles: {
          data: state.commentArticles.data.filter((comment) => comment.id != id),
          isLoading: false,
          error: null,
        },
      };
    }
    case articleConst.MANAGER_DESTROY_CHILD_COMMENT_SUCCESS: {
      const { data } = action.payload;
      toastSuccess("Xóa bình luận thành công");
      return {
        ...state,
        deleteChildComment: {
          data: data,
          isLoading: false,
          error: null,
        },
      };
    }
    case articleConst.USER_DESTROY_COMMENT_SUCCESS: {
      const { id } = action.payload;
      toastSuccess("Xóa bình luận thành công");
      return {
        ...state,
        commentArticles: {
          data: state.commentArticles.data.filter((comment) => comment.id != id),
          isLoading: false,
          error: null,
        },
      };
    }
    case articleConst.USER_DESTROY_CHILD_COMMENT_SUCCESS: {
      const { id } = action.payload;
      toastSuccess("Xóa bình luận thành công");
      return {
        ...state,
        deleteChildComment: {
          data: id,
          isLoading: false,
          error: null,
        },
      };
    }
    case articleConst.CREATE_ARTICLE_COMMENT_SUCCESS: {
      const { data } = action.payload;
      return {
        ...state,
        commentArticles: {
          data: [data.data, ...state.commentArticles.data],
          isLoading: false,
          error: null,
        },
      };
    }
    default:
      return state;
  }
};

export default reducer;
