import * as diagnosticConst from 'constants/diagnostic';

export const fetchDiagnostics = (params) => {
  return {
    type: diagnosticConst.FETCH_DIAGNOSTICS,
    payload: {
      params
    },
  };
};

export const fetchDiagnosticsSuccess = (data) => {
  return {
    type: diagnosticConst.FETCH_DIAGNOSTICS_SUCCESS,
    payload: {
      data
    },
  };
};

export const fetchDiagnosticsFailed = (error) => {
  return {
    type: diagnosticConst.FETCH_DIAGNOSTICS_FAILED,
    payload: {
      error
    },
  };
};

export const fetchDiagnosticInfoDetails = (params) => {
  return {
    type: diagnosticConst.FETCH_DIAGNOSTICS_DETAILS,
    payload: {
      params
    }
  }
}

export const fetchDiagnosticInfoDetailsSuccess = (data) => {
  return {
    type: diagnosticConst.FETCH_DIAGNOSTICS_DETAILS_SUCCESS,
    payload: {
      data
    }
  }
}

export const fetchDiagnosticInfoDetailsFailed = error => {
  return {
    type: diagnosticConst.FETCH_DIAGNOSTICS_DETAILS_FAILED,
    payload: {
      error
    }
  }

}
