import axiosService from 'services/axiosService';
import { DOMAIN } from 'constants';
import qs from 'query-string';

const url = 'api/v2/gifts';
const url_topup = 'api/v2/topup_data';

export const getCatalogueGifts = () => {
  return axiosService.get(`${DOMAIN}/${url}/catalogues`, true);
};

export const getStoreGifts = (params) => {
  let query = '';

  if (Object.keys(params).length > 0) {
    query = `?${qs.stringify(params)}`;
  }
  return axiosService.get(`${DOMAIN}/${url}/store_gifts${query}`);
}

export const exchangeGifts = (params) => {
  return axiosService.post(`${DOMAIN}/${url}/exchange_points`, params);
}

export const exchangePointsTransaction = (params) => {
  let query = '';

  if (Object.keys(params).length > 0) {
    query = `?${qs.stringify(params)}`;
  }
  return axiosService.get(`${DOMAIN}/${url}/points_transactions${query}`, true)
}

export const getLitTopUp  = () => {
  return axiosService.get(`${DOMAIN}/${url_topup}`, true);
}

export const topUpDataForUser = (params) => {
  return axiosService.post(`${DOMAIN}/${url_topup}`, params);
}

export const getListGift = (params = ({limit: '', page: ''})) => {
  let query = '';

  if (Object.keys(params).length > 0) {
    query = `?${qs.stringify(params)}`;
  }
  return axiosService.get(`${DOMAIN}/${url}/list_gifts${query}`)
}

export const getPointSetting = (params = {page: '', limit: ''}) => {
  let query = '';

  if (Object.keys(params).length > 0) {
    query = `?${qs.stringify(params)}`;
  }
  return axiosService.get(`${DOMAIN}/api/v2/point_settings${query}`, true)
}