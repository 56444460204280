export const FETCH_NOTIFICATION = 'FETCH_NOTIFICATION';
export const FETCH_NOTIFICATION_SUCCESS = 'FETCH_NOTIFICATION_SUCCESS';
export const FETCH_NOTIFICATION_FAILED = 'FETCH_NOTIFICATION_FAILED';

export const DELETE_NOTIFICATION = 'DELETE_NOTIFICATION';
export const DELETE_NOTIFICATION_SUCCESS = 'DELETE_NOTIFICATION_SUCCESS';
export const DELETE_NOTIFICATION_FAILED = 'DELETE_NOTIFICATION_FAILED';

export const COUNT_NOTIFICATION = 'COUNT_NOTIFICATION';
export const COUNT_NOTIFICATION_SUCCESS = 'COUNT_NOTIFICATION_SUCCESS';

export const LOAD_MORE_NOTIFICATION = 'LOAD_MORE_NOTIFICATION';
export const LOAD_MORE_NOTIFICATION_SUCCESS = 'LOAD_MORE_NOTIFICATION_SUCCESS';
export const LOAD_MORE_NOTIFICATION_FAILED = 'LOAD_MORE_NOTIFICATION_FAILED';

export const VIEW_NOTIFICATION = 'VIEW_NOTIFICATION';
export const VIEW_NOTIFICATION_SUCCESS = 'VIEW_NOTIFICATION_SUCCESS';
export const VIEW_NOTIFICATION_FAILED = 'VIEW_NOTIFICATION_FAILED';

