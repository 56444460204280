import React from "react";

function MaterialDetails({ data }) {
  return (
    <div className="row border-bottom mt-3">
      <div className="col-md-12">
        <div className="green-clr font-weight-bold mb-1">Nguyên liệu</div>
        {data &&
          data.map(function (item, index) {
            return (
              <div className="d-flex justify-content-between" key={index}>
                <div className="info ">
                  <div className="mb-1">Diện tích(m2): &nbsp; {item.ingredient?.land_area}</div>
                  <div className="mb-1">
                    Sản lượng thu hoạch (kg): &nbsp;{item.ingredient?.ingredient_name}
                  </div>
                  <div className="mb-1">Địa chỉ: &nbsp;{item.ingredient?.ingredient_address}</div>
                  <div className="video">
                    <div className="font-weight-bold text-dark mb-1">Quá trình canh tác</div>
                    <div>Hiển thị các thông tin quá trình canh tác</div>
                  </div>
                </div>
                <div className=" d-block media">
                  <div className=" font-weight-bold text-dark mb-1 "> Hình ảnh canh tác</div>

                  <div className="image d-flex">
                    {item.ingredient?.images.map((image, index) => {
                      return (
                        <div className="list-images" key={index}>
                          <img className="traceaility-image ml-1" src={image.name} alt="" />
                        </div>
                      );
                    })}
                  </div>
                  <br />
                </div>
              </div>
            );
          })}
      </div>
    </div>
  );
}
export default MaterialDetails;
