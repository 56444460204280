import React, { useState } from "react";
import * as weatherActions from "actions/weather";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import AppContext from "components/app_context";
import { useEffect, useContext } from "react";
import { getWeatherWithAddress } from "apis/weather";
import { toastError } from "helpers/toastHelper";
import AddMetaTags from "components/add_meta_tags";
import SearchBoxWeather from "./components/searchBoxWeather";
import WeatherInfo from "./components/weatherInfo";
import { LAT_LONG_HCM } from "constants";
import { translateDay } from "lib";

function Weather(props) {
  const { current_user } = useContext(AppContext);
  let { fetchWeather, fetchWeatherAddress, fetchWeatherAudio} = props.weatherActionsCreator;
  let { data: listWeathers, isLoading } = props.listWeathers;
  let { data: audioWeather, isLoading: isLoadingAudioWeather } = props.audioWeather;
  let [searchAddress, setSearchAddress] = useState(false);
  let [load, setLoad] = useState(false);
  let [keyword, setKeyword] = useState("");

  useEffect(() => {
    if (!current_user) {
      fetchWeather(LAT_LONG_HCM);
    } else {
      fetchWeather({ lat: current_user.lat, lng: current_user.lng });
    }
  }, [fetchWeather, current_user]);

  const fixProvinceName = (province_name) => {
    if (
      province_name === "Hồ Chí Minh" ||
      province_name === "Cần Thơ" ||
      province_name === "Đà Nẵng" ||
      province_name === "Hải Phòng"
    ) {
      return "Thành phố " + province_name;
    } else if (province_name === "Hà Nội") {
      return "Thủ đô" + province_name;
    } else if (!province_name?.includes("Tỉnh")) {
      return "Tỉnh " + province_name;
    } else {
      return null;
    }
  };

  const searchWeatherWithAddress = () => {
    setLoad(true);
    getWeatherWithAddress({ address: keyword }).then((respone) => {
      if (respone) {
        setSearchAddress(true);
        fetchWeatherAddress({ address: keyword });
        setLoad(false);
      } else {
        toastError("Không tìm thấy địa chỉ");
        setLoad(false);
      }
    });
  };

  return (
    <>
      <AddMetaTags title={"Thời tiết"} />
      <div className="row weather-details">
        <SearchBoxWeather
          searchAddress={searchAddress}
          current_user={current_user}
          fixProvinceName={fixProvinceName}
          listWeathers={listWeathers}
          load={load}
          searchWeatherWithAddress={searchWeatherWithAddress}
          isLoading={isLoading}
          setKeyword={setKeyword}
          audioWeather={audioWeather}
          isLoadingAudioWeather={isLoadingAudioWeather}
          fetchWeather={fetchWeather}
          fetchWeatherAudio={fetchWeatherAudio}
        />
        <WeatherInfo listWeathers={listWeathers} isLoading={isLoading} translateDay={translateDay} />
      </div>
    </>
  );
}
export const mapStateToProps = (state) => {
  return {
    listWeathers: state.weather.listWeathers,
    listWeatherPlaces: state.weather.listWeatherPlaces,
    audioWeather: state.weather.weatherAudio,
  };
};

export const mapDispatchToProps = (dispatch) => {
  return {
    weatherActionsCreator: bindActionCreators(weatherActions, dispatch),
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(Weather);
