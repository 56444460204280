import React from 'react'
import Modal from 'react-bootstrap/Modal';
const ModalViewImage = ({openModal ,closeModal, image}) => {
  return (
    <Modal show={openModal} onHide={closeModal} centered>
        <Modal.Body>
          <img src={image} alt="Zoom In Image" />
        </Modal.Body>
      </Modal>
  )
}

export default ModalViewImage
