import React, { useState } from "react";
import FadeIn from "react-fade-in";
import SubComment from "./subComment";
import { toastError } from "helpers/toastHelper";
import Modal from "react-bootstrap/Modal";
import ModalDeleteConfirmComment from "components/shared/modalDeleteConfirmComment/modal";
import Dropdown from "react-bootstrap/Dropdown";
import InputComment from "../../../pages/postDetail/components/InputComment";
import CommentBody from "../../CommentBody";
import { useTranslation } from "react-i18next";

function Comment(props) {

  let {
    isToggle,
    post,
    postId,
    comments,
    handleSortComment,
    activeSort,
    current_user,
    deleteComment,
    options,
    class_like_comment,
    LikeComment,
    showReportComment,
    setContent,
    handleCloseReportComment,
    reportComment,
    handleShowReportComment,
    content,
    submitReportComment,
    setReportComment,
    dataUpdateComment,
    actionUpdateComment,
    isLoadMore,
    handleLoadMoreComment,
    actionReplyComment,
    dataUpdateAnswerComment,
    actionReportComment
  } = props;
  let [id, setId] = useState(null);
  const [idComment, setIdComment] = useState(null);
  const [idSubComment, setIdSubComment] = useState(null);
  const [typeInput, setTypeInput] = useState("");
  const { t } = useTranslation();

  const showInputComment = (id) => setIdComment(id);
  const showSubComment = (id) => setIdSubComment(id);
  const submitReply = (id, content, user_id) => {
    if (content === "") {
      return toastError("Type your content");
    } else if (content.length > 1000) {
      toastError("Do not more than  one thousand letters");
    } else {
      if (typeInput === "reply") {
        actionReplyComment(id, content, user_id);
      } else {
        actionUpdateComment("Comment", idComment, content);
      }
    }
    setIdComment(null);
    setTypeInput("");
  };
  return (
    <>
      {isToggle && postId == post.id && comments.length > 0 && (
        <div className="cmt-listing cmt-listing mrgt-0 mrgb-10 border-0">
          <div className="actions-sort-cmt d-flex mt-3">
            <ul>
              <li
                className={`${activeSort === "asc" && "active"} action-sort-like`}
                onClick={() => handleSortComment("asc")}
              >
                {t("button.favourite")}
              </li>
              <li
                className={`${activeSort === "new" && "active"} action-sort-new`}
                onClick={() => handleSortComment("new")}
              >
                {t("button.lastest")}
              </li>
            </ul>
          </div>
          {comments.map(function (comment, index) {
            return (
              <FadeIn key={index}>
                <div key={comment.id} className="mt-1">
                  <div className="user-inf border-0">
                    <CommentBody comment={comment} />
                    <div className="mt-2 actions d-flex align-items-center mh-35">
                      <div className="d-flex align-items-center">
                        <div
                          className={`${class_like_comment}  hover cmnt d-inline-block mr-3`}
                          onClick={() => LikeComment(comment.classable_id, comment.classable_type, comment.user_liked)}
                        >
                          <a className={`${comment?.user_liked ? "active" : ""} like d-flex align-items-center`}>
                            {comment.user_liked ? (
                              <div className="d-inline-block">
                                <span className="icon-like01"></span>
                                <span>{t("button.dislike")}</span>
                              </div>
                            ) : (
                              <div className="d-inline-block">
                                <span className="icon-like01"></span>
                                <span>{t("button.like")}</span>
                              </div>
                            )}
                            <small>({comment.total_likes === null ? 0 : comment.total_likes})</small>
                          </a>
                        </div>

                        {comment?.total_answers !== 0 && (
                          <div className="d-inline-block cmnt mr-3" onClick={() => showSubComment(comment.id)}>
                            <a className="d-flex align-items-center">
                              <span className="icon-comment-line"></span>
                              <span>{t("button.comment")}</span>
                              <small>({comment?.total_answers})</small>
                            </a>
                          </div>
                        )}

                        <Modal
                          show={showReportComment}
                          onHide={handleCloseReportComment}
                          aria-labelledby="contained-modal-title-vcenter"
                          centered
                        >
                          {/* modal báo cáo bình luận */}
                          <Modal.Header closeButton>
                            <Modal.Title>{t("dropdown.report")} this comment</Modal.Title>
                          </Modal.Header>
                          <Modal.Body>
                            <input
                              className="form-control"
                              name="message"
                              placeholder="Type your reason"
                              value={reportComment}
                              onChange={(e) => setReportComment(e.target.value)}
                            />
                          </Modal.Body>
                          <Modal.Footer>
                            <button
                              type="button"
                              className="btn btn-primary"
                              onClick={() => submitReportComment(current_user.id, reportComment)}
                            >
                              {t("button.submit")}
                            </button>
                          </Modal.Footer>
                        </Modal>

                        <div
                          className="d-inline-block"
                          onClick={() => {
                            showInputComment(comment.id);
                            setTypeInput("reply");
                          }}
                        >
                          <a>
                            <span>{t('button.reply')}</span>
                          </a>
                        </div>
                      </div>

                      <div className="dropdown post-report ms-auto">
                        <Dropdown>
                          <Dropdown.Toggle as="div" className="dropdown post-report ms-auto">
                            <button className="btn dropdown-toggle">
                              <span className="icon-menu-dot"></span>
                            </button>
                          </Dropdown.Toggle>
                          <Dropdown.Menu as="ul" className="dropdown-menu dropdown-menu-end">
                            <Dropdown.Item as="li" className="dropdown-item">
                              <div
                                className=""
                                onClick={() => handleShowReportComment(comment.id, comment.classable_type)}
                              >
                                <a>{t("dropdown.report")}</a>
                              </div>
                            </Dropdown.Item>
                            {current_user?.id == comment.user_id ||
                            current_user?.manager_type == "admin" ||
                            current_user?.manager_type == "smod" ? (

                              <Dropdown.Item as="li" className="dropdown-item">
                                <div
                                  className=""
                                  onClick={() => {
                                    showInputComment(comment.id);
                                    setTypeInput("edit");
                                  }}
                                >
                                  <span>{t("dropdown.update")}</span>
                                </div>
                              </Dropdown.Item>
                            ) : (
                              ""
                            )}
                            {current_user?.id == comment.user_id ||
                            current_user?.manager_type == "admin" ||
                            current_user?.manager_type == "smod" ? (
                              <Dropdown.Item as="li" onClick={() => setId(comment.id)} className="dropdown-item">
                                <div className="">
                                  <span>{t("dropdown.delete")}</span>
                                </div>
                              </Dropdown.Item>
                            ) : (
                              ""
                            )}
                            <ModalDeleteConfirmComment
                              onDelete={() => deleteComment(id, current_user?.manager_type, comment.classable_type)}
                              isOpen={id}
                              onToggle={() => setId(null)}
                            />
                          </Dropdown.Menu>
                        </Dropdown>
                      </div>
                    </div>
                  </div>
                  {idComment === comment.id && (
                    <InputComment
                      current_user={current_user}
                      commentContent={content}
                      setCommentContent={setContent}
                      submitReplyComment={submitReply}
                      comment={comment}
                      typeInput={typeInput}
                    />
                  )}
                  {idSubComment === comment.id && (
                    <SubComment
                      dataUpdateComment={dataUpdateComment}
                      deleteComment={deleteComment}
                      current_user={current_user}
                      LikeComment={LikeComment}
                      comment={comment}
                      id={comment.id}
                      dataUpdateAnswerComment={dataUpdateAnswerComment}
                      actionUpdateComment={actionUpdateComment}
                      actionReportComment={actionReportComment}
                    />
                  )}
                </div>
              </FadeIn>
            );
          })}
          {isLoadMore && (
            <span className="load-more-comment-post" onClick={() => handleLoadMoreComment(post.id)}>
            {t("button.more")}
            </span>
          )}
        </div>
      )}
    </>
  );
}

export default Comment;
