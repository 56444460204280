import React, { useState } from "react";
import ImageGallery from "react-image-gallery";
import "react-image-gallery/styles/css/image-gallery.css";
import ModalComponent from "../../lib/react-carousel/component/Modal";
import { Link, useNavigate } from "react-router-dom";
import { imageUrl } from "lib";

function renderLeftNav(onClick, disabled) {
  return (
    <button
      type="button"
      style={{ left: "5px" }}
      className="image-gallery-left-nav btn-slide"
      aria-label="Prev Slide"
      disabled={disabled}
      onClick={onClick}
    >
      <svg xmlns="http://www.w3.org/2000/svg" width="2.5rem" height="2.5rem" fill="currentColor" className="bi bi-arrow-left-short" viewBox="0 0 16 16">
        <path
          fillRule="evenodd"
          d="M12 8a.5.5 0 0 1-.5.5H5.707l2.147 2.146a.5.5 0 0 1-.708.708l-3-3a.5.5 0 0 1 0-.708l3-3a.5.5 0 1 1 .708.708L5.707 7.5H11.5a.5.5 0 0 1 .5.5z"
        />
      </svg>
    </button>
  );
}

function renderRightNav(onClick, disabled) {
  return (
    <button
      type="button"
      className="image-gallery-right-nav btn-slide"
      aria-label="Next Slide"
      disabled={disabled}
      onClick={onClick}
      style={{ right: "5px" }}
    >
      <svg xmlns="http://www.w3.org/2000/svg" width="2.5rem" height="2.5rem" fill="currentColor" className="bi bi-arrow-right-short" viewBox="0 0 16 16">
        <path
          fillRule="evenodd"
          d="M4 8a.5.5 0 0 1 .5-.5h5.793L8.146 5.354a.5.5 0 1 1 .708-.708l3 3a.5.5 0 0 1 0 .708l-3 3a.5.5 0 0 1-.708-.708L10.293 8.5H4.5A.5.5 0 0 1 4 8z"
        />
      </svg>
    </button>
  );
}

const Carousels = ({ images, rootUrlImage, page, infoImageFromHome }) => {
  const htmlRegexG = /<(?:"[^"]*"['"]*|'[^']*'['"]*|[^'">])+>/g;
  const navigate = useNavigate();
  const [currentIndex, setCurrentIndex] = useState(0);
  const [modalOpen, setModalOpen] = useState(false);

  const handleIndexImage = (index) => {
    setCurrentIndex(index);
  };

  const openModal = () => {
    if (page === "Product") {
      setModalOpen(true);
    } else {
      const currentItem = infoImageFromHome[currentIndex];
      if (currentItem) {
        navigate(`/p/${currentItem.id}`);
      }
    }
  };

  const closeModal = () => {
    setModalOpen(false);
  };

  return (
    <>
      <div className="react-carousel position-relative">
        <ImageGallery
          items={images}
          onSlide={(index) => handleIndexImage(index)}
          onClick={() => openModal()}
          renderLeftNav={renderLeftNav}
          renderRightNav={renderRightNav}
          showPlayButton={false}
          autoPlay={true}
          showBullets={true}
          showFullscreenButton={false}
          showThumbnails={false}
          slideDuration={600}
          slideInterval={1000000}
        />
        {page === "Product" && modalOpen && <ModalComponent onClose={closeModal} index={currentIndex} images={rootUrlImage} />}
      </div>
      {page === "Home" && infoImageFromHome[currentIndex] && (
        <div className="home-wrapper">
          <div className="posts-sectn">
            <div className="inf-post position-absolute w-100" key={currentIndex}>
              <div className="poster-txt">
                <div className="carousel-caption">
                  <Link to={`/p/${infoImageFromHome[currentIndex].id}`}>
                    <p className="mb-0 line-clamp-02 fs-6">{infoImageFromHome[currentIndex].title.replace(htmlRegexG, "")}</p>
                  </Link>
                </div>
              </div>
              <div className="user-act d-flex align-items-center">
                <div>
                  <div className="d-flex align-items-center">
                    <img
                      src={imageUrl(infoImageFromHome[currentIndex].user_image)}
                      alt="avatar"
                      title="avatar"
                      className="avtar img-cover rounded-circle mr-2"
                    />
                    <div className="name-txt" style={{ zIndex: 99 }}>
                      <Link to={`/nguoi-dung/${infoImageFromHome[currentIndex].user_id}`}>
                        <div className="fw-semibold d-inline">{infoImageFromHome[currentIndex].user_name}</div>
                      </Link>
                      <small className="time ml-2">
                        <span className="icon-time mr-1"></span>
                        <span className="">{infoImageFromHome[currentIndex].created_name}</span>
                      </small>
                    </div>
                  </div>
                </div>
                <div className="ml-auto fw-bold xs-hidden">
                  <Link to={`/p/${infoImageFromHome[currentIndex].id}`}>
                    <small className="fw-bold ml-3">
                      <span className=" icon-like01" />
                      <span className="numb ml-1">{infoImageFromHome[currentIndex].total_like}</span>
                    </small>
                  </Link>
                  <small className="fw-bold ml-3">
                    <span className="icon-comment-line"></span>
                    <span className="numb ml-1">{infoImageFromHome[currentIndex].total_comment}</span>
                  </small>
                  <small className="fw-bold ml-3">
                    <span className="icon-eye-line01"></span>
                    <span className="numb ml-1">{infoImageFromHome[currentIndex].viewed}</span>
                  </small>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default Carousels;
