import qs from "query-string";
import axiosService from "services/axiosService";
import { DOMAIN } from "constants";

const url_invoice = "api/v2/invoice_users";
export const listInvoiceUser = (params) => {
  let query = "";
  if (Object.keys(params).length > 0) {
    query = `?${qs.stringify(params)}`;
  }
  return axiosService.get(`${DOMAIN}/${url_invoice}${query}`, true); // Danh sách đơn hàng đã order dành cho người mua
};

export const createInvoice = (params) => {
  return axiosService.post(`${DOMAIN}/${url_invoice}`, params); // Người mua tạo đơn hàng chi tiết
};

export const buyerCancelInvoice = (invoiceId) => {
  return axiosService.put(`${DOMAIN}/${url_invoice}/${invoiceId}`); // Người mua hủy đơn hàng
};

export const sellerUpdateStatusInvoice = (params) => {
  const invoice_user_id = params.invoiceUserId;
  const status = {
    status: params.status,
  };
  return axiosService.put(`${DOMAIN}/${url_invoice}/shop/${invoice_user_id}`, status);
}; // người bán cập nhật trạng thái đơn hàng

export const buyerViewInvoice = (invoiceId) => {
  return axiosService.get(`${DOMAIN}/${url_invoice}/${invoiceId}`, true);
};

export const sellerViewInvoice = (invoiceId) => {
  return axiosService.get(`${DOMAIN}/${url_invoice}/shop/${invoiceId}`, true);
};

export const listInvoiceSeller = (params) => {
  let query = "";
  if (Object.keys(params).length > 0) {
    query = `?${qs.stringify(params)}`;
  }
  return axiosService.get(`${DOMAIN}/${url_invoice}/shop${query}`, true); //Danh sách đơn hàng dành cho người bán
};

export const invoiceDetailPublic = (invoiceId) => {
  return axiosService.get(`${DOMAIN}/${url_invoice}/public_show/${invoiceId}`);
}
