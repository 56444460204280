import { useFormik } from "formik";
import React from "react";
import Modal from "react-bootstrap/Modal";
import { useDispatch } from "react-redux";
import * as Yup from "yup";
import { createAnAlbum } from "actions/albums";
import { Form } from "react-bootstrap";
function ModalAddAlbums({
  isOpen,
  onToggle,
}) {
  const dispatch = useDispatch();

  const validationSchema = Yup.object().shape({
    name: Yup.string().required("Không thể bỏ trống tên albums"),
  });

  const formik = useFormik({
    initialValues: {
      name: "",
    },
    validationSchema: validationSchema,
    onSubmit: (values, { resetForm }) => {
      dispatch(createAnAlbum({ name: formik.values.name }));
      resetForm();
      onToggle();
    },
  });

  return (
    <>
      <Modal
        show={isOpen}
        onHide={onToggle}
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title>Thêm albums</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form onSubmit={formik.handleSubmit}>
            <input
              className={`form-control ${
                formik.errors.name && "border border-danger"
              }`}
              name="name"
              placeholder="Nhập tên albums"
              value={formik.values.name}
              onChange={formik.handleChange}
            />
            {formik.touched.name && formik.errors.name && (
              <label className="alert-invalid ">{formik.errors.name}</label>
            )}
            <button
              type="submit"
              className="btn btn-secondary float-right mt-2 fw-semibold"
            >
              Đồng ý
            </button>
          </Form>
        </Modal.Body>
      </Modal>
    </>
  );
}

export default ModalAddAlbums;
