import { call } from 'redux-saga/effects'
import { track_activities } from 'apis/track_activity'

export function* createActivity({ payload }) {
  try {
    const { params } = payload;
    yield call(track_activities, params);
  } catch (error) {
    console.log(error);
  }
}
