import React from "react";
import ContentLoader from "react-content-loader";
import * as _ from 'lodash';

function SidebarProductsPlaceholder(props) {
  return(
    <div className="sidebar_products">
      {_.chunk(_.range(16), 2).map(function (posts, index) {
        return(
          <div key={index} className="row">
            <div className="clearfix">
              {
                posts.map(function (post, index) {
                  return (
                    <div key={post} className="col col-lg-2">
                      <ContentLoader
                        viewBox="0 0 250 250"
                        height={250}
                        width="98%"
                        speed={3}
                        backgroundColor="#cfcfcf"
                        foregroundColor="#e7dede"
                      >
                        <rect x="3" y="3" rx="10" ry="10" width="250" height="180" />
                        <rect x="6" y="190" rx="0" ry="0" width="292" height="20" />
                        <rect x="4" y="215" rx="0" ry="0" width="274" height="20" />
                      </ContentLoader>
                    </div>
                  )
                })
              }
            </div>
          </div>
        )
      })}
    </div>
  )
};

export default SidebarProductsPlaceholder;
