import React from "react";
import ContentLoader from "react-content-loader";
import * as _ from 'lodash';

function PostsPlaceholder(props) {
  return(
    <div className="loader">
      {_.range(5).map(function (post, index) {
        return(
          <div key={index} className="row post">
            <div className="col-6-77">
              <ContentLoader
                speed={2}
                backgroundColor="#cfcfcf"
                foregroundColor="#e7dede"
                width="100%"
                height="400"
              >
                <circle cx="30" cy="25" r="20" />
                <rect x="58" y="8" rx="3" ry="3" width="88" height="6" />
                <rect x="58" y="26" rx="3" ry="3" width="52" height="6" />
                <rect x="10" y="50px" width="100%" height="10px" />
                <rect x="10" y="72px" width="100%" height="10px" />
                <rect x="10" y="100px" width="100%" height="250px" />
                <rect x="10" y="370px" width="40%" height="10px" />
              </ContentLoader>
            </div>
          </div>
        )
      })}
    </div>
  )
};

export default PostsPlaceholder;
