import * as giftConst from 'constants/gift';
import {toastSuccess, toastError} from "helpers/toastHelper";

const initialState = {
  catalogue_gift_products: {
    catalogue: [],
    isLoading: false,
  },
  exchangeGift: {
    data: null,
    isLoading: false,
    error: null,
  },
  pointsTransactions: {
    data: [],
    isLoading: false,
    error: null,
  },
  giftDetail: {
    data: null,
    isLoading: false,
    error: null,
  },
  listTopUp: {
    data: [],
    isLoading: false,
    error: null,
  },
  topUpDataForUser: {
    data: null,
    isLoading: false,
    error: null,
  },
  listGift: {
    data: [],
    isLoading: false,
    error: null,
  }
}

export const reducer = (state = initialState, action) => {
  switch(action.type){
    case giftConst.FETCH_CATALOGUE_GIFT_AND_PRODUCT: {
      return {
        ...state,
        catalogue_gift_products: {
          catalogue: initialState.catalogue_gift_products.catalogue,
          isLoading: true
        }
      }
    }

    case giftConst.FETCH_CATALOGUE_GIFT_AND_PRODUCT_SUCCESS: {
      const {data} = action.payload
      const catalogues = data.catalogues;
      return {
        ...state,
        catalogue_gift_products: {
          catalogue: catalogues,
          isLoading: false
        }
      }
    }

    case giftConst.EXCHANGE_POINTS: {
      return {
        ...state,
        exchangeGift: {
          data: initialState.exchangeGift.data,
          isLoading: true,
          error: null
        }
      }
    }

    case giftConst.EXCHANGE_POINTS_SUCCESS: {
      const {data} = action.payload
      toastSuccess(data.data.msg);
      return {
        ...state,
        exchangeGift: {
          data: data.params.store_id,
          isLoading: false,
          error: null
        }
      }
    }

    case giftConst.EXCHANGE_POINTS_FAILED: {
      const {error} = action.payload
      toastError(error.msg);
      return {
        ...state,
        exchangeGift: {
          data: error.msg,
          isLoading: false,
          error: null
        }
      }
    }

    case giftConst.FETCH_POINTS_TRANSACTION: {
      return {
        ...state,
        pointsTransactions: {
          data: initialState.pointsTransactions.data,
          isLoading: true,
          error: null,
        }
      }
    }

    case giftConst.FETCH_POINTS_TRANSACTION_SUCCESS: {
      const {data} = action.payload
      return {
        ...state,
        pointsTransactions: {
          data: data.data,
          isLoading: false,
          error: null,
        }
      }
    }
    case giftConst.FETCH_LIST_TOP_UP: {
      return {
        ...state,
        listTopUp: {
          data: initialState.listTopUp.data,
          isLoading: true,
          error: null
        }
      }
    }
    case giftConst.FETCH_LIST_TOP_UP_SUCCESS: {
      const {data} = action.payload;
      return {
        ...state,
        listTopUp: {
          data: data.data,
          isLoading: false,
          error: null
        }
      }
    }
    case giftConst.TOP_UP_DATA_USER: {
      return {
        ...state,
        topUpDataForUser: {
          data: initialState.topUpDataForUser.data,
          isLoading: true,
          error: null
        }
      }
    }
    case giftConst.TOP_UP_DATA_USER_SUCCESS: {
      const {data} = action.payload;
      toastSuccess(data.msg);
      return {
        ...state,
        topUpDataForUser: {
          data: data.msg,
          isLoading: false,
          error: null
        }
      }
    }
    case giftConst.TOP_UP_DATA_USER_FAILED: {
      const {error} = action.payload;
      toastError(error.msg);
      return {
        ...state,
        topUpDataForUser: {
          data: error.msg,
          isLoading: false,
          error: error.msg
        }
      }
    }
    case giftConst.FETCH_LIST_GIFT: {
      return {
        ...state,
        listGift: {
          data: initialState.listGift.data,
          isLoading: true,
          error: null
        }
      }
    }
    case giftConst.FETCH_LIST_GIFT_SUCCESS: {
      const {data} = action.payload;
      return {
        ...state,
        listGift: {
          data: data.data,
          isLoading: false,
          error: null
        }
      }
    }
    case giftConst.UPDATE_QUANTITY_OF_GIFT_SUCCESS: {
      const {gift_id} = action.payload;
      return {
        ...state,
        listGift: {
          data: state.listGift.data.map((gift) => {
            if (gift_id == gift.id) {
              gift.quantity_exchanged ++ ;
            }
            return gift;
          }),
          isLoading: false,
          error: null
        }
      }
    }

    default: {
      return state
    }
  }
}
export default reducer;
