import React, { useEffect, useState } from "react";
import LeftSidebar from "components/left_sidebar";
import AddMetaTags from "components/add_meta_tags";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
import Form from "react-bootstrap/Form";
import { toastSuccess } from "helpers/toastHelper";
import { getWeatherStatus, createWeatherSchedule } from "apis/weather";
import { useNavigate } from "react-router-dom";
import * as Yup from "yup";
import { useFormik } from "formik";
import { ArrowLeft } from "react-bootstrap-icons";
import { useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { fetchWeatherScheduleId } from "actions/weather";
function New(props) {
  let [listWeatherStatus, setListWeatherStatus] = useState([]);
  const nagivate = useNavigate();
  const { weatherId } = useParams();
  const dispatch = useDispatch();
  const { data } = useSelector((state) => state.weather.weatherScheduleId);

  useEffect(() => {
    getWeatherStatus().then((res) => {
      if (res) {
        setListWeatherStatus(res.data.data);
      }
    });
    if (weatherId) {
      dispatch(fetchWeatherScheduleId(weatherId));
    }
  }, [weatherId]);

  const formik = useFormik({
    initialValues: {
      title: "",
      weather_status: "",
      address: "",
      trigger_point: "",
      trigger_condition: ">=",
    },
    validationSchema: Yup.object().shape({
      title: Yup.string().required("Tiêu đề không được bỏ trống"),
      trigger_point: Yup.string()
        .required("Nhiệt độ không được bỏ trống")
        .matches(/^-?\d+$/, "Chỉ được nhập số"),
      address: Yup.string().required("Địa chỉ không được bỏ trống"),
    }),
    onSubmit: (values, { resetForm }) => {
      createWeatherSchedule({
        title: values.title,
        weather_status: values.weather_status,
        address: values.address,
        trigger_point: values.trigger_point,
        trigger_condition: values.trigger_condition,
      }).then((response) => {
        if (response) {
          toastSuccess("Tạo thông báo thành công");
          nagivate("/nguoi-dung/thiet-lap-thong-bao");
        }
      });
    },
  });

  return (
    <>
      <div className="new-setting-weather">
        <AddMetaTags title={"Tạo mới thiết lập thời tiết"} />
        <Row>
          <LeftSidebar />
          <Col as="div" md={9}>
            <div className="cont-center">
              <h5 className="title-line-lt text-uppercase fw-bold mt-3 d-flex align-items-center line-none">
                <ArrowLeft
                  size={24}
                  fill="#009a59"
                  style={{ cursor: "pointer" }}
                  onClick={() => nagivate("/nguoi-dung/thiet-lap-thong-bao")}
                />
                <span className="d-block ml-2">{props.isEditMode ? "Chỉnh sửa" : "Tạo mới"} thông báo thời tiết</span>
              </h5>
            </div>
            <form onSubmit={formik.handleSubmit}>
              <div className="field-new-setting-weather">
                <Row>
                  <Col md={6}>
                    <label>Tên sự kiện</label>
                    <input
                      className="form-control"
                      type="text"
                      value={formik.values.title}
                      onChange={formik.handleChange}
                      name="title"
                    />
                    {formik.errors.title && formik.touched.title && (
                      <label className="alert-invalid">{formik.errors.title}</label>
                    )}
                  </Col>
                  <Col md={6}>
                    <label>Nhiệt độ</label>
                    <input
                      className="form-control"
                      type="text"
                      value={formik.values.trigger_point}
                      name="trigger_point"
                      onChange={formik.handleChange}
                    />
                    {formik.errors.trigger_point && formik.touched.trigger_point && (
                      <label className="alert-invalid">{formik.errors.trigger_point}</label>
                    )}
                  </Col>
                  <Col md={6}>
                    <label>Điều kiện</label>
                    <div className="input-group">
                      <select
                        className="custom-select"
                        id="inputGroupSelect02"
                        onChange={formik.handleChange}
                        defaultValue=">="
                        name="trigger_condition"
                      >
                        <option value=">=">{">="}</option>
                        <option value="<=">{"<="}</option>
                      </select>
                      <div className="input-group-append">
                        <input
                          className="form-control"
                          type="text"
                          style={{ borderRadius: "0px" }}
                          onChange={formik.handleChange}
                          name="address"
                          placeholder="Địa chỉ: TPHCM, Bến tre"
                        />
                      </div>
                    </div>
                    {formik.errors.address && formik.touched.address && (
                      <label className="alert-invalid">{formik.errors.address}</label>
                    )}
                  </Col>
                  <Col md={6}>
                    <label>Thời tiết</label>
                    <Form.Select
                      aria-label="Default select example"
                      onChange={formik.handleChange}
                      defaultValue="Bầu trời quang đãng"
                      name="weather_status"
                    >
                      <option value="">Tất cả</option>
                      {listWeatherStatus &&
                        listWeatherStatus.map(function (data, index) {
                          return (
                            <option value={data.lable} key={index}>
                              {data.lable}
                            </option>
                          );
                        })}
                    </Form.Select>
                  </Col>
                </Row>

                <div className="text-center mt-3">
                  <button type="submit" className="btn btn-danger" disabled={listWeatherStatus ? false : true}>
                    {props.isEditMode ? "Edit" : " New"}
                  </button>
                </div>
              </div>
            </form>
          </Col>
        </Row>
      </div>
    </>
  );
}

export default New;
