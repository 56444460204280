import qs from 'query-string';
import axiosService from 'services/axiosService';
import { DOMAIN } from 'constants';

const url = 'api/v2/notifications';
export const getNotifications = (params) => {

  let query = '';
  if (Object.keys(params).length > 0) {
    query = `?${qs.stringify(params)}`;
  }
  return axiosService.get(`${DOMAIN}/${url}${query}`, true)
};

export const deleteNotification = id => {
  return axiosService.post(`${DOMAIN}/${url}/${id}`);
};

export const getCountNotifications = () => {
  return axiosService.get(`${DOMAIN}/${url}/count`, true);
};

export const viewNotification = id => {
  return axiosService.put(`${DOMAIN}/${url}/seen/${id}`);
};
