import {
	FETCH_POST_DETAIL,
	FETCH_POST_DETAIL_SUCCESS,
	FETCH_POST_DETAIL_FAILED,
	LIKE_POST_DETAIL,
	LIKE_POST_DETAIL_SUCCESS,
	FETCH_COMMENT_POST_DETAIL,
	FETCH_COMMENT_POST_DETAIL_SUCCESS,
	FETCH_COMMENT_POST_DETAIL_FAIL,
	LIKE_COMMENT_POST_DETAIL,
	LIKE_COMMENT_POST_DETAIL_SUCCESS,
	FETCH_SUBCOMMENT_POST_DETAIL,
	FETCH_SUBCOMMENT_POST_DETAIL_SUCCESS,
	COMMENT_POST_DETAIL,
	COMMENT_POST_DETAIL_SUCCESS,
	REPLY_COMMENT_POST_DETAIL,
	UPDATE_COMMENT_WITH_PATTERN,
	MODIFY_REPLY_DATA,
	MODIFY_EDIT_DATA,
	EDIT_COMMENT_POST_DETAIL,
	EDIT_COMMENT_POST_DETAIL_SUCCESS,
	DELETE_COMMENT_POST_DETAIL,
	DELETE_COMMENT_POST_DETAIL_SUCCESS,
	DELETE_SUBCOMMENT_POST_DETAIL,
	DELETE_SUBCOMMENT_POST_DETAIL_SUCCESS,
	EDIT_SUBCOMMENT_POST_DETAIL,
	EDIT_SUBCOMMENT_POST_DETAIL_SUCCESS,
	FAST_FOLLOW_USER_IN_POST_DETAIL,
	FAST_FOLLOW_USER_IN_POST_DETAIL_SUCCESS,
	FAST_UNFOLLOW_USER_IN_POST_DETAIL,
	FAST_UNFOLLOW_USER_IN_POST_DETAIL_SUCCESS,

} from "constants/postDetail";

export const fetchPostDetailImage = ({ postId, imageId }) => {
	return {
		type: FETCH_POST_DETAIL,
		payload: {
			postId,
			imageId,
		},
	};
};

export const fetchPostDetailSuccess = (data) => {
	return {
		type: FETCH_POST_DETAIL_SUCCESS,
		payload: data,
	};
};

export const fetchPostDetailFailed = (error) => {
	return {
		type: FETCH_POST_DETAIL_FAILED,
		payload: {
			error,
		},
	};
};

export const likePostDetail = (params) => ({
	type: LIKE_POST_DETAIL,
	payload: params
})
export const likePostDetailSuccess = (data) => ({
	type: LIKE_POST_DETAIL_SUCCESS,
	payload: data
})

export const fetchCommentPost = (params) => ({ type: FETCH_COMMENT_POST_DETAIL, payload: { params } })
export const fetchCommentPostSuccess = (data) => ({ type: FETCH_COMMENT_POST_DETAIL_SUCCESS, payload: data })
export const fetchCommentPostFail = (data) => ({ type: FETCH_COMMENT_POST_DETAIL_FAIL, payload: data })

export const fetchSubCommentPost = (params) => ({ type: FETCH_SUBCOMMENT_POST_DETAIL, payload: params })
export const fetchSubCommentPostSuccess = (data) => ({ type: FETCH_SUBCOMMENT_POST_DETAIL_SUCCESS, payload: data })

export const likeCommentPostDetail = (params) => ({ type: LIKE_COMMENT_POST_DETAIL, payload: params })
export const likeCommentPostDetailSuccess = (data) => ({ type: LIKE_COMMENT_POST_DETAIL_SUCCESS, payload: data })

export const commentPostDetail = (params) => ({ type: COMMENT_POST_DETAIL, payload: params })
export const commentPostDetailSuccess = (data) => ({ type: COMMENT_POST_DETAIL_SUCCESS, payload: data })

export const replyCommentPostDetail = (params) => ({ type: REPLY_COMMENT_POST_DETAIL, payload: params })

export const updateCommentWithPattern = (data) => ({ type: UPDATE_COMMENT_WITH_PATTERN, payload: data })
export const modifyReplyData = (data) => ({ type: MODIFY_REPLY_DATA, payload: data })
export const modifyEditData = (data) => ({ type: MODIFY_EDIT_DATA, payload: data })
export const editCommentPostDetail = (params) => ({type: EDIT_COMMENT_POST_DETAIL, payload: params})
export const editCommentPostDetailSuccess = (data) => ({ type: EDIT_COMMENT_POST_DETAIL_SUCCESS, payload: data})
export const deleteCommentPostDetail = (params) => ({ type: DELETE_COMMENT_POST_DETAIL, payload: params})
export const deleteCommentPostDetailSuccess = (data) => ({ type: DELETE_COMMENT_POST_DETAIL_SUCCESS, payload: data});
export const deleteSubCommentPostDetail = (params) => ({type: DELETE_SUBCOMMENT_POST_DETAIL, payload: params})
export const deleteSubCommentPostDetailSuccess = (data) => ({type: DELETE_SUBCOMMENT_POST_DETAIL_SUCCESS, payload: data})
export const editSubCommmentPostDetail = (params) => ({type: EDIT_SUBCOMMENT_POST_DETAIL, payload: params});
export const editSubCommmentPostDetailSuccess = (data) => ({type: EDIT_SUBCOMMENT_POST_DETAIL_SUCCESS, payload: data});
export const fastFollowUser = (params) => ({type: FAST_FOLLOW_USER_IN_POST_DETAIL, payload: {params	}});
export const fastFollowUserSuccess = (data) => ({type: FAST_FOLLOW_USER_IN_POST_DETAIL_SUCCESS, payload: {data	}});

export const fastUnFollowUser = (params) => ({type: FAST_UNFOLLOW_USER_IN_POST_DETAIL, payload: {params	}});
export const fastUnFollowUserSuccess = (data) => ({type: FAST_UNFOLLOW_USER_IN_POST_DETAIL_SUCCESS, payload: {data}});