export const FETCH_LIST_BUSINESS = "FETCH_LIST_BUSINESS";
export const FETCH_LIST_BUSINESS_SUCCESS = "FETCH_LIST_BUSINESS_SUCCESS";
export const FETCH_LIST_BUSINESS_FAILED = "FETCH_LIST_BUSINESS_FAILED";

export const FETCH_LIST_USER_NOT_JOIN_BUSINESS = "FETCH_LIST_USER_NOT_JOIN_BUSINESS";
export const FETCH_LIST_USER_NOT_JOIN_BUSINESS_SUCCESS = "FETCH_LIST_USER_NOT_JOIN_BUSINESS_SUCCESS";
export const FETCH_LIST_USER_NOT_JOIN_BUSINESS_FAILED = "FETCH_LIST_USER_NOT_JOIN_BUSINESS_FAILED";

export const DELETE_BUSINESS = "DELETE_BUSINESS";
export const DELETE_BUSINESS_SUCCESS = "DELETE_BUSINESS_SUCCESS";
export const DELETE_BUSINESS_FAILED = "DELETE_BUSINESS_FAILED";

export const FETCH_BUSINESS_DETAIL = "FETCH_BUSINESS_DETAIL";
export const FETCH_BUSINESS_DETAIL_SUCCESS = "FETCH_BUSINESS_DETAIL_SUCCESS";
export const FETCH_BUSINESS_DETAIL_FAILED = "FETCH_BUSINESS_DETAIL_FAILED";
