import React from "react";
import { Link } from "react-router-dom";
const Breadcumb = ({ breadcumb }) => {
  return (
    <nav aria-label="breadcrumb">
      <ol className="breadcrumb mb-0">
        <li className="breadcrumb-item">
          <Link to={`/quy-trinh-ky-thuat`}>
            <span className="mr-1">Quy trình kỹ thuật</span>
          </Link>
        </li>
        <span className="icon-arrow-black mt-1"></span>
        <span className="mr-1 gray-clr">{breadcumb.catagory}</span>
        {breadcumb.section && (
          <>
            <span className="icon-arrow-black  mt-1"></span>
            <span className="mr-1 gray-clr">{breadcumb.section}</span>
          </>
        )}
        {breadcumb.chapter && (
          <>
            <span className="icon-arrow-black  mt-1"></span>
            <span className="mr-1 gray-clr">{breadcumb.chapter}</span>
          </>
        )}
      </ol>
    </nav>
  );
};

export default Breadcumb;
