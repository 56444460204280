export const FETCH_ARTICLE = "FETCH_ARTICLE";
export const FETCH_ARTICLE_SUCCESS = "FETCH_ARTICLE_SUCCESS";
export const FETCH_ARTICLE_FAILED = "FETCH_ARTICLE_FAILED";

export const FETCH_SIDEBAR_ARTICLE = "FETCH_SIDEBAR_ARTICLE";
export const FETCH_SIDEBAR_ARTICLE_SUCCESS = "FETCH_SIDEBAR_ARTICLE_SUCCESS";
export const FETCH_SIDEBAR_ARTICLE_FAILED = "FETCH_SIDEBAR_ARTICLE_FAILED";

export const FETCH_ARTICLE_DETAIL_ID = "FETCH_ARTICLE_DETAIL_ID";
export const FETCH_ARTICLE_DETAIL_ID_SUCCESS = "FETCH_ARTICLE_DETAIL_ID_SUCCESS";
export const FETCH_ARTICLE_DETAIL_ID_FAILED = "FETCH_ARTICLE_DETAIL_ID_FAILED";

export const FETCH_RELATION_ARTICLE = "FETCH_RELATION_ARTICLE";
export const FETCH_RELATION_ARTICLE_SUCCESS = "FETCH_RELATION_ARTICLE_SUCCESS";
export const FETCH_RELATION_ARTICLE_FAILED = "FETCH_RELATION_ARTICLE_FAILED";

export const FETCH_ARTICLE_COMMENT = "FETCH_ARTICLE_COMMENT";
export const FETCH_ARTICLE_COMMENT_SUCCESS = "FETCH_ARTICLE_COMMENT_SUCCESS";
export const FETCH_ARTICLE_COMMENT_FAILED = "FETCH_ARTICLE_COMMENT_FAILED";

export const CREATE_ARTICLE_COMMENT = "CREATE_ARTICLE_COMMENT";
export const CREATE_ARTICLE_COMMENT_SUCCESS = "CREATE_ARTICLE_COMMENT_SUCCESS";
export const CREATE_ARTICLE_COMMENT_FAILED = "CREATE_ARTICLE_COMMENT_FAILED";

export const FETCH_COMMENT_ANSWER_ARTICLE = "FETCH_COMMENT_ANSWER_ARTICLE";
export const FETCH_COMMENT_ANSWER_ARTICLE_SUCCESS = "FETCH_COMMENT_ANSWER_ARTICLE_SUCCESS";
export const FETCH_COMMENT_ANSWER_ARTICLE_FAILED = "FETCH_COMMENT_ANSWER_ARTICLE_FAILED";

export const CREATE_ANSWER_COMMENT_ARTICLE = "CREATE_ANSWER_COMMENT";
export const CREATE_ANSWER_COMMENT_ARTICLE_SUCCESS = "CREATE_ANSWER_COMMENT_SUCCESS";
export const CREATE_ANSWER_COMMENT_ARTICLE_FAILED = "CREATE_ANSWER_COMMENT_FAILED";

export const CREATE_LIKE_COMMENT_ARTICLE = "CREATE_LIKE_COMMENT_ARTICLE";
export const CREATE_LIKE_COMMENT_ARTICLE_SUCCESS = "CREATE_LIKE_COMMENT_ARTICLE_SUCCESS";
export const CREATE_LIKE_COMMENT_ARTICLE_FAILED = "CREATE_LIKE_COMMENT_ARTICLE_FAILED";

export const CREATE_UNLIKE_COMMENT_ARTICLE = "CREATE_UNLIKE_COMMENT_ARTICLE";
export const CREATE_UNLIKE_COMMENT_ARTICLE_SUCCESS = "CREATE_UNLIKE_COMMENT_ARTICLE_SUCCESS";
export const CREATE_UNLIKE_COMMENT_ARTICLE_FAILED = "CREATE_UNLIKE_COMMENT_ARTICLE_FAILED";

export const LOAD_MORE_COMMENT_ARTICLE = "LOAD_MORE_COMMENT_ARTICLE";
export const LOAD_MORE_COMMENT_ARTICLE_SUCCESS = "LOAD_MORE_COMMENT_ARTICLE_SUCCESS";
export const LOAD_MORE_COMMENT_ARTICLE_FAILED = "LOAD_MORE_COMMENT_ARTICLE_FAILED";

export const FETCH_FEATURE_ARTICLE = "FETCH_FEATURE_ARTICLE";
export const FETCH_FEATURE_ARTICLE_SUCCESS = "FETCH_FEATURE_ARTICLE_SUCCESS";
export const FETCH_FEATURE_ARTICLE_FAILED = "FETCH_FEATURE_ARTICLE_FAILED";

export const FETCH_FAVORITES_ARTICLE = "FETCH_FAVORITES_ARTICLE";
export const FETCH_FAVORITES_ARTICLE_SUCCESS = "FETCH_FAVORITES_ARTICLE_SUCCESS";
export const FETCH_FAVORITES_ARTICLE_FAILED = "FETCH_FAVORITES_ARTICLE_FAILED";

export const CREATE_FAVORITE_ARTICLE = "CREATE_FAVORITE_ARTICLE";
export const CREATE_FAVORITE_ARTICLE_SUCCESS = "CREATE_FAVORITE_ARTICLE_SUCCESS";
export const CREATE_FAVORITE_ARTICLE_FAILED = "CREATE_FAVORITE_ARTICLE_FAILED";

export const DELETE_FAVORITE_ARTICLE = "DELETE_FAVORITE_ARTICLE";
export const DELETE_FAVORITE_ARTICLE_SUCCESS = "DELETE_FAVORITE_ARTICLE_SUCCESS";
export const DELETE_FAVORITE_ARTICLE_FAILED = "DELETE_FAVORITE_ARTICLE_FAILED";

export const FETCH_OBJECT_HASHTAG = "FETCH_OBJECT_HASHTAG";
export const FETCH_OBJECT_HASHTAG_SUCCESS = "FETCH_OBJECT_HASHTAG_SUCCESS";
export const FETCH_OBJECT_HASHTAG_FAILED = "FETCH_OBJECT_HASHTAG_FAILED";

export const REPORT_COMMENT_ARTICLE = "REPORT_COMMENT_ARTICLE";
export const REPORT_COMMENT_ARTICLE_FAILED = "REPORT_COMMENT_ARTICLE_FAILED";

export const REPLY_COMMENT = "REPLY_COMMENT";
export const REPLY_COMMENT_SUCCESS = "REPLY_COMMENT_SUCCESS";
export const REPLY_COMMENT_FAILED = "REPLY_COMMENT_FAILED";

export const UPDATE_COMMENT = "UPDATE_COMMENT";
export const UPDATE_COMMENT_SUCCESS = "UPDATE_COMMENT_SUCCESS";
export const UPDATE_COMMENT_FAILED = "UPDATE_COMMENT_FAILED";

export const UPDATE_CHILD_COMMENT = "UPDATE_CHILD_COMMENT";
export const UPDATE_CHILD_COMMENT_SUCCESS = "UPDATE_CHILD_COMMENT_SUCCESS";
export const UPDATE_CHILD_COMMENT_FAILED = "UPDATE_CHILD_COMMENT_FAILED";

export const MANAGER_UPDATE_COMMENT = "MANAGER_UPDATE_COMMENT";
export const MANAGER_UPDATE_COMMENT_SUCCESS = "MANAGER_UPDATE_COMMENT_SUCCESS";

export const MANAGER_UPDATE_CHILD_COMMENT = "MANAGER_UPDATE_CHILD_COMMENT";
export const MANAGER_UPDATE_CHILD_COMMENT_SUCCESS = "MANAGER_UPDATE_CHILD_COMMENT_SUCCESS";

export const MANAGER_DESTROY_COMMENT = "MANAGER_DESTROY_COMMENT";
export const MANAGER_DESTROY_COMMENT_SUCCESS = "MANAGER_DESTROY_COMMENT_SUCCESS";

export const MANAGER_DESTROY_CHILD_COMMENT = "MANAGER_DESTROY_CHILD_COMMENT";
export const MANAGER_DESTROY_CHILD_COMMENT_SUCCESS = "MANAGER_DESTROY_CHILD_COMMENT_SUCCESS";

export const USER_DESTROY_COMMENT = "USER_DESTROY_COMMENT";
export const USER_DESTROY_COMMENT_SUCCESS = "USER_DESTROY_COMMENT_SUCCESS";

export const USER_DESTROY_CHILD_COMMENT = "USER_DESTROY_CHILD_COMMENT";
export const USER_DESTROY_CHILD_COMMENT_SUCCESS = "USER_DESTROY_CHILD_COMMENT_SUCCESS";

export const FETCH_LOAD_MORE_COMMENT_ARTICLE = "FETCH_LOAD_MORE_COMMENT_ARTICLE";
export const FETCH_LOAD_MORE_COMMENT_ARTICLE_SUCCESS = "FETCH_LOAD_MORE_COMMENT_ARTICLE_SUCCESS";
