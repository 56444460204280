import React, { useEffect, useContext } from "react";
import { useDispatch, useSelector } from "react-redux";
import { fetchUserAlbums, createAnAlbum, destroyUserAlbums } from "actions/albums";
import Loader from "components/loader";
import { Link } from "react-router-dom";
import AppContext from "components/app_context";
import ModalAddAlbums from "./ModalAddAlbums";
import { useState } from "react";
import { useParams } from "react-router-dom";
import { imageUrl } from "lib";
import Dropdown from "react-bootstrap/Dropdown";
import ModalConfirmDelete from "components/shared/modalDeleteConfirmComment/modal";
import ModalEditAlbum from "./ModalEditAlbums";
import { useNavigate } from "react-router-dom";

function Albums() {
  const dispatch = useDispatch();
  let { albumId } = useParams();
  let [showModalAddAlbums, setShowModalAddAlbums] = useState(false);
  let [albumsIdSelected, setAlbumsIdSelected] = useState("");
  let [albumsIdEdit, setAlbumsIdEdit] = useState("");
  const navigate = useNavigate();

  const { current_user } = useContext(AppContext);
  const { data, isLoading } = useSelector((state) => state.albums.listAlbums);

  useEffect(() => {
    if (!albumId && current_user) {
      dispatch(fetchUserAlbums({ user_id: current_user?.id }));
    } else if (current_user && albumId) {
      dispatch(fetchUserAlbums({ user_id: current_user?.id, albumsId: albumId }));
    }
  }, [dispatch, current_user, albumId]);

  const defaultAlbums = data.find((album) => album.is_default == true);

  const handleDeleteAlbums = () => {
    if (albumsIdSelected == albumId) {
      dispatch(destroyUserAlbums(albumsIdSelected));
      navigate(`/nguoi-dung/albums/${defaultAlbums?.id}`);
    } else {
      dispatch(destroyUserAlbums(albumsIdSelected));
    }
  };

  return (
    <>
      {current_user && (
        <div className="mb-0">
          <div className="cate-lst news album-sectn mb-2">
            <div className="row">
              <Loader isLoading={isLoading} />
              {data.map(function (album, index) {
                return (
                  <div className="col-sm-4 col-xs-6 col-md-6 col-lg-3 col-12p5 my-1 albums" key={index}>
                    <div className="position-relative">
                      <Link to={`/nguoi-dung/albums/${album.id}`} className="cate-item position-relative">
                        <div className="img-box w-100 h-100 position-absolute">
                          <img
                            src={album?.thumbnail ? imageUrl(album?.thumbnail?.name) : "/images/logo.png"}
                            alt="product cate"
                            title="photo"
                            className="img-cover"
                          />
                        </div>
                        <div className="prod-inf position-absolute d-flex align-items-center albums">
                          <div className="line-clamp-02 fw-bold fs-6 w-100 ">{album?.name}</div>
                        </div>
                      </Link>
                      {window.location.pathname !== "/" && (
                        <Dropdown className="position-absolute top-0 end-0 more-btn">
                          <Dropdown.Toggle variant="success" id="icon-dot"></Dropdown.Toggle>
                          <Dropdown.Menu as="ul">
                            <Dropdown.Item as="li" onClick={() => setAlbumsIdEdit(album.id)}>
                              Sửa
                            </Dropdown.Item>
                            <Dropdown.Item as="li" onClick={() => setAlbumsIdSelected(album.id)}>
                              Delete
                            </Dropdown.Item>
                          </Dropdown.Menu>
                        </Dropdown>
                      )}
                    </div>
                  </div>
                );
              })}

              <ModalConfirmDelete
                onDelete={() => handleDeleteAlbums()}
                onToggle={() => setAlbumsIdSelected("")}
                isOpen={albumsIdSelected}
              />
              <ModalEditAlbum onToggle={() => setAlbumsIdEdit("")} isOpen={albumsIdEdit} />
              {window.location.pathname !== "/" && (
                <div className="col-sm-4 col-xs-6 col-md-6 col-lg-3 col-12p5 my-1">
                  <button className="btn cate-item position-relative" onClick={() => setShowModalAddAlbums(true)}>
                    <div className="img-box w-100 h-100 position-absolute top-0 start-0 d-flex justify-content-center align-items-center">
                      <span className="icon-close"></span>
                      <div className="line-clamp-02 fw-bold fs-6">Tạo Album</div>
                    </div>
                  </button>
                </div>
              )}
            </div>
          </div>
          <ModalAddAlbums onToggle={() => setShowModalAddAlbums(false)} isOpen={showModalAddAlbums} />
        </div>
      )}
    </>
  );
}

export default Albums;
