import qs from "query-string";
import axiosService from "services/axiosService";
import { DOMAIN, PAGE, PER_PAGE } from "constants";

const url = "api/v2/comments";

export const getComment = (params) => {
  let query = "";
  if (Object.keys(params).length > 0) {
    query = `?${qs.stringify(params)}`;
  }
  return axiosService.get(`${DOMAIN}/${url}${query}`, true);
};

export const createComment = (params) => {
  return axiosService.post(`${DOMAIN}/${url}`, params);
};

export const reportComment = (params) => {
  let query = "";
  if (Object.keys(params).length > 0) {
    query = `?${qs.stringify(params)}`;
  }
  return axiosService.post(`${DOMAIN}/${url}/warning_comment${query}`, true);
};

export const deleteComment = (id) => {
  return axiosService.post(`${DOMAIN}/${url}/${id}`);
};

export const getAnswersComment = (params) => {
  let query = "";
  if (Object.keys(params).length > 0) {
    query = `?${qs.stringify(params)}`;
  }
  return axiosService.get(`${DOMAIN}/${url}/${params.id}/answers${query}`, true);
};

export const replyComment = (params) => {
  return axiosService.post(`${DOMAIN}/${url}/${params.id}/create_answer`, params);
};

export const updateComment = (params) => {
  return axiosService.put(`${DOMAIN}/${url}/${params.id}`, params);
};

export const updateCommentAnswer = (params) => {
  return axiosService.put(`${DOMAIN}/${url}/update_answer/${params.id}`, params);
};
export const destroyComment = (id) => {
  return axiosService.delete(`${DOMAIN}/${url}/${id}`);
};

export const destroyChildComment = (id) => {
  return axiosService.delete(`${DOMAIN}/${url}/destroy_answer/${id}`);
};

export const getDetailComment = (id) => {
  return axiosService.get(`${DOMAIN}/${url}/${id}`, true);
};
const url_action_item = "api/v2/account";

export const likeComment = (params) => {
  return axiosService.post(`${DOMAIN}/${url_action_item}/like_item`, params);
};
export const disLikeComment = (params) => {
  return axiosService.post(`${DOMAIN}/${url_action_item}/unlike`, params);
};
