
import { useEffect } from 'react';
import ScrollToTop  from 'components/ScrollToTop';
import AddMetaTags from "components/add_meta_tags"
import LeftSidebar from 'components/left_sidebar'
import RightSidebar from 'components/right_sidebar'
import { useParams } from "react-router-dom"
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import { DOMAIN } from 'constants'
import parse from "html-react-parser"
import * as diagnosticActions from 'actions/diagnostic'
import moment from 'moment'

function DiagnosticDetail(props){
  let { diagnosticsId } = useParams();
  const { fetchDiagnosticInfoDetails } = props.diagnosticActionsCreator;
  let { data } = props.diagnosticDetail;

  useEffect(() => {
    fetchDiagnosticInfoDetails({id: diagnosticsId});
  }, [fetchDiagnosticInfoDetails]);

  return (
    <ScrollToTop>
      <div className="diagnostic_detail">
        <AddMetaTags title= {`${data?.title}`} />
        <div className='row'>
         <LeftSidebar />
         <div className="col-md-6 col-sm-12">
          <div className="cont-center">
            <div className="cntry-news">
              <div className="w-100 text-center"><img className="image" style={{ width: '100%' }} src={`${data?.image}`} alt="slider" /></div>
              <small className="grey-light-clr fst-italic cap articles_time d-block mt-2">{moment(data?.created_at).format("DD/MM/YYYY ")}</small>
              <h3 className="mt-3">{data && parse(data?.title)}</h3>
              <div>{data && parse(data?.content)}</div>
            </div>
          </div>
        </div>
        <RightSidebar />
        </div>
      </div>
    </ScrollToTop>
  )
}
const mapStateToProps = state => {
  return {
    diagnosticDetail: state.diagnostic.diagnosticDetail,
  };
};

const mapDispatchToProps = dispatch => ({
  diagnosticActionsCreator: bindActionCreators(diagnosticActions, dispatch)
});

export default connect(mapStateToProps, mapDispatchToProps)(DiagnosticDetail);