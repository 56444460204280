import React, { Component } from "react";

class Footer extends Component {
  render() {
    return (
      <footer className="footer-sectn">
        <div className="container">
          <div className="row">
            <div className="col-md-3 col-sm-12 d-flex align-items-center">
              <a className="logo" href="#">
                <img src="/images/logo.png" alt="logo images" title="Go to Homepage" />
              </a>
            </div>
            <div className="col-md-5 col-sm-12 contact-inf">
              <h4>Liên Hệ</h4>
              <div>Địa chỉ: Tầng 18, tòa nhà Petroland, số 12 Tân Trào, quận 7, Tp. Hồ Chí Minh</div>
              <div>
                Tell:{" "}
                <a href="tel:19008696" target="_blank">
                  19008696
                </a>
              </div>
              <div>
                Email:{" "}
                <a href="mailto:2nong.pvcfc@gmail.com" target="_blank">
                  2nong.pvcfc@gmail.com{" "}
                </a>
              </div>
              <div>
                Liên kết hỗ trợ:{" "}
                <a href="http://help.hainong.vn/" target="_blank">
                  help.hainong.vn
                </a>
              </div>
            </div>
            <div className="col-md-4 col-sm-12">
              <h5 className="mb-3">Liên kết theo dõi</h5>
              <div className="social-ico mt-1 d-flex">
                <a href="https://www.facebook.com/2nong.vn?mibextid=LQQJ4d" target="_blank">
                  <span className="icon-meta">
                    <img
                      src="/images/facebook.png"
                      style={{ width: "30px", height: "30px", marginTop: "8px", marginLeft: "1px" }}
                    />
                  </span>
                </a>
                <a href="https://www.youtube.com/channel/UC61g_bNwMvtYyWd4bju7NWA" target="_blank">
                  <span className="icon-youtube"></span>
                </a>
                <a href="https://zalo.me/1966695983625078558" target="_blank">
                  <span className="icon-zalo"></span>
                </a>
                <a href="https://www.tiktok.com/@2nong.tinnongnghiep?_t=8a5CdgRBl1m&_r=1" target="_blank">
                  <span className="icon-tiktok"></span>
                </a>
              </div>
            </div>
          </div>
        </div>
      </footer>
    );
  }
}

export default Footer;
