import { call, put } from 'redux-saga/effects'
import { getSiteMap } from 'apis/sitemap'
import { fetchSitemapSuccess, fetchSitemapFailed } from 'actions/sitemap'
import { STATUS_CODE } from 'constants/index';
import { toastError } from 'helpers/toastHelper'


export function* fetchSiteMap() {
  try {
    const resp = yield call(getSiteMap);
    const { data, status } = resp;
    if (status === STATUS_CODE.SUCCESS) {
      yield put(fetchSitemapSuccess(data));
    } else {
      yield put(fetchSitemapFailed(data));
    }
  } catch (error) {
    toastError(error);
  }
}