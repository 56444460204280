import { call, put } from "redux-saga/effects";
import {
  fetchUserAlbumsSuccess,
  fetchUserAlbumsFailed,
  fetchUserAlbumsIdSuccess,
  fetchUserAlbumsIdFailed,
  fetchListImageAlbumsSuccess,
  fetchListImageAlbumsFailed,
  uploadImageToAlbumsSuccess,
  uploadImageToAlbumsFailed,
  deleteImageAlbumsSuccess,
  deleteImageAlbumsFailed,
  createAnAlbumSuccess,
  moveImageToOtherAlbumsSuccess,
  destroyUserAlbumsSuccess,
  destroyUserAlbumsFailed,
  editUserAlbumsSuccess,
  fetchUserAlbumsId
} from "actions/albums";
import {
  getUserAlbums,
  getUserAlbumsId,
  getListImageAlbums,
  UploadImageToAlbums,
  destroyImageAlbums, 
  createUserAlbums,
  moveImageToOtherAlbums,
  updateUserAlbums,
  destroyUserAlbums,
} from "apis/albums";
import { STATUS_CODE } from "constants";

export function* userFetchAlbums({ payload }) {
  try {
    const { params } = payload;
    const resp = yield call(getUserAlbums, params);
    const { data } = resp;
    if (data.success) {
      yield put(fetchUserAlbumsSuccess({data: data, albumsId: params.albumsId}))
      yield put(fetchUserAlbumsId(params.albumsId));
    } else {
      yield put(fetchUserAlbumsFailed(data));
    }
  } catch (error) {
    yield put(fetchUserAlbumsFailed(error));
  }
}

export function* userFetchAlbumsId({ payload }) {
  try {
    const { params } = payload;
    const resp = yield call(getUserAlbumsId, params);
    const { data, status } = resp;
    if (status === STATUS_CODE.SUCCESS) {
      yield put(fetchUserAlbumsIdSuccess(data));
    }
  } catch (error) {
    yield put(fetchUserAlbumsIdFailed(error.data));
  }
}

export function* userFetchImageAlbums({ payload }) {
  try {
    const { params } = payload;
    const resp = yield call(getListImageAlbums, params);
    const { data, status } = resp;
    if (status === STATUS_CODE.SUCCESS) {
      yield put(fetchListImageAlbumsSuccess(data));
    }
  } catch (error) {
    yield put(fetchListImageAlbumsSuccess(error.data));
  }
}

export function* userUploadImageAlbums({ payload }) {
  try {
    const { albumsId, params } = payload;
    const resp = yield call(UploadImageToAlbums, params, albumsId);
    const { data, status } = resp;
    if (status === STATUS_CODE.SUCCESS) {
      yield put(uploadImageToAlbumsSuccess(data));
    }
  } catch (error) {
    yield put(uploadImageToAlbumsFailed(error.data));
  }
}

export function* userDestroyImageAlbums({ payload }) {
  try {
    const { albumsId, imageId } = payload;
    const resp = yield call(destroyImageAlbums, albumsId, imageId);
    const { data, status } = resp;
    if (status === STATUS_CODE.SUCCESS) {
      yield put(deleteImageAlbumsSuccess(data))
    }
  } catch (error) {

  }
}

export function* userAddNewAlbum({payload}){
  try {
    const {params} = payload;
    const resp = yield call(createUserAlbums, params);
    const { data, status } = resp;
      yield put(createAnAlbumSuccess(data));
  } catch (error) {
    
  }
}

export function* userMoveImageToOtherAlbums({payload}){
  try {
    const {albumsId, imageId, otherAlbumsId} = payload;
    const resp = yield call(moveImageToOtherAlbums, albumsId, imageId, otherAlbumsId);
    const { data, status } = resp;
    if(status === STATUS_CODE.SUCCESS)  yield put(moveImageToOtherAlbumsSuccess({imageId: imageId}));
  } catch (error) {
    
  }
}

export function* userDeleteAlbums ({payload}){
  try {
    const {params} = payload;
    const resp = yield call(destroyUserAlbums, params)
    const {data, status} = resp;
    if(status === STATUS_CODE.SUCCESS) {
      yield put(destroyUserAlbumsSuccess(data))
    }else{
      
    }
  } catch (error) {
    yield put(destroyUserAlbumsFailed(error.data))
  }
}

export function* userEditAlbums ({payload}){
  try {
    const {params} = payload;
    const resp = yield call(updateUserAlbums, params)
    const {data, status} = resp;
    if(status === STATUS_CODE.SUCCESS) yield put(editUserAlbumsSuccess(data))
  } catch (error) {
    
  }
}