import * as expertConst from 'constants/expert';

export const fetchExpert = params => {
  return {
    type: expertConst.FETCH_EXPERTS,
    payload: {
      params,
    },
  };
};

export const fetchExpertSuccess = data => {
  return {
    type: expertConst.FETCH_EXPERTS_SUCCESS,
    payload: {
      data,
    },
  };
};

export const fetchExpertFailed = error => {
  return {
    type: expertConst.FETCH_EXPERTS_FAILED,
    payload: {
      error,
    },
  };
};