import React from "react";
import { generateExternalZaloShareLink } from "../../utils";

const ButtonShareZalo = ({ children }) => {
  const postUrl = window.location.href;
  const path = generateExternalZaloShareLink(postUrl);
  return (
    <a role="button" target="_blank" href={path}>
      <img src="/images/zalo-icon.png" alt="fb-share" />
      {children}
    </a>
  );
};

export default ButtonShareZalo;

// interve-box
