import axiosService from 'services/axiosService';
import { DOMAIN } from 'constants';
import qs from 'query-string';

const url = 'api/managers/v2';

export const managerDeleteComment = (id) => {
  return axiosService.delete(`${DOMAIN}/${url}/comments/${id}`)
}

export const managerDeleteSubComment = (id) => {
  return axiosService.delete(`${DOMAIN}/${url}/comments/destroy_answer/${id}`)
}

export const managerUpdateComment = (params) => {
  return axiosService.put(`${DOMAIN}/${url}/comments/${params.id}`, params);
}

export const managerUpdateAnswerComment = (params) => {
  return axiosService.put(`${DOMAIN}/${url}/comments/update_answer/${params.id}`, params);
}

export const managerDeletePost = params => {
  return axiosService.delete(`${DOMAIN}/${url}/posts/${params.id}`);
}

export const managerFetchCommentWarnings = (params = {page: 1, limit: 10}) => {
  let query = '';

  if (Object.keys(params).length > 0) {
    query = `?${qs.stringify(params)}`;
  }
  return axiosService.get(`${DOMAIN}/${url}/warnings${query}`, true);
}

export const managerDeleteWarning = params => {
  let query = '';

  if (Object.keys(params).length > 0) {
    query = `?${qs.stringify(params)}`;
  }
  return axiosService.delete(`${DOMAIN}/${url}/warnings/${params.id}${query}`);
}

export const fetchTechnicalProcessWaitingForApprove = (params) => {
  let query = '';

  if (Object.keys(params).length > 0) {
    query = `?${qs.stringify(params)}`;
  }
  return axiosService.get(`${DOMAIN}/${url}/technical_processes${query}`, true);
}

export const fetchContributePests = (params) => {
  let query = '';

  if (Object.keys(params).length > 0) {
    query = `?${qs.stringify(params)}`;
  }
  return axiosService.get(`${DOMAIN}/${url}/training_contribution_data${query}`, true);
}

export const fetchContributeMarketPrice = (params) => {
  let query = '';

  if (Object.keys(params).length > 0) {
    query = `?${qs.stringify(params)}`;
  }
  return axiosService.get(`${DOMAIN}/${url}/market_prices${query}`, true);
}

export const approveContributeMarketPrice = (params) => {
  const price_id = params.id;
  const status = params.status;
  return axiosService.put(`${DOMAIN}/${url}/market_prices/update_price/${price_id}`, status);
}

export const deleteContributeMarketPrice = (params) => {
  const marketPriceId = params.marketPriceId;
  return axiosService.delete(`${DOMAIN}/${url}/market_prices/destroy_price/${marketPriceId}`);
}

export const approveContributeTechnicalProcess = (params) => {
  const id = params.id;
  const status = params.status;
  return axiosService.put(`${DOMAIN}/${url}/technical_processes/${id}`, status)
}

export const deleteContributeTechnicalProcess = (id) => {
  return axiosService.delete(`${DOMAIN}/${url}/technical_processes/${id}`)
}

export const approveContributePests = (params) => {
  const id = params.id;
  const approve = params.approve;
  return axiosService.put(`${DOMAIN}/${url}/training_contribution_data/${id}`, approve);
}

export const deleteContributePests = (params) => {
  const id = params.id;
  const approve = params.approve;
  return axiosService.put(`${DOMAIN}/${url}/training_contribution_data/${id}`, approve);
}

