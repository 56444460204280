import React, { useLayoutEffect, useState, useContext } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useParams, useLocation } from "react-router-dom";

import AppContext from "components/app_context";
import AddMetaTags from "components/add_meta_tags";
import LeftSidebar from "components/left_sidebar";
import RightSidebar from "components/right_sidebar";
import Catalogue from "./catalogue";
import ListOfStep from "../partials/ListOfStep";
import Breadcumb from "../partials/breadcumb";
import TabSteps from "../partials/TabSteps";

import {
  fetchTechnicalProcess,
  fetchDetailCatalogues,
  fetchCataloguesChildren,
  fetchTechnicalProcessId,
  fetchCatalogueTechnicalProcess,
} from "redux/actions/technical_process";
import { getCataloguesChildren } from "apis/technical_process";

export default function CatalogueDetail() {
  const detailCatalogues = useSelector((state) => state.technicalProcess.detailCatalogues);
  const listCatalogueChildren = useSelector((state) => state.technicalProcess.listCatalogueChildren.data);
  const listTechnicalProcess = useSelector((state) => state.technicalProcess.listTechnicalProcess.data);
  const technicalProcessItem = useSelector((state) => state.technicalProcess.technicalProcessItem.data);

  const firstChildCataId = listCatalogueChildren[0]?.id;
  const dispatch = useDispatch();
  const location = useLocation();
  const catalogName = location.state?.catName || "";
  const { categoryId } = useParams();
  const { current_user } = useContext(AppContext);
  const [sections, setSections] = useState([]);
  const [firstRender, setFirstRender] = useState(true);
  const [activeTabId, setActiveTabId] = useState(null);
  const [activeKeyId, setActiveKeyId] = useState(null);
  const [activeItemId, setActiveItemId] = useState(null);
  const [breadcumb, setBreadcumb] = useState({
    catagory: catalogName ?? detailCatalogues?.data?.name,
    section: "",
    chapter: "",
  });
  const getInitData = (id) => {
    dispatch(fetchDetailCatalogues(categoryId));
    dispatch(fetchCataloguesChildren(id || categoryId));
    dispatch(fetchCatalogueTechnicalProcess({ limit: "", parent_id: "is_parent" }));
  };
  const getDetailTechnicalProcess = async (id) => {
    try {
      const sectionBreadcumb = listCatalogueChildren.filter((item) => item.id === id);
      const response = await getCataloguesChildren(id || firstChildCataId);
      setSections(response.data.data);

      await dispatch(
        fetchTechnicalProcess({
          technical_process_catalogue_id: response.data.data[0]?.id,
        })
      );
      setBreadcumb({
        catagory: catalogName ?? detailCatalogues?.data?.name,
        section: sectionBreadcumb[0]?.name ?? "",
        chapter: response.data.data[0]?.name ?? "",
      });
      setActiveKeyId(response.data.data[0]?.id);
    } catch (error) {}
  };
  const getTechnicalProcessId = () => {
    if (listTechnicalProcess[0]?.id) {
      dispatch(fetchTechnicalProcessId(listTechnicalProcess[0]?.id));
      setActiveItemId(listTechnicalProcess[0]?.id);
    }
  };
  useLayoutEffect(() => {
    getInitData(categoryId);
  }, [categoryId]);

  useLayoutEffect(() => {
    if (firstRender) {
      getDetailTechnicalProcess(firstChildCataId || categoryId);
    }
    else{

      getDetailTechnicalProcess(firstChildCataId || categoryId);
    }
    setActiveTabId(firstChildCataId);
  }, [firstChildCataId]);

  useLayoutEffect(() => {
    getTechnicalProcessId();
    if (firstRender) {
      setBreadcumb({
        catagory: catalogName ?? detailCatalogues?.data?.name,
        section: listCatalogueChildren[0]?.name ?? "",
        chapter: sections[0]?.name ?? "",
      });
    }
  }, [listTechnicalProcess]);

  const changeCatalogue = async (id) => {
    if (id !== firstChildCataId) {
      setFirstRender(false);
    }
    setActiveTabId(id);
    await getDetailTechnicalProcess(id);
  };
  const changeTab = (id) => {
    const chapterBreadcrumb = sections.find((item) => item.id == id);
    setFirstRender(false);
    setActiveKeyId(id);
    dispatch(
      fetchTechnicalProcess({
        technical_process_catalogue_id: id,
      })
    );
    setBreadcumb((prev) => ({
      ...prev,
      chapter: chapterBreadcrumb?.name ?? "",
    }));
  };

  const changeItem = (id) => {
    dispatch(fetchTechnicalProcessId(id));
    setActiveItemId(id);
  };
  return (
    <>
      <AddMetaTags title={"Quy trình kỹ thuật"} />
      <div className="list_traceability">
        <div className="row">
          <LeftSidebar />
          <div className="col-md-6 col-sm-12 mkt-price">
            <div className="cont-center">
              <div className="cate-lst mb-4">
                <h6 className="mb-0 d-flex align-items-center">
                  <Breadcumb breadcumb={breadcumb} />
                  {current_user && (
                    <Link to={`/dong-gop-quy-trinh-ky-thuat`} className="btn btn-sm contribute ms-auto">
                      <img src="/images/donggop_icon.png" alt="image" title="Đóng Góp" />
                      <span className=" ml-1 txt">Đóng góp</span>
                    </Link>
                  )}
                </h6>
              </div>
            </div>
            <ListOfStep data={detailCatalogues} changeCatalogue={changeCatalogue} activeId={activeTabId} />
            <div className="saved-items" id="inf-details">
              <TabSteps
                data={sections}
                detail={listTechnicalProcess}
                contentDetail={technicalProcessItem}
                activeKey={activeKeyId}
                changeTab={changeTab}
                activeItemId={activeItemId}
                changeItem={changeItem}
                current_user={current_user}
              />
            </div>
            <h5 className="title-line-lt text-uppercase fw-bold mb-2 mt-4">Quy trình kỹ thuật khác</h5>
            <Catalogue currentCatalogId={categoryId} />
          </div>
          <RightSidebar />
        </div>
      </div>
    </>
  );
}
