
import {followUser, unFollow} from "actions/auth";
import { useDispatch } from "react-redux";

export const FollowUser = ({isOwner, post}) => {
  const dispatch = useDispatch();
  const handleFollowUser = (shop_id) => {
    dispatch(followUser({
      classable_type: "Shop",
      classable_id: shop_id,
      post_id: post.id,
    }))
  }

  const handleUnFollowUser = (shop_id) => {
    dispatch(
      unFollow({
        classable_type: "Shop",
        classable_id: shop_id,
        post_id: post.id,
      })
    );
  }

  const renderFollowUser = () => {
    switch (isOwner) {
      case true: {
        return;
      }
      case false: {
        return post?.user_followed_shop ? (
          <a className="follow pointer" onClick={() => handleUnFollowUser(post?.shop_id)}>
            <div title="Đang theo dõi">
              <img src="/images/not-follow.png" style={{ width: "20px" }} />
            </div>
          </a>
        ) : (
          <a className="not-follow pointer" onClick={() => handleFollowUser(post?.shop_id)}>
            <div title="Theo dõi">
              <img src="/images/follow.png" style={{ width: "20px" }} />
            </div>
          </a>
        );
      }
      default: {
      }
    }
  };

  return (
    <>
      {renderFollowUser()}
    </>
  )
}
export default FollowUser;