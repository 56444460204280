import * as cartTypes from 'constants/cart';

let initState = {
  carts: {
    data: [],
    error: null,
  },
  numberCart: 0,
  totalPrice: 0,
}
if(!localStorage.getItem('carts')) {
  localStorage.setItem('carts', JSON.stringify([]))
}else {
  let listProductInCarts = JSON.parse(localStorage.getItem('carts'));
  initState.carts.data = listProductInCarts;
  initState.numberCart = listProductInCarts.length;
  initState.totalPrice = listProductInCarts.reduce((sum, product) => {
    sum += product.price * product.quantity;
    return sum;
  }, 0);
}
const reducer = (state = initState, action) => {
  switch (action.type){
    case cartTypes.ADD_TO_CART: {
      return {
        ...state,
      }
    }
    case cartTypes.ADD_TO_CART_SUCCESS: {
      const { data } = action.payload;
      localStorage.setItem('carts', JSON.stringify(data))
      return {
        ...state,
        carts: {
          data: data,
          error: null,
        }
      }
    }
    case cartTypes.UPDATE_NUMBER_PRODUCT_IN_CART: {
      return {
        ...state,
      }
    }
    case cartTypes.UPDATE_NUMBER_PRODUCT_IN_CART_SUCCESS: {
      const { data } = action.payload;
      return {
        ...state,
        numberCart: data
      }
    }
    case cartTypes.CALCULATE_TOTAL_PRICE_IN_CART: {
      return {
        ...state,
      }
    }
    case cartTypes.CALCULATE_TOTAL_PRICE_IN_CART_SUCCESS: {
      const {data} = action.payload;
      return {
        ...state,
        totalPrice: data
      }
    }
    case cartTypes.REMOVE_PRODUCT_FROM_CART: {
      return {
        ...state
      }
    }

    case cartTypes.REMOVE_PRODUCT_FROM_CART_SUCCESS: {
      const {data} = action.payload;
      localStorage.setItem('carts', JSON.stringify(data))
      return {
        ...state,
        carts: {
          data: data
        }
      }
    }

    default:
      return {
        ...state
      }
  }
}

export default reducer;