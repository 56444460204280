import React from "react";

const SearchInvoiceBox = (props) => {
  return (
    <div className="mkt-price">
      <div className=" search-box ">
        <form>
          <div className="row">
            <div className="col-md-12 col-sm-10 col-xs-12 d-flex  invoice-search-box">
              <div className="col-md-4 text-center ">
                <button
                  type="button"
                  className={`btn status-box fw-semibold ${props.statusBox === "pending" && "bg-warning text-white"}`}
                  onClick={() => props.filter("pending")}
                >
                  Đang chờ
                </button>
              </div>
              <div className="col-md-4 text-center">
                <button
                  type="button"
                  className={`btn status-box fw-semibold ${props.statusBox === "done" && "bg-success text-white "}`}
                  onClick={() => props.filter("done")}
                >
                  Hoàn tất
                </button>
              </div>
              <div className="col-md-4 text-center">
                <button
                  type="button"
                  className={`btn status-box fw-semibold ${props.statusBox === "cancelled" && "bg-danger text-white"}`}
                  onClick={() => props.filter("cancelled")}
                >
                  Đã hủy
                </button>
              </div>
            </div>
          </div>
        </form>
      </div>
    </div>
  );
};

export default SearchInvoiceBox;
