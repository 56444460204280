import React, { useEffect, useRef } from "react";
import {searchHome, updateStatusSearch, resetData} from 'actions/home';
import { useDispatch, useSelector } from "react-redux";
import SearchResult from "../SearchResult";
import { useLocation } from "react-router-dom";

function BoxSearchHomePage({isLoadingNav}) {
  const location = useLocation();
  const pathname = location.pathname;

  const dispatch = useDispatch();
  const boxSearchRef = useRef();
  const resultRef =  useRef();
  let status = useSelector((state) => state.home.search_result.extra.status);
  let data = useSelector((state) => state.home.search_result.data);

  const handleClickInside = () => {
    if(data.length > 0 ) dispatch(updateStatusSearch('show'));
  };

  const handleClickOutside = e => {
    if (!boxSearchRef.current.contains(e.target) && !resultRef?.current?.contains(e.target)) {
      dispatch(updateStatusSearch('close'));
    }
  };

  useEffect(() => {
      document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    }
  });

  useEffect(() => {
    return () => {
      dispatch(updateStatusSearch('close'));
      dispatch(resetData());
      boxSearchRef.current.value = "";
    }
  }, [pathname]);

  const handleSearch = (keyword) => {
    if(keyword.length > 0) {
      dispatch(searchHome(keyword));
    }else{
      dispatch(resetData());
    }
  }

  return (
    <React.Fragment>
      <div className="search-top">
        <input
          ref={boxSearchRef}
          className="form-control me-2 search-input"
          type="search"
          placeholder=""
          aria-label="Search"
          onChange={(evt) => handleSearch(evt.target.value)}
          disabled={isLoadingNav}
          onFocus={handleClickInside}
        />
        <span className="icon-search" />
      </div>
      {status === 'show' && <SearchResult ref={resultRef} />}
    </React.Fragment>
  );
}

export default BoxSearchHomePage;
