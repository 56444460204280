import * as cartTypes from "constants/cart";

const addProductToCart = (params) => {
  return {
    type: cartTypes.ADD_TO_CART,
    payload: { params },
  };
};

const addProductToCartSuccess = (data) => {
  return {
    type: cartTypes.ADD_TO_CART_SUCCESS,
    payload: { data },
  };
};

const addProductToCartFailed = (error) => {
  return {
    type: cartTypes.ADD_TO_CART_FAILED,
    payload: { error },
  };
};

const updateNumberProductInCart = (params) => {
  return {
    type: cartTypes.UPDATE_NUMBER_PRODUCT_IN_CART,
    payload: { params },
  };
};

const updateNumberProductInCartSuccess = (data) => {
  return {
    type: cartTypes.UPDATE_NUMBER_PRODUCT_IN_CART_SUCCESS,
    payload: { data },
  };
};

const calculateTotalPriceInCart = (params) => {
  return {
    type: cartTypes.CALCULATE_TOTAL_PRICE_IN_CART,
    payload: { params },
  };
};
const calculateTotalPriceInCartSuccess = (data) => {
  return {
    type: cartTypes.CALCULATE_TOTAL_PRICE_IN_CART_SUCCESS,
    payload: { data },
  };
};

const removeProductFromCart = (params) => {
  return {
    type: cartTypes.REMOVE_PRODUCT_FROM_CART,
    payload: { params },
  }
}

const removeProductFromCartSuccess = (data) => {
  return {
    type: cartTypes.REMOVE_PRODUCT_FROM_CART_SUCCESS,
    payload: { data },
  }
}

export {
  addProductToCart,
  addProductToCartSuccess,
  addProductToCartFailed,
  updateNumberProductInCart,
  updateNumberProductInCartSuccess,
  calculateTotalPriceInCart,
  calculateTotalPriceInCartSuccess,
  removeProductFromCart,
  removeProductFromCartSuccess
};
