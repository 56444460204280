import * as callConst from 'constants/call';

export const createCall= params => {
  return {
    type: callConst.CREATE_CALL,
    payload: {
      params,
    },
  };
};

export const createCallSuccess = data => {
  return {
    type: callConst.CREATE_CALL_SUCCESS,
    payload: {
      data,
    },
  };
};

export const createCallFailed = error => {
  return {
    type: callConst.CREATE_CALL_FAILED,
    payload: {
      error,
    },
  };
};