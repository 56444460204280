import * as technicalProcessConst from "constants/technical_process";
import { checkLoadMore } from "lib";
const initialState = {
  listTechnicalProcess: {
    data: [],
    error: null,
    isLoading: false,
    isLoadMore: false,
  },
  technicalProcessItem: {
    data: [],
    error: null,
    isLoading: false,
  },
  listTechnicalProcessComment: {
    data: [],
    error: null,
    isLoading: false,
    isLoadMore: false,
  },
  deleteChildComment: {
    data: null,
    error: null,
    isLoading: false,
  },
  listTechnicalProcessChildComment: {
    data: [],
    error: null,
    isLoading: false,
    isLoadMore: false,
  },
  listCatalogueTechnicalProcess: {
    data: [],
    isLoading: false,
    error: null,
  },
  listCatalogueChildren: {
    data: [],
    isLoading: false,
    error: null,
  },
  detailCatalogues: {
    data: null,
    isLoading: false,
    error: null,
    children: [],
  },
};
const reducer = (state = initialState, action) => {
  switch (action.type) {
    case technicalProcessConst.UPDATE_CHILD_COMMENT_TECHNICAL_PROCESS_SUCCESS: {
      const { data } = action.payload;

      return {
        ...state,
        listTechnicalProcessChildComment: {
          data: state.listTechnicalProcessChildComment.data.map(function (answer) {
            if (answer.id == data.data.classable_id) {
              answer.content = data.data.content;
            }
            return answer;
          }),
          isLoading: false,
          error: null,
        },
      };
    }
    case technicalProcessConst.MANAGER_DELETE_CHILD_COMMENT_SUCCESS: {
      const { id } = action.payload;

      return {
        ...state,
        listTechnicalProcessChildComment: {
          data: state.listTechnicalProcessChildComment.data.filter((answer) => answer.id != id),
          isLoading: false,
          error: null,
        },
      };
    }
    case technicalProcessConst.DELETE_CHILD_COMMENT_TECHNICAL_PROCESS_SUCCESS: {
      const { id } = action.payload;

      return {
        ...state,
        listTechnicalProcessChildComment: {
          data: state.listTechnicalProcessChildComment.data.filter((answer) => answer.id != id),
          isLoading: false,
          error: null,
        },
      };
    }
    case technicalProcessConst.FETCH_TECHNICAL_PROCESS: {
      return {
        ...state,
        listTechnicalProcess: {
          data: initialState.listTechnicalProcess.data,
          isLoading: true,
          error: null,
          isLoadMore: true,
        },
      };
    }

    case technicalProcessConst.FETCH_TECHNICAL_PROCESS_SUCCESS: {
      const { data } = action.payload;
      return {
        ...state,
        listTechnicalProcess: {
          data: data.data,
          isLoading: false,
          error: null,
          isLoadMore: checkLoadMore(data),
        },
      };
    }

    case technicalProcessConst.FETCH_TECHNICAL_PROCESS_FAILED: {
      const { error } = action.payload;
      return {
        ...state,
        listTechnicalProcess: {
          data: initialState.listTechnicalProcess.data,
          isLoading: false,
          error: error,
          isLoadMore: false,
        },
      };
    }

    case technicalProcessConst.FETCH_TECHNICAL_PROCESS_ID: {
      return {
        ...state,
        technicalProcessItem: {
          data: initialState.technicalProcessItem.data,
          isLoading: true,
          error: null,
        },
      };
    }

    case technicalProcessConst.FETCH_TECHNICAL_PROCESS_ID_SUCCESS: {
      const { data } = action.payload;
      return {
        ...state,
        technicalProcessItem: {
          data: data.data,
          isLoading: false,
          error: null,
        },
      };
    }

    case technicalProcessConst.FETCH_TECHNICAL_PROCESS_ID_FAILED: {
      const { error } = action.payload;

      return {
        ...state,
        technicalProcessItem: {
          data: initialState.technicalProcessItem.data,
          isLoading: false,
          error: error,
        },
      };
    }

    case technicalProcessConst.FETCH_LOAD_MORE_TECHNICAL_PROCESS: {
      return {
        ...state,
        listTechnicalProcess: {
          data: state.listTechnicalProcess.data,
          isLoading: true,
          isLoadMore: false,
          error: null,
        },
      };
    }

    case technicalProcessConst.FETCH_LOAD_MORE_TECHNICAL_PROCESS_SUCCESS: {
      const { data } = action.payload.data;
      const isLoadMore = data.length > 0;
      return {
        ...state,
        listTechnicalProcess: {
          data: [...state.listTechnicalProcess.data, ...data],
          isLoading: false,
          isLoadMore: isLoadMore,
          error: null,
        },
      };
    }

    case technicalProcessConst.FETCH_LOAD_MORE_TECHNICAL_PROCESS_FAILED: {
      const { error } = action.payload;

      return {
        ...state,
        listTechnicalProcess: {
          data: initialState.listTechnicalProcess.data,
          isLoading: false,
          isLoadMore: false,
          error: error,
        },
      };
    }

    case technicalProcessConst.CREATE_COMMENT_TECHNICAL_PROCESS: {
      return {
        ...state,
        listTechnicalProcessComment: {
          data: state.listTechnicalProcessComment.data,
          isLoading: true,
          isLoadMore: false,
          error: null,
        },
      };
    }

    case technicalProcessConst.CREATE_COMMENT_TECHNICAL_PROCESS_SUCCESS: {
      const { data } = action.payload;
      return {
        ...state,
        listTechnicalProcessComment: {
          data: [data.data, ...state.listTechnicalProcessComment.data],
          isLoadMore: false,
          error: null,
          isLoading: false,
        },
      };
    }

    case technicalProcessConst.CREATE_COMMENT_TECHNICAL_PROCESS_FAILED: {
      const { error } = action.payload;
      return {
        ...state,
        listTechnicalProcessComment: {
          data: initialState.listTechnicalProcessComment.data,
          isLoading: false,
          isLoadMore: false,
          error: error,
        },
      };
    }

    case technicalProcessConst.FETCH_COMMENT_TECHNICAL_PROCESS: {
      return {
        ...state,
        listTechnicalProcessComment: {
          data: state.listTechnicalProcessComment.data,
          isLoading: true,
          isLoadMore: false,
          error: null,
        },
      };
    }

    case technicalProcessConst.FETCH_COMMENT_TECHNICAL_PROCESS_SUCCESS: {
      const { data } = action.payload;

      return {
        ...state,
        listTechnicalProcessComment: {
          data: data.data,
          isLoadMore: false,
          error: null,
          isLoading: false,
        },
      };
    }

    case technicalProcessConst.FETCH_COMMENT_TECHNICAL_PROCESS_FAILED: {
      const { error } = action.payload;
      return {
        ...state,
        listTechnicalProcessComment: {
          data: initialState.listTechnicalProcessComment.data,
          isLoading: false,
          isLoadMore: false,
          error: error,
        },
      };
    }

    case technicalProcessConst.LIKE_COMMENT_TECHNICAL_PROCESS_SUCCESS: {
      const { data } = action.payload;

      if (data.data.likeable_type !== "SubComment") {
        return {
          ...state,
          listTechnicalProcessComment: {
            data: state.listTechnicalProcessComment.data.map((item) => {
              if (data.data.likeable_id == item.id) {
                item.user_liked = true;
                item.total_likes = item.total_likes + 1;
              }
              return item;
            }),
            isLoadMore: false,
            error: null,
            isLoading: false,
          },
        };
      } else {
        return {
          ...state,
          listTechnicalProcessChildComment: {
            data: state.listTechnicalProcessChildComment.data.map((item) => {
              if (data.data.likeable_id == item.id) {
                item.user_liked = true;
                item.total_likes = item.total_likes + 1;
              }
              return item;
            }),
            isLoading: false,
            isLoadMore: false,
            error: null,
          },
        };
      }
    }

    case technicalProcessConst.LIKE_COMMENT_TECHNICAL_PROCESS_FAILED: {
      const { error } = action.payload;
      return {
        ...state,
        listTechnicalProcessComment: {
          data: initialState.listTechnicalProcessComment.data,
          isLoading: false,
          isLoadMore: false,
          error: error,
        },
      };
    }

    case technicalProcessConst.DISLIKE_COMMENT_TECHNICAL_PROCESS_SUCCESS: {
      const { data } = action.payload;
      if (data.likeable_type !== "SubComment") {
        return {
          ...state,
          listTechnicalProcessComment: {
            data: state.listTechnicalProcessComment.data.map((item) => {
              if (item.id == data.classable_id) {
                item.user_liked = false;
                item.total_likes = item.total_likes - 1;
              }
              return item;
            }),
            isLoadMore: false,
            error: null,
            isLoading: false,
          },
        };
      } else {
        return {
          ...state,
          listTechnicalProcessChildComment: {
            data: state.listTechnicalProcessChildComment.data.map((item) => {
              if (data.classable_id == item.id) {
                item.user_liked = false;
                item.total_likes = item.total_likes - 1;
              }
              return item;
            }),
            isLoading: false,
            isLoadMore: false,
            error: null,
          },
        };
      }
    }

    case technicalProcessConst.DISLIKE_COMMENT_TECHNICAL_PROCESS_FAILED: {
      const { error } = action.payload;
      return {
        ...state,
        listTechnicalProcessComment: {
          data: initialState.listTechnicalProcessComment.data,
          isLoading: false,
          isLoadMore: false,
          error: error,
        },
      };
    }

    case technicalProcessConst.MANAGER_DELETE_COMMENT_TECHNICAL_PROCESS_SUCCESS: {
      const { id } = action.payload;
      return {
        ...state,
        listTechnicalProcessComment: {
          data: state.listTechnicalProcessComment.data.filter((comment) => comment.id != id),
          isLoadMore: false,
          error: null,
          isLoading: false,
        },
      };
    }
    case technicalProcessConst.DELETE_COMMENT_TECHNICAL_PROCESS_SUCCESS: {
      const { id } = action.payload;
      return {
        ...state,
        listTechnicalProcessComment: {
          data: state.listTechnicalProcessComment.data.filter((comment) => comment.id != id),
          isLoadMore: false,
          error: null,
          isLoading: false,
        },
      };
    }
    case technicalProcessConst.REPLY_COMMENT_TECHNICAL_PROCESS_SUCCESS: {
      const { data } = action.payload;
      return {
        ...state,
        listTechnicalProcessComment: {
          data: state.listTechnicalProcessComment.data.map((comment) => {
            if (comment.id === data.data.comment_id) {
              comment.total_answers = comment.total_answers + 1;
            }
            return comment;
          }),
          isLoadMore: false,
          error: null,
          isLoading: false,
        },
        listTechnicalProcessChildComment: {
          data: [data.data, ...state.listTechnicalProcessChildComment.data],
          isLoading: false,
          isLoadMore: false,
          error: null,
        },
      };
    }

    case technicalProcessConst.REPLY_CHILD_COMMENT_TECHNICAL_PROCESS_SUCCESS: {
      const { data } = action.payload;
      return {
        ...state,
        listTechnicalProcessComment: {
          data: state.listTechnicalProcessComment.data.map((comment) => {
            if (comment.id === data.data.comment_id) {
              comment.total_answers = comment.total_answers + 1;
            }
            return comment;
          }),
          isLoadMore: false,
          error: null,
          isLoading: false,
        },
        listTechnicalProcessChildComment: {
          data: [data.data, ...state.listTechnicalProcessChildComment.data],
          isLoading: false,
          isLoadMore: false,
          error: null,
        },
      };
    }

    case technicalProcessConst.UPDATE_COMMENT_TECHNICAL_PROCESS_SUCCESS: {
      const { data } = action.payload;
      return {
        ...state,
        listTechnicalProcessComment: {
          data: state.listTechnicalProcessComment.data.map((comment) => {
            if (comment.id == data.data.classable_id) {
              comment.content = data.data.content;
            }
            return comment;
          }),
          isLoadMore: false,
          error: null,
          isLoading: false,
        },
      };
    }

    case technicalProcessConst.MANAGER_UPDATE_COMMENT_TECHNICAL_PROCESS_SUCCESS: {
      const { data } = action.payload;

      return {
        ...state,
        listTechnicalProcessComment: {
          data: state.listTechnicalProcessComment.data.map((comment) => {
            if (comment.id == data.data.classable_id) {
              comment.content = data.data.content;
            }
            return comment;
          }),
          isLoadMore: false,
          error: null,
          isLoading: false,
        },
      };
    }
    case technicalProcessConst.FETCH_CHILD_COMMENT_TECHNICAL_PROCESS: {
      return {
        ...state,
        listTechnicalProcessChildComment: {
          data: state.listTechnicalProcessChildComment.data,
          isLoading: true,
          isLoadMore: false,
          error: null,
        },
      };
    }

    case technicalProcessConst.FETCH_CHILD_COMMENT_TECHNICAL_PROCESS_SUCCESS: {
      const { data } = action.payload;

      return {
        ...state,
        listTechnicalProcessChildComment: {
          data: data.data,
          isLoadMore: false,
          error: null,
          isLoading: false,
        },
      };
    }

    case technicalProcessConst.FETCH_CHILD_COMMENT_TECHNICAL_PROCESS_FAILED: {
      const { error } = action.payload;
      return {
        ...state,
        listTechnicalProcessChildComment: {
          data: initialState.listTechnicalProcessChildComment.data,
          isLoading: false,
          isLoadMore: false,
          error: error,
        },
      };
    }
    case technicalProcessConst.FETCH_CATALOGUE_TECHNICAL_PROCESS: {
      return {
        ...state,
        listCatalogueTechnicalProcess: {
          data: initialState.listCatalogueTechnicalProcess.data,
          isLoading: true,
          error: null,
        },
      };
    }

    case technicalProcessConst.FETCH_CATALOGUE_TECHNICAL_PROCESS_SUCCESS: {
      const { data } = action.payload;
      return {
        ...state,
        listCatalogueTechnicalProcess: {
          data: data.data,
          isLoading: false,
          error: null,
        },
      };
    }
    case technicalProcessConst.FETCH_CATALOGUES_CHILDREN_TECHNICAL_PROCESS: {
      return {
        ...state,
        listCatalogueChildren: {
          data: initialState.listCatalogueChildren.data,
          isLoading: true,
          error: null,
        },
      };
    }
    case technicalProcessConst.FETCH_CATALOGUES_CHILDREN_TECHNICAL_PROCESS_SUCCESS: {
      const { data } = action.payload;
      return {
        ...state,
        listCatalogueChildren: {
          data: data.data,
          isLoading: false,
          error: null,
        },
      };
    }
    case technicalProcessConst.FETCH_DETAIL_CATALOGUES: {
      return {
        ...state,
        detailCatalogues: {
          data: initialState.detailCatalogues.data,
          isLoading: true,
          error: null,
          children: initialState.detailCatalogues.children,
        },
      };
    }
    case technicalProcessConst.FETCH_DETAIL_CATALOGUES_SUCCESS: {
      const { data } = action.payload;
      const { detail, children } = data;
      return {
        ...state,
        detailCatalogues: {
          data: detail.data,
          isLoading: false,
          error: null,
          children: children.data,
        },
      };
    }
    case technicalProcessConst.RESET_DATA_CHILDREN_TECHNICAL_PROCESS_SUCCESS: {
      return {
        ...state,
        listCatalogueChildren: {
          data: [],
          isLoading: false,
          error: null,
        },
      };
    }
    default:
      return state;
  }
};
export default reducer;
