export const FETCH_POST = "FETCH_POST";
export const FETCH_POST_SUCCESS = "FETCH_POST_SUCCESS";
export const FETCH_POST_FAILED = "FETCH_POST_FAILED";

export const LIKE_POST = "LIKE_POST";
export const LIKE_POST_SUCCESS = "LIKE_POST_SUCCESS";
export const LIKE_POST_FAILED = "LIKE_POST_FAILED";

export const LIKE_POST_DETAIL_PAGE = "LIKE_POST_DETAIL_PAGE";
export const LIKE_POST_DETAIL_PAGE_SUCCESS = "LIKE_POST_DETAIL_PAGE_SUCCESS";
export const LIKE_POST_DETAIL_PAGE_FAILED = "LIKE_POST_DETAIL_PAGE_FAILED";

export const LIKE_COMMENT = "LIKE_COMMENT";
export const LIKE_COMMENT_SUCCESS = "LIKE_COMMENT_SUCCESS";
export const LIKE_COMMENT_FAILED = "LIKE_COMMENT_FAILED";

export const UNLIKE_POST = "UNLIKE_POST";
export const UNLIKE_POST_SUCCESS = "UNLIKE_POST_SUCCESS";
export const UNLIKE_POST_FAILED = "UNLIKE_POST_FAILED";

export const UNLIKE_POST_DETAIL_PAGE = "UNLIKE_POST_DETAIL_PAGE";
export const UNLIKE_POST_DETAIL_PAGE_SUCCESS = "UNLIKE_POST_DETAIL_PAGE_SUCCESS";
export const UNLIKE_POST_DETAIL_PAGE_FAILED = "UNLIKE_POST_DETAIL_PAGE_FAILED";

export const UNLIKE_COMMENT = "UNLIKE_COMMENT";
export const UNLIKE_COMMENT_SUCCESS = "UNLIKE_COMMENT_SUCCESS";
export const UNLIKE_COMMENT_FAILED = "UNLIKE_COMMENT_FAILED";

export const ADD_POST = "ADD_POST";
export const ADD_POST_SUCCESS = "ADD_POST_SUCCESS";
export const ADD_POST_FAILED = "ADD_POST_FAILED";

export const LOAD_MORE_POST = "LOAD_MORE_POST";
export const LOAD_MORE_POST_SUCCESS = "LOAD_MORE_POST_SUCCESS";
export const LOAD_MORE_POST_FAILED = "LOAD_MORE_POST_FAILED";

export const FETCH_HIGHLIGHT_POST = "FETCH_HIGHLIGHT_POST";
export const FETCH_HIGHLIGHT_POST_SUCCESS = "FETCH_HIGHLIGHT_POST_SUCCESS";
export const FETCH_HIGHLIGHT_POST_FAILED = "FETCH_HIGHLIGHT_POST_FAILED";

export const FETCH_USER_POST = "FETCH_USER_POST";
export const FETCH_USER_POST_SUCCESS = "FETCH_USER_POST_SUCCESS";
export const FETCH_USER_POST_FAILED = "FETCH_USER_POST_FAILED";

export const FETCH_POST_CATALOGUE = "FETCH_POST_CATALOGUE";
export const FETCH_POST_CATALOGUE_SUCCESS = "FETCH_POST_CATALOGUE_SUCCESS";
export const FETCH_POST_CATALOGUE_FAILED = "FETCH_POST_CATALOGUE_FAILED";

export const FETCH_FAVORITES_POST = "FETCH_FAVORITES_POST";
export const FETCH_FAVORITES_POST_SUCCESS = "FETCH_FAVORITES_POST_SUCCESS";
export const FETCH_FAVORITES_POST_FAILED = "FETCH_FAVORITES_POST_FAILED";

export const DELETE_POST = "DELETE_POST";
export const DELETE_POST_SUCCESS = "DELETE_POST_SUCCESS";
export const DELETE_POST_FAILED = "DELETE_POST_FAILED";

export const CREATE_COMMENT_POST = "CREATE_COMMENT_POST";
export const CREATE_COMMENT_POST_SUCCESS = "CREATE_COMMENT_POST_SUCCESS";
export const CREATE_COMMENT_POST_FAILED = "CREATE_COMMENT_POST_FAILED";

export const DELETE_COMMENT_POST = "DELETE_COMMENT_POST";
export const DELETE_COMMENT_POST_SUCCESS = "DELETE_COMMENT_POST_SUCCESS";
export const DELETE_COMMENT_POST_FAILED = "DELETE_COMMENT_POST_FAILED";

export const FETCH_COMMENT_POST = "FETCH_COMMENT_POST";
export const FETCH_COMMENT_POST_SUCCESS = "FETCH_COMMENT_POST_SUCCESS";
export const FETCH_COMMENT_POST_FAILED = "FETCH_COMMENT_POST_FAILED";

export const FETCH_COMMENT_ANSWERS = "FETCH_COMMENT_ANSWERS";
export const FETCH_COMMENT_ANSWERS_SUCCESS = "FETCH_COMMENT_ANSWERS_SUCCESS";
export const FETCH_COMMENT_ANSWERS_FAILED = "FETCH_COMMENT_ANSWERS_FAILED";

export const REPLY_COMMENT_POST = "REPLY_COMMENT_POST";
export const REPLY_COMMENT_POST_SUCCESS = "REPLY_COMMENT_POST_SUCCESS";
export const REPLY_COMMENT_POST_FAILED = "REPLY_COMMENT_POST_FAILED";

export const SHARE_POST = "SHARE_POST";
export const SHARE_POST_SUCCESS = "SHARE_POST_SUCCESS";
export const SHARE_POST_FAILED = "SHARE_POST_FAILED";

export const REPORT_POST = "REPORT_POST";
export const REPORT_POST_SUCCESS = "REPORT_POST_SUCCESS";
export const REPORT_POST_FAILED = "REPORT_POST_FAILED";

export const REPLACE_POST_ID = "REPLACE_POST_ID";
export const REPLACE_POST_ID_SUCCESS = "REPLACE_POST_ID_SUCCESS";
export const REPLACE_POST_ID_FAILED = "REPLACE_POST_ID_FAILED";

export const REPORT_COMMENT = "REPORT_COMMENT";
export const REPORT_COMMENT_SUCCESS = "REPORT_COMMENT_SUCCESS";
export const REPORT_COMMENT_FAILED = "REPORT_COMMENT_FAILED";

export const UPDATE_COMMENT = "UPDATE_COMMENT";
export const UPDATE_COMMENT_SUCCESS = "UPDATE_COMMENT_SUCCESS";
export const UPDATE_COMMENT_FAILED = "UPDATE_COMMENT_FAILED";

export const UPDATE_ANSWER_COMMENT = "UPDATE_ANSWER_COMMENT";
export const UPDATE_ANSWER_COMMENT_SUCCESS = "UPDATE_ANSWER_COMMENT_SUCCESS";
export const UPDATE_ANSWER_COMMENT_FAILED = "UPDATE_ANSWER_COMMENT_FAILED";

export const LOAD_MORE_COMMENT_POST = "LOAD_MORE_COMMENT_POST";
export const LOAD_MORE_COMMENT_POST_SUCCESS = "LOAD_MORE_COMMENT_POST_SUCCESS";
export const LOAD_MORE_COMMENT_POST_FAILED = "LOAD_MORE_COMMENT_POST_FAILED";

export const USER_SHARE_ARTICLE = "USER_SHARE_ARTICLE";
export const USER_SHARE_ARTICLE_SUCCESS = "USER_SHARE_ARTICLE_SUCCESS";
export const USER_SHARE_ARTICLE_FAILED = "USER_SHARE_ARTICLE_FAILED";

export const FETCH_DETAIL_POST = "FETCH_DETAIL_POST";
export const FETCH_DETAIL_POST_SUCCESS = "FETCH_DETAIL_POST_SUCCESS";
export const FETCH_DETAIL_POST_FAILED = "FETCH_DETAIL_POST_FAILED";

export const FETCH_PICK_TOP = "FETCH_PICK_TOP";
export const FETCH_PICK_TOP_SUCCESS = "FETCH_PICK_TOP_SUCCESS";
export const FETCH_PICK_TOP_FAILED = "FETCH_PICK_TOP_FAILED";

export const REPORT_IMAGE_POST = "REPORT_IMAGE_POST";
export const REPORT_IMAGE_POST_SUCCESS = "REPORT_IMAGE_POST_SUCCESS";
export const REPORT_IMAGE_POST_FAILED = "REPORT_IMAGE_POST_FAILED";

export const FAST_FOLLOW_USER_IN_POST = "FAST_FOLLOW_USER_IN_POST";
export const FAST_UNFOLLOW_USER_IN_POST = "FAST_UNFOLLOW_USER_IN_POST";

export const MODIFY_DATA_DELETE = "MODIFY_DATA_DELETE";
export const MODIFY_DATA_DELETE_SUCCESS = "MODIFY_DATA_DELETE_SUCCESS";

export const MODIFY_DATA_SHOW_COMMENT = "MODIFY_DATA_SHOW_COMMENT";
export const RESET_DATA = "RESET_DATA";

export const FETCH_ANSWER_COMMENT = "FETCH_ANSWER_COMMENT";
export const FETCH_ANSWER_COMMENT_SUCCESS = "FETCH_ANSWER_COMMENT_SUCCESS";
