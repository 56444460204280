import React, { useEffect, useState } from "react";
import { DOMAIN } from "constants";
import _ from "lodash";
import YouTube from "react-youtube";
import { youtubeGetID, convertString, validURL } from "lib";
import FbImageLibrary from "lib/react-fb-image-grid";
import MetaPost from "components/meta_post";
import * as linkify from "linkifyjs";
import parse, { domToReact } from "html-react-parser";
import { getPostId } from "apis/post";
import { Link } from "react-router-dom";

function ShowSharePost(props) {
  let { shared_post_id, options, opts, onReady, post: postShare, navigate, listImage } = props;
  let [post, setPost] = useState(null);
  let [showTitle, setShowTitle] = useState(false);
  useEffect(() => {
    let isSubscribed = true;
    getPostId(shared_post_id).then((res) => {
      let result = res["data"]["data"];
      if (result && isSubscribed) {
        setPost(result);
      }
    });
  }, []);
  let shared_link_status = post?.shared_link_status;
  const renderPost = () => {
    let youtubeID = youtubeGetID(post.title);
    let images = _.map(post.images, "name").map((n) => `${validURL(n) ? n : DOMAIN + n}`);
    let shop_image = validURL(post.shop_image)
      ? `${post.shop_image}`
      : post.shop_image !== null
      ? `${DOMAIN}${post.shop_image}`
      : "/images/avatar.png";
    let class_like = post.user_liked === true ? "like" : "unlike";
    let links =
      post.title !== null
        ? _.uniqBy(
            _.map(linkify.find(post.title, "url"), (item) =>
              _.extend(
                {
                  domain: item.value.match(/^(?:https?:\/\/)?(?:[^@\/\n]+@)?(?:www\.)?([^:\/?\n]+)/)[1],
                },
                item
              )
            ),
            "domain"
          )
        : [];

    let title = `<div className="title"> ${post.title !== null ? convertString(post.title) : ""} </div>`;
    const viewAllTitle = (title) => {
      setShowTitle(true);
    };

    const replace_tag = (children) => {
      return children.replace("<a href='https://more'>More</a>", "");
    };
    return (
      <div>
        <div className="media" style={{ display: "block" }}>
          {youtubeID != null && (
            <YouTube containerClassName={"video-youtube"} videoId={youtubeID} opts={opts} onReady={onReady} />
          )}
          <div key={post.id} className="post_images">
            <FbImageLibrary
              listImage={post?.images}
              postId={postShare}
              navigate={navigate}
              post={post}
              images={images}
              countFrom={3}
              renderOverlay={() => <div className="view-image">Xem full hình</div>}
              overlayBackgroundColor={"#0005"}
            />
          </div>
          {youtubeID === null && links.length > 0 && (
            <MetaPost links={links} shared_link_status={shared_link_status} postId={post.id} />
          )}
        </div>
        <div
          className="cont-desc"
          style={{
            marginTop: "10px",
          }}
        >
          <Link to={`/nguoi-dung/${post?.user_id}`}>
            <div className="author-frm">
              <img src={shop_image} alt="comment images" />
              <div className="user-name">{post.user_name}</div>
            </div>
          </Link>
          {post.short_title == "" ? (
            parse(title, options)
          ) : (
            <div className="cont">
              {showTitle ? parse(post?.title.replace(/\n/g, "<br />"), options) : parse(replace_tag(post.short_title))}{" "}
              {showTitle ? (
                ""
              ) : (
                <a role="button" className="btn-more fw-500" onClick={() => viewAllTitle(post?.title)}>
                  <u>More</u>
                </a>
              )}{" "}
            </div>
          )}
        </div>
      </div>
    );
  };

  return <div className="sharing-post p-2">{post && renderPost()}</div>;
}

export default ShowSharePost;
