import React, { useEffect, useState, useContext, useRef } from "react";
import { getArticles } from "apis/article";
import { DOMAIN } from "constants";
import { Link } from "react-router-dom";
import _ from "lodash";
import AppContext from "components/app_context";
import { validURL } from "lib";
import moment from "moment";
import { useTranslation } from "react-i18next";
function VideoOfCatalogues(props) {
  let { id } = props;
  const isMounted = useRef(true);
  const { keyword } = useContext(AppContext);
  const [listVideos, setListVideos] = useState([]);
  const [page, setPage] = useState(1);
  const [isLoadMore, setIsloadMore] = useState(false);
  const { t } = useTranslation("translation");
  useEffect(() => {
    isMounted.current = true;
    getArticles({
      catalogue_id: id,
      keyword: keyword,
      article_type: "Video",
      limit: 10,
    }).then((res) => {
      if (isMounted.current) {
        if (res.data.data.length > 9) {
          setIsloadMore(true);
        } else {
          setIsloadMore(false);
        }
        setListVideos(res.data.data);
      }
    });
    return () => {
      isMounted.current = false;
    };
  }, [keyword]);

  const handleLoadMoreVideos = () => {
    setPage(page + 1);
    getArticles({
      catalogue_id: id,
      keyword: keyword,
      article_type: "Video",
      limit: 9,
      page: page + 1,
    }).then((res) => {
      const response = res.data.data;
      if (response.length >= 9) {
        setIsloadMore(true);
      } else {
        setIsloadMore(false);
      }
      const pop = listVideos.slice(0, listVideos.length - 1);
      setListVideos([...pop, ...response]);
    });
  };
  const pop = listVideos.slice(0, listVideos.length - 1);

  const data = listVideos;
  const furtureVideo = _.first(data);
  var htmlRegexG = /<(?:"[^"]*"['"]*|'[^']*'['"]*|[^'">])+>/g;

  return (
    <>
      <div className="my-3">
        <div className="video-main ">
          <Link
            to={`/videos/${
              furtureVideo?.slug ? furtureVideo?.slug : furtureVideo?.id
            }`}
          >
            <div
              className="bg-img-orgnal article-bg position-relative"
              style={{ backgroundImage: `url(${furtureVideo?.image})` }}
            >
              <span className="video-play-icon icon-arrow-solid text-center md-size position-absolute"></span>
            </div>
            <div className="inf-main hover">
              <h5
                className="hashtag-title line-clamp-01 my-1
              fw-bold"
              >
                {furtureVideo?.title}
              </h5>
              <div className="d-inline-block">
                <span className="icon-time align-middle mr-1"></span>
                <small>
                  <time dateTime="" title="">
                    {moment(furtureVideo?.created_at).format("DD-MM-YYYY")}
                  </time>
                </small>
              </div>
              <div className="d-inline-block ml-3">
                <small>
                  <span
                    className="icon-eye-line01 align-middle mr-1"
                    style={{ color: "#919191", fontSize: "13px" }}
                  ></span>
                  <span>{furtureVideo?.viewed}</span>
                </small>
              </div>
              <div className="d-inline-block ml-3">
                <small>
                  <span className="icon-comment-line align-middle mr-1"></span>
                  <span>{furtureVideo?.total_comment}</span>
                </small>
              </div>
              <p className="my-1 line-clamp-02">
                {furtureVideo?.content.replace(htmlRegexG, "")}
              </p>
            </div>
          </Link>
        </div>
      </div>
      {data.slice(1, data.length - 1).map(function (article, index) {
        let image = validURL(article.image)
          ? `${article.image}`
          : `${DOMAIN}${article.image}`;
        return (
          <div className="col-md-3 col-sm-6 col-xs-6" key={index}>
            <div className="item-lst hover">
              <Link to={`${article.id}`}>
                <div className="position-relative">
                  <div
                    className="bg-img thumb-pt"
                    style={{ backgroundImage: `url(${image})` }}
                  />
                  <span className="video-play-icon icon-arrow-solid text-center sm-size position-absolute"></span>
                </div>

                <div className="prod-inf">
                  <small>
                    <span className="icon-time align-middle mr-1"></span>
                    <small>
                      <time dateTime="" title="">
                        {moment(article?.created_at).format("DD-MM-YYYY")}
                      </time>
                    </small>
                  </small>
                  <div className="txt-name">{article.title}</div>

                  <small className="d-inline-block mr-2">
                    <span
                      className="icon-eye-line01 align-middle mr-1"
                      style={{ color: "#919191", fontSize: "12px" }}
                    ></span>
                    <small>{article.viewed}</small>
                  </small>

                  <small className="d-inline-block">
                    <span className="icon-comment-line align-middle mr-1"></span>
                    <small>{article.total_comment}</small>
                  </small>
                </div>
              </Link>
            </div>
          </div>
        );
      })}
      {isLoadMore && (
        <div
          className="post-more hover pointer mt-0 mb-3  background-clr-f5 "
          onClick={() => handleLoadMoreVideos()}
        >
          <span className="txt">
            <u className=" fw-500">{t("button.more")}</u>
          </span>
          <span className="icon-arrow-solid"></span>
        </div>
      )}
    </>
  );
}

export default VideoOfCatalogues;
