import React, { useEffect, useState } from "react";
import AddMetaTags from "components/add_meta_tags";
import LeftSidebar from "components/left_sidebar";
import { Card, Table } from "react-bootstrap";
import FilterBoxInvoice from "./FilterInvoiceBox";
import { useDispatch, useSelector } from "react-redux";
import { fetchListBusinessInvoice } from "actions/business";
import { Link, useParams } from "react-router-dom";
import moment from "moment/moment";
import NumberFormat from "components/number_format";

function BusinessInvoice() {
  const { businessId } = useParams();
  const dispatch = useDispatch();
  const [statusBox, setStatusBox] = useState("");
  const { data: listBusinessInvoice, isLoading } = useSelector(
    (state) => state.business.business_invoice
  );

  useEffect(() => {
    dispatch(fetchListBusinessInvoice({ businessId: businessId }));
  }, [businessId]);

  const filterInvoices = (tab) => {
    setStatusBox(tab);
    // dispatch(fetchListBusinessInvoice({businessId: businessId ,status: tab }));
  };
  const statusCheck = (string) => {
    switch (string) {
      case "done":
        return { text: "Hoàn thành", color: "success" };
      case "pending":
        return { text: "Đang chờ", color: "warning" };
      case "cancelled":
        return { text: "Đã hủy", color: "danger" };
      default:
        return { text: "", color: "primary" };
    }
  };

  return (
    <>
      <div>
        <AddMetaTags title={"Danh sách đơn hàng "} />
        <div className="row">
          <LeftSidebar />
          <div className="col-md-9 col-sm-12">
            <div className="cont-center saved-items">
              {/* <FilterBoxInvoice filter={filterInvoices} statusBox={statusBox}/> */}
              <div className="row">
                <div className="d-flex justify-content-between">
                  <h5 className="title-line-lt text-uppercase fw-bold">
                    <Link to={`/lien-ket-danh-nghiep/chuc-nang/${businessId}`}>
                      <span className="d-block">Danh sách đơn hàng</span>
                    </Link>
                  </h5>
                </div>
                <Card className="p-0 mt-3 cart-responsive">
                  <Card.Body>
                    <Table>
                      <thead>
                        <tr className="text-center">
                          <th>Mã đơn hàng</th>
                          <th>Tên người mua</th>
                          <th>Số lượng</th>
                          <th>Thời gian đặt</th>
                          <th>Tổng tiền</th>
                          <th>Trạng thái</th>
                        </tr>
                      </thead>

                      {listBusinessInvoice.length > 0 ? (
                        listBusinessInvoice?.map((item, index) => {
                          return (
                            <tbody id="invoice-listing" key={index}>
                              <tr className="text-center">
                                <td>
                                  <Link
                                    to={`/lien-ket-danh-nghiep/thong-tin-don-hang/${item.id}/business/${businessId}`}
                                  >
                                    <div className="sku-detail">
                                      {" "}
                                      {item.sku}
                                    </div>
                                  </Link>
                                </td>
                                <td>{item.name}</td>
                                <td>{item.quantity}</td>
                                <td>
                                  {moment(item.created_at).format("DD-MM-YYYY")}
                                </td>
                                <td>
                                  <NumberFormat>
                                    {item.price_total}
                                  </NumberFormat>
                                  <span className="ml-1 red-clr font-italic">
                                    VNĐ
                                  </span>
                                </td>
                                <td>
                                  <div
                                    className={`mt-1 badge badge-${
                                      statusCheck(item.status).color
                                    }`}
                                  >
                                    {statusCheck(item.status).text}
                                  </div>
                                </td>
                              </tr>
                            </tbody>
                          );
                        })
                      ) : (
                        <tbody>
                          <tr>
                            <td className="empty-data">Không có đơn hàng</td>
                          </tr>
                        </tbody>
                      )}
                    </Table>
                  </Card.Body>
                </Card>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default BusinessInvoice;
