import  { useEffect} from 'react'
import AddMetaTags from "components/add_meta_tags"
import LeftSidebar from 'components/left_sidebar'
import RightSidebar from 'components/right_sidebar'
import Diagnostics from 'components/diagnostics'
import { connect } from 'react-redux'
import * as diagnosticActions from 'actions/diagnostic'
import { bindActionCreators } from 'redux'
import { useSearchParams } from 'react-router-dom';

function Diagnostic (props) {
  let { fetchDiagnostics } = props.diagnosticActionsCreator;
  let { data }  = props.listDiagnostics;
  const [searchParams] = useSearchParams();
  var diagnostic_name = searchParams.get('name');
  
  useEffect(() => {
    fetchDiagnostics( diagnostic_name === null ? {} : { diagnostic_name: diagnostic_name });
  }, [fetchDiagnostics]);

 return (
  <div className="diagnostics">
  <AddMetaTags title={"Cẩm nang sâu bệnh"}/>
    <div className="row">
      <LeftSidebar />
      <Diagnostics 
        data={data} 
        diagnostic_name={diagnostic_name}
      />
      <RightSidebar />
    </div>
  </div>
)}

const mapStateToProps = state => {
  return {
    listDiagnostics: state.diagnostic.listDiagnostics
  };
};

const mapDispatchToProps = (dispatch, ownProps) => {
  return {
    diagnosticActionsCreator: bindActionCreators(diagnosticActions, dispatch),
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(Diagnostic);