import React from "react";
import LeftSidebar from "../../components/left_sidebar";
import AddMetaTags from "../../components/add_meta_tags";
import Tabs from "./components/tabs";

const InvoicesIndex = () => {
  return (
    <div>
      <AddMetaTags title={"Danh sách đơn hàng "} />
      <div className="row">
        <LeftSidebar />
        <div className="col-md-9 col-sm-12">
          <div className="cont-center saved-items">
            <Tabs />
          </div>
        </div>
      </div>
    </div>
  );
};

export default InvoicesIndex;
