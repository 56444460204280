import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { toastError } from "helpers/toastHelper";
import CommentHeader from "./partials/CommentHeader";
import CommentBody from "../../../components/CommentBody/index";
import CommentActions from "./partials/CommentActions";
import InputComment from "./partials/InputComment";
import SubComment from "./SubComment";
import {
  fetchCommentProduct,
  likeCommentProduct,
  unLikeCommentProduct,
  destroyComment,
  destroyChildComment,
  managerDeleteComment,
  managerDeleteSubComment,
  reportCommentProduct,
  replyCommentProduct,
  updateCommentProduct,
  managerUpdateComment,
  managerUpdateChildComment,
  updateChildComment,
} from "redux/actions/product";
export default function CommentArea(props) {
  const { product, current_user } = props;
  const dispatch = useDispatch();
  const { data: comments, isLoadMore } = useSelector((state) => state.product.commentProducts);
  const [content, setContent] = useState("");
  const [activeSort, setActiveSort] = useState("total_likes");
  const [typeInput, setTypeInput] = useState("");
  const [idShowChildComment, setIdShowChildComment] = useState(null);
  const [showInputBox, setShowInputBox] = useState({});
  const [reportId, setReportId] = useState(null);
  const [reportType, setReportType] = useState("");
  const [showModal, setShowModal] = useState(false);
  const [isOpenModalDelete, setIsOpenModalDelete] = useState(null);
  const [reportContent, setReportContent] = useState("");

  const hideModal = () => setShowModal(false);
  const handleShowInput = (id) => setShowInputBox({ id: id });

  const sortComments = (id, type) => {
    setActiveSort(type);
    dispatch(
      fetchCommentProduct({
        classable_id: id,
        classable_type: "Product",
        page: 1,
        limit: 5,
        sort: type,
      })
    );
  };
  const LikeComment = (id, type, isLiked) => {
    if (isLiked) {
      dispatch(unLikeCommentProduct({ classable_id: id, classable_type: type }));
    } else {
      dispatch(likeCommentProduct({ classable_id: id, classable_type: type }));
    }
  };
  const showReportModal = (id, type) => {
    setReportType(type);
    setReportId(id);
    setShowModal(true);
  };

  const handleLoadMore = (product_id) => {
    loadMoreComment({
      page: 2,
      limit: 5,
      classable_id: product_id,
      classable_type: "Product",
    });
  };

  const submitReport = (reason) => {
    if (reason === "") {
      toastError("Type your reason ");
    } else {
      dispatch(
        reportCommentProduct({
          reason: reason,
          classable_id: reportId,
          classable_type: reportType,
        })
      );
      setShowModal(false);
      setReportContent("");
    }
  };
  const deleteComment = (id, type, role) => {
    if (type === "comment") {
      if (role === "admin" || role === "smod") {
        dispatch(managerDeleteComment({ id: id }));
      } else {
        dispatch(destroyComment({ id: id }));
      }
    } else {
      if (role === "admin" || role === "smod") {
        dispatch(managerDeleteSubComment({ id: id }));
      } else {
        dispatch(destroyChildComment({ id: id }));
      }
    }
  };
  const submitReplyComment = (id, content, type) => {
    const commentData = {
      id: id,
      content: content,
    };
    if (content === "") {
      toastError("Type your content bình luận");
    } else if (content.length > 1000) {
      toastError("Do not more than  one thousand letters");
    } else {
      if (type === "reply") {
        dispatch(replyCommentProduct(commentData));
      } else {
        if (current_user.manager_type !== "member") {
          dispatch(managerUpdateComment(commentData));
        } else {
          dispatch(updateCommentProduct(commentData));
        }
      }
      setContent("");
      setShowInputBox(false);
    }
  };
  return (
    <>
      {comments.length > 0 && (
        <CommentHeader product_id={product?.id} activeSort={activeSort} sortComments={sortComments} />
      )}
      <div className={`${comments.length > 0 ? "cmt-listing" : "d-none"}`}>
        {comments.map(function (comment, index) {
          return (
            <div key={index}>
              <div key={comment.id} className="mt-3">
                <CommentBody comment={comment} />
                <CommentActions
                  current_user={current_user}
                  comment={comment}
                  LikeComment={LikeComment}
                  setIdShowChildComment={setIdShowChildComment}
                  setTypeInput={setTypeInput}
                  handleShowInput={handleShowInput}
                  showReportModal={showReportModal}
                  isOpenModalDelete={isOpenModalDelete}
                  setIsOpenModalDelete={setIsOpenModalDelete}
                  deleteComment={deleteComment}
                  hideModal={hideModal}
                  showModal={showModal}
                  reportContent={reportContent}
                  setReportContent={setReportContent}
                  submitReport={submitReport}
                />
                {showInputBox.id == comment.id && (
                  <InputComment
                    current_user={current_user}
                    setContent={setContent}
                    content={content}
                    submitReplyComment={submitReplyComment}
                    typeInput={typeInput}
                    comment={comment}
                  />
                )}
              </div>
              {idShowChildComment == comment.id && (
                <SubComment
                  current_user={current_user}
                  comment_id={comment.id}
                  LikeComment={LikeComment}
                  showReportModal={showReportModal}
                  hideModal={hideModal}
                  showModal={showModal}
                  reportContent={reportContent}
                  setReportContent={setReportContent}
                  submitReport={submitReport}
                  deleteComment={deleteComment}
                  handleShowInput={handleShowInput}
                  showInputBox={showInputBox}
                  setShowInputBox={setShowInputBox}
                  managerUpdateChildComment={managerUpdateChildComment}
                  updateChildComment={updateChildComment}
                  replyCommentProduct={replyCommentProduct}
                />
              )}
            </div>
          );
        })}
        {isLoadMore && (
          <span className="load-more-comment-post" onClick={() => handleLoadMore(product.id)}>
            More
          </span>
        )}
      </div>
    </>
  );
}
