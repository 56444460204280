import { call, put } from 'redux-saga/effects';
import { toastError, toastSuccess } from 'helpers/toastHelper';
import { PAGE, PER_PAGE } from 'constants';
import { followPostSuccess, followPostFailed, unFollowPostSuccess, unFollowPostFailed } from 'actions/auth';

import {
  fetchPost,
  fetchPostSuccess,
  fetchPostFailed,
  fetchHighlightPostSuccess,
  fetchHighlightPostFailed,
  fetchLoadMorePostSuccess,
  fetchLoadMorePostFailed,
  fetchUserPostSuccess,
  fetchUserPostFailed,
  fetchPostCatalogueSuccess,
  fetchPostCatalogueFailed,
  likePostSuccess,
  likePostFailed,
  likeCommentSuccess,
  likeCommentFailed,
  unlikeCommentSuccess,
  unlikeCommentFailed,
  unlikePostSuccess,
  unlikePostFailed,
  addPostSuccess,
  addPostFailed,
  fetchFavoritesPostSuccess,
  fetchFavoritesPostFailed,
  deletePostSuccess,
  deleteCommentPostSuccess,
  deletePostFailed,
  createCommentPostSuccess,
  createCommentPostFailed,
  sharePostFailed,
  fetchPostDetailSuccess,
  fetchPostDetailFailed,
  reportPostFailed,
  replacePost,
  replacePostSuccess,
  fetchCommentPostSuccess,
  fetchCommentPostFailed,
  fetchAnswersCommentsSuccess,
  reportCommentFailed,
  updateCommentSuccess,
  updateAnswerCommentSuccess,
  fetchLoadMoreCommentsSuccess,
  reportImagePostSuccess,
  reportImagePostFailed,
  replyCommentPostSuccess,
  fetchPickTopSuccess,
  fetchPostDetail,
  fetchAnswerCommentSuccess,
  likePostDetailPageSuccess,
  likePostDetailPageFailed,
  unlikePostDetailPageSuccess,
  unlikePostDetailPageFailed,
} from 'actions/post';

import {
  getPosts,
  getHighlightPosts,
  getUserPosts,
  getPostCatalogues,
  likePost,
  unlikePost,
  addPost,
  getFavoritesPosts,
  deletePost,
  getPostDetail,
  sharePost,
  reportPost,
  getPostId,
  getPostsAfterDeleteComment,
  likeComment,
  unlikeComment,
  createPost,
  fetchPickTop,
  reportImage,
} from 'apis/post';

import { managerDeleteCommentPostDetailSuccess, managerDeleteCommentPostDetailFailed } from 'actions/manager';

import { updateComment, updateCommentAnswer } from 'apis/comment';

import { login, followItem, unFollow } from 'apis/auth';

import { createComment, deleteComment, getComment, getAnswersComment, reportComment } from 'apis/comment';
import { STATUS_CODE } from 'constants/index';
import { replyComment } from 'apis/comment';
import { managerDeleteComment } from 'apis/manager';

function* check_valid_token() {
  let access_token = JSON.parse(localStorage.getItem('user_storage')).user_data.authToken;
  const resp = yield call(login, access_token);
  const { data } = resp;
  if (data.success && access_token !== null) {
    return true;
  } else {
    return false;
  }
}

const request_logout_user = () => {
  localStorage.removeItem('user_storage');
  toastError('Tài khoản của bạn đã đăng nhập trên thiết bị khác');
  setTimeout(() => {
    window.location.reload();
  }, 3000);
};

export function* userShareArticleAndVideo({ payload }) {
  try {
    const { params } = payload;
    const resp = yield call(createPost, params);
    const { data, status } = resp;
    if (status === STATUS_CODE.SUCCESS) {
      if ('message' in data.data) toastSuccess(data.data.message);
      else toastSuccess('Chia sẻ lên tường thành công');
    }
  } catch (error) {}
}

export function* fetchPosts({ payload }) {
  try {
    const { params } = payload;
    const resp = yield call(getPosts, params);
    const { data, status } = resp;
    if (status === STATUS_CODE.SUCCESS) {
      yield put(fetchPostSuccess(data));
    } else {
      yield put(fetchPostFailed(data));
    }
  } catch (error) {}
}

export function* fetchLoadMoreHomePosts({ payload }) {
  try {
    const { params } = payload;
    const { container } = params;
    const url = container == 'favorite' ? getFavoritesPosts : container == 'post' ? getUserPosts : getPosts;
    const resp = yield call(url, params);
    const { data, status } = resp;
    if (status === STATUS_CODE.SUCCESS) {
      yield put(fetchLoadMorePostSuccess(data));
    } else {
      yield put(fetchLoadMorePostFailed(data));
    }
  } catch (error) {}
}

export function* fetchHighlightPosts() {
  try {
    const { params } = {};
    const resp = yield call(getHighlightPosts, params);
    const { data, status } = resp;
    if (status === STATUS_CODE.SUCCESS) {
      yield put(fetchHighlightPostSuccess(data));
    } else {
      yield put(fetchHighlightPostFailed(data));
    }
  } catch (error) {}
}

export function* fetchPostCatalogues() {
  try {
    const { params } = {};
    const resp = yield call(getPostCatalogues, params);
    const { data, status } = resp;
    if (status === STATUS_CODE.SUCCESS) {
      yield put(fetchPostCatalogueSuccess(data));
    } else {
      yield put(fetchPostCatalogueFailed(data));
    }
  } catch (error) {
    toastError('Không tải được danh mục vui lòng đợi giây lát.');
  }
}

export function* fetchUserPosts({ payload }) {
  try {
    const { params } = payload;
    const resp = yield call(getUserPosts, params);
    const { data, status } = resp;
    if (status === STATUS_CODE.SUCCESS) {
      yield put(fetchUserPostSuccess(data));
    } else {
      yield put(fetchUserPostFailed(data));
    }
  } catch (error) {}
}

export function* userLikePost({ payload }) {
  try {
    const { params } = payload;
    const resp = yield call(likePost, params);
    const { data, status } = resp;
    if (status === STATUS_CODE.SUCCESS) {
      yield put(likePostSuccess(data));
      yield put(fetchPostDetail(params.classable_id));
    } else {
      yield put(likePostFailed(data));
    }
  } catch (error) {
    request_logout_user();
  }
}
export function* userLikePostDetailPage({ payload }) {
  try {
    const { params } = payload;
    const resp = yield call(likePost, params);
    const { data, status } = resp;
    if (status === STATUS_CODE.SUCCESS) {
      yield put(likePostDetailPageSuccess(data));
    } else {
      yield put(likePostDetailPageFailed(data));
    }
  } catch (error) {
    console.log(error);
  }
}
export function* userUnikePostDetailPage({ payload }) {
  try {
    const { params } = payload;
    const resp = yield call(unlikePost, params);
    const { data, status } = resp;
    if (status === STATUS_CODE.SUCCESS) {
      yield put(unlikePostDetailPageSuccess(data));
    } else {
      yield put(unlikePostDetailPageFailed(data));
    }
  } catch (error) {
    console.log(error);
  }
}

export function* userLikeComment({ payload }) {
  try {
    const { params } = payload;
    const resp = yield call(likeComment, params);
    const { data, status } = resp;
    if (status === STATUS_CODE.SUCCESS) {
      yield put(likeCommentSuccess(data));
    } else {
      yield put(likeCommentFailed(data));
    }
  } catch (error) {
    request_logout_user();
  }
}

export function* userUnlikePost({ payload }) {
  try {
    const { params } = payload;
    const resp = yield call(unlikePost, params);
    const { data, status } = resp;
    if (status === STATUS_CODE.SUCCESS) {
      yield put(unlikePostSuccess(params));
      yield put(fetchPostDetail(params.classable_id));
    } else {
      yield put(unlikePostFailed(data));
    }
  } catch (error) {
    request_logout_user();
  }
}

export function* userUnlikeComment({ payload }) {
  try {
    const { params } = payload;
    const resp = yield call(unlikeComment, params);
    const { data, status } = resp;
    if (status === STATUS_CODE.SUCCESS) {
      yield put(unlikeCommentSuccess(params));
    } else {
      yield put(unlikeCommentFailed(data));
    }
  } catch (error) {
    request_logout_user();
  }
}

export function* userAddPost({ payload }) {
  try {
    const { params } = payload;
    const resp = yield call(addPost, params);
    const { data, status } = resp;
    if (status === STATUS_CODE.SUCCESS) {
      yield put(addPostSuccess(data));
    } else {
      yield put(addPostFailed(data));
    }
  } catch (error) {}
}

export function* userFavoritesPost({ payload }) {
  try {
    const { params } = payload;
    const resp = yield call(getFavoritesPosts, params);
    const { data, status } = resp;
    if (status === STATUS_CODE.SUCCESS) {
      yield put(fetchFavoritesPostSuccess(data));
    } else {
      yield put(fetchFavoritesPostFailed(data));
    }
  } catch (error) {}
}

export function* userDeletePost({ payload }) {
  try {
    const { id } = payload.id;
    const resp = yield call(deletePost, id);
    const { data, status } = resp;
    if (status === STATUS_CODE.SUCCESS) {
      yield put(deletePostSuccess(id));
    } else {
      yield put(deletePostFailed(data));
    }
  } catch (error) {}
}

export function* userReplacePost({ payload }) {
  try {
    const { id } = payload.id;
    const resp = yield call(getPostId, id);
    const { data, status } = resp;
    if (status === STATUS_CODE.SUCCESS) {
      yield put(replacePostSuccess(data));
    }
  } catch (error) {}
}

export function* userCreateCommentPost({ payload }) {
  try {
    const { params } = payload;
    const resp = yield call(createComment, params);
    const { data, status } = resp;
    const res_comment = yield call(getComment, {
      classable_id: params.commentable_id,
      classable_type: 'Post',
    });
    const { data: listComment } = res_comment;
    if (status === STATUS_CODE.SUCCESS) {
      yield put(replacePost({ id: data['data'].commentable_id }));
      yield put(fetchCommentPostSuccess(listComment));
      yield put(createCommentPostSuccess(data));
    } else {
      yield put(createCommentPostFailed(data));
    }
  } catch (error) {
    request_logout_user();
  }
}

export function* userDeleteCommentPost({ payload }) {
  try {
    const { id } = payload.id;
    const resp = yield call(deleteComment, id);
    const response = yield call(getPostsAfterDeleteComment);
    let { data: listPost } = response;
    const { data, status } = resp;
    if (status === STATUS_CODE.SUCCESS) {
      yield put(deleteCommentPostSuccess(data));
      yield put(fetchPostSuccess(listPost));
    } else {
      yield put(deletePostFailed(data));
    }
  } catch (error) {}
}

export function* fetchCommentPost({ payload }) {
  try {
    const { params } = payload;
    const resp = yield call(getComment, params);
    const { data, status } = resp;
    if (status === STATUS_CODE.SUCCESS) {
      yield put(fetchCommentPostSuccess(data));
    } else {
      yield fetchCommentPostFailed(data);
    }
  } catch (error) {
    request_logout_user();
  }
}

export function* userSharePost({ payload }) {
  try {
    const resp = yield call(login, JSON.parse(localStorage.getItem('user_storage')).user_data.authToken);
    if (JSON.parse(localStorage.getItem('user_storage')).user_data.authToken !== null && !resp) {
      toastError('Phiên làm việc đã hết hạn / thay đổi, vui lòng đăng nhập lại');
      localStorage.removeItem('user_storage');
      setTimeout(() => {
        window.location.reload();
      }, 3000);
    } else {
      const { params } = payload;
      const resp = yield call(sharePost, params);
      const { data, status } = resp;

      if (status === STATUS_CODE.SUCCESS) {
        if ('message' in data.data) toastSuccess(data.data.message);
        else toastSuccess('Chia sẻ bài đăng thành công');
        yield put(
          fetchPost({
            page: PAGE,
            limit: PER_PAGE,
          }),
        );
      } else {
        yield put(sharePostFailed(data));
      }
    }
  } catch (error) {}
}

export function* userReportPost({ payload }) {
  try {
    const { params } = payload;
    const resp = yield call(reportPost, params);
    const { data, status } = resp;
    if (status === STATUS_CODE.SUCCESS) {
      toastSuccess('Báo cáo bài viết thành công');
    } else {
      yield put(reportPostFailed(data));
    }
  } catch (error) {}
}

export function* fetchCommentAnswer({ payload }) {
  try {
    const { params } = payload;
    const resp = yield call(getAnswersComment, params);
    const { data, status } = resp;
    if (status === STATUS_CODE.SUCCESS) {
      yield put(fetchAnswersCommentsSuccess(data));
    }
  } catch (error) {
    request_logout_user();
  }
}

export function* userReplyCommentPost({ payload }) {
  try {
    const { params } = payload;
    const resp = yield call(replyComment, params);
    const { data, status } = resp;
    if (status === STATUS_CODE.SUCCESS) {
      yield put(replyCommentPostSuccess(data));
    }
  } catch (error) {
    request_logout_user();
  }
}

export function* ReportComment({ payload }) {
  try {
    const { params } = payload;
    const resp = yield call(reportComment, params);
    const { data, status } = resp;
    if (status === STATUS_CODE.SUCCESS) {
      toastSuccess('Báo cáo bình luận thành công');
    } else {
      yield put(reportCommentFailed(data));
    }
  } catch (error) {}
}

export function* userUpdateComment({ payload }) {
  try {
    const { params } = payload;
    const resp = yield call(updateComment, params);
    const { data, status } = resp;
    if (status === STATUS_CODE.SUCCESS) {
      yield put(updateCommentSuccess(data));
    }
  } catch (error) {
    request_logout_user();
  }
}

export function* userUpdateCommentAnswer({ payload }) {
  try {
    const { params } = payload;
    const resp = yield call(updateCommentAnswer, params);
    const { data, status } = resp;
    if (status === STATUS_CODE.SUCCESS) {
      yield put(updateAnswerCommentSuccess(data));
    }
  } catch (error) {
    request_logout_user();
  }
}

export function* userLoadMoreCommentsPost({ payload }) {
  try {
    const { params } = payload;
    const resp = yield call(getComment, params);
    const { data, status } = resp;
    if (status === STATUS_CODE.SUCCESS) {
      yield put(fetchLoadMoreCommentsSuccess(data));
    }
  } catch (error) {}
}

export function* userFetchPostDetail({ payload }) {
  try {
    const { id } = payload;
    const resp = yield call(getPostDetail, id);
    const { data, status } = resp;
    if (status === STATUS_CODE.SUCCESS) {
      yield put(fetchPostDetailSuccess(data));
    }
  } catch (error) {
    yield put(fetchPostDetailFailed(error));
  }
}

export function* managerDeleteComments({ payload }) {
  try {
    const { id } = payload;
    const resp = yield call(managerDeleteComment, id);
    const { data, status } = resp;
    if (status === STATUS_CODE.SUCCESS) {
      yield put(managerDeleteCommentPostDetailSuccess({ id: id, data: data }));
    } else {
      yield put(managerDeleteCommentPostDetailFailed(data));
    }
  } catch (error) {}
}

export function* userFollowPost({ payload }) {
  try {
    const { params } = payload;
    const resp = yield call(followItem, params);
    const { data, status } = resp;
    if (status === STATUS_CODE.SUCCESS) {
      yield put(followPostSuccess(data));
    } else {
      yield put(followPostFailed(data));
    }
  } catch (error) {}
}

export function* userUnFollowPost({ payload }) {
  try {
    const { params } = payload;
    const resp = yield call(unFollow, params);
    const { data, status } = resp;
    if (status === STATUS_CODE.SUCCESS) {
      yield put(unFollowPostSuccess(data));
    } else {
      yield put(unFollowPostFailed(data));
    }
  } catch (error) {}
}

export function* userFetchPickTop({ payload }) {
  try {
    const { params } = payload;
    const resp = yield call(fetchPickTop, params);
    const { data, status } = resp;
    if (status === STATUS_CODE.SUCCESS) {
      yield put(fetchPickTopSuccess(data));
    }
  } catch (error) {}
}

export function* userReportImage({ payload }) {
  try {
    const { params } = payload;
    const resp = yield call(reportImage, params);
    const { data, status } = resp;
    if (status === STATUS_CODE.SUCCESS) {
      yield put(reportImagePostSuccess(data));
    }
  } catch (error) {}
}

export function* fetchAnsComments({ payload }) {
  try {
    const { params } = payload;
    const resp = yield call(getAnswersComment, params);
    const { data, status } = resp;

    if (status === STATUS_CODE.SUCCESS) {
      yield put(fetchAnswerCommentSuccess(data));
    }
  } catch (error) {
    request_logout_user();
  }
}
