import React, { useState, useEffect } from "react";
import { fetchAnswerComment } from "redux/actions/product";
import { useDispatch, useSelector } from "react-redux";
import ReactTimeAgo from "react-time-ago";
import Modal from "react-bootstrap/Modal";
import parse from "html-react-parser";
import ModalDeleteConfirmComment from "components/shared/modalDeleteConfirmComment/modal";
import InputSubComment from "./partials/InputSubComment";
export default function SubComment(props) {
  const {
    current_user,
    comment_id,
    LikeComment,
    showReportModal,
    hideModal,
    showModal,
    reportContent,
    setReportContent,
    submitReport,
    deleteComment,
    showInputBox,
    handleShowInput,
    setShowInputBox,
    managerUpdateChildComment,
    updateChildComment,
    replyCommentProduct,
  } = props;
  const dispatch = useDispatch();
  const listAnswerComment = useSelector((state) => state.product.listAnswerComment.data);
  const [subCommentId, setSubCommentId] = useState(null);
  const [typeInput, setTypeInput] = useState("");
  const [subContent, setSubContent] = useState("");

  useEffect(() => {
    dispatch(fetchAnswerComment({ id: comment_id }));
  }, [comment_id]);
  const submitReply = (content, type, replier_id) => {
    if (!current_user) {
      toastError("Please login first!");
      return;
    }

    if (content === "") {
      toastError("Type your content");
      return;
    }

    const commentData = {
      id: showInputBox.id,
      content: content,
    };
    if (type === "reply") {
      commentData.replier_id = current_user.id;
      dispatch(
        replyCommentProduct({
          id: comment_id,
          parent_id: showInputBox.id,
          content: content,
          replier_id: replier_id,
        })
      );
    } else {
      if (current_user.manager_type !== "member") {
        dispatch(managerUpdateChildComment(commentData));
      } else {
        dispatch(updateChildComment(commentData));
      }
    }
    setSubContent("");
    setShowInputBox(false);
  };
  return (
    <>
      {listAnswerComment?.length > 0 &&
        listAnswerComment.map(function (answer, index) {
          return (
            <div className="ml-5 mt-1 position-relative" key={index}>
              <div className="user-inf border-0">
                <a href={`/nguoi-dung/${answer.user_id}`}>
                  <div
                    className="bg-img-orgnal avtar40 "
                    style={{
                      backgroundImage: `url(${answer.user.image})`,
                      width: "35px",
                      height: "35px",
                      marginRight: "5px",
                    }}
                  />
                </a>
                <div className="d-inline-block">
                  <a href={`/nguoi-dung/${answer.user_id}`}>
                    <p className="user-name">{answer.user.name}</p>
                  </a>
                  <p className="post-time">
                    <span className="icon-time align-middle"></span>{" "}
                    <ReactTimeAgo date={Date.parse(answer.created_at)} />
                  </p>
                </div>

                <div>
                  {answer.parent_id !== null && answer.replier_id !== null ? (
                    <p className="txt-cmt">
                      <span style={{ color: "blue" }}>@{answer?.replier?.name}</span>
                      &nbsp;
                      {answer.content && parse(answer.content)}
                    </p>
                  ) : (
                    <p className="txt-cmt"> {answer.content && parse(answer.content)} </p>
                  )}
                  <div className="actions d-flex align-items-center my-2">
                    <div className="d-inline-block mr-3">
                      <div
                        className={`${answer?.user_liked ? "active" : ""} like d-flex align-items-center`}
                        onClick={() => LikeComment(answer.id, answer.classable_type, answer.user_liked)}>
                        <span className="icon-like01 me-1 fs-6"></span>
                        {answer.user_liked ? <span>Dislike &nbsp; </span> : <span>Like &nbsp; </span>}
                        <small>({answer.total_likes === null ? 0 : answer.total_likes})</small>
                      </div>
                    </div>
                    <div className="d-inline-block mr-3">
                      <div className="d-flex align-items-center">
                        <span
                          onClick={() => {
                            handleShowInput(answer.id);
                            setTypeInput("reply");
                          }}>
                          Reply
                        </span>
                      </div>
                    </div>

                    <div className="dropdown post-report ms-auto">
                      <button
                        className="btn dropdown-toggle"
                        type="button"
                        data-bs-toggle="dropdown"
                        aria-expanded="false">
                        <span className="icon-menu-dot"></span>
                      </button>
                      <ul className="dropdown-menu dropdown-menu-end" aria-labelledby="true">
                        {current_user?.id !== answer.user_id && (
                          <li className="dropdown-item">
                            <div className="" onClick={() => showReportModal(answer.id, answer.classable_type)}>
                              <a>Vi phạm</a>
                            </div>
                            <Modal />
                          </li>
                        )}
                        {current_user?.id == answer.user_id || current_user?.manager_type !== "member" ? (
                          <li className="dropdown-item">
                            <div
                              className=""
                              onClick={() => {
                                handleShowInput(answer.id);
                                setTypeInput("edit");
                              }}>
                              <a>Edit</a>
                            </div>
                          </li>
                        ) : (
                          ""
                        )}
                        {current_user?.id === answer.user_id ||
                        current_user?.manager_type == "smod" ||
                        current_user?.manager_type == "admin" ? (
                          <li className="dropdown-item">
                            <button
                              type="button"
                              className="btn btn-line mrgb-20"
                              onClick={() => setSubCommentId(answer.id)}>
                              Delete
                            </button>
                          </li>
                        ) : (
                          ""
                        )}
                        {subCommentId && (
                          <ModalDeleteConfirmComment
                            onDelete={() =>
                              deleteComment(answer.classable_id, "subComment", current_user?.manager_type)
                            }
                            isOpen={subCommentId}
                            onToggle={() => setSubCommentId(null)}
                          />
                        )}
                      </ul>
                    </div>
                    <Modal show={showModal} onHide={hideModal} aria-labelledby="contained-modal-title-vcenter" centered>
                      <Modal.Header closeButton>
                        <Modal.Title>Báo cáo bình luận vi phạm</Modal.Title>
                      </Modal.Header>
                      <Modal.Body>
                        <input
                          className="form-control"
                          name="message"
                          placeholder="Nhập lý do"
                          value={reportContent}
                          onChange={(e) => setReportContent(e.target.value)}
                        />
                      </Modal.Body>
                      <Modal.Footer>
                        <button
                          type="button"
                          className="btn btn-primary"
                          onClick={() => submitReport(reportContent, answer.id, answer.classable_type)}>
                          {" "}
                          gửi
                        </button>
                      </Modal.Footer>
                    </Modal>
                  </div>
                  {showInputBox.id == answer.id && (
                    <InputSubComment
                      current_user={current_user}
                      subContent={subContent}
                      setSubContent={setSubContent}
                      typeInput={typeInput}
                      submitReplyComment={submitReply}
                      answer={answer}
                    />
                  )}
                </div>
              </div>
            </div>
          );
        })}
    </>
  );
}
