import axiosService from "services/axiosService";
import qs from "query-string";
import { DOMAIN } from "constants";

const home_url = "api/v2/home/search";

export const searchHome = (params) => {
  let query = '';
  if (Object.keys(params).length > 0) {
    query = `?${qs.stringify(params)}`;
  }
  return axiosService.get(`${DOMAIN}/${home_url}${query}`);
}

