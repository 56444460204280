import { Outlet, Navigate } from "react-router-dom";
import useAuth from "hooks/useAuth";
import { toastError } from "helpers/toastHelper";

export default function PrivateRoutes() {
  const isAuth = useAuth();
  if (!isAuth) {
    toastError("Bạn chưa đăng nhập");
  }
  return isAuth ? <Outlet /> : <Navigate to={"/"} />;
}
