import * as traceabilityConst from "constants/traceability";

export const fetchTraceability = (params) => {
  return {
    type: traceabilityConst.FETCH_TRACEABILITY,
    payload: { params },
  };
};
export const fetchTraceabilitySuccess = (data) => {
  return {
    type: traceabilityConst.FETCH_TRACEABILITY_SUCCESS,
    payload: { data },
  };
};
export const fetchTraceabilityFailed = (error) => {
  return {
    type: traceabilityConst.FETCH_TRACEABILITY_FAILED,
    payload: { error },
  };
};

export const deleteTraceability = (params) => {
  return {
    type: traceabilityConst.DELETE_TRACEABILITY,
    payload: { params },
  };
};
export const deleteTraceabilitySuccess = (data) => {
  return {
    type: traceabilityConst.DELETE_TRACEABILITY_SUCCESS,
    payload: { data },
  };
};
export const deleteTraceabilityFailed = (error) => {
  return {
    type: traceabilityConst.DELETE_TRACEABILITY_FAILED,
    payload: { error },
  };
};

export const scanTraceability = (params) => {
  return {
    type: traceabilityConst.SCAN_TRACEABILITY,
    payload: { params },
  };
};

export const scanTraceabilitySuccess = (data) => {
  return {
    type: traceabilityConst.SCAN_TRACEABILITY_SUCCESS,
    payload: { data },
  };
};

export const scanTraceabilityFailed = (error) => {
  return {
    type: traceabilityConst.SCAN_TRACEABILITY_FAILED,
    payload: { error },
  };
};

export const newTraceability = (params) => {
  return {
    type: traceabilityConst.NEW_TRACEABILITY,
    payload: { params },
  };
};

export const newTraceabilitySuccess = (data) => {
  return {
    type: traceabilityConst.NEW_TRACEABILITY_SUCCESS,
    payload: { data },
  };
};

export const newTraceabilityFailed = (error) => {
  return {
    type: traceabilityConst.NEW_TRACEABILITY_FAILED,
    payload: { error },
  };
};

export const fetchTraceabilityDetail = (id) => {
  return {
    type: traceabilityConst.FETCH_TRACEABILITY_DETAIL,
    payload: { id },
  };
};
export const fetchTraceabilityDetailSuccess = (data) => {
  return {
    type: traceabilityConst.FETCH_TRACEABILITY_DETAIL_SUCCESS,
    payload: { data },
  };
};
export const fetchTraceabilityDetailFailed = (error) => {
  return {
    type: traceabilityConst.FETCH_TRACEABILITY_DETAIL_FAILED,
    payload: { error },
  };
};
