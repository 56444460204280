import { useEffect, useState, useRef } from "react";
import { DOMAIN } from "constants";
import classNames from "classnames";
import { useFormik } from "formik";
import { Button, Card, Col, Form, Row } from "react-bootstrap";
import { useNavigate, useParams } from "react-router-dom";
import * as Yup from "yup";
import { getCataloguesProducts } from "../../apis/catalogue";
import { getProductId, getlistUnits } from "../../apis/product";
import { getDetailBusinessProduct } from "apis/business";
import ImageUploading from "react-images-uploading";
import AddImageIcon from "/assets/images/add-image.png";
import "./index.scss";
import { useDispatch } from "react-redux";
import Quill from "./editor";
import { toastError } from "helpers/toastHelper";
import { loadImageFile } from "../../lib";

const formSchema = Yup.object().shape({
  title: Yup.string().required("Tên sản phẩm không được để trống").trim("Tên sản phẩm không được để trống"),
  retail_price: Yup.number()
    .required("Không được để trống")
    .positive("Số tiền là một số lớn hơn 0")
    .integer("Số tiền là một số lớn hơn 0")
    .truncate(),
  quantity: Yup.number()
    .required("Không được để trống")
    .positive("Số lượng là một số lớn hơn 0")
    .integer("Số lượng là một số lớn hơn 0")
    .truncate(),
});

const AddEditProduct = () => {
  const { productId } = useParams();
  const { businessId } = useParams();
  const [isLoading, setIsLoading] = useState(false);
  const navigate = useNavigate();
  const [catalogueOptions, setCatalogueOptions] = useState([]);
  const [listUnits, setListUnits] = useState([]);
  const [images, setImages] = useState([]);
  const dispatch = useDispatch();
  const [descriptions, setDescriptions] = useState("");
  const [enoughImage, setEnoughImage] = useState(false);
  const { values, setValues, handleChange, handleSubmit, errors, resetForm, isSubmitting, setSubmitting, touched } =
    useFormik({
      initialValues: {
        title: "",
        description: "",
        product_code: "",
        hot_pick: false,
        optional_name: "",
        retail_price: 0,
        wholesale_price: "Contact",
        quantity: 0,
        product_catalogue_id: 0,
        product_unit_id: 0,
      },
      onSubmit: async (values) => {
        const formData = new FormData();
        setIsLoading(true);
        for (const key in values) {
          if (key === "product_unit_id" && values[key] === 0) {
            values[key] = listUnits[0].id;
          }
          if (key === "product_catalogue_id" && values[key] === 0) {
            values[key] = catalogueOptions[0].key;
          }
          if (key === "description") {
            values[key] = descriptions;
          }
          formData.append(key, values[key]);
        }
        if (images.length > 0) {
          const imageArray = [];
          for (const image of images) {
            if (image?.file) formData.append("attachment[file][]", image.file);
            if (!image?.file) imageArray.push(`image_url[]=${image.dataURL}`);
          }
          const results = imageArray.map((e) => e).join("&");
          if (imageArray.length > 0) {
            const files = await loadImageFile(
              `${DOMAIN}/api/v2/generate_images?${results}`
            );
            for (const file of files) {
              formData.append("attachment[file][]", file);
            }
          }
        }

        if (productId) {
          dispatch({
            type: "UPDATE_PRODUCT",
            payload: {
              id: productId,
              params: formData,
              callback: () => {
                navigate("/nguoi-dung/san-pham");
              },
            },
          });
          setTimeout(() => {
            setIsLoading(false);
          }, 3000);
        } else {
          dispatch({
            type: "CREATE_PRODUCT",
            payload: {
              params: formData,
              callback: () => {
                navigate("/nguoi-dung/san-pham");
              },
            },
          });
          setTimeout(() => {
            setIsLoading(false);
          }, 3000);
        }
        setDescriptions("");
      },
      validationSchema: formSchema,
    });
  const handleChangeImages = (imageList) => {
    if (imageList.length > 10) return toastError("Không vượt quá 10 ảnh");
    if (imageList.length <= 10) {
      setImages(imageList);
      setEnoughImage(false);
    }
    if (imageList.length == 10) setEnoughImage(true);
  };
  async function fetchDataOptions() {
    const response = await getCataloguesProducts();
    const { data } = response.data;
    const options = data.map((it) => ({
      label: it.fullname,
      key: it.id,
    }));
    return options;
  }

  async function fetchDataUnits() {
    const response = await getlistUnits();
    const { data } = response.data;
    const options = data.map((it) => ({
      name: it.name,
      id: it.id,
    }));
    setListUnits(options);
  }

  async function fetchDetailProduct() {
    const response = await getProductId(productId);
    const { data } = response.data;
    return data;
  }

  async function fetchDetailProductBusiness() {
    const response = await getDetailBusinessProduct({
      productId: productId,
      businessId: businessId,
    });
    const { data } = response.data;
    return data;
  }

  useEffect(() => {
    (async function () {
      fetchDataUnits();
      const arr = [fetchDataOptions()];
      if (businessId && productId) {
        arr.push(fetchDetailProductBusiness());
      }
      if (productId) {
        arr.push(fetchDetailProduct());
      }
      const [options, product] = await Promise.all(arr);
      setCatalogueOptions(options);
      if (productId || businessId) {
        await setDescriptions(product.description);
        await setValues({
          title: product.title,
          description: product.description,
          product_code: product.product_code,
          hot_pick: product.hot_pick,
          optional_name: product.optional_name,
          retail_price: product.retail_price,
          wholesale_price: product.wholesale_price > 0 ? product.wholesale_price : "Contact",
          quantity: product.quantity,
          product_catalogue_id: options.find((it) => +it.key === +product.product_catalogue_id)?.key || options[0].key,
          product_unit_id: product.product_unit_id,
        });

        setImages(product.images.map((image) => ({ dataURL: image.name })));
      }
    })();
    return () => {
      resetForm();
    };
  }, [productId, businessId]);
  // const loadImageFile = async (url) => {
  //   let response = await axios.get(url);
  //   if (response.status === 200) {
  //     if (response.data) {
  //       let data = await b64toBlob(response.data.data, {
  //         type: "image/jpeg",
  //       });
  //       return new File([data], url.substring(url.lastIndexOf("/") + 1), {
  //         type: "image/jpeg",
  //       });
  //     }
  //   }
  //   return;
  // };

  // const b64toBlob = (b64Data, contentType = "", sliceSize = 1042) => {
  //   const byteCharacters = atob(b64Data);
  //   const byteArrays = [];

  //   for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
  //     const slice = byteCharacters.slice(offset, offset + sliceSize);

  //     const byteNumbers = new Array(slice.length);
  //     for (let i = 0; i < slice.length; i++) {
  //       byteNumbers[i] = slice.charCodeAt(i);
  //     }

  //     const byteArray = new Uint8Array(byteNumbers);
  //     byteArrays.push(byteArray);
  //   }

  //   return new Blob(byteArrays, { type: contentType });
  // };

  return (
    <Card className="FormAddEditProduct">
      <Card.Header>
        <h5 className="title-line-lt text-uppercase fw-bold my-3">{productId ? "Sửa sản phẩm" : "Thêm sản phẩm"}</h5>
      </Card.Header>
      <Card.Body>
        <Row className="g-2">
          <ImageUploading multiple maxNumber={10} value={images} onChange={handleChangeImages}>
            {({ imageList, onImageRemove, onImageUpload }) => {
              return imageList.concat(null).map((image, index) =>
                image ? (
                  <Col key={index} xs={2}>
                    <div className="border border-secondary border-opacity-50 ImageAttachment shadow-sm">
                      <button className="btn-close" onClick={() => onImageRemove(index)} />
                      <img src={image.dataURL} alt="" />
                    </div>
                  </Col>
                ) : (
                  !enoughImage && (
                    <Col xs={2} key={"empty-image"}>
                      <div className="d-flex w-100 h-100 bg-white" onClick={onImageUpload}>
                        <div className="ImageAttachment">
                          <img src={AddImageIcon} className="buttonAddImage" />
                        </div>
                      </div>
                    </Col>
                  )
                )
              );
            }}
          </ImageUploading>
        </Row>
        <Form onSubmit={handleSubmit} className="mb-0">
          <Form.Group className="mb-3"></Form.Group>
          <Row className="mb-3">
            <Form.Group as={Col}>
              <Form.Label>Mã sản phẩm</Form.Label>
              <Form.Control
                type="text"
                name="product_code"
                value={values.product_code}
                onChange={handleChange}
                placeholder="Mã sản phẩm"
              />
            </Form.Group>
            <Form.Group as={Col}>
              <Form.Label>
                Tên sản phẩm
                <span className="text-danger"> *</span>
              </Form.Label>
              <Form.Control
                type="text"
                className={classNames({
                  "is-invalid": touched.title && errors?.title,
                })}
                name="title"
                value={values.title}
                onChange={handleChange}
                placeholder="Tên sản phẩm"
              />
              {touched.title && errors?.title && <div className="invalid-feedback">{errors.title}</div>}
            </Form.Group>
            {/* <Form.Group as={Col}>
              <Form.Label>Danh mục</Form.Label>
              <Form.Select
                value={values.product_catalogue_id}
                name="product_catalogue_id"
                onChange={handleChange}
              >
                {catalogueOptions.map((option, index) => (
                  <option value={option.key} key={index}>
                    {option.label}
                  </option>
                ))}
              </Form.Select>
            </Form.Group> */}
          </Row>
          <Row className="mb-3">
            <Form.Group as={Col} className="d-none">
              <Form.Label>Tên sản phẩm</Form.Label>
              <Form.Control
                type="text"
                name="optional_name"
                value={values.optional_name}
                onChange={handleChange}
                placeholder="Tên tài khoản"
              />
            </Form.Group>
            <Form.Group as={Col}>
              <Form.Label>Danh mục</Form.Label>
              <Form.Select value={values.product_catalogue_id} name="product_catalogue_id" onChange={handleChange}>
                {catalogueOptions.map((option, index) => (
                  <option value={option.key} key={index}>
                    {option.label}
                  </option>
                ))}
              </Form.Select>
            </Form.Group>
            <Form.Group as={Col}>
              <Form.Label>
                Số lượng
                <span className="text-danger"> *</span>
              </Form.Label>
              <Form.Control
                name="quantity"
                value={values.quantity}
                onChange={handleChange}
                className={classNames({
                  "is-invalid": touched.quantity && errors?.quantity,
                })}
                type="text"
                placeholder="Số lượng"
              />
              {touched.quantity && errors?.quantity && <div className="invalid-feedback">{errors.quantity}</div>}
            </Form.Group>
            <Form.Group as={Col}>
              <Form.Label>Đơn vị</Form.Label>
              <Form.Select value={values.product_unit_id} name="product_unit_id" onChange={handleChange}>
                {listUnits.map((unit, index) => (
                  <option value={unit.id} key={index}>
                    {unit.name}
                  </option>
                ))}
              </Form.Select>
            </Form.Group>
          </Row>
          <Row className="mb-3">
            <Form.Group as={Col}>
              <Form.Label>
                Retail price
                <span className="text-danger"> *</span>
              </Form.Label>
              <Form.Control
                name="retail_price"
                className={classNames({
                  "is-invalid": touched.retail_price && errors?.retail_price,
                })}
                value={values.retail_price}
                onChange={handleChange}
                type="text"
                placeholder="Retail price"
              />
              {touched.retail_price && errors?.retail_price && (
                <div className="invalid-feedback">{errors.retail_price}</div>
              )}
            </Form.Group>
            <Form.Group as={Col}>
              <Form.Label>Wholesale price</Form.Label>
              <Form.Control
                name="wholesale_price"
                className={classNames({
                  "is-invalid": touched.wholesale_price && errors?.wholesale_price,
                })}
                value={values.wholesale_price}
                onChange={handleChange}
                type="text"
                placeholder="Wholesale price"
              />
              {touched.wholesale_price && errors?.wholesale_price && (
                <div className="invalid-feedback">{errors.wholesale_price}</div>
              )}
            </Form.Group>
          </Row>
          <Form.Group className="mb-3">
            <Form.Check
              type="checkbox"
              name="hot_pick"
              checked={values.hot_pick}
              onChange={handleChange}
              label="Sản phẩm nổi bật"
            />
          </Form.Group>

          <Form.Group className="mb-2">
            <Form.Label>
              <span className="fw-bold">Mô tả</span>
            </Form.Label>

            <Quill
              onChange={setDescriptions}
              value={descriptions}
              placeholder="Nhập mô tả sản phẩm"
              id="description"
              name="description"
            />
          </Form.Group>
          <div className="d-flex justify-content-end">
            <Button variant="success" type="submit" disabled={isLoading}>
              Lưu
            </Button>
          </div>
        </Form>
      </Card.Body>
    </Card>
  );
};

export default AddEditProduct;
