import * as authConst from 'constants/auth';

export const fetchLogin = access_token => {
  return {
    type: authConst.LOGIN,
    payload: {
      access_token,
    },
  };
};

export const loginSuccess = data => {
  return {
    type: authConst.LOGIN_SUCCESS,
    payload: {
      data,
    },
  };
};

export const loginFail = error => {
  return {
    type: authConst.LOGIN_FAILED,
    payload: {
      error,
    },
  };
};

export const fetchLogout = access_token => {
  return {
    type: authConst.LOGOUT,
    payload: {
      access_token,
    },
  };
};

export const logoutSuccess = data => {
  return {
    type: authConst.LOGOUT_SUCCESS,
    payload: {
      data,
    },
  };
};

export const logoutFail = error => {
  return {
    type: authConst.LOGOUT_FAILED,
    payload: {
      error,
    },
  };
};

export const logout = () => {
  return {
    type: authConst.LOGOUT,
  };
};

export const updateUser = params => {
  return {
    type: authConst.UPDATE_USER,
    payload: {
      params,
    },
  };
};

export const updateUserSuccess = data => {
  return {
    type: authConst.UPDATE_USER_SUCCESS,
    payload: {
      data,
    },
  };
};

export const updateUserFail = error => {
  return {
    type: authConst.UPDATE_USER_FAILED,
    payload: {
      error,
    },
  };
};

export const followUser = params => {
  return {
    type: authConst.FOLLOW_USER,
    payload: {
      params,
    },
  };
};

export const followUserSuccess = data => {
  return {
    type: authConst.FOLLOW_USER_SUCCESS,
    payload: {
      data,
    },
  };
};

export const followUserFail = error => {
  return {
    type: authConst.FOLLOW_USER_FAILED,
    error,
  };
};

export const fetchFollowing = params => {
  return {
    type: authConst.FETCH_FOLLOWING,
    payload: {
      params,
    },
  };
};

export const fetchFollowingSuccess = data => {
  return {
    type: authConst.FETCH_FOLLOWING_SUCCESS,
    payload: {
      data,
    },
  };
};

export const fetchFollowingFail = error => {
  return {
    type: authConst.FETCH_FOLLOWING_FAILED,
    payload: {
      error,
    },
  };
};

export const unFollow = params => {
  return {
    type: authConst.UNFOLOW,
    payload: {
      params,
    },
  };
};

export const unFollowSuccess = data => {
  return {
    type: authConst.UNFOLOW_SUCCESS,
    payload: {
      data,
    },
  };
};

export const unFollowFail = error => {
  return {
    type: authConst.UNFOLOW_FAILED,
    payload: {
      error,
    },
  };
};

export const sharePoint = params => {
  return {
    type: authConst.SHARE_POINT,
    payload: {
      params,
    },
  };
};

export const sharePointSuccess = data => {
  return {
    type: authConst.SHARE_POINT_SUCCESS,
    payload: {
      data,
    },
  };
};

export const sharePointFailed = error => {
  return {
    type: authConst.SHARE_POINT_FAILED,
    payload: {
      error,
    },
  };
};

export const followPost = params => {
  return {
    type: authConst.FOLLOW_POST,
    payload: {
      params,
    },
  };
};

export const followPostSuccess = data => {
  return {
    type: authConst.FOLLOW_POST_SUCCESS,
    payload: {
      data,
    },
  };
};

export const followPostFailed = error => {
  return {
    type: authConst.FOLLOW_POST_FAILED,
    payload: {
      error,
    },
  };
};

export const unFollowPost = params => {
  return {
    type: authConst.UNFOLLOW_POST,
    payload: {
      params,
    },
  };
};

export const unFollowPostSuccess = data => {
  return {
    type: authConst.UNFOLLOW_POST_SUCCESS,
    payload: {
      data,
    },
  };
};

export const unFollowPostFailed = error => {
  return {
    type: authConst.UNFOLLOW_POST_FAILED,
    payload: {
      error,
    },
  };
};

export const showDeepLink = () => {
  return {
    type: authConst.SHOW_DEEP_LINK,
  };
};

export const addCurrentURL = params => {
  return {
    type: authConst.ADD_CURRENT_URL,
    payload: {
      params,
    },
  };
};
export const addCurrentURLSuccess = url => {
  return {
    type: authConst.ADD_CURRENT_URL_SUCCESS,
    payload: {
      url,
    },
  };
};

export const fetchSidebarFollowings = params => {
  return {
    type: authConst.FETCH_SIDEBAR_FOLLOWING,
    payload: {
      params,
    },
  };
};

export const fetchSidebarFollowingsSuccess = data => {
  return {
    type: authConst.FETCH_SIDEBAR_FOLLOWING_SUCCESS,
    payload: {
      data,
    },
  };
};

export const fetchFollowPost = params => {
  return {
    type: authConst.FETCH_FOLLOW_POST,
    payload: {
      params,
    },
  };
};

export const fetchFollowPostSuccess = data => {
  return {
    type: authConst.FETCH_FOLLOW_POST_SUCCESS,
    payload: {
      data,
    },
  };
};

export const fetchFollowPostFailed = error => {
  return {
    type: authConst.FETCH_FOLLOW_POST_SUCCESS,
    payload: {
      error,
    },
  };
};

export const fetchInfoUser = user_id => {
  return {
    type: authConst.FETCH_INFO_USER,
    payload: {
      user_id,
    },
  };
};

export const fetchInfoUserSuccess = data => {
  return {
    type: authConst.FETCH_INFO_USER_SUCCESS,
    payload: {
      data,
    },
  };
};

export const updatePointUser = data => {
  return {
    type: authConst.UPDATE_POINT_USER,
    payload: {
      data,
    },
  };
};

export const unFollowFavPost = params => {
  return {
    type: authConst.UNFOLLOW_FAVORITES_POST,
    payload: {
      params,
    },
  };
};

export const unFollowFavPostSuccess = data => {
  return {
    type: authConst.UNFOLLOW_FAVORITES_POST_SUCCESS,
    payload: {
      data,
    },
  };
};

export const unFollowFavPostFailed = error => {
  return {
    type: authConst.UNFOLLOW_FAVORITES_POST_FAILED,
    payload: {
      error,
    },
  };
};

export const likeFollowingPost = params => {
  return {
    type: authConst.LIKE_FOLLOWING_POST,
    payload: {
      params,
    },
  };
};

export const likeFollowingPostSuccess = data => {
  return {
    type: authConst.LIKE_FOLLOWING_POST_SUCCESS,
    payload: {
      data,
    },
  };
};

export const likeFollowingPostFailed = error => {
  return {
    type: authConst.LIKE_FOLLOWING_POST_FAILED,
    payload: {
      error,
    },
  };
};
export const unlikeFollowingPost = params => {
  return {
    type: authConst.UNLIKE_FOLLOWING_POST,
    payload: {
      params,
    },
  };
};

export const unlikeFollowingPostSuccess = data => {
  return {
    type: authConst.UNLIKE_FOLLOWING_POST_SUCCESS,
    payload: {
      data,
    },
  };
};

export const unlikeFollowingPostFailed = error => {
  return {
    type: authConst.UNLIKE_FOLLOWING_POST_FAILED,
    payload: {
      error,
    },
  };
};

export const fetchPointOfUser = user_id => {
  return {
    type: authConst.FETCH_POINT_OF_USER,
    payload: {
      user_id,
    },
  };
};

export const fetchPointOfUserSuccess = data => {
  return {
    type: authConst.FETCH_POINT_OF_USER_SUCCESS,
    payload: {
      data,
    },
  };
};

export const fetchPointOfUserFailed = error => {
  return {
    type: authConst.FETCH_POINT_OF_USER_FAILED,
    payload: {
      error,
    },
  };
};

export const updatePointOfUser = new_point => {
  return {
    type: authConst.UPDATE_POINT_OF_USER,
    payload: {
      new_point,
    },
  };
};

export const updatePointOfUserSuccess = new_point => {
  return {
    type: authConst.UPDATE_POINT_OF_USER_SUCCESS,
    payload: {
      new_point,
    },
  };
};
