import React from "react";
import Loader from "components/loader";
import { Link } from "react-router-dom";
import { DOMAIN } from "constants";
import { useTranslation } from "react-i18next";
const SidebarWeather = (props) => {
  const { t } = useTranslation("translation");
  return (
    <div>
      <div className="box-bd weather" id="box-weather">
        <div className="row align-items-center py-3">
          <div className="col-4 d-block text-center p-0 wth-icon">
            {props.listWeathers.current_date && (
              <img
                src={`${DOMAIN}/images/weather_icons/${props.listWeathers.current_date.weather_status_icon}.png`}
                alt="icon-weather"
              />
            )}
          </div>
          <div className="col-8 d-sm-block">
            <Link to="/modules/thoi-tiet">
              <span className="icon-location01"></span>
            </Link>

            <small className="fw-semibold ml-1">
              {!props.current_user
                ? "Ho Chi Minh city"
                : props.current_user?.province_name !== null
                ? props.current_user?.province_name
                : "Ho Chi Minh city"}
            </small>
            <div className="row align-items-center d-flex">
              <div className="col-6">
                <Loader isLoading={props.isLoading} />
                {!props.isLoading && props.listWeathers?.current_date?.temp && (
                  <h2 className="mb-0 fn-temp">
                    {Math.round(props.listWeathers?.current_date?.temp)}
                    <sup>
                      <span className="fw-normal fs-6">o</span>
                    </sup>
                    C
                  </h2>
                )}
              </div>
              <div className="col-6">
                <div className="fs-6"></div>
                <small className="fw-semibold d-block">
                Humidity:{" "}
                  {props.listWeathers?.current_date?.humidity && Math.round(props.listWeathers?.current_date?.humidity)}
                  %
                </small>
                <span className="fst-italic">
                  {props.listWeathers?.current_date?.temp_min ? (
                    <>
                      {" "}
                      {Math.round(props.listWeathers?.current_date?.temp_min)}{" "}
                      <sup>
                        <span className="fw-normal">o</span>
                      </sup>
                      C
                    </>
                  ) : (
                    " Unknown"
                  )}{" "}
                  ~
                  {props.listWeathers?.current_date?.temp_max ? (
                    <>
                      {" "}
                      {Math.round(props.listWeathers?.current_date?.temp_max)}{" "}
                      <sup>
                        <span className="fw-normal">o</span>
                      </sup>
                      C
                    </>
                  ) : (
                    " Unknown"
                  )}
                </span>
              </div>
            </div>
          </div>
          <p className="text-capitalize mb-0">{props.listWeathers?.current_date?.weather_status}</p>
        </div>

        <Loader isLoading={props.isLoading} />
        <div className="days-lst shadow">
          {props.listWeathers.current_date &&
            props.listWeathers.current_date.current_weather_group.slice(0, 3).map(function (weather, index) {
              return (
                <div className="row box-gradient-height" key={index}>
                  <div className="col-sm d-flex box-tranform">{weather.hour}:00</div>
                  <div className="col-sm d-flex ">
                    <div className="col-sm icon-tranform">
                      {" "}
                      <img
                        src={`${DOMAIN}/images/weather_icons/${weather && weather?.weather_status_icon}.png`}
                        alt="icon-weather"
                      />
                    </div>
                    <div className="col-sm box-tranform">
                      {Math.round(weather?.temp)}
                      <sup>o</sup>C
                    </div>
                  </div>
                </div>
              );
            })}
        </div>
        <Link to="/modules/thoi-tiet" type="button" className="btn-cmore more-btn-fll w-100 shadow text-center d-block">
          <u>{t("button.forecast")}</u>
        </Link>
      </div>
    </div>
  );
};

export default React.memo(SidebarWeather);
