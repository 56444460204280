import axiosService from "services/axiosService";
import { DOMAIN } from "constants";
import qs from "query-string";

const url = "api/v2/traceabilities";

export const getTraceability = (params) => {
  let query = "";

  if (Object.keys(params).length > 0) {
    query = `?${qs.stringify(params)}`;
  }
  return axiosService.get(`${DOMAIN}/${url}${query}`, true);
};

export const deleteTrace = (id) => {
  return axiosService.delete(`${DOMAIN}/${url}/${id}`, true);
};
export const scanTraceability = (params) => {
  let query = "";
  if (Object.keys(params).length > 0) {
    query = `?${qs.stringify(params)}`;
  }
  return axiosService.get(`${DOMAIN}/${url}/scan${query}`, true);
};
export const newTraceability = (params) => {
  return axiosService.post(`${DOMAIN}/${url}`, params.formData);
};
export const getTraceabilityById = (id) => {
  return axiosService.get(`${DOMAIN}/${url}/${id}`);
};
export const editTraceability = (params, id) => {
  return axiosService.put(`${DOMAIN}/${url}/${id}`, params.formData);
};
