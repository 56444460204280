import React from "react";
import moment from "moment";
import { Link } from "react-router-dom";
import NumberFormat from "components/number_format";
import { checkPriceDiffer } from "../lib";
import DownArrowIcon from "../assets/images/down-arrow.png";
import UpArrowIcon from "../assets/images/incr-arr.png";
import EqualArrowIcon from "../assets/images/2arr-yel.png";
import { useTranslation } from "react-i18next";

moment.locale("en");

const SidebarMarket = (props) => {
  const { t } = useTranslation();
  return (
    <div className="gctt-box mkt-prod" style={{ border: "1px solid #e1e1e1" }}>
      <div className="title text-center">
        <h4 className="mb-0 fw-bold text-uppercase">{t("label.market_price")}</h4>
        <small className="primary-clr fw-semibold">
          Today, <span>{moment().format("DD.MM")}</span>
        </small>
      </div>
      <table className="table">
        <thead>
          <tr>
            <th scope="col">{t("table.product")}</th>
            <th scope="col" className="text-center">
            {t("table.price")}
            </th>
            <th scope="col" className="text-center">
            {t("table.area")}
            </th>
          </tr>
        </thead>
        <tbody>
          {props.data.map(function (market, index) {
            const priceDifference = market.get_market_price_last.price_difference | 0;
            return (
              <tr key={index}>
                <td>
                  <Link to={`/modules/thong-tin-gia-ca-thi-truong/${market.id}`}>
                    <div className="fw-semibold line-clamp-02">{market.title}</div>
                  </Link>
                </td>
                <td className="text-right">
                  <div className="fw-semibold fs-12">
                    <div>
                      <NumberFormat>{market.get_market_price_last.price}</NumberFormat>
                      <span style={{ marginLeft: "2px" }}>đ</span>
                    </div>
                  </div>
                  <span
                    className="redu-pri fs-10 "
                    style={priceDifference > 0 ? { color: "#1CC619" } : { color: "#ef4923" }}>
                    <span>
                      <img
                        src={
                          priceDifference != 0 ? (priceDifference > 0 ? UpArrowIcon : DownArrowIcon) : EqualArrowIcon
                        }
                        style={{ width: "10px", height: "10px", marginBottom: "2px" }}
                      />
                    </span>
                    {priceDifference != 0 ? (
                      <span className={`${priceDifference != 0 && "font-italic"}`}>
                        {parseInt(priceDifference) > 0 && "+"}
                        <NumberFormat>{checkPriceDiffer(priceDifference)}</NumberFormat> đ
                      </span>
                    ) : (
                      ""
                    )}
                  </span>
                </td>
                <td className="text-center text-secondary">{market.province_name}</td>
              </tr>
            );
          })}
        </tbody>
      </table>
      <Link
        onClick={(e) => moveToMarketPrice(e)}
        to={`/modules/gia-ca-thi-truong`}
        className="more-btn-fll text-center d-block">
        <u>{t("button.more")}</u>
      </Link>
    </div>
  );
};

export default SidebarMarket;
