import React, { useContext, useEffect, useState } from "react";
import { useParams, useLocation, Link } from "react-router-dom";
import { bindActionCreators } from "redux";
import { connect, useDispatch } from "react-redux";
import * as articleActions from "actions/article";
import * as postActions from "actions/post";
import parse from "html-react-parser";
import LeftSidebar from "components/left_sidebar";
import RightSidebar from "components/right_sidebar";
import { validateStringEditor } from "lib";
import { toastError, toastErrorLogin, toastSuccess } from "helpers/toastHelper";
import AddMetaTags from "components/add_meta_tags";
import ScrollToTop from "components/ScrollToTop";
import moment from "moment";
import AppContext from "components/app_context";
import AudioPlayer from "react-h5-audio-player";
import "react-h5-audio-player/lib/styles.css";
import Loader from "components/loader";
import SubComment from "./components/subComment";
import Editor from "../../components/simple_editor";
import ShareSocialGroup from "../../components/ShareSocialGroup/ShareSocialGroup";
import Comment from "./components/Comment";
import RelativeArticles from "./components/RelativeArticles.jsx";
import SharingArea from "./components/SharingArea";
import { setParamsTrackActivity } from "lib";
import { createTrackActivity } from "actions/track_activity";

function ArticleDetail(props) {
  const location = useLocation();
  const pathname = location.pathname;
  const isVideoPage = pathname.includes("/videos") ? true : false;
  const { setSearch } = useContext(AppContext);
  const search = useLocation().search;
  const audio = new URLSearchParams(search).get("audio");
  const { articleId } = useParams();
  const { articleActionsCreator, postActionsCreator, type } = props;
  const {
    fetchArticleDetailID,
    createCommentArticle,
    createFavoriteArticle,
    fetchFavoriteArticle,
    deleteFavoriteArticle,
    createLikeCommentArticle,
    createUnLikeCommentArticle,
    reportCommentArticle,
    userReplyComment,
    updateComment,
    updateChildComment,
    managerUpdateComment,
    managerUpdateChildComment,
    managerDestroyComment,
    managerDestroyChildComment,
    userDestroyComment,
    userDestroyChildComment,
    fetchCommentArticle,
    fetchArticle,
  } = articleActionsCreator;
  const { userShareArticle } = postActionsCreator;
  const [content, setContent] = useState("");
  const [contentRoot, setContentRoot] = useState("");
  const [activeSort, setActiveSort] = useState("liked");
  const [run, setRun] = useState(false);
  const { current_user, isMobile, navigate } = useContext(AppContext);
  const article = props.articleDetail;
  const { data: replyComment, isLoading: isLoadingReply } = props.replyComment;
  const { data: listArticles } = props.listArticles;
  const { data } = props.relationArticles;
  const { data: comments, isLoading } = props.commentArticles;
  const { data: favoriteArticles } = props.favoritesArticles;
  const [reportContent, setReportContent] = useState("");
  const [showModal, setShowModal] = useState(false);
  const [reportId, setReportId] = useState(null);
  const [reportType, setReportType] = useState("");
  const [isShowModalShare, setIsShowModalShare] = useState(null);
  const [showPicker, setShowPicker] = useState(false);
  const [innerString, setInnerString] = useState("");
  const [idShowChildComment, setIdShowChildComment] = useState(null);
  const [showInputBox, setShowInputBox] = useState({});
  const [numOfComment, setNumOfComment] = useState(5);
  const [loadMore, setLoadMore] = useState(false);
  const dispatch = useDispatch();

  const showReportModal = (comment_id, comment_type) => {
    if (current_user) {
      setReportId(comment_id);
      setReportType(comment_type);
      setShowModal(true);
    } else toastError("Please login first!");
  };
  const hideModal = () => setShowModal(false);
  const submitReport = (reason) => {
    if (reason === "") {
      toastError("Type your reason ");
    } else {
      reportCommentArticle({
        reason: reason,
        classable_id: reportId,
        classable_type: reportType,
      });
      setShowModal(false);
      setReportContent("");
    }
  };
  useEffect(() => {
    if (current_user) {
      fetchFavoriteArticle();
    }
    fetchArticleDetailID(articleId);
    fetchArticle({ article_type: type === "Video" ? "Video" : "Article" });
    clearEditorState();
  }, [`fetchArticleDetailID`, articleId, fetchFavoriteArticle, current_user]);

  useEffect(() => {
    if (type === "Video") {
      dispatch(
        createTrackActivity(
          setParamsTrackActivity("video detail ", "GET", {}, "videos_detail")
        )
      );
    } else {
      dispatch(
        createTrackActivity(
          setParamsTrackActivity("article ", "GET", {}, "articles_detail")
        )
      );
    }
  }, [articleId]);

  const submitComment = () => {
    if (!current_user) {
      return toastError("Please login first!");
    }
    if (!validateStringEditor(contentRoot)) {
      return toastError("Type your content");
    }

    if (validateStringEditor(contentRoot) === "too long") {
      return toastError("Do not more than  one thousand letters");
    }

    createCommentArticle({
      commentable_id: article?.id,
      commentable_type: article.classable_type,
      content: contentRoot,
    });
    clearEditorState();
  };
  const clearEditorState = () => {
    $("#editor").html("");
    setContentRoot("");
    setShowPicker(false);
    setInnerString("");
  };

  const onClickFavourite = (article_id) => {
    if (current_user === null) {
      toastErrorLogin(isMobile);
    } else {
      createFavoriteArticle({
        classable_type: "Article",
        classable_id: article_id,
      });
    }
  };

  const onclickDeleteFavoriteArticle = (id) => {
    if (current_user === null) {
      toastErrorLogin(isMobile);
    } else {
      deleteFavoriteArticle(id);
      toastError("Xóa lưu bài viết yêu thích thành công");
    }
  };
  const favoriteArticles_id =
    favoriteArticles &&
    favoriteArticles.find((article_1) => article?.id == article_1?.id);

  const actionLikeComment = (id, liked, type) => {
    if (current_user) {
      if (!liked) {
        createLikeCommentArticle({
          classable_id: id,
          classable_type: type,
          id: article.id,
          type: article.classable_type,
        });
      } else {
        createUnLikeCommentArticle({
          classable_id: id,
          classable_type: type,
          id: article.id,
          type: article.classable_type,
        });
      }
    } else toastError("Please login first!");
  };
  const handleShowReplyBox = (id, replier) => {
    if (current_user) {
      setShowInputBox({
        id,
        replier,
      });
    } else toastError("Please login first!");
  };

  const submitReplyComment = (id, content, type) => {
    const commentData = {
      id: id,
      content: content,
    };

    if (content === "") {
      toastError("Type your content");
    } else if (content.length > 1000) {
      toastError("Do not more than  one thousand letters");
    } else {
      if (type === "reply") {
        userReplyComment(commentData);
      } else {
        if (current_user.manager_type !== "member") {
          managerUpdateComment(commentData);
        } else {
          updateComment(commentData);
        }
      }
      setContent("");
      setShowInputBox(false);
    }
  };

  const deleteComment = (id, type, role) => {
    if (type === "comment") {
      if (role === "admin" || role === "smod") {
        managerDestroyComment({ id: id });
      } else {
        userDestroyComment({ id: id });
      }
    } else {
      if (role === "admin" || role === "smod") {
        managerDestroyChildComment({ id: id });
      } else {
        userDestroyChildComment({ id: id });
      }
    }
  };

  const handleLoadMore = () => {
    setNumOfComment((num) => num + 7);
    setLoadMore(true);
  };

  const sortComments = (id, type) => {
    if (type === "liked") {
      setActiveSort(type);
      fetchCommentArticle({
        classable_id: id,
        classable_type: article.classable_type,
        sort: "total_likes",
      });
    } else {
      setActiveSort(type);
      fetchCommentArticle({
        classable_id: id,
        classable_type: article.classable_type,
        sort: "created_at",
      });
    }
  };

  function nextArticle() {
    if (listArticles) {
      const index = listArticles.findIndex((object) => {
        return object.id === article.id;
      });
      const nextIndex = (index + 1) % listArticles.length;
      if (nextIndex === 0) {
        return null;
      } else {
        return listArticles[nextIndex];
      }
    }
  }

  const handleNextArticle = () => {
    let next = nextArticle();
    if (next && run) {
      navigate({
        pathname: `${type === "Video" ? "/videos/" : "/tin-tuc/"}${
          next.slug ? next.slug : next.id
        }`,
        search: run && "?audio=1&next=1",
      });
    } else if (!run) {
      return;
    } else {
      toastSuccess("Bạn đã xem hết tin tức trong danh mục");
    }
  };

  const shareArticleToHomepage = () => {
    if (current_user === null) {
      toastErrorLogin(isMobile);
    } else {
      let link = window.location.href;
      userShareArticle({
        title: link,
        post_type: "public",
        user_shared_id: current_user.id,
        shared_link: link,
        shared_link_status: 200,
      });
      setIsShowModalShare(null);
    }
  };
  const getArticleWithTags = (tag) => {
    setSearch(tag);
  };
  return (
    <ScrollToTop>
      <div className="detail-article">
        <AddMetaTags title={article?.title} />
        <div className="row">
          <LeftSidebar />
          <div className="col-md-6 col-sm-12">
            <div className="cont-center">
              <div className="cntry-news">
                <div className="d-flex justify-content-between">
                  {article && (
                    <nav aria-label="breadcrumb">
                      <ol className="breadcrumb">
                        <li className="breadcrumb-item">
                          <Link
                            to={
                              article?.classable_type === "Article"
                                ? "/tin-tuc"
                                : "/videos"
                            }
                          >
                            {article?.classable_type === "Article"
                              ? "News"
                              : "Videos"}
                          </Link>
                        </li>
                        <li
                          className="breadcrumb-item active d-flex align-items-center"
                          aria-current="page"
                        >
                          {" "}
                          <span className="icon-arrow-black"></span>{" "}
                          <span>{article?.article_catalogue_name}</span>
                        </li>
                      </ol>
                    </nav>
                  )}
                  <small className="grey-light-clr fst-italic cap articles_time d-block">
                    {moment(article?.created_at).format("DD/MM/YYYY ")}
                  </small>
                </div>
                <h3 className="mt-3">{article && parse(article.title)}</h3>
                {article?.audio_link && (
                  <div className="d-flex">
                    <AudioPlayer
                      hasDefaultKeyBindings={true}
                      src={`${article?.audio_link}`}
                      showSkipControls={false}
                      showJumpControls={false}
                      layout="horizontal-reverse"
                      showFilledVolume={false}
                      loop={false}
                      autoPlay={audio ? true : false}
                      onEnded={() => handleNextArticle()}
                    />
                    {type === "Article" && (
                      <div className="custom-control custom-switch">
                        <input
                          type="checkbox"
                          className="custom-control-input"
                          id="customSwitch1"
                          onChange={() => setRun(!run)}
                        />
                        <label
                          className="custom-control-label customSwitch1"
                          htmlFor="customSwitch1"
                          data-toggle="tooltip"
                          data-placement="top"
                          title="Chuyển sang bài tiếp theo"
                        ></label>
                      </div>
                    )}
                  </div>
                )}
                <div className="mt-3">{article && parse(article.content)}</div>
                <SharingArea
                  article={article}
                  getArticleWithTags={getArticleWithTags}
                  favoriteArticles={favoriteArticles}
                  onclickDeleteFavoriteArticle={onclickDeleteFavoriteArticle}
                  onClickFavourite={onClickFavourite}
                  favoriteArticles_id={favoriteArticles_id}
                >
                  <ShareSocialGroup
                    shareMarketPriceToHomePage={shareArticleToHomepage}
                    setMarketPlaceId={setIsShowModalShare}
                    marketPlaceId={isShowModalShare}
                    market_place={article}
                    share2Nong={true}
                  />
                </SharingArea>
              </div>
              <div className="cmt-box">
                <div className="inpt-cntn cmnt-box position-relative">
                  <div className="editor">
                    <Editor
                      component="div"
                      setShowPicker={setShowPicker}
                      showPicker={showPicker}
                      onChange={setContentRoot}
                      value={contentRoot}
                      createComment={submitComment}
                      setInnerString={setInnerString}
                      innerString={innerString}
                      placeholder="Type your comment"
                    />
                  </div>
                </div>
                <Loader isLoading={isLoading} />
                {comments.length > 0 && (
                  <div className="actions-sort-cmt d-flex mt-3">
                    <ul>
                      <li
                        className={`${
                          activeSort === "liked" && "active-sort"
                        } action-sort-like hover`}
                        onClick={() => sortComments(article.id, "liked")}
                      >
                        Favourite
                      </li>
                      <li
                        className={`${
                          activeSort === "created" && "active-sort"
                        } action-sort-new hover`}
                        onClick={() => sortComments(article.id, "created")}
                      >
                        Lastest
                      </li>
                    </ul>
                  </div>
                )}
                <div className="cmt-listing">
                  {comments
                    .slice(0, numOfComment)
                    .map(function (comment, index) {
                      return (
                        <div key={index}>
                          <Comment
                            comment={comment}
                            current_user={current_user}
                            actionLikeComment={actionLikeComment}
                            setIdShowChildComment={setIdShowChildComment}
                            handleShowReplyBox={handleShowReplyBox}
                            showReportModal={showReportModal}
                            handleLoadMore={handleLoadMore}
                            setContent={setContent}
                            content={content}
                            hideModal={hideModal}
                            showModal={showModal}
                            reportContent={reportContent}
                            setReportContent={setReportContent}
                            submitReplyComment={submitReplyComment}
                            idShowChildComment={idShowChildComment}
                            showInputBox={showInputBox}
                            submitReport={submitReport}
                            deleteComment={deleteComment}
                          >
                            <SubComment
                              id={comment.id}
                              current_user={current_user}
                              comment={comment}
                              actionLikeComment={actionLikeComment}
                              replyComment={replyComment}
                              handleShowReplyBox={handleShowReplyBox}
                              submitReport={submitReport}
                              setShowInputBox={setShowInputBox}
                              showReportModal={showReportModal}
                              hideModal={hideModal}
                              showModal={showModal}
                              showInputBox={showInputBox}
                              updateChildComment={updateChildComment}
                              managerUpdateChildComment={
                                managerUpdateChildComment
                              }
                              userReplyComment={userReplyComment}
                            />
                          </Comment>
                        </div>
                      );
                    })}
                </div>
                {!loadMore &&
                  comments.length > 5 &&
                  numOfComment <= comments.length && (
                    <span
                      className="load-more-comment-post"
                      onClick={() => handleLoadMore()}
                    >
                      More
                    </span>
                  )}
              </div>
              <RelativeArticles isVideoPage={isVideoPage} data={data} />
            </div>
          </div>
          <RightSidebar />
        </div>
      </div>
    </ScrollToTop>
  );
}

const mapStateToProps = (state) => {
  return {
    articleDetail: state.article.articleDetail,
    relationArticles: state.article.relationArticles,
    commentArticles: state.article.commentArticles,
    favoritesArticles: state.article.favorite_articles,
    replyComment: state.article.replyCommentArticle,
    listArticles: state.article.listArticles,
  };
};

const mapDispatchToProps = (dispatch) => ({
  postActionsCreator: bindActionCreators(postActions, dispatch),
  articleActionsCreator: bindActionCreators(articleActions, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(ArticleDetail);
