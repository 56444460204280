import * as marketConst from "constants/gia_ca_thi_truong";

export const fetchAllMarketPrices = params => {
    return {
        type: marketConst.FETCH_ALL_MARKET_PRICES,
        payload: {params}
    }
};

export const fetchAllMarketPricesSuccess = data => {
    return {
        type: marketConst.FETCH_ALL_MARKET_PRICES_SUCCESS,
        payload: {data}
    }
};

export const fetchAllMarketPricesFailed = error => {
    return {
        type: marketConst.FETCH_ALL_MARKET_PRICES_SUCCESS,
        payload: {error}
    }
};


export const fetchSideBarMarketPrices = params => {
    return {
        type: marketConst.FETCH_SIDEBAR_MARKET_PRICES,
        payload: {params}
    }
};

export const fetchSideBarMarketPricesSuccess = data => {
    return {
        type: marketConst.FETCH_SIDEBAR_MARKET_PRICES_SUCCESS,
        payload: {data}
    }
};

export const fetchSideBarMarketPricesFailed = error => {
    return {
        type: marketConst.FETCH_SIDEBAR_MARKET_PRICES_FAILED,
        payload: {error}
    }
};


export const fetchMarketPrices = params => {
    return {
        type: marketConst.FETCH_MARKET_PRICES,
        payload: {params}
    }
};

export const fetchMarketPricesSuccess = data => {
    return {
        type: marketConst.FETCH_MARKET_PRICES_SUCCESS,
        payload: {data}
    }
};

export const fetchMarketPricesFailed = error => {
    return {
        type: marketConst.FETCH_MARKET_PRICES_FAILED,
        payload: {error}
    }
};


export const fetchLoadMoreMarketPrices = params => {
    return {
        type: marketConst.FETCH_LOAD_MORE_MARKET_PRICES,
        payload: {params}
    }
};

export const fetchLoadMoreMarketPricesSuccess = data => {
    return {
        type: marketConst.FETCH_LOAD_MORE_MARKET_PRICES_SUCCESS,
        payload: {data}
    }
};

export const fetchLoadMoreMarketPricesFailed = error => {
    return {
        type: marketConst.FETCH_LOAD_MORE_MARKET_PRICES_FAILED,
        payload: {error}
    }
};
export const likeMarketPrice = params => {
    return {
        type: marketConst.LIKE_MARKET_PRICE,
        payload: {params}
    }
};

export const likeMarketPriceSuccess = data => {
    return {
        type: marketConst.LIKE_MARKET_PRICE_SUCCESS,
        payload: {data}
    }
};

export const dislikeMarketPrice = params => {
    return {
        type: marketConst.DISLIKE_MARKET_PRICE,
        payload: {params}
    }
};

export const dislikeMarketPriceSuccess = data => {
    return {
        type: marketConst.DISLIKE_MARKET_PRICE_SUCCESS,
        payload: {data}
    }
};

export const fetchFollowingMarketPrice = params => {
	return {
		type: marketConst.FETCH_FOLLOWING_MARKET_PRICE,
		payload: {
			params
		}
	}
}

export const fetchFollowingMarketPriceSuccess = data => {
	return {
		type: marketConst.FETCH_FOLLOWING_MARKET_PRICE_SUCCESS,
		payload: {
			data
		}
	}
}

export const fetchFollowingMarketPriceFailed = error => {
	return {
		type: marketConst.FETCH_FOLLOWING_MARKET_PRICE_FAILED,
		payload: {
			error
		}
	}
}