export const LOGIN = 'LOGIN';
export const LOGIN_SUCCESS = 'LOGIN_SUCCESS';
export const LOGIN_FAILED = 'LOGIN_FAILED';

export const LOGOUT = 'LOGOUT';
export const LOGOUT_SUCCESS = 'LOGOUT_SUCCESS';
export const LOGOUT_FAILED = 'LOGOUT_FAILED';

export const UPDATE_USER = 'UPDATE_USER';
export const UPDATE_USER_SUCCESS = 'UPDATE_USER_SUCCESS';
export const UPDATE_USER_FAILED = 'UPDATE_USER_FAILED';

export const FOLLOW_USER = 'FOLLOW_USER';
export const FOLLOW_USER_SUCCESS = 'FOLLOW_USER_SUCCESS';
export const FOLLOW_USER_FAILED = 'FOLLOW_USER_FAILED';

export const FETCH_FOLLOWING = 'FETCH_FOLLOWING';
export const FETCH_FOLLOWING_SUCCESS = 'FETCH_FOLLOWING_SUCCESS';
export const FETCH_FOLLOWING_FAILED = 'FETCH_FOLLOWING_FAILED';

export const UNFOLOW = 'UNFOLOW';
export const UNFOLOW_SUCCESS = 'UNFOLOW_SUCCESS';
export const UNFOLOW_FAILED = 'UNFOLOW_FAILED';

export const SHARE_POINT = 'SHARE_POINT';
export const SHARE_POINT_SUCCESS = 'SHARE_POINT_SUCCESS';
export const SHARE_POINT_FAILED = 'SHARE_POINT_FAILED';

export const FOLLOW_POST = 'FOLLOW_POST';
export const FOLLOW_POST_SUCCESS = 'FOLLOW_POST_SUCCESS';
export const FOLLOW_POST_FAILED = 'FOLLOW_POST_FAILED';

export const UNFOLLOW_POST = 'UNFOLLOW_POST';
export const UNFOLLOW_POST_SUCCESS = 'UNFOLLOW_POST_SUCCESS';
export const UNFOLLOW_POST_FAILED = 'UNFOLLOW_POST_FAILED';

export const SHOW_DEEP_LINK = 'SHOW_DEEP_LINK';
export const ADD_CURRENT_URL = 'ADD_CURRENT_URL';
export const ADD_CURRENT_URL_SUCCESS = 'ADD_CURRENT_URL_SUCCESS';

export const FETCH_SIDEBAR_FOLLOWING = 'FETCH_SIDEBAR_FOLLOWING';
export const FETCH_SIDEBAR_FOLLOWING_SUCCESS = 'FETCH_SIDEBAR_FOLLOWING_SUCCESS';
export const FETCH_SIDEBAR_FOLLOWING_FAILED = 'FETCH_SIDEBAR_FOLLOWING_FAILED';

export const FETCH_FOLLOW_POST = 'FETCH_FOLLOW_POST';
export const FETCH_FOLLOW_POST_SUCCESS = 'FETCH_FOLLOW_POST_SUCCESS';
export const FETCH_FOLLOW_POST_FAILED = 'FETCH_FOLLOW_POST_FAILED';

export const FETCH_INFO_USER = 'FETCH_INFO_USER';
export const FETCH_INFO_USER_SUCCESS = 'FETCH_INFO_USER_SUCCESS';
export const FETCH_INFO_USER_FAILED = 'FETCH_INFO_USER_FAILED';

export const UPDATE_POINT_USER = 'UPDATE_POINT_USER';

export const UNFOLLOW_FAVORITES_POST = 'UNFOLLOW_FAVORITES_POST';
export const UNFOLLOW_FAVORITES_POST_SUCCESS = 'UNFOLLOW_FAVORITES_POST_SUCCESS';
export const UNFOLLOW_FAVORITES_POST_FAILED = 'UNFOLLOW_FAVORITES_POST_FAILED';

export const LIKE_FOLLOWING_POST = 'LIKE_FOLLOWING_POST';
export const LIKE_FOLLOWING_POST_SUCCESS = 'LIKE_FOLLOWING_POST_SUCCESS';
export const LIKE_FOLLOWING_POST_FAILED = 'LIKE_FOLLOWING_POST_FAILED';

export const UNLIKE_FOLLOWING_POST = 'UNLIKE_FOLLOWING_POST';
export const UNLIKE_FOLLOWING_POST_SUCCESS = 'UNLIKE_FOLLOWING_POST_SUCCESS';
export const UNLIKE_FOLLOWING_POST_FAILED = 'UNLIKE_FOLLOWING_POST_FAILED';

export const FETCH_POINT_OF_USER = 'FETCH_POINT_OF_USER';
export const FETCH_POINT_OF_USER_SUCCESS = 'FETCH_POINT_OF_USER_SUCCESS';
export const FETCH_POINT_OF_USER_FAILED = 'FETCH_POINT_OF_USER_FAILED';


export const UPDATE_POINT_OF_USER = 'UPDATE_POINT_OF_USER';
export const UPDATE_POINT_OF_USER_SUCCESS = 'UPDATE_POINT_OF_USER_SUCCESS';
export const UPDATE_POINT_OF_USER_FAILED = 'UPDATE_POINT_OF_USER_FAILED';


