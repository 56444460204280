import axiosService from 'services/axiosService';
import { DOMAIN } from 'constants';
import { get_user } from "lib";

const url = 'api/v2/calls';
export const createCall = (data) => {
  let current_user = get_user();
  axiosService.setHeader('Authorization', `Bearer ${current_user.token_user}`);
  return axiosService.post(`${DOMAIN}/${url}`, data);
};
