export const FETCH_TRACEABILITY = "FETCH_TRACEABILITY";
export const FETCH_TRACEABILITY_SUCCESS = "FETCH_TRACEABILITY_SUCCESS";
export const FETCH_TRACEABILITY_FAILED = "FETCH_TRACEABILITY_FAILED";

export const DELETE_TRACEABILITY = "DELETE_TRACEABILITY";
export const DELETE_TRACEABILITY_SUCCESS = "DELETE_TRACEABILITY_SUCCESS";
export const DELETE_TRACEABILITY_FAILED = "DELETE_TRACEABILITY_FAILED";

export const SCAN_TRACEABILITY = "SCAN_TRACEABILITY";
export const SCAN_TRACEABILITY_SUCCESS = "SCAN_TRACEABILITY_SUCCESS";
export const SCAN_TRACEABILITY_FAILED = "SCAN_TRACEABILITY_FAILED";

export const NEW_TRACEABILITY = "NEW_TRACEABILITY";
export const NEW_TRACEABILITY_SUCCESS = "NEW_TRACEABILITY_SUCCESS";
export const NEW_TRACEABILITY_FAILED = "SCAN_TRACEABILITY_FAILED";

export const FETCH_TRACEABILITY_DETAIL = "FETCH_TRACEABILITY_DETAIL";
export const FETCH_TRACEABILITY_DETAIL_SUCCESS = "FETCH_TRACEABILITY_DETAIL_SUCCESS";
export const FETCH_TRACEABILITY_DETAIL_FAILED = "FETCH_TRACEABILITY_DETAIL_FAILED";
