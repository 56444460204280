import React from "react";

function Material({
  setIngredientLand,
  setIngredientQuantity,
  setIngredientAddress,
  setIngredientDescription,
  ingredientImage,
  ingredientLand,
  ingredientQuantity,
  ingredientAddress,
  ingredientDescription,
  uploadImage,
  removeImage,
}) {
  return (
    <>
      <div className="row">
        <div className="traceability-form mt-2">
          <div className="row">
            <label>Diện tích</label>
            <input
              className="traceability-input"
              type="text"
              placeholder="Diện tích"
              value={ingredientLand}
              onChange={(e) => setIngredientLand(e.target.value)}
            />
          </div>
          <div className="row">
            <label>Sản lượng thu hoạch</label>
            <input
              className="traceability-input"
              type="text"
              placeholder="Sản lượng thu hoạch"
              value={ingredientQuantity}
              onChange={(e) => setIngredientQuantity(e.target.value)}
            />
          </div>
          <div className="row">
            <label>Địa chỉ</label>
            <input
              className="traceability-input"
              type="text"
              placeholder="Địa chỉ"
              value={ingredientAddress}
              onChange={(e) => setIngredientAddress(e.target.value)}
            />
          </div>
          <div className="row">
            <div className="d-flex justify-content-between">
              <label htmlFor="myFile" className="text-uppercase mb-2">
                Hình ảnh canh tác
              </label>
              <span
                onChange={(e) => {
                  uploadImage(e);
                }}>
                <span className="icon-camera" />
              </span>
            </div>
            {ingredientImage.length > 0 &&
              ingredientImage.map((file, index) => (
                <div>
                  <img
                    key={index}
                    src={URL.createObjectURL(file)}
                    alt="avatar"
                    className="traceaility-image position-relative"
                  />
                  <button
                    className="btn remove-img position-absolute bg-secondary"
                    onClick={() => removeImage(file, "ingredient")}>
                    x
                  </button>
                </div>
              ))}
            <input
              type="file"
              id="myfile"
              name="myfile"
              lang="vi"
              multiple
              style={{ height: "45px" }}
              className="form-control"
              onChange={(e) => {
                uploadImage(e, "ingredient");
              }}
            />
          </div>
          <div className="row">
            <div className=" d-flex align-items-center traceability-subtitle">Thông tin canh tác</div>
          </div>
          {/*}
          <div className="traceability-title">Quá trình canh tác</div>
          <div className="row">
            <select disabled>
              <option className="placeholder" value="default" hidden>
                Chọn từ canh tác
              </option>
              <option value="1">1</option>
              <option value="2">2</option>
              <option value="3">3</option>
            </select>
          </div>
            */}
          <div className="row">
            <label htmlFor="description">Mô tả</label>
            <textarea
              className="traceability-input"
              name="description"
              type="text"
              placeholder="Mô tả"
              value={ingredientDescription ?? ""}
              onChange={(e) => setIngredientDescription(e.target.value)}
            />
          </div>
        </div>
      </div>
    </>
  );
}
export default Material;
