import qs from "query-string";
import axiosService from "services/axiosService";
import { DOMAIN, PAGE, PER_PAGE } from "constants";
import { get_user } from "lib";

const url = "api/v2/products";
export const getProducts = (params) => {
  let query = "";

  if (Object.keys(params).length > 0) {
    query = `?${qs.stringify(params)}`;
  }
  return axiosService.get(`${DOMAIN}/${url}${query}`);
};

export const getProductsByUser = (params) => {
  let query = "";

  if (Object.keys(params).length > 0) {
    query = `?${qs.stringify(params)}`;
  }
  return axiosService.get(`${DOMAIN}/${url}/list_products${query}`);
};
export const getlistUnits = () => {
  return axiosService.get(`${DOMAIN}/${url}/list_units`);
};
export const getUserProducts = (params = { page: PAGE, limit: PER_PAGE }) => {
  let query = "";

  if (Object.keys(params).length > 0) {
    query = `?${qs.stringify(params)}`;
  }
  return axiosService.get(`${DOMAIN}/${url}/current_products${query}`, true);
};

export const getProductId = (params) => {
  let current_user = get_user();
  let isAuth = current_user !== null ? true : false;
  return axiosService.get(`${DOMAIN}/${url}/${params}`, isAuth);
};

export const createProductByOwner = (params) => {
  let current_user = get_user();
  let isAuth = current_user !== null ? true : false;
  return axiosService.post(`${DOMAIN}/${url}`, params, isAuth);
};

export const deleteProduct = (params) => {
  let current_user = get_user();
  let isAuth = current_user !== null ? true : false;
  return axiosService.delete(`${DOMAIN}/${url}/${params}`, isAuth);
};

export const updateProductByOwner = (params, id) => {
  let current_user = get_user();
  let isAuth = current_user !== null ? true : false;
  return axiosService.put(`${DOMAIN}/${url}/${id}`, params, isAuth);
};

const highlight_url = "api/v2/markets/highlight_products";
export const getHighlightProducts = (params = { page: PAGE, limit: PER_PAGE }) => {
  let query = "";

  if (Object.keys(params).length > 0) {
    query = `?${qs.stringify(params)}`;
  }
  return axiosService.get(`${DOMAIN}/${highlight_url}${query}`);
};

const url_account = "api/v2/account";
export const getFavoritesProducts = (params) => {
  return axiosService.get(`${DOMAIN}/${url_account}/list_favourites`, true);
};

export const favoriteProduct = (params) => {
  return axiosService.post(`${DOMAIN}/${url_account}/create_favourite`, params);
};

export const deleteFavoriteProduct = (id) => {
  return axiosService.post(`${DOMAIN}/${url_account}/favourite/${id}`);
};

export const likeCommentProduct = (params) => {
  // like comment or sub comment
  return axiosService.post(`${DOMAIN}/${url_account}/like_item`, params);
};

export const unLikeCommentProduct = (params) => {
  // unlike comment or sub comment
  return axiosService.post(`${DOMAIN}/${url_account}/unlike`, params);
};
