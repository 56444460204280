export const FETCH_PRODUCT = "FETCH_PRODUCT";
export const FETCH_PRODUCT_SUCCESS = "FETCH_PRODUCT_SUCCESS";
export const FETCH_PRODUCT_FAILED = "FETCH_PRODUCT_FAILED";

export const FETCH_SIDEBAR_PRODUCT = "FETCH_SIDEBAR_PRODUCT";
export const FETCH_SIDEBAR_PRODUCT_SUCCESS = "FETCH_SIDEBAR_PRODUCT_SUCCESS";
export const FETCH_SIDEBAR_PRODUCT_FAILED = "FETCH_SIDEBAR_PRODUCT_FAILED";

export const FETCH_PRODUCT_DETAIL_ID = "FETCH_PRODUCT_DETAIL_ID";
export const FETCH_PRODUCT_DETAIL_ID_SUCCESS = "FETCH_PRODUCT_DETAIL_ID_SUCCESS";
export const FETCH_PRODUCT_DETAIL_ID_FAILED = "FETCH_PRODUCT_DETAIL_ID_FAILED";

export const FETCH_HIGHLIGHT_PRODUCT = "FETCH_HIGHLIGHT_PRODUCT";
export const FETCH_HIGHLIGHT_PRODUCT_SUCCESS = "FETCH_HIGHLIGHT_PRODUCT_SUCCESS";
export const FETCH_HIGHLIGHT_PRODUCT_FAILED = "FETCH_HIGHLIGHT_PRODUCT_FAILED";

export const FETCH_USER_PRODUCT = "FETCH_USER_PRODUCT";
export const FETCH_USER_PRODUCT_SUCCESS = "FETCH_USER_PRODUCT_SUCCESS";
export const FETCH_USER_PRODUCT_FAILED = "FETCH_USER_PRODUCT_FAILED";

export const LOAD_MORE_PRODUCT = "LOAD_MORE_PRODUCT";
export const LOAD_MORE_PRODUCT_SUCCESS = "LOAD_MORE_PRODUCT_SUCCESS";
export const LOAD_MORE_PRODUCT_FAILED = "LOAD_MORE_PRODUCT_FAILED";

export const FETCH_RELATION_PRODUCT = "FETCH_RELATION_PRODUCT";
export const FETCH_RELATION_PRODUCT_SUCCESS = "FETCH_RELATION_PRODUCT_SUCCESS";
export const FETCH_RELATION_PRODUCT_FAILED = "FETCH_RELATION_PRODUCT_FAILED";

export const FETCH_PRODUCT_BY_CATALOGUE = "FETCH_PRODUCT_BY_CATALOGUE";
export const FETCH_PRODUCT_BY_CATALOGUE_SUCCESS = "FETCH_PRODUCT_BY_CATALOGUE_SUCCESS";
export const FETCH_PRODUCT_BY_CATALOGUE_FAILED = "FETCH_PRODUCT_BY_CATALOGUE_FAILED";

export const FETCH_PRODUCT_COMMENT = "FETCH_PRODUCT_COMMENT";
export const FETCH_PRODUCT_COMMENT_SUCCESS = "FETCH_PRODUCT_COMMENT_SUCCESS";
export const FETCH_PRODUCT_COMMENT_FAILED = "FETCH_PRODUCT_COMMENT_FAILED";

export const CREATE_PRODUCT_COMMENT = "CREATE_PRODUCT_COMMENT";
export const CREATE_PRODUCT_COMMENT_SUCCESS = "CREATE_PRODUCT_COMMENT_SUCCESS";
export const CREATE_PRODUCT_COMMENT_FAILED = "CREATE_PRODUCT_COMMENT_FAILED";

export const FETCH_FAVORITES_PRODUCT = "FETCH_FAVORITES_PRODUCT";
export const FETCH_FAVORITES_PRODUCT_SUCCESS = "FETCH_FAVORITES_PRODUCT_SUCCESS";
export const FETCH_FAVORITES_PRODUCT_FAILED = "FETCH_FAVORITES_PRODUCT_FAILED";

export const FETCH_CATALOGUE_OF_PRODUCT = "FETCH_CATALOGUE_OF_PRODUCT";
export const FETCH_CATALOGUE_OF_PRODUCT_SUCCESS = "FETCH_CATALOGUE_OF_PRODUCT_SUCCESS";
export const FETCH_CATALOGUE_OF_PRODUCT_FAILED = "FETCH_CATALOGUE_OF_PRODUCT_FAILED";

export const FETCH_CHILD_CATALOGUE_OF_PRODUCT = "FETCH_CHILD_CATALOGUE_OF_PRODUCT";
export const FETCH_CHILD_CATALOGUE_OF_PRODUCT_SUCCESS = "FETCH_CHID_CATALOGUE_OF_PRODUCT_SUCCESS";
export const FETCH_CHILD_CATALOGUE_OF_PRODUCT_FAILED = "FETCH_CHILD_CATALOGUE_OF_PRODUCT_FAILED";

export const CREATE_FAVORITE_PRODUCT = "CREATE_FAVORITE_PRODUCT";
export const CREATE_FAVORITE_PRODUCT_SUCCESS = "CREATE_FAVORITE_PRODUCT_SUCCESS";
export const CREATE_FAVORITE_PRODUCT_FAILED = "CREATE_FAVORITE_PRODUCT_FAILED";

export const DELETE_FAVORITE_PRODUCT = "DELETE_FAVORITE_PRODUCT";
export const DELETE_FAVORITE_PRODUCT_SUCCESS = "DELETE_FAVORITE_PRODUCT_SUCCESS";
export const DELETE_FAVORITE_PRODUCT_FAILED = "DELETE_FAVORITE_PRODUCT_FAILED";

export const FETCH_PRODUCT_BY_USER = "FETCH_PRODUCT_BY_USER";
export const FETCH_PRODUCT_BY_USER_SUCCESS = "FETCH_PRODUCT_BY_USER_SUCCESS";
export const FETCH_PRODUCT_BY_USER_FAILED = "FETCH_PRODUCT_BY_USER_FAILED";

export const LIKE_COMMENT_PRODUCT = "LIKE_COMMENT_PRODUCT";
export const LIKE_COMMENT_PRODUCT_SUCCESS = "LIKE_COMMENT_PRODUCT_SUCCESS";
export const LIKE_COMMENT_PRODUCT_FAILED = "LIKE_COMMENT_PRODUCT_FAILED";

export const UNLIKE_COMMENT_PRODUCT = "UNLIKE_COMMENT_PRODUCT";
export const UNLIKE_COMMENT_PRODUCT_SUCCESS = "UNLIKE_COMMENT_PRODUCT_SUCCESS";
export const UNLIKE_COMMENT_PRODUCT_FAILED = "UNLIKE_COMMENT_PRODUCT_FAILED";

export const REPORT_COMMENT_PRODUCT = "REPORT_COMMENT_PRODUCT";
export const REPORT_COMMENT_PRODUCT_SUCCESS = "REPORT_COMMENT_PRODUCT_SUCCESS";
export const REPORT_COMMENT_PRODUCT_FAILED = "REPORT_COMMENT_PRODUCT_FAILED";

export const REPLY_COMMENT_PRODUCT = "REPLY_COMMENT_PRODUCT";
export const REPLY_COMMENT_PRODUCT_SUCCESS = "REPLY_COMMENT_PRODUCT_SUCCESS";
export const REPLY_COMMENT_PRODUCT_FAILED = "REPLY_COMMENT_PRODUCT_FAILED";

export const FETCH_ANSWER_COMMENT = "FETCH_ANSWER_COMMENT";
export const FETCH_ANSWER_COMMENT_SUCCESS = "FETCH_ANSWER_COMMENT_SUCCESS";
export const FETCH_ANSWER_COMMENT_FAILED = "FETCH_ANSWER_COMMENT_FAILED";

export const UPDATE_COMMENT_PRODUCT = "UPDATE_COMMENT_PRODUCT";
export const UPDATE_COMMENT_PRODUCT_SUCCESS = "UPDATE_COMMENT_PRODUCT_SUCCESS";
export const UPDATE_COMMENT_FAILED = "UPDATE_COMMENT_FAILED";

export const UPDATE_CHILD_COMMENT = "UPDATE_CHILD_COMMENT";
export const UPDATE_CHILD_COMMENT_SUCCESS = "UPDATE_CHILD_COMMENT_SUCCESS";
export const UPDATE_CHILD_COMMENT_FAILED = "UPDATE_CHILD_COMMENT_FAILED";

export const MANAGER_UPDATE_COMMENT = "MANAGER_UPDATE_COMMENT";
export const MANAGER_UPDATE_COMMENT_SUCCESS = "MANAGER_UPDATE_COMMENT_SUCCESS";
export const MANAGER_UPDATE_COMMENT_FAILED = "MANAGER_UPDATE_COMMENT_FAILED";

export const MANAGER_UPDATE_CHILD_COMMENT = "MANAGER_UPDATE_CHILD_COMMENT";
export const MANAGER_UPDATE_CHILD_COMMENT_SUCCESS = "MANAGER_UPDATE_CHILD_COMMENT_SUCCESS";
export const MANAGER_UPDATE_CHILD_COMMENT_FAILED = "MANAGER_UPDATE_CHILD_COMMENT_FAILED";

export const DESTROY_COMMENT = "DESTROY_COMMENT";
export const DESTROY_COMMENT_SUCCESS = "DESTROY_COMMENT_SUCCESS";
export const DESTROY_COMMENT_FAILED = "DESTROY_COMMENT_FAILED";

export const DESTROY_CHILD_COMMENT = "DESTROY_CHILD_COMMENT";
export const DESTROY_CHILD_COMMENT_SUCCESS = "DESTROY_CHILD_COMMENT_SUCCESS";
export const DESTROY_CHILD_COMMENT_FAILED = "DESTROY_CHILD_COMMENT_FAILED";

export const MANAGER_DELETE_COMMENT = "MANAGER_DELETE_COMMENT";
export const MANAGER_DELETE_COMMENT_SUCCESS = "MANAGER_DELETE_COMMENT_SUCCESS";
export const MANAGER_DELETE_COMMENT_FAILED = "MANAGER_DELETE_COMMENT_FAILED";

export const MANAGER_DELETE_SUB_COMMENT = "MANAGER_DELETE_SUB_COMMENT";
export const MANAGER_DELETE_SUB_COMMENT_SUCCESS = "MANAGER_DELETE_SUB_COMMENT_SUCCESS";
export const MANAGER_DELETE_SUB_COMMENT_FAILED = "MANAGER_DELETE_SUB_COMMENT_FAILED";

export const LOAD_MORE_COMMENT = "LOAD_MORE_COMMENT";
export const LOAD_MORE_COMMENT_SUCCESS = "LOAD_MORE_COMMENT_SUCCESS";
export const LOAD_MORE_COMMENT_FAILED = "LOAD_MORE_COMMENT_FAILED";

export const CREATE_PRODUCT = "CREATE_PRODUCT";
export const CREATE_PRODUCT_SUCCESS = "CREATE_PRODUCT_SUCCESS";

export const UPDATE_PRODUCT = "UPDATE_PRODUCT";
export const UPDATE_PRODUCT_SUCCESS = "UPDATE_PRODUCT_SUCCESS";

export const DELETE_PRODUCT = "DELETE_PRODUCT";
export const DELETE_PRODUCT_SUCCESS = "DELETE_PRODUCT_SUCCESS";

export const FETCH_PARENT_CATALOG = "FETCH_PARENT_CATALOG";
export const FETCH_PARENT_CATALOG_SUCCESS = "FETCH_PARENT_CATALOG_SUCCESS";
