import React, { useState, useEffect } from "react";
import LeftSidebar from "components/left_sidebar";
import AddMetaTags from "components/add_meta_tags";
import { toastError, toastSuccess } from "helpers/toastHelper";
import { contributeTechnical } from "apis/technical_process";
import { fetchCatalogueTechnicalProcess } from "redux/actions/technical_process";
import { useDispatch, useSelector } from "react-redux";
function ContributeTechnicalProcess(props) {
  const dispatch = useDispatch();
  const [title, setTitle] = useState("");
  const [content, setContent] = useState("");
  const [categoryId, setCategoryId] = useState(null);
  const [fileImage, setFileImage] = useState("");
  const [urlImage, setUrlImage] = useState("");
  const [isSending, setIsSending] = useState(false);
  const listCatalog = useSelector((state) => state.technicalProcess.listCatalogueTechnicalProcess.data);
  const fieldParams = {
    title: title,
    content: content,
    technical_process_catalogue_id: categoryId,
    "attachment[file][]": fileImage,
  };
  useEffect(() => {
    dispatch(fetchCatalogueTechnicalProcess());
  }, []);

  const uploadImage = (e) => {
    let file = e.target.files[0];
    if (!file.type.includes("image")) {
      return toastError("Vui lòng chỉ chọn ảnh");
    }
    let url = URL.createObjectURL(file);
    setFileImage(file);
    setUrlImage(url);
  };

  const resetState = () => {
    setTitle("");
    setContent("");
    setCategoryId(null);
    setFileImage("");
    setUrlImage("");
    setIsSending(false);
  };

  const sendData = (fieldParams) => {
    if (!Object.values(fieldParams).every(Boolean)) {
      return toastError("Vui lòng nhập đủ thông tin");
    }
    const formData = new FormData();
    for (const key in fieldParams) {
      formData.append(key, fieldParams[key]);
    }
    setIsSending(true);
    contributeTechnical({ formData: formData }).then((res) => {
      if (res) {
        toastSuccess("Đóng góp thành công");
        resetState();
      }
    });
  };

  return (
    <>
      <AddMetaTags title={"Đóng góp quy trình kĩ thuật"} />
      <div className="row">
        <LeftSidebar />
        <div className="col-md-9 col-sm-12">
          <div className="container traceability-home">
            <div className="row">
              <h5> Đóng góp quy trình kĩ thuật</h5>
            </div>
            <div className="col-9 mx-auto mt-3">
              <div className="traceability-form">
                <div className="row">
                  <label htmlFor="title">
                    Nhập tiêu đề<span className="red-clr">*</span>
                  </label>
                  <input
                    className="traceability-input"
                    name="title"
                    type="text"
                    placeholder="Nhập tiêu đề"
                    required
                    value={title}
                    onChange={(e) => setTitle(e.target.value)}
                  />
                </div>

                <div className="row">
                  <label htmlFor="content">
                    Type your content<span className="red-clr">*</span>
                  </label>
                  <textarea
                    className="traceability-input"
                    name="content"
                    type="text"
                    placeholder="Type your content"
                    value={content}
                    onChange={(e) => setContent(e.target.value)}
                  />
                </div>

                <div className="row">
                  <label>
                    Loại quy trình
                    <span className="red-clr">*</span>
                  </label>

                  <select value={categoryId || "default"} onChange={(e) => setCategoryId(e.target.value)}>
                    <option className="placeholder" value="default" hidden>
                      Chọn loại quy trình
                    </option>
                    {listCatalog?.map((item) => {
                      return (
                        <option key={item.id} value={item.id}>
                          {item.fullname}
                        </option>
                      );
                    })}
                  </select>
                </div>
                <div className="row">
                  <div className="d-flex justify-content-between">
                    <label htmlFor="myFile" className="mb-2">
                      Hình ảnh đóng góp<span className="red-clr">*</span>
                    </label>

                    <span className="icon-camera" />
                  </div>
                  {urlImage && <img src={urlImage} alt="dong-gop-quy-trinh-ki-thuat-hinh-anh" />}
                  <input
                    type="file"
                    id="myfile"
                    name="myfile"
                    lang="vi"
                    style={{ height: "45px" }}
                    value=""
                    className="form-control mt-2"
                    onChange={(e) => {
                      uploadImage(e);
                    }}
                  />
                </div>
                <div className="row">
                  <button
                    type="button"
                    className="save-btn btn-lg btn-block code-box"
                    onClick={() => sendData(fieldParams)}
                    disabled={isSending}
                    style={{ backgroundColor: isSending ? "#ccc" : null }}
                  >
                    {isSending ? "Đang gửi" : "Đóng góp"}
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
export default ContributeTechnicalProcess;
