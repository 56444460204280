import React from 'react';
import ScrollToTop from 'components/ScrollToTop';
import AddMetaTags from 'components/add_meta_tags';
import LeftSidebar from 'components/left_sidebar';
import ListProduct from './Components/ListProduct';
import UserInformation from './Components/UserInformation';
import { useCart } from './Context';

function Cart() {
  const {listProductInCarts} = useCart();
  return (
    <>
      <ScrollToTop>
        <AddMetaTags title={"Giỏ hàng"} />
          <div className='cart'>
            <div className='row'>
              <LeftSidebar />
              <div className="col-md-9 col-sm-12">
                <div className="bg-white border rounded-3">
                  <section className='w-100 p-4 table-responsive'>
                  <ListProduct listProductInCarts={listProductInCarts}/>
                  <UserInformation />
                  </section>
                </div>
              </div>
            </div>
          </div>
      </ScrollToTop>
    </>
  );
}

export default Cart;