import * as productConst from "constants/product";

export const fetchProduct = (params) => {
  return {
    type: productConst.FETCH_PRODUCT,
    payload: {
      params,
    },
  };
};

export const fetchProductSuccess = (data) => {
  return {
    type: productConst.FETCH_PRODUCT_SUCCESS,
    payload: {
      data,
    },
  };
};

export const fetchProductFailed = (error) => {
  return {
    type: productConst.FETCH_PRODUCT_FAILED,
    payload: {
      error,
    },
  };
};

export const fetchHighlightProduct = (params) => {
  return {
    type: productConst.FETCH_HIGHLIGHT_PRODUCT,
    payload: {
      params,
    },
  };
};

export const fetchHighlightProductSuccess = (data) => {
  return {
    type: productConst.FETCH_HIGHLIGHT_PRODUCT_SUCCESS,
    payload: {
      data,
    },
  };
};

export const fetchHighlightProductFailed = (error) => {
  return {
    type: productConst.FETCH_HIGHLIGHT_PRODUCT_FAILED,
    payload: {
      error,
    },
  };
};

export const fetchSidebarProduct = (params) => {
  return {
    type: productConst.FETCH_SIDEBAR_PRODUCT,
    payload: {
      params,
    },
  };
};

export const fetchSidebarProductSuccess = (data) => {
  return {
    type: productConst.FETCH_SIDEBAR_PRODUCT_SUCCESS,
    payload: {
      data,
    },
  };
};

export const fetchSidebarProductFailed = (error) => {
  return {
    type: productConst.FETCH_SIDEBAR_PRODUCT_FAILED,
    payload: {
      error,
    },
  };
};

export const fetchLoadMoreProduct = (params) => {
  return {
    type: productConst.LOAD_MORE_PRODUCT,
    payload: {
      params,
    },
  };
};

export const fetchLoadMoreProductSuccess = (data) => {
  return {
    type: productConst.LOAD_MORE_PRODUCT_SUCCESS,
    payload: {
      data,
    },
  };
};

export const fetchLoadMoreProductFailed = (error) => {
  return {
    type: productConst.LOAD_MORE_PRODUCT_FAILED,
    payload: {
      error,
    },
  };
};

export const fetchProductID = (id) => {
  return {
    type: productConst.FETCH_PRODUCT_DETAIL_ID,
    payload: {
      id,
    },
  };
};

export const fetchProductIDSuccess = (data) => {
  return {
    type: productConst.FETCH_PRODUCT_DETAIL_ID_SUCCESS,
    payload: {
      data,
    },
  };
};

export const fetchProductIDFailed = (error) => {
  return {
    type: productConst.FETCH_PRODUCT_DETAIL_ID_FAILED,
    payload: {
      error,
    },
  };
};

export const fetchUserProduct = (params) => {
  return {
    type: productConst.FETCH_USER_PRODUCT,
    payload: {
      params,
    },
  };
};

export const fetchUserProductSuccess = (data) => {
  return {
    type: productConst.FETCH_USER_PRODUCT_SUCCESS,
    payload: {
      data,
    },
  };
};

export const fetchUserProductFailed = (error) => {
  return {
    type: productConst.FETCH_USER_PRODUCT_FAILED,
    payload: {
      error,
    },
  };
};

export const fetchRelationProduct = (params) => {
  return {
    type: productConst.FETCH_RELATION_PRODUCT,
    payload: {
      params,
    },
  };
};

export const fetchRelationProductSuccess = (data) => {
  return {
    type: productConst.FETCH_RELATION_PRODUCT_SUCCESS,
    payload: {
      data,
    },
  };
};

export const fetchRelationProductFailed = (error) => {
  return {
    type: productConst.FETCH_RELATION_PRODUCT_FAILED,
    payload: {
      error,
    },
  };
};

export const fetchProductByCatalogue = (params) => {
  return {
    type: productConst.FETCH_PRODUCT_BY_CATALOGUE,
    payload: {
      params,
    },
  };
};

export const fetchProductByCatalogueSuccess = (data) => {
  return {
    type: productConst.FETCH_PRODUCT_BY_CATALOGUE_SUCCESS,
    payload: {
      data,
    },
  };
};

export const fetchProductByCatalogueFailed = (error) => {
  return {
    type: productConst.FETCH_PRODUCT_BY_CATALOGUE_FAILED,
    payload: {
      error,
    },
  };
};

export const fetchCommentProduct = (params) => {
  return {
    type: productConst.FETCH_PRODUCT_COMMENT,
    payload: {
      params,
    },
  };
};

export const fetchCommentProductSuccess = (data) => {
  return {
    type: productConst.FETCH_PRODUCT_COMMENT_SUCCESS,
    payload: {
      data,
    },
  };
};

export const fetchCommentProductFailed = (error) => {
  return {
    type: productConst.FETCH_PRODUCT_COMMENT_FAILED,
    payload: {
      error,
    },
  };
};

export const createCommentProduct = (params) => {
  return {
    type: productConst.CREATE_PRODUCT_COMMENT,
    payload: {
      params,
    },
  };
};

export const createCommentProductSuccess = (data) => {
  return {
    type: productConst.CREATE_PRODUCT_COMMENT_SUCCESS,
    payload: {
      data,
    },
  };
};

export const createCommentProductFailed = (error) => {
  return {
    type: productConst.CREATE_PRODUCT_COMMENT_FAILED,
    payload: {
      error,
    },
  };
};

export const fetchFavoritesProduct = (params) => {
  return {
    type: productConst.FETCH_FAVORITES_PRODUCT,
    payload: {
      params,
    },
  };
};

export const fetchFavoritesProductSuccess = (data) => {
  return {
    type: productConst.FETCH_FAVORITES_PRODUCT_SUCCESS,
    payload: {
      data,
    },
  };
};

export const fetchFavoritesProductFailed = (error) => {
  return {
    type: productConst.FETCH_FAVORITES_PRODUCT_FAILED,
    payload: {
      error,
    },
  };
};

export const fetchCatalogueOfProduct = (params) => {
  return {
    type: productConst.FETCH_CATALOGUE_OF_PRODUCT,
    payload: {
      params,
    },
  };
};

export const fetchCatalogueOfProductSuccess = (data) => {
  return {
    type: productConst.FETCH_CATALOGUE_OF_PRODUCT_SUCCESS,
    payload: {
      data,
    },
  };
};

export const fetchCatalogueOfProductFailed = (error) => {
  return {
    type: productConst.FETCH_CATALOGUE_OF_PRODUCT_FAILED,
    payload: {
      error,
    },
  };
};

export const fetchChildCatalogue = (params) => ({
  type: productConst.FETCH_CHILD_CATALOGUE_OF_PRODUCT,
  payload: params,
});
export const fetchChildCatalogueSuccess = (data) => ({
  type: productConst.FETCH_CHILD_CATALOGUE_OF_PRODUCT_SUCCESS,
  payload: data,
});
export const fetchChildCatalogueFailed = (error) => ({
  type: productConst.FETCH_CHILD_CATALOGUE_OF_PRODUCT_FAILED,
  payload: error,
});
export const fetchParentCatalogue = (parent_id) => {
  return {
    type: productConst.FETCH_PARENT_CATALOG,
    payload: {
      parent_id,
    },
  };
};
export const fetchParentCatalogueSuccess = (data) => {
  return {
    type: productConst.FETCH_PARENT_CATALOG_SUCCESS,
    payload: {
      data,
    },
  };
};
export const createFavoriteProduct = (params) => {
  return {
    type: productConst.CREATE_FAVORITE_PRODUCT,
    payload: {
      params,
    },
  };
};

export const createFavoriteProductSuccess = (data) => {
  return {
    type: productConst.CREATE_FAVORITE_PRODUCT_SUCCESS,
    payload: {
      data,
    },
  };
};

export const createFavoriteProductFailed = (error) => {
  return {
    type: productConst.CREATE_FAVORITE_PRODUCT_FAILED,
    payload: {
      error,
    },
  };
};

export const deleteFavoriteProduct = (params) => {
  return {
    type: productConst.DELETE_FAVORITE_PRODUCT,
    payload: {
      params,
    },
  };
};

export const deleteFavoriteProductSuccess = (data) => {
  return {
    type: productConst.DELETE_FAVORITE_PRODUCT_SUCCESS,
    payload: {
      data,
    },
  };
};

export const deleteFavoriteProductFailed = (error) => {
  return {
    type: productConst.DELETE_FAVORITE_PRODUCT_FAILED,
    payload: {
      error,
    },
  };
};

export const fetchProductsByUser = (params) => {
  return {
    type: productConst.FETCH_PRODUCT_BY_USER,
    payload: {
      params,
    },
  };
};

export const fetchProductsByUserSuccess = (data) => {
  return {
    type: productConst.FETCH_PRODUCT_BY_USER_SUCCESS,
    payload: {
      data,
    },
  };
};

export const fetchProductsByUserFailed = (error) => {
  return {
    type: productConst.FETCH_PRODUCT_BY_USER_FAILED,
    payload: {
      error,
    },
  };
};

export const likeCommentProduct = (params) => {
  return {
    type: productConst.LIKE_COMMENT_PRODUCT,
    payload: {
      params,
    },
  };
};

export const likeCommentProductSuccess = (data) => {
  return {
    type: productConst.LIKE_COMMENT_PRODUCT_SUCCESS,
    payload: {
      data,
    },
  };
};

export const likeCommentProductFailed = (error) => {
  return {
    type: productConst.LIKE_COMMENT_PRODUCT_FAILED,
    payload: {
      error,
    },
  };
};

export const unLikeCommentProduct = (params) => {
  return {
    type: productConst.UNLIKE_COMMENT_PRODUCT,
    payload: {
      params,
    },
  };
};

export const unLikeCommentProductSuccess = (data) => {
  return {
    type: productConst.UNLIKE_COMMENT_PRODUCT_SUCCESS,
    payload: {
      data,
    },
  };
};

export const unLikeCommentProductFailed = (error) => {
  return {
    type: productConst.UNLIKE_COMMENT_PRODUCT_FAILED,
    payload: {
      error,
    },
  };
};

export const reportCommentProduct = (params) => {
  return {
    type: productConst.REPORT_COMMENT_PRODUCT,
    payload: {
      params,
    },
  };
};

export const reportCommentProductSuccess = (data) => {
  return {
    type: productConst.REPORT_COMMENT_PRODUCT_SUCCESS,
    payload: {
      data,
    },
  };
};

export const reportCommentProductFailed = (error) => {
  return {
    type: productConst.REPORT_COMMENT_PRODUCT_FAILED,
    payload: {
      error,
    },
  };
};

export const replyCommentProduct = (params) => {
  return {
    type: productConst.REPLY_COMMENT_PRODUCT,
    payload: {
      params,
    },
  };
};

export const replyCommentProductSuccess = (data) => {
  return {
    type: productConst.REPLY_COMMENT_PRODUCT_SUCCESS,
    payload: {
      data,
    },
  };
};

export const replyCommentProductFailed = (error) => {
  return {
    type: productConst.REPLY_COMMENT_PRODUCT_FAILED,
    payload: {
      error,
    },
  };
};

export const fetchAnswerComment = (params) => {
  return {
    type: productConst.FETCH_ANSWER_COMMENT,
    payload: {
      params,
    },
  };
};

export const fetchAnswerCommentSuccess = (data) => {
  return {
    type: productConst.FETCH_ANSWER_COMMENT_SUCCESS,
    payload: {
      data,
    },
  };
};

export const fetchAnswerCommentFailed = (error) => {
  return {
    type: productConst.FETCH_ANSWER_COMMENT_FAILED,
    payload: {
      error,
    },
  };
};

export const updateCommentProduct = (params) => {
  return {
    type: productConst.UPDATE_COMMENT_PRODUCT,
    payload: {
      params,
    },
  };
};

export const updateCommentSuccess = (data) => {
  return {
    type: productConst.UPDATE_COMMENT_PRODUCT_SUCCESS,
    payload: {
      data,
    },
  };
};

export const updateCommentFailed = (error) => {
  return {
    type: productConst.UPDATE_COMMENT_FAILED,
    payload: {
      error,
    },
  };
};

export const updateChildComment = (params) => {
  return {
    type: productConst.UPDATE_CHILD_COMMENT,
    payload: {
      params,
    },
  };
};

export const updateChildCommentSuccess = (data) => {
  return {
    type: productConst.UPDATE_CHILD_COMMENT_SUCCESS,
    payload: {
      data,
    },
  };
};

export const managerUpdateComment = (params) => {
  return {
    type: productConst.MANAGER_UPDATE_COMMENT,
    payload: {
      params,
    },
  };
};

export const managerUpdateCommentSuccess = (data) => {
  return {
    type: productConst.MANAGER_UPDATE_COMMENT_SUCCESS,
    payload: {
      data,
    },
  };
};

export const managerUpdateChildComment = (params) => {
  return {
    type: productConst.MANAGER_UPDATE_CHILD_COMMENT,
    payload: {
      params,
    },
  };
};

export const managerUpdateChildCommentSuccess = (data) => {
  return {
    type: productConst.MANAGER_UPDATE_CHILD_COMMENT_SUCCESS,
    payload: {
      data,
    },
  };
};

export const updateChildCommentFailed = (error) => {
  return {
    type: productConst.UPDATE_CHILD_COMMENT_FAILED,
    payload: {
      error,
    },
  };
};

export const destroyComment = (id) => {
  return {
    type: productConst.DESTROY_COMMENT,
    payload: {
      id,
    },
  };
};
export const destroyCommentSuccess = (id) => {
  return {
    type: productConst.DESTROY_COMMENT_SUCCESS,
    payload: {
      id,
    },
  };
};

export const destroyChildComment = (id) => {
  return {
    type: productConst.DESTROY_CHILD_COMMENT,
    payload: {
      id,
    },
  };
};

export const destroyChildCommentSuccess = (id) => {
  return {
    type: productConst.DESTROY_CHILD_COMMENT_SUCCESS,
    payload: {
      id,
    },
  };
};

export const managerDeleteComment = (id) => {
  return {
    type: productConst.MANAGER_DELETE_COMMENT,
    payload: {
      id,
    },
  };
};

export const managerDeleteCommentSuccess = (id) => {
  return {
    type: productConst.MANAGER_DELETE_COMMENT_SUCCESS,
    payload: {
      id,
    },
  };
};
export const managerDeleteSubComment = (id) => {
  return {
    type: productConst.MANAGER_DELETE_SUB_COMMENT,
    payload: {
      id,
    },
  };
};

export const managerDeleteSubCommentSuccess = (id) => {
  return {
    type: productConst.MANAGER_DELETE_SUB_COMMENT_SUCCESS,
    payload: {
      id,
    },
  };
};

export const loadMoreComment = (params) => {
  return {
    type: productConst.LOAD_MORE_COMMENT,
    payload: {
      params,
    },
  };
};

export const loadMoreCommentSuccess = (data) => {
  return {
    type: productConst.LOAD_MORE_COMMENT_SUCCESS,
    payload: {
      data,
    },
  };
};

export const loadMoreCommentFailed = (error) => {
  return {
    type: productConst.LOAD_MORE_COMMENT_FAILED,
    payload: {
      error,
    },
  };
};
