export const FETCH_USER_ALBUMS = 'FETCH_USER_ALBUMS';
export const FETCH_USER_ALBUMS_SUCCESS = 'FETCH_USER_ALBUMS_SUCCESS';
export const FETCH_USER_ALBUMS_FAILED = 'FETCH_USER_ALBUMS_FAILED';

export const FETCH_USER_ALBUMS_ID = 'FETCH_USER_ALBUMS_ID';
export const FETCH_USER_ALBUMS_ID_SUCCESS = 'FETCH_USER_ALBUMS_ID_SUCCESS';
export const FETCH_USER_ALBUMS_ID_FAILED = 'FETCH_USER_ALBUMS_ID_FAILED';

export const UPDATE_USER_ALBUMS = 'UPDATE_USER_ALBUMS';
export const UPDATE_USER_ALBUMS_SUCCESS = 'UPDATE_USER_ALBUMS_SUCCESS';
export const UPDATE_USER_ALBUMS_FAILED = 'UPDATE_USER_ALBUMS_FAILED';

export const DESTROY_USER_ALBUMS = 'DESTROY_USER_ALBUMS';
export const DESTROY_USER_ALBUMS_SUCCESS = 'DESTROY_USER_ALBUMS_SUCCESS';
export const DESTROY_USER_ALBUMS_FAILED = 'DESTROY_USER_ALBUMS_FAILED';

export const CREATE_USER_ALBUMS = 'CREATE_USER_ALBUMS';
export const CREATE_USER_ALBUMS_SUCCESS = 'CREATE_USER_ALBUMS_SUCCESS';
export const CREATE_USER_ALBUMS_FAILED = 'CREATE_USER_ALBUMS_FAILED';

export const FETCH_LIST_IMAGE_ALBUMS = 'FETCH_LIST_IMAGE_ALBUMS';
export const FETCH_LIST_IMAGE_ALBUMS_SUCCESS = 'FETCH_LIST_IMAGE_ALBUMS_SUCCESS';
export const FETCH_LIST_IMAGE_ALBUMS_FAILED = 'FETCH_LIST_IMAGE_ALBUMS_FAILED';

export const UPLOAD_IMAGE_TO_ALBUMS = 'UPLOAD_IMAGE_TO_ALBUMS';
export const UPLOAD_IMAGE_TO_ALBUMS_SUCCESS = 'UPLOAD_IMAGE_TO_ALBUMS_SUCCESS';
export const UPLOAD_IMAGE_TO_ALBUMS_FAILED = 'UPLOAD_IMAGE_TO_ALBUMS_FAILED';

export const DELETE_IMAGE_ALBUMS = 'DELETE_IMAGE_ALBUMS';
export const DELETE_IMAGE_ALBUMS_SUCCESS = 'DELETE_IMAGE_ALBUMS_SUCCESS';
export const DELETE_IMAGE_ALBUMS_FAILED = 'DELETE_IMAGE_ALBUMS_FAILED';

export const CREATE_AN_ALBUM = 'CREATE_AN_ALBUM';
export const CREATE_AN_ALBUM_SUCCESS = 'CREATE_AN_ALBUM_SUCCESS';
export const CREATE_AN_ALBUM_FAILED = 'CREATE_AN_ALBUM_FAILED';

export const MOVE_IMAGE_TO_OTHER_ALBUM = 'MOVE_IMAGE_TO_OTHER_ALBUM';
export const MOVE_IMAGE_TO_OTHER_ALBUM_SUCCESS = 'MOVE_IMAGE_TO_OTHER_ALBUM_SUCCESS';
export const MOVE_IMAGE_TO_OTHER_ALBUM_FAILED = 'MOVE_IMAGE_TO_OTHER_ALBUM_FAILED';

export const EDIT_USER_ALBUMS = 'EDIT_USER_ALBUMS';
export const EDIT_USER_ALBUMS_SUCCESS = 'EDIT_USER_ALBUMS_SUCCESS';
export const EDIT_USER_ALBUMS_FAILED = 'EDIT_USER_ALBUMS_FAILED';

export const DELETE_USER_ALBUMS = 'DELETE_USER_ALBUMS';
export const DELETE_USER_ALBUMS_SUCCESS = 'DELETE_USER_ALBUMS_SUCCESS';
export const DELETE_USER_ALBUMS_FAILED = 'DELETE_USER_ALBUMS_FAILED';

