import React from "react";

const ButtonShareFacebook = ({ children }) => {
  const href = `https://www.facebook.com/sharer.php?u=` + `${window.location.href}`;

  return (
    <a role="button" href={href} target="_blank">
      <img src="/images/fb-icon.png" alt="fb-share" style={{ borderRadius: "3px" }} />
      {children}
    </a>
  );
};

export default ButtonShareFacebook;
