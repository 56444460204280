import * as notificationConst from 'constants/notification';

export const fetchNotifications = params => {
  return {
    type: notificationConst.FETCH_NOTIFICATION,
    payload: {
      params,
    },
  };
};

export const fetchNotificationsSuccess = data => {
  return {
    type: notificationConst.FETCH_NOTIFICATION_SUCCESS,
    payload: {
      data,
    },
  };
};

export const fetchNotificationsFailed = error => {
  return {
    type: notificationConst.FETCH_NOTIFICATION_FAILED,
    payload: {
      error,
    },
  };
};

export const deleteNotification = id => {
  return {
    type: notificationConst.DELETE_NOTIFICATION,
    payload: {
      id,
    },
  };
};

export const deleteNotificationSuccess = data => {
  return {
    type: notificationConst.DELETE_NOTIFICATION_SUCCESS,
    payload: {
      data,
    },
  };
};

export const deleteNotificationFailed = error => {
  return {
    type: notificationConst.DELETE_NOTIFICATION_FAILED,
    payload: {
      error,
    },
  };
};


export const fetchLoadMoreNotifications = params => {
  return {
    type: notificationConst.LOAD_MORE_NOTIFICATION,
    payload: {
      params,
    },
  };
};

export const fetchLoadMoreNotificationsSuccess = data => {
  return {
    type: notificationConst.LOAD_MORE_NOTIFICATION_SUCCESS,
    payload: {
      data,
    },
  };
};

export const fetchLoadMoreNotificationsFailed = error => {
  return {
    type: notificationConst.LOAD_MORE_NOTIFICATION_FAILED,
    payload: {
      error,
    },
  };
};

export const viewNotification = id => {
  return {
    type: notificationConst.VIEW_NOTIFICATION,
    payload: {
      id,
    },
  };
};

export const viewNotificationSuccess = id => {
  return {
    type: notificationConst.VIEW_NOTIFICATION_SUCCESS,
    payload: {
      id,
    },
  };
};

export const viewNotificationFailed = error => {
  return {
    type: notificationConst.VIEW_NOTIFICATION_FAILED,
    payload: {
      error,
    },
  };
};
export const countNotifications = params => {
  return {
    type: notificationConst.COUNT_NOTIFICATION,
    payload: {
      params,
    },
  };
};

export const countNotificationsSuccess = data => {
  return {
    type: notificationConst.COUNT_NOTIFICATION_SUCCESS,
    payload: {
      data,
    },
  };
};
