import React from "react";
import { imageUrl } from "lib";
import Loader from "components/loader";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";

function Catalogue({ currentCatalogId }) {
  const catalogues = useSelector((state) => state.technicalProcess.listCatalogueTechnicalProcess);

  return (
    <div className="cate-lst qtkt">
      <div className="row">
        <Loader isLoading={catalogues.isLoading} />
        {catalogues.data
          .filter((catalog) => catalog.id != currentCatalogId)
          .map(function (cat, index) {
            let data = {
              catName: cat.name,
            };
            let imageCatalogue = !cat.image ? "/images/product-image-default.png" : imageUrl(cat.image);
            return (
              <div className="col-md-3 col-sm-6 col-xs-12 mb-2" key={index}>
                <Link
                  className="cate-item position-relative"
                  title={cat.name}
                  to={`/quy-trinh-ky-thuat/danh-muc/${cat.id}`}
                  state={data}
                >
                  <div className="img-box w-100 h-100 position-absolute">
                    <div className="wrap-img">
                      <img src={imageCatalogue} alt="product cate" title="product cate" className="img-cover" />
                    </div>
                  </div>

                  <div className="prod-inf position-absolute d-flex align-items-center">
                    <div className={`line-clamp-02 fw-bold w-100 `}>{cat.name}</div>
                  </div>
                </Link>
              </div>
            );
          })}
      </div>
    </div>
  );
}

export default Catalogue;
