import React, { useEffect } from "react";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import * as productActions from "actions/product";
import _ from "lodash";
import { imageUrl } from "lib/index";
import { Link } from "react-router-dom";

function SidebarCatalogueOfProduct(props) {
  let { productActionsCreator } = props;
  let { fetchCatalogueOfProduct } = productActionsCreator;
  let { data } = props.catalogueOfProducts;

  useEffect(() => {
    fetchCatalogueOfProduct({ parent_id: "is_parent" });
  }, [fetchCatalogueOfProduct]);

  return (
    <div className="col-md-3 col-sm-12 order-md-2 order-xs-1">
      <div className="cate-prod">
        <div className="captn-tag">Danh mục</div>
        <div className="mt-1">
          {_.chunk(data, 2).map(function (catalogues, index) {
            return (
              <div key={index} className={`${index > 0 ? "row mt-3" : "row"}`}>
                {catalogues.map(function (cat, index) {
                  let data = {
                    id: cat.id,
                    name: cat.name,
                    slug: cat.slug,
                  };
                  let image = cat.image !== null ? imageUrl(cat.image) : "/images/photos/photo-default.png";
                  return (
                    <div key={index} className="col-6">
                      <Link
                        to={`/san-pham/danh-muc/${cat?.slug}`}
                        className="bg-img box-shadow"
                        state={data}
                        style={{ backgroundImage: `url(${image})` }}>
                        <div>
                          <span className="cate-txt">{cat.name}</span>
                        </div>
                      </Link>
                    </div>
                  );
                })}
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
}

const mapStateToProps = (state) => {
  return {
    catalogueOfProducts: state.product.catalogueOfProducts,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    productActionsCreator: bindActionCreators(productActions, dispatch),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(SidebarCatalogueOfProduct);
