import React from "react";
import moment from "moment/moment";
import { Link } from "react-router-dom";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";

function TraceabilityItem({ data, userdeleteTraceability, isOpen, setIsOpen }) {
  const handleClose = () => setIsOpen(false);
  if (data.length > 0) {
    return (
      <>
        <div className="row">
          {data.map(function (traceability, index) {
            return (
              <div className="col-md-6 col-sm-12 d-flex mt-3" key={index}>
                <div className="traceability-item">
                  <img src={traceability.qr_code} alt="" />
                </div>
                <div className="traceability-info">
                  <div>Tên: {traceability.name} </div>
                  <div>Mã số: {traceability.code} </div>
                  <div>
                    Edit: &nbsp;
                    {moment(traceability.updated_at).format("DD/MM/YYYY")}
                  </div>
                </div>{" "}
                &nbsp;
                <div className="d-flex  traceability-button">
                  <Link to={`/modules/truy-xuat-nguon-goc/chinh-sua?id=${traceability.id}`}>
                    <span className="mr-2">Sửa</span>
                  </Link>
                  <span onClick={() => setIsOpen(true)}>Delete</span>
                </div>
                <Modal show={isOpen} onHide={handleClose} className="modal-confirm">
                  <Modal.Header className="flex-column">
                    <div className="icon-box">
                      <i className="material-icons">&#xe872;</i>
                    </div>
                    <Modal.Title>Xác nhận</Modal.Title>
                  </Modal.Header>
                  <Modal.Footer>
                    <Button variant="secondary" onClick={handleClose}>
                      Đóng
                    </Button>
                    <Button variant="danger" onClick={() => userdeleteTraceability(traceability.id)}>
                      Delete
                    </Button>
                  </Modal.Footer>
                </Modal>
              </div>
            );
          })}
        </div>
      </>
    );
  } else {
    return (
      <div className="row">
        <h5>Chưa có dữ liệu</h5>
      </div>
    );
  }
}
export default TraceabilityItem;
