import React from "react";
import { Button, Card, Table } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { imageUrl } from "lib/index";
import NumberFormat from "components/number_format";
import Swal from "sweetalert2";

const ManageProduct = () => {
  const dispatch = useDispatch();
  let { data, isLoadMore } = useSelector(
    (state) => state.product.listUserProducts
  );

  const deleteProduct = (id) => {
    Swal.fire({
      title: "Bạn có chắc chắn?",
      text: "Xóa sản phẩm này?",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "OK",
      cancelButtonText: "Đóng",
    }).then((result) => {
      if (result.isConfirmed) {
        dispatch({
          type: "DELETE_PRODUCT",
          payload: {
            id,
          },
        });
      }
    });
  };

  return (
    <div className="row mt-1">
      <div className="d-flex justify-content-between">
        <h5 className="title-line-lt text-uppercase fw-bold">
          <span className="d-block">Danh sách sản phẩm</span>
        </h5>
        <Link to={"new"}>
          <div className="btn btn-primary">Tạo mới</div>
        </Link>
      </div>
      <Card className="p-0 mt-3">
        <Card.Body>
          <Table bordered className="m-0">
            <thead>
              <tr>
                <th>Hình ảnh</th>
                <th>Tên sản phẩm</th>
                <th>Thông tin</th>
                <th>Tùy chọn</th>
              </tr>
            </thead>
            <tbody>
              {data.map((product, index) => {
                let product_image =
                  product.images[0] !== undefined
                    ? imageUrl(product.images[0]["name"])
                    : "/images/product-image-default.png";
                return (
                  <tr key={product.id}>
                    <td>
                      <div
                        style={{
                          maxWidth: "100px",
                          margin: "auto",
                          minHeight: "100px",
                        }}
                      >
                        <img
                          className="d-block w-100"
                          src={product_image}
                          alt={`product-${product.title}`}
                        />
                      </div>
                    </td>
                    <td>
                      <Link to={`/san-pham/${product?.slug}`}>
                        <div className="btn btn-link">{product.title}</div>
                      </Link>
                    </td>
                    <td>
                      <div
                        className="price01 d-flex justify-content-center align-items-center"
                        style={{
                          marginTop: "8px",
                        }}
                      >
                        <span>Retail price:</span>
                        <span className="price-clr">
                          &nbsp;
                          <NumberFormat>{product.retail_price}</NumberFormat>
                        </span>
                      </div>
                    </td>
                    <td>
                      <Link to={`edit/${product.id}`}>
                        <span className="btn btn-link">Sửa</span>
                      </Link>
                      <span
                        className="btn btn-link"
                        onClick={() => deleteProduct(product.id)}
                      >
                        {" "}
                        Xoá
                      </span>
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </Table>
        </Card.Body>
      </Card>
    </div>
  );
};

export default ManageProduct;
