import AddMetaTags from "components/add_meta_tags";
import LeftSidebar from "components/left_sidebar";
import React, { useContext } from "react";
import AppContext from "components/app_context";
import { Link } from "react-router-dom";
function HomeTraceabilites(props) {
  const { current_user } = useContext(AppContext);
  return (
    <>
      <AddMetaTags title={"Truy xuất nguồn gốc"} />
      <div className="list_traceability">
        <div className="row">
          <LeftSidebar />
          <div className="col-md-9 col-sm-12">
            <div className="container traceability-home">
              <div className="row">
                <p className="fs-3 d-flex justify-content-center text-uppercase fw-bold">Truy xuất nguồn gốc</p>
              </div>
              {current_user && (
                <div className="row">
                  <div className="mt-2">
                    <Link to="/modules/truy-xuat-nguon-goc/danh-sach" className="blue-clr">
                      Danh sách truy xuất
                    </Link>
                  </div>
                </div>
              )}
              <div className="row">
                <div className="mt-2">
                  <Link to="/modules/truy-xuat-nguon-goc/nhap-ma" className="blue-clr">
                    Nhập mã định danh
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
export default HomeTraceabilites;
