import { useEffect } from "react";
import SidebarArticles from "components/sidebar_acticles";
import SidebarProducts from "components/sidebar_products";
import SidebarPosts from "./SidebarPosts";
import { useLocation } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { fetchSidebarArticle } from "redux/actions/article";
import { fetchPickTop } from "redux/actions/post";
function RightSidebar() {
  const currentPathname = window.location.pathname;
  const dispatch = useDispatch();
  const location = useLocation();
  const whatPage = location.pathname;
  const notPostPage = !(currentPathname === "/" || currentPathname.includes("/p/"));
  const data = useSelector((state) =>
    notPostPage ? state.article.sidebarArticles.data : state.post.listPickTops.data
  );
  const isLoading = useSelector((state) =>
    notPostPage ? state.article.sidebarArticles.isLoading : state.post.listPickTops.isLoading
  );
  const params = {
    page: 1,
    limit: 10,
    ...(notPostPage && { is_feature: 1, article_type: !whatPage.includes("/tin-tuc") ? "Video" : "Article" }),
  };
  const fetchData = !notPostPage ? fetchPickTop : fetchSidebarArticle;
  useEffect(() => {
    dispatch(fetchData(params));
  }, [whatPage, dispatch]);

  return (
    <div className="col-md-3 col-sm-12">
      {notPostPage ? (
        <SidebarArticles data={data} whatPage={whatPage} isLoading={isLoading} currentPathname={currentPathname} />
      ) : (
        <SidebarPosts data={data} isLoading={isLoading} currentPathname={currentPathname} />
      )}
      <SidebarProducts />
    </div>
  );
}

export default RightSidebar;
