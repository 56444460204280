import React, { useEffect, useContext, useState } from 'react';
import { Link } from 'react-router-dom';
import { bindActionCreators } from 'redux';
import { connect, useDispatch, useSelector } from 'react-redux';
import * as postActions from 'actions/post';
import LeftSidebar from 'components/left_sidebar';
import AppContext from 'components/app_context';
import Post from 'components/shared/post/index';
import Loader from 'components/loader';
import FadeIn from 'react-fade-in';
import PostsPlaceholder from 'components/placeholder/posts';
import AddMetaTags from 'components/add_meta_tags';
import { DOMAIN, PAGE, PER_PAGE } from 'constants';
import * as productActions from 'actions/product';
import * as articleActions from 'actions/article';
import * as managerActions from 'actions/manager';
import NumberFormat from 'components/number_format';
import * as authActions from 'actions/auth';
import { getProfile } from 'apis/auth';
import ModalSharePost from 'components/shared/ModalSharePost';

function Favorites(props) {
  let { data, isLoading, isLoadMore } = props.listPosts;
  let { postActionsCreator } = props;
  let [page, setPage] = useState(PAGE);
  let { productActionsCreator, articleActionsCreator } = props;
  let { fetchFavoritesProduct, fetchLoadMoreProduct } = productActionsCreator;
  let { data: dataProducts, isLoading: isLoadingProduct, isLoadMore: isLoadMoreProduct } = props.favoritesProducts;
  let { fetchFavoriteArticle } = articleActionsCreator;

  let {
    fetchFavoritesPost,
    addPost,
    likePost,
    unlikePost,
    fetchLoadMorePost,
    createCommentPost,
    sharePost,
    reportPost,
    fetchCommentPost,
    likeComment,
    unlikeComment,
    updateComment,
    updateAnswerComment,
    replyCommentPost,
  } = postActionsCreator;

  let { managerUpdateComment, managerUpdateAnswerComment, managerDeletePost } = props.managerActionsCreator;
  let {
    followPost,
    unFollowPost,
    fetchFollowPost,
    unFollowFavPost,
    likeFollowingPost,
    unlikeFollowingPost,
    sharePoint,
  } = props.authActionsCreator;

  let comments = props.listCommentPosts;
  const dispatch = useDispatch();
  const { data: listFollowPost } = useSelector(state => state.login.listFollowPost);
  const { keyword, setDataFilter, current_user } = useContext(AppContext);
  let [postId, setPostId] = useState(null);
  let [pageMore, setPageMore] = useState(1);
  const [currentPost, setCurrentPost] = useState(null);
  const [content, setContent] = useState('');
  const [sharedPoint, setSharedPoint] = useState(0);
  const [userPoint, setUserPoint] = useState(0);
  useEffect(() => {
    fetchFavoritesPost({ limit: 10 });
    fetchFavoriteArticle();
    dispatch(fetchFollowPost({ limit: 10 }));
    fetchFavoritesProduct({ keyword: '', page: page, limit: 8 });
  }, [fetchFavoritesPost, fetchFavoriteArticle]);

  useEffect(() => {
    fetchProfilePoint();
  }, [current_user]);

  const postFilter = value => {
    setDataFilter(value);
  };
  const fetchProfilePoint = async () => {
    if (current_user) {
      const user = await getProfile(current_user?.id);
      setUserPoint(user.data.data.points);
    }
  };

  const actionLike = (id, type, isLiked) => {
    if (isLiked) {
      unlikePost({ classable_id: id, classable_type: type });
    } else {
      likePost({ classable_id: id, classable_type: type });
    }
  };
  const actionLikeFollowingPost = (id, type, isLiked) => {
    if (isLiked) {
      unlikeFollowingPost({ classable_id: id, classable_type: type });
    } else {
      likeFollowingPost({ classable_id: id, classable_type: type });
    }
  };

  const listener = e => {
    setPage(page + 1);
    // fetchLoadMorePost({page: page + 1, limit: PER_PAGE, container: "favorite"});
  };

  const listenerProduct = e => {
    setPage(page + 1);
    fetchLoadMoreProduct({ page: page + 1, limit: PER_PAGE });
  };

  const addComment = (id, type, content) => {
    createCommentPost({
      commentable_id: id,
      commentable_type: type,
      content: content,
    });
  };

  const actionSharePost = () => {
    sharePost({
      classable_id: currentPost.id,
      classable_type: currentPost.classable_type,
      share_type: 'config_share',
      post_type: 'public',
      description: content,
    });
    setCurrentPost(null);
  };
  function actionSharePoint(access_token, receiver_id, points) {
    sharePoint({
      access_token: access_token,
      receiver_id: receiver_id,
      points: points,
    });
    setSharedPoint(prevPoints => prevPoints + parseInt(points));
  }
  const actionReportPost = (id, reason) => {
    reportPost({ id: id, reason: reason });
  };

  let actionFetchCommentPost = id => {
    fetchCommentPost({
      classable_id: id,
      classable_type: 'Post',
      page: 1,
      limit: 5,
    });
  };

  const actionLikeComment = (id, type, isLiked) => {
    if (isLiked) {
      unlikeComment({ classable_id: id, classable_type: type });
    } else {
      likeComment({ classable_id: id, classable_type: type });
    }
  };

  const actionUpdateComment = (type, id, content) => {
    if (type == 'Comment') {
      if (current_user?.manager_type == 'admin' || current_user.manager_type == 'smod') {
        managerUpdateComment({ id: id, content: content });
      } else {
        updateComment({ id: id, content: content });
      }
    } else {
      if (
        current_user?.manager_type == 'admin' ||
        current_user.manager_type == 'smod' ||
        current_user.manager_type == 'mod'
      ) {
        managerUpdateAnswerComment({ id: id, content: content });
      } else {
        updateAnswerComment({ id: id, content: content });
      }
    }
  };

  const actionSortComment = (id, type) => {
    if (type === 'asc') {
      fetchCommentPost({
        classable_id: id,
        classable_type: 'Post',
        page: 1,
        limit: 5,
        sort: 'total_likes',
      });
    } else {
      fetchCommentPost({
        classable_id: id,
        classable_type: 'Post',
        page: 1,
        limit: 5,
        sort: 'created_at',
      });
    }
  };
  const actionReplyComment = (id, content) => {
    replyCommentPost({ id: id, content: content, replier_id: current_user.id });
  };

  const handleFollowPost = (classable_type, classable_id) => {
    followPost({ classable_id: classable_id, classable_type: classable_type });
  };

  const handleUnFollowPost = (classable_type, classable_id) => {
    unFollowPost({
      classable_id: classable_id,
      classable_type: classable_type,
    });
  };

  const handleUnFollowFavPost = (classable_type, classable_id) => {
    unFollowFavPost({
      classable_id: classable_id,
      classable_type: classable_type,
    });
  };
  return (
    <div className="posts">
      <AddMetaTags title={'Danh sách yêu thích của người dùng'} />
      <div className="row">
        <LeftSidebar />
        <div className="col-md-9 col-sm-12">
          <div className="cont-center saved-items">
            <ul className="nav nav-tabs justify-content-evenly" id="myTab" role="tablist">
              <li className="nav-item" role="presentation">
                <button
                  className="nav-link active"
                  id="news-tab"
                  data-bs-toggle="tab"
                  data-bs-target="#news-saved"
                  type="button"
                  role="tab"
                  aria-controls="news-saved"
                  aria-selected="true"
                >
                  Bài viết quan tâm
                </button>
              </li>
              <li className="nav-item" role="presentation">
                <button
                  className="nav-link"
                  id="prod-tab"
                  data-bs-toggle="tab"
                  data-bs-target="#prod-saved"
                  type="button"
                  role="tab"
                  aria-controls="prod-saved"
                  aria-selected="false"
                >
                  Sản phẩm đã lưu
                </button>
              </li>
              <li className="nav-item" role="presentation">
                <button
                  className="nav-link"
                  id="article-tab"
                  data-bs-toggle="tab"
                  data-bs-target="#article-saved"
                  type="button"
                  role="tab"
                  aria-controls="article-saved"
                  aria-selected="false"
                >
                  Tin tức đã lưu
                </button>
              </li>
              <li className="nav-item" role="presentation">
                <button
                  className="nav-link"
                  id="post-tab"
                  data-bs-toggle="tab"
                  data-bs-target="#post-saved"
                  type="button"
                  role="tab"
                  aria-controls="post-saved"
                  aria-selected="false"
                >
                  Bài viết theo dõi
                </button>
              </li>
            </ul>
            <div className="tab-content border-0 shadow-none mt-3" id="myTabContent">
              <div className="tab-pane fade show active" id="news-saved" role="tabpanel" aria-labelledby="news-tab">
                {isLoading && (
                  <FadeIn>
                    <PostsPlaceholder />
                  </FadeIn>
                )}
                <FadeIn>
                  {data.length > 0 ? (
                    data.map(function (post, index) {
                      return (
                        <div key={index}>
                          <Post
                            post={post}
                            postFilter={postFilter}
                            actionLike={actionLike}
                            owner={false}
                            addComment={addComment}
                            current_user={current_user}
                            actionSharePost={actionSharePost}
                            actionReportPost={actionReportPost}
                            comments={comments.data}
                            postId={postId}
                            setPageMore={setPageMore}
                            setPostId={setPostId}
                            actionFetchCommentPost={actionFetchCommentPost}
                            actionLikeComment={actionLikeComment}
                            actionUpdateComment={actionUpdateComment}
                            actionReplyComment={actionReplyComment}
                            replyCommentPost={replyCommentPost}
                            handleFollowPost={handleFollowPost}
                            handleUnFollowPost={handleUnFollowPost}
                            actionSortComment={actionSortComment}
                            setCurrentPost={setCurrentPost}
                            actionSharePoint={actionSharePoint}
                            userPoint={userPoint}
                          />
                        </div>
                      );
                    })
                  ) : (
                    <div className="font-italic" style={{ backgroundColor: '#f9f9f9' }}>
                      Không có bài viết
                    </div>
                  )}
                </FadeIn>
                <ModalSharePost
                  post={currentPost}
                  handleClose={() => setCurrentPost(null)}
                  setContent={setContent}
                  onSubmit={actionSharePost}
                  current_user={current_user}
                  content={content}
                />
                <Loader isLoading={isLoading} />
                {data?.length > 10 && (
                  <div className="post-more" onClick={listener}>
                    <span className="txt">
                      <u className="hover fw-500">More</u>
                    </span>
                    <span className="icon-arrow-solid"></span>
                  </div>
                )}
              </div>
              <div className="tab-pane fade" id="prod-saved" role="tabpanel" aria-labelledby="prod-tab">
                <FadeIn className="row prod-list" childClassName="col-md-3 col-sm-6 col-xs-12">
                  {dataProducts.length > 0 ? (
                    dataProducts.map(function (product, index) {
                      let product_image =
                        product.images[0] !== undefined
                          ? `${product.images[0]['name']}`
                          : '/images/product-image-default.png';
                      return (
                        <div key={product.id} className="item-lst">
                          <Link to={`/san-pham/${product?.slug}`}>
                            {product.images[0]?.name.includes('https://') ? (
                              <div className="bg-img-orgnal" style={{ background: `url(${product_image})` }} />
                            ) : (
                              <div
                                className="bg-img-orgnal"
                                style={{
                                  background: `url(${DOMAIN}/${product_image})`,
                                }}
                              />
                            )}
                            <div className="prod-inf">
                              <div className="txt-name">{product?.title}</div>
                              {product?.province_name ? (
                                <div className="locatn">
                                  <span className="icon-location01" />
                                  <span className="txt-city">{product?.province_name}</span>
                                </div>
                              ) : (
                                <div className="locatn">
                                  <span className="icon-location01" />
                                  <span className="txt-city">Unknown</span>
                                </div>
                              )}
                              <div className="price-item">
                                <div className="price01">
                                  <span>Retail price:</span>
                                  {product.retail_price > 0 ? (
                                    <span className="price-clr">
                                      &nbsp;
                                      <NumberFormat>{product?.retail_price}</NumberFormat>
                                    </span>
                                  ) : (
                                    <span className="price-clr">&nbsp;Contact</span>
                                  )}
                                </div>
                              </div>
                            </div>
                          </Link>
                        </div>
                      );
                    })
                  ) : (
                    <div className="font-italic text-secondary">Không có sản phẩm</div>
                  )}
                </FadeIn>
                <Loader isLoading={isLoadingProduct} />
              </div>
              <div className="tab-pane fade" id="article-saved" role="tabpanel" aria-labelledby="article-tab">
                <div className="row">
                  <div className="row news-more">
                    {props.favoritesArticles.data.length > 0 ? (
                      props.favoritesArticles.data.map(function (article, index) {
                        return (
                          <div className="col-md-6 col-sm-12" key={index}>
                            <Link
                              to={`/tin-tuc/${article.slug}`}
                              className=" d-flex align-items-center favourite-article "
                            >
                              <div className="pt-show">
                                {article?.image?.includes('https://') ? (
                                  <div
                                    className="bg-img "
                                    style={{
                                      backgroundImage: `url(${article.image})`,
                                    }}
                                  />
                                ) : (
                                  <div
                                    className="bg-img "
                                    style={{
                                      backgroundImage: `url(${DOMAIN}/${article.image})`,
                                    }}
                                  />
                                )}
                              </div>
                              <div className="ctnt line-clamp-03 artile-title">{article.title}</div>
                            </Link>
                          </div>
                        );
                      })
                    ) : (
                      <div className="font-italic text-secondary">Không có bài viết</div>
                    )}
                  </div>
                </div>
              </div>
              {/* post saved */}
              <div className="tab-pane fade" id="post-saved" role="tabpanel" aria-labelledby="post-tab">
                <div className="row">
                  {isLoading && (
                    <FadeIn>
                      <PostsPlaceholder />
                    </FadeIn>
                  )}
                  <FadeIn>
                    {listFollowPost.length > 0 ? (
                      listFollowPost.map(function (post, index) {
                        return (
                          <div key={index}>
                            <Post
                              post={post}
                              postFilter={postFilter}
                              actionLike={actionLikeFollowingPost}
                              owner={false}
                              addComment={addComment}
                              current_user={current_user}
                              actionSharePost={actionSharePost}
                              actionReportPost={actionReportPost}
                              comments={comments.data}
                              postId={postId}
                              setPageMore={setPageMore}
                              setPostId={setPostId}
                              actionFetchCommentPost={actionFetchCommentPost}
                              actionLikeComment={actionLikeComment}
                              actionUpdateComment={actionUpdateComment}
                              actionReplyComment={actionReplyComment}
                              replyCommentPost={replyCommentPost}
                              handleFollowPost={handleFollowPost}
                              handleUnFollowPost={handleUnFollowFavPost}
                              setCurrentPost={setCurrentPost}
                              actionSharePoint={actionSharePoint}
                              userPoint={userPoint}
                            />
                          </div>
                        );
                      })
                    ) : (
                      <div className="font-italic" style={{ backgroundColor: '#f9f9f9' }}>
                        Không có bài viết
                      </div>
                    )}
                  </FadeIn>
                  <ModalSharePost
                    post={currentPost}
                    handleClose={() => setCurrentPost(null)}
                    setContent={setContent}
                    onSubmit={actionSharePost}
                    current_user={current_user}
                    content={content}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

const mapStateToProps = state => {
  return {
    listPosts: state.post.listPosts,
    favoritesProducts: state.product.favoritesProducts,
    favoritesArticles: state.article.favorite_articles,
    listCommentPosts: state.post.listCommentsPost,
  };
};

const mapDispatchToProps = (dispatch, ownProps) => {
  return {
    postActionsCreator: bindActionCreators(postActions, dispatch),
    productActionsCreator: bindActionCreators(productActions, dispatch),
    articleActionsCreator: bindActionCreators(articleActions, dispatch),
    managerActionsCreator: bindActionCreators(managerActions, dispatch),
    authActionsCreator: bindActionCreators(authActions, dispatch),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Favorites);
