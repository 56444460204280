import React, { useEffect, useState, useContext } from "react";
import { bindActionCreators } from "redux";
import { connect, useDispatch } from "react-redux";
import { Link } from "react-router-dom";
import * as productActions from "actions/product";
import PropTypes from "prop-types";
import { PAGE, PER_PAGE } from "constants";
import FadeIn from "react-fade-in";
import AppContext from "components/app_context";
import LeftSidebar from "components/left_sidebar";
import SidebarCatalogueOfProduct from "components/sidebar_catalogueOfProduct";
import NumberFormat from "components/number_format";
import { getCataloguesProducts } from "apis/catalogue";
import AddMetaTags from "components/add_meta_tags";
import ListProduct from "./components/listProduct";
import { imageUrl } from "lib/index";
import UserProduct from "./components/UserProduct";
import { setParamsTrackActivity } from "lib";
import { createTrackActivity } from "actions/track_activity";

function Products(props) {
  const { keyword, current_user } = useContext(AppContext);
  const dispatch = useDispatch();

  const listener = (e) => {
    let element = e.target.documentElement;
    if (element.scrollHeight - element.scrollTop - 500 <= element.clientHeight && isLoadMore) {
      setPage(page + 1);
      fetchLoadMoreProduct({ page: page + 1, limit: PER_PAGE });
    }
  };

  let { productActionsCreator } = props;
  let { fetchHighlightProduct, fetchLoadMoreProduct, fetchUserProduct } = productActionsCreator;
  let { data, isLoading, isLoadMore } = props.highlightProducts;
  let { data: userProduct, isLoadMore: isLoadMoreUserProduct } = props.listUserProducts;

  let [page, setPage] = useState(PAGE);

  useEffect(() => {
    fetchHighlightProduct({ keyword: keyword, page: page, limit: 8 });
    if (current_user) {
      fetchUserProduct({ keyword: keyword, page: page, limit: 8 });
    }
  }, [fetchHighlightProduct, keyword, current_user]);

  let [catalogue, setCatalogue] = useState([]);

  useEffect(() => {
    getCataloguesProducts({ parent_id: "is_parent" }).then((res) => {
      let result = res["data"]["data"];
      if (result) {
        setCatalogue(result);
      }
    });
    dispatch(createTrackActivity(setParamsTrackActivity("List products", "GET", {}, "products")));
  }, []);

  const loadmoreProduct = () => {
    fetchHighlightProduct({ keyword: keyword, page: page });
  };

  return (
    <div className="products">
      <AddMetaTags title={"Danh sách sản phẩm"} />
      <div className="row">
        <LeftSidebar />
        <div className="col-md-6 col-sm-12 order-md-1 order-xs-2">
          <div className="cont-center">
            <p className="fs-3 d-flex justify-content-center text-uppercase fw-bold">Chợ 2Nông</p>
            {current_user && <UserProduct data={userProduct} />}
            <div className="mt-1">
              <div className=" fw-bold">
                <h5 className="title-line-lt text-uppercase fw-bold my-3">Hot Products</h5>
              </div>
              <FadeIn className="row prod-list" childClassName="col-md-3 col-sm-6 col-xs-12">
                {data.map(function (product, index) {
                  let product_image = product.images.length > 0 ? imageUrl(product.images[0]["name"]) : "images/photos/no_image.png";
                  return (
                    <div key={index} className="item-lst hover">
                      <Link to={`/san-pham/${product?.slug}`}>
                        <div className="bg-img-orgnal" style={{ background: `url(${product_image})` }} />
                        <div className="prod-inf">
                          <div className="txt-name">{product.title}</div>
                          {product.province_name ? (
                            <div className="locatn">
                              <span className="icon-location01" />
                              <span className="txt-city">{product?.shop?.province_name}</span>
                            </div>
                          ) : (
                            <div className="locatn">
                              <span className="icon-location01" />
                              <span className="txt-city"> Unknown</span>
                            </div>
                          )}
                          <div className="price-item">
                            <div className="price01">
                              <span className="d-block text-muted">Retail price:</span>
                              {product.retail_price > 0 ? (
                                <span className="price-clr">
                                  &nbsp;<NumberFormat>{product.retail_price}</NumberFormat> $/{product.unit_name}
                                </span>
                              ) : (
                                <span className="price-clr">Contact</span>
                              )}
                            </div>
                            <div className="price02 mt-1">
                              <span className="d-block text-muted">Views: {product.view_count}</span>
                            </div>
                          </div>
                        </div>
                      </Link>
                    </div>
                  );
                })}
              </FadeIn>
              {data.length < 40 && (
                <div className="product-more background-clr-f5 border-0 w-100" onClick={loadmoreProduct}>
                  <span className="txt hover fw-500">
                    <u>More</u>
                  </span>
                  <span className="icon-arrow-solid" style={{ fontSize: "8px", marginLeft: "2px", color: "grey" }}></span>
                </div>
              )}
            </div>
            {catalogue.map((cat, index) => (
              <ListProduct key={index} catalogue_ids={cat.id} catalogue_name={cat.fullname} />
            ))}
          </div>
        </div>
        <SidebarCatalogueOfProduct />
      </div>
    </div>
  );
}

Products.propTypes = {
  productActionsCreator: PropTypes.shape({
    fetchProduct: PropTypes.func,
    fetchProductSuccess: PropTypes.func,
    fetchProductFailed: PropTypes.func,
  }),
};

const mapStateToProps = (state) => {
  return {
    highlightProducts: state.product.highlightProducts,
    listUserProducts: state.product.listUserProducts,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    productActionsCreator: bindActionCreators(productActions, dispatch),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Products);
