import { call, put, race, delay} from "redux-saga/effects";
import {
  getWeather,
  getWeatherPlaces,
  getWeatherWithAddress,
  getAudioWeather,
  getWeatherSchedule,
  deleteWeatherSchedule,
  getWeatherScheduleId
} from "apis/weather";
import {
  fetchWeatherSuccess,
  fetchWeatherFailed,
  fetchWeatherPlacesFailed,
  fetchWeatherPlacesSuccess,
  fetchWeatherAddressSuccess,
  fetchWeatherAddressFailed,
  fetchWeatherAudioSuccess,
  fetchWeatherAudio,
  fetchWeatherScheduleSuccess,
  deleteWeatherScheduleSuccess,
  fetchWeatherScheduleIdSuccess
} from "actions/weather";
import { toastError } from "helpers/toastHelper";
import { STATUS_CODE } from "constants";

export function* fetchWeather({ payload }) {
  try {
    const { params } = payload;
    const resp = yield call(getWeather, params);
    const { data, status } = resp;
    if (status === STATUS_CODE.SUCCESS) {
      yield put(fetchWeatherSuccess(data));
      yield put(fetchWeatherAudio({lat: data.data.current_date.lat, lng: data.data.current_date.lng}));
    } else {
      yield put(fetchWeatherFailed(data));
    }
  } catch (error) {
    yield put(fetchWeatherFailed("Không lấy được thông tin thời tiết"));
  }
}

export function* fetchWeatherPlaces({ payload }) {
  try {
    const { params } = payload;
    const resp = yield call(getWeatherPlaces, params);
    const { data, status } = resp;
    if (status === STATUS_CODE.SUCCESS) {
      yield put(fetchWeatherPlacesSuccess(data));
      // handle audio
    } else {
      yield put(fetchWeatherPlacesFailed(data));
    }
  } catch (error) {
  }
}

export function* fetchWeatherAddress({ payload }) {
  try {
    const { params } = payload;
    const resp = yield call(getWeatherWithAddress, params);
    const { data, status } = resp;
    if (status === STATUS_CODE.SUCCESS) {
      yield put(fetchWeatherAddressSuccess(data));
      // handle audio
      yield put(fetchWeatherAudio({lat: data.data.current_date.lat, lng: data.data.current_date.lng}));
    } else {
      yield put(fetchWeatherAddressFailed(data));
    }
  } catch (error) {
    toastError("Địa chỉ không hợp lệ!");
  }
}

export function* UserFetchWeatherAudio({ payload }) {
  try {
    const { params } = payload;
    const resp = yield call(getAudioWeather, params);
    const { data, status } = resp;
    if (status === STATUS_CODE.SUCCESS) {
      yield put(fetchWeatherAudioSuccess(data));
    }
  } catch (error) {
    toastError("Địa chỉ không hợp lệ!");
  }
}


export function* UserFetchWeatherSchedule({ payload }) {
  try {
    const { params } = payload;
    const resp = yield call(getWeatherSchedule, params);
    const { data, status } = resp;
    if (status === STATUS_CODE.SUCCESS) {
      yield put(fetchWeatherScheduleSuccess(data));
    }
  } catch (error) {
  
  }
}
export function* UserDeletehWeatherSchedule({ payload }) {
  try {
    const { params } = payload;
    const resp = yield call(deleteWeatherSchedule, params);
    const { data, status } = resp;
    if (status === STATUS_CODE.SUCCESS) {
      yield put(deleteWeatherScheduleSuccess(data));
    }
  } catch (error) {
  
  }
}

export function* userFetchWeatherScheduleId({ payload }) {
  try {
    const { id } = payload;
    const resp = yield call(getWeatherScheduleId, id);
    const { data, status } = resp;
    if (status === STATUS_CODE.SUCCESS) {
      yield put(fetchWeatherScheduleIdSuccess(data));
    }
  } catch (error) {
  
  }
}

