import React, { useEffect } from "react";
import { imageUrl } from "lib";

import NumberFormat from "components/number_format";
import FadeIn from "react-fade-in";
import { Link } from "react-router-dom";

function UserProduct({ data }) {
  return (
    <React.Fragment>
      <div className="mt-1">
        <div className=" fw-bold">
          <h5 className="title-line-lt text-uppercase fw-bold my-3">My Products</h5>
        </div>
        <FadeIn className="row prod-list" childClassName="col-md-3 col-sm-6 col-xs-12">
          {data.length > 0 ? (
            data.map(function (product, index) {
              let product_image =
                product.images.length > 0 ? imageUrl(product.images[0]["name"]) : "images/photos/no_image.png";
              return (
                <div key={index} className="item-lst hover">
                  <Link to={`/san-pham/${product?.slug}`}>
                    <div className="bg-img-orgnal" style={{ background: `url(${product_image})` }} />
                    <div className="prod-inf">
                      <div className="txt-name">{product.title}</div>
                      {product.province_name ? (
                        <div className="locatn">
                          <span className="icon-location01" />
                          <span className="txt-city">{product?.shop?.province_name}</span>
                        </div>
                      ) : (
                        <div className="locatn">
                          {" "}
                          <span className="icon-location01" />
                          <span className="txt-city"> Unknown</span>
                        </div>
                      )}
                      <div className="price-item">
                        <div className="price01">
                          <span className="d-block text-muted">Retail price:</span>
                          {product.retail_price > 0 ? (
                            <span className="price-clr">
                              &nbsp;<NumberFormat>{product.retail_price}</NumberFormat> $/{product.unit_name}
                            </span>
                          ) : (
                            <span className="price-clr">Contact</span>
                          )}
                        </div>
                        <div className="price02 mt-1">
                          <span className="d-block text-muted">Views: {product.view_count}</span>
                        </div>
                      </div>
                    </div>
                  </Link>
                </div>
              );
            })
          ) : (
            <div className="font-italic gray-clr ml-2">Không có sản phẩm</div>
          )}
        </FadeIn>
      </div>
    </React.Fragment>
  );
}

export default UserProduct;
