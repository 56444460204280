import CurrencyFormat from "react-currency-format";

function NumberFormat({ children }) {
  if (children != null) {
    return (
      <CurrencyFormat
        value={children}
        thousandSeparator={"."}
        decimalSeparator={","}
        displayType={"text"}
        suffix={""}
      />
    );
  } else {
    return " Liên hệ";
  }
}

export default NumberFormat;
