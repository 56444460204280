import { call, put, select } from 'redux-saga/effects';
import {
  getCatalogueGifts,
  exchangeGifts,
  exchangePointsTransaction,
  getLitTopUp,
  topUpDataForUser,
  getListGift,
} from 'apis/gift';
import {
  fetchCatalogueGiftAndProductSuccess,
  exchangePointsForGiftSuccess,
  exchangePointsForGiftFailed,
  fetchPointsTransactionSuccess,
  fetchListTopUpSuccess,
  topUpDataForUserSuccess,
  topUpDataForUserFailed,
  fetchListGiftSuccess,
  updateQuantityExchangedOfGiftSuccess
} from 'actions/gift';

import { fetchInfoUser } from 'actions/auth';

import { STATUS_CODE } from 'constants';

export function* fetchCataloguesAndGift({ payload }) {
  try {
    const { params } = payload;
    const catalogues = yield call(getCatalogueGifts, params);
    const arrayCatalogue = catalogues.data.data;
    yield put(fetchCatalogueGiftAndProductSuccess({ catalogues: arrayCatalogue }));
  } catch (error) {}
}

export function* userExchangePointsForGift({ payload }) {
  try {
    const { params } = payload;
    const resp = yield call(exchangeGifts, params);
    const { data } = resp;
    if (data.success === true) {
      yield put(exchangePointsForGiftSuccess({ data: data, params: params }));
    } else {
      yield put(exchangePointsForGiftFailed(data));
    }
  } catch (error) {}
}

export function* userFetchPointsTransaction({ payload }) {
  try {
    const { params } = payload;
    const resp = yield call(exchangePointsTransaction, params);
    const { data, status } = resp;
    if (status === STATUS_CODE.SUCCESS) {
      yield put(fetchPointsTransactionSuccess(data));
    }
  } catch (error) {}
}

export function* userFetchTopUp() {
  try {
    const resp = yield call(getLitTopUp, {});
    const { data, status } = resp;
    if (status === STATUS_CODE.SUCCESS) {
      yield put(fetchListTopUpSuccess(data));
    }
  } catch (error) {}
}

export function* userTopUpData({ payload }) {
  try {
    const { params } = payload;
    const resp = yield call(topUpDataForUser, params);
    const info = yield select(state => state.login.user_data);
    const { data } = resp;
    if (data.success === true) {
      yield put(topUpDataForUserSuccess(data));
      yield put(fetchInfoUser(info.current_user.id));
    } else {
      yield put(topUpDataForUserFailed(data));
    }
  } catch (error) {
    yield put(topUpDataForUserFailed(error.data));
  }
}

export function* userFetchListGift({ payload }) {
  try {
    const { params } = payload;
    const resp = yield call(getListGift, params);
    const { data, status } = resp;
    if (status === STATUS_CODE.SUCCESS) {
      yield put(fetchListGiftSuccess(data));
    }
  } catch (error) {}
}

export function* userUpdateQuantityExchangeOfGift({ payload }) {
  try {
    const { gift_id } = payload;
    yield put(updateQuantityExchangedOfGiftSuccess(gift_id));
  } catch (error) {
    console.log(error);
  }
}
