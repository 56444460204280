import { getMetaTags } from "apis/post";
import { CALLBACK_URL, SSO_ENDPOINT, PER_PAGE, ROLE } from "constants";
import { DOMAIN } from "constants";
import axios from "axios";
const rule = /([#|＃][^\s]+)/g;

export const parse = (value, renderer, action) => {
  if (value !== undefined && value["type"] !== "iframe") {
    return value.split(rule).map((chunk) => {
      if (chunk.match(rule)) {
        return renderer(chunk, action);
      }
      return chunk;
    });
  } else {
    let chunk = value;
    return renderer(chunk, action);
  }
};

export const youtubeGetID = (url) => {
  let ID = null;
  if (url !== null) {
    url = url.replace(/(>|<)/gi, "").split(/(vi\/|v=|\/v\/|youtu\.be\/|\/embed\/)/);
    if (url[2] !== undefined) {
      ID = url[2].split(/[^0-9a-z_\-]/i);
      ID = ID[0];
    }
  }
  return ID;
};

export const isDropLines = (content, limit_length) => {
  let line = 0;
  let isDropLine = false;
  if (content !== null) {
    line = content.length;
    isDropLine = line / limit_length > 3;
  }
  return isDropLine;
};

export const get_user = () => {
  const data_user = JSON.parse(localStorage.getItem("user_storage")).user_data;
  return data_user["current_user"];
};

export const redirectLogin = () => {
  window.location.assign(
    `${SSO_ENDPOINT}/ssos/new?client_key=54e623ef2dc6bf0a281445fc8e3be054531b2fee&response_type=code&callback_url=${CALLBACK_URL}`
  );
};

export const validURL = (str) => {
  let pattern = new RegExp(
    "^(https?:\\/\\/)?" + // protocol
      "((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|" + // domain name
      "((\\d{1,3}\\.){3}\\d{1,3}))" + // OR ip (v4) address
      "(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*" + // port and path
      "(\\?[;&a-z\\d%_.~+=-]*)?" + // query string
      "(\\#[-a-z\\d_]*)?$",
    "i"
  ); // fragment locator
  return !!pattern.test(str);
};

export const imageUrl = (image) => {
  let check = validURL(image);
  let link = "";
  if (image === null || image === undefined) {
    return "/images/avatar.png";
  } else if (check) {
    link = image;
    return link;
  } else if (!check) {
    link = `${DOMAIN}/${image}`;
    return link;
  }
};

const extractContent = (s) => {
  var span = document.createElement("span");
  span.innerHTML = s;
  return span.textContent || span.innerText;
};

export const convertString = (str) => {
  if (str === null) {
    return;
  }
  const reg = /\<\s*a[^>]*>(.+)\<\/a\>/g;
  let content = str.replace(reg, (newLink) => {
    return newLink.replaceAll(`href="`, `href="/diagnostics?name=`);
  });

  let reg_hashtag = /([#|＃][^\s]+)/gi;
  let replacement_hashtag = "<span className='tag'>$1</span>";
  let parsed = content.replace(reg_hashtag, replacement_hashtag);
  let href = content.replace(reg_hashtag, "");
  let reg_url = /(http|https)?:\/\/(\S+)/g;
  let tag = parsed.replace(reg_url, "");
  return parsed.replace(reg_url, "").replace(/\n/g, "<br />");
};

export const fetchMetatags = (links) => {
  var metaTags = [];
  if (links.length > 0) {
    links.map(function (link) {
      getMetaTags(link.href).then((res) => {
        let result = res["data"]["data"];
        let domain = new URL(result.url);
        const index = metaTags.findIndex((object) => object.title === result.title);
        if (index === -1) {
          metaTags.push(result);
        }
      });
    });
  }
  return metaTags;
};

export const checkLoadMore = (data) => {
  let isLoadMore = data.data.length < PER_PAGE;
  return !isLoadMore;
};

export const setParamsTrackActivity = (url, method, object = {}, main_function = null) => {
  let data_track = {
    user_id:
      JSON.parse(localStorage.getItem("user_storage")).user_data.current_user !== null
        ? JSON.parse(localStorage.getItem("user_storage")).user_data.current_user.id
        : null,
    client_key: "54e623ef2dc6bf0a281445fc8e3be054531b2fee",
    payload: {
      method: `${method}`,
      path: `${url}`,
      params: {
        object,
      },
    },
    main_function: main_function
  };
  return data_track;
};

export const checkRole = (role) => {
  let checkRole = ROLE.includes(role);
  if (checkRole) {
    return role;
  } else {
    return checkRole;
  }
};

export const translateDay = (day) => {
  switch (day) {
    case "sunday":
      return "Chủ Nhật";
    case "saturday":
      return "Thứ Bảy";
    case "monday":
      return "Thứ Hai";
    case "tuesday":
      return "Thứ Ba";
    case "wednesday":
      return "Thứ Tư";
    case "thursday":
      return "Thứ Năm";
    case "friday":
      return "Thứ Sáu";
    default:
      return day;
  }
};

export const handleHref = (noti, manager_type) => {
  const mapping = {
    "Post": {
      like: `/p/${noti.notificationable_id}`,
      warning_post: manager_type !== "member" ? "/nguoi-dung/quan-ly-binh-luan" : "#",
      default: "#"
    },
    "system": {
      article: "/p/tin-tuc",
      post: "/",
      market: "/cho-2nong",
      product: "/cho-2nong",
      weather: "/modules/thoi-tiet",
      npk: "/modules/npk",
      market_price: "/modules/gia-ca-thi-truong",
      default: "#"
    },
    "Shop": `/nguoi-dung/${noti.sender_id}`,
    "Comment": {
      Article: `/tin-tuc/${noti.source_id}`,
      Post: `/p/${noti.source_id}`,
      Product: `/san-pham/${noti.source_id}`,
      Video: `/videos/${noti.source_id}`,
      default: "#"
    },
    "MarketPlace": `/modules/thong-tin-gia-ca-thi-truong/${noti.notificationable_id}`,
    "NoticeSchedule": `/nguoi-dung/thiet-lap-thong-bao/chinh-sua/${noti.notificationable_id}`,
    "InvoiceUser": {
      null: `/nguoi-dung/thong-tin-don-hang-ban/${noti.notificationable_id}`,
      default: "#"
    },
    "BusinessAssociation": `/lien-ket-danh-nghiep/thong-tin-don-hang/${noti.notificationable_id}/business/${noti.connectable_id}`
  };
  const type = noti.notificationable_type || noti.notification_type;
  const subtype = noti.notification_type || noti?.connectable_type;
  const result = mapping[type] && (mapping[type][subtype] || mapping[type]["default"]);
  return result || mapping.default || "#";
};

export const getBrowserInfo = () => {
  const userAgent = navigator.userAgent;
  switch (true) {
    case userAgent.indexOf("Firefox") > -1:
      return "Firefox";
    case userAgent.indexOf("Chrome") > -1:
      return "Chrome";
    case userAgent.indexOf("Safari") > -1:
      return "Safari";
    case userAgent.indexOf("Opera") > -1:
      return "Opera";
    case userAgent.indexOf("Edge") > -1:
      return "Microsoft Edge";
    case userAgent.indexOf("MSIE") > -1 || userAgent.indexOf("Trident/") > -1:
      return "Internet Explorer";
    default:
      return "Unknown browser";
  }
};
export const validateStringEditor = (content) => {
  let replaceRG = /(<div>(&nbsp;|<br>)<\/div>|&nbsp;|<br>|<div>|<\/div>)+/g;
  let emptyRG = /^\s*$/;
  let newContent = content.replace(replaceRG, "");
  if (content.length > 1000) {
    return "too long";
  }
  if (emptyRG.test(newContent)) {
    return false;
  } else {
    return true;
  }
};
export const checkPriceDiffer = (number) => {
  if (number === 0) {
    return number;
  }
  if (number > 0) {
    return "+" + number;
  } else {
    return number;
  }
};
export function removeTagsAndNbsp(htmlString) {
  const cleanString = htmlString.replace(/<[^>]+>/g, ""); // remove HTML tags
  const finalString = cleanString.replace(/&nbsp;/g, " "); // replace &nbsp; with whitespace
  return finalString;
}

export const loadImageFile = async (url) => {
  let response = await axios.get(url);
  if (response.status === 200) {
      const datas = []
      response.data.data.map((result, index) => (
        datas.push(new File([b64toBlob(result, {type: "image/jpeg"})], url.substring(url.lastIndexOf("/") + 1), {
          type: "image/jpeg",
        }))
      ))
      return datas
    }
    return;
};

const b64toBlob = (b64Data, contentType = "", sliceSize = 1042) => {
  const byteCharacters = atob(b64Data);
  const byteArrays = [];
  for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
    const slice = byteCharacters.slice(offset, offset + sliceSize);
    const byteNumbers = new Array(slice.length);
    for (let i = 0; i < slice.length; i++) {
      byteNumbers[i] = slice.charCodeAt(i);
    }
    const byteArray = new Uint8Array(byteNumbers);
    byteArrays.push(byteArray);
  }
  return new Blob(byteArrays, { type: contentType });
};
