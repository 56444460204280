import { call, put } from 'redux-saga/effects'
import {
  fetchNotificationsSuccess,
  fetchNotificationsFailed,
  deleteNotificationSuccess,
  deleteNotificationFailed,
  countNotifications,
  countNotificationsSuccess,
  fetchLoadMoreNotificationsSuccess,
  fetchLoadMoreNotificationsFailed,
  viewNotificationSuccess,
  viewNotificationFailed
} from 'actions/notification';

import {
  getNotifications,
  deleteNotification,
  getCountNotifications,
  viewNotification
} from 'apis/notification'
import { STATUS_CODE } from 'constants/index'
import { toastError } from 'helpers/toastHelper'

export function* fetchNotifications({ payload }) {
  try {
    const { params } = payload;
    const resp = yield call(getNotifications, params);
    const { data, status } = resp;
    if (status === STATUS_CODE.SUCCESS) {
      yield put(fetchNotificationsSuccess(data));
      yield put(countNotifications());
    } else {
      yield put(fetchNotificationsFailed(data));
      toastError("Phiên làm việc đã hết hạn / thay đổi, vui lòng đăng nhập lại");
      localStorage.removeItem("user_storage");
      setTimeout(() => {
        window.location.assign("/");
      }, 2000);
    }

  } catch (error) {
  }
}

export function* userDeleteNotification({ payload }) {
  try {
    const { id } = payload;
    const resp = yield call(deleteNotification, id);
    const { data, status } = resp;
    if (status === STATUS_CODE.SUCCESS) {
      yield put(deleteNotificationSuccess({id: id}));
      yield put(countNotifications());
    } else {
      yield put(deleteNotificationFailed(data));
    }
  } catch (error) {
  }
}

export function* fetchCountNotifications({ payload }) {
  try {
    const { params } = payload;
    const resp = yield call(getCountNotifications);
    const { data, status } = resp;
    if (status === STATUS_CODE.SUCCESS) {
      yield put(countNotificationsSuccess(data));
    }
  } catch (error) {
  }
}


export function* userViewNotification({ payload }) {
  try {
    const { id } = payload.id;
    const resp = yield call(viewNotification, id);
    const { data, status } = resp;
    if (status === STATUS_CODE.SUCCESS) {
      yield put(countNotifications());
      yield put(viewNotificationSuccess(id))
    } else {
      yield put(viewNotificationFailed(data));
    }
  } catch (error) {
    toastError(error);
  }
}

export function* fetchLoadMoreNotifications({ payload }) {
  try {
    const { params } = payload;
    const resp = yield call(getNotifications, params);
    const { data, status } = resp;
    if (status === STATUS_CODE.SUCCESS) {
      yield put(fetchLoadMoreNotificationsSuccess(data));
    } else {
      yield put(fetchLoadMoreNotificationsFailed(data));
    }
  } catch (error) {
  }
}
