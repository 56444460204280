import React from "react";
import _ from "lodash";

function ListTechnicalProcess({ data, activeItem, changeItem }) {
  return (
    <>
      <div className="row">
        {_.sortBy(data, function (t) {
          return t.order;
        })?.map(function (data) {
          const active = activeItem === data.id;
          return (
            <div className="col-md-6 col-sm-6 col-xs-12" key={data.id}>
              <div
                className="news-item d-flex align-items-center pointer hove sub-news-item hover"
                onClick={() => changeItem(data.id)}>
                <div className="pt-show">
                  <div
                    className="bg-img"
                    style={{
                      backgroundImage: `url(${data.image ?? "/images/product-image-default.png"})`,
                      width: "45px",
                      height: "45px",
                      marginLeft: "10px",
                    }}
                  />
                </div>
                <div>
                  <h6
                    className={`ctnt line-clamp-02 mb-1 fw-semibold ${active && "green-clr"} `}
                    style={{ fontSize: "13px" }}>
                    {data.title}
                  </h6>
                </div>
              </div>
            </div>
          );
        })}
      </div>
    </>
  );
}

export default ListTechnicalProcess;
