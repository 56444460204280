import React from "react";
import Tab from "react-bootstrap/Tab";
import Tabs from "react-bootstrap/Tabs";
import ListTechnicalProcess from "./listTechnicalProcess";
import RenderTechnicalProcess from "./renderTechnicalProceess";

export default function TabSteps(props) {
  const { data, detail, contentDetail, activeKey, changeTab, activeItemId, changeItem, current_user } = props;

  return (
    <Tabs
      as="ul"
      id="myTab"
      activeKey={activeKey}
      onSelect={(key) => {
        changeTab(key);
      }}
      className="nav nav-tabs">
      {data.length > 0 &&
        _.sortBy(data, function (t) {
          return t.order;
        }).map(function (step, index) {
          return (
            <Tab eventKey={`${step?.id}`} title={`${step?.name}`} as="li" key={index}>
              <div className="tab-pane fade show active" id="step01" role="tabpanel" aria-labelledby="step01-tab">
                <div className="row">
                  {detail?.length > 0 ? (
                    <ListTechnicalProcess data={detail} activeItem={activeItemId} changeItem={changeItem} />
                  ) : (
                    <div className="tab-content border-0 shadow-none" id="myTabContent">
                      <div className="tab-pane fade show active" id="step01" role="tabpanel" aria-labelledby="step01-tab">
                        <p className="mb-0 font-italic text-secondary">
                          Tạm thời chưa có bài viết, hãy đóng góp ngay nhé!
                        </p>
                      </div>
                    </div>
                  )}
                  <RenderTechnicalProcess stepId={activeKey} data={contentDetail} current_user={current_user} />
                </div>
              </div>
            </Tab>
          );
        })}
    </Tabs>
  );
}
