import * as articleConst from "constants/article";

export const fetchArticle = (params) => {
  return {
    type: articleConst.FETCH_ARTICLE,
    payload: {
      params,
    },
  };
};

export const fetchArticleSuccess = (data) => {
  return {
    type: articleConst.FETCH_ARTICLE_SUCCESS,
    payload: {
      data,
    },
  };
};

export const fetchArticleFailed = (error) => {
  return {
    type: articleConst.FETCH_ARTICLE_FAILED,
    payload: {
      error,
    },
  };
};

export const fetchSidebarArticle = (params) => {
  return {
    type: articleConst.FETCH_SIDEBAR_ARTICLE,
    payload: {
      params,
    },
  };
};

export const fetchSidebarArticleSuccess = (data) => {
  return {
    type: articleConst.FETCH_SIDEBAR_ARTICLE_SUCCESS,
    payload: {
      data,
    },
  };
};

export const fetchSidebarArticleFailed = (error) => {
  return {
    type: articleConst.FETCH_SIDEBAR_ARTICLE_FAILED,
    payload: {
      error,
    },
  };
};

export const fetchArticleDetailID = (id) => {
  return {
    type: articleConst.FETCH_ARTICLE_DETAIL_ID,
    payload: {
      id,
    },
  };
};

export const fetchArticleIDSuccess = (data) => {
  return {
    type: articleConst.FETCH_ARTICLE_DETAIL_ID_SUCCESS,
    payload: {
      data,
    },
  };
};

export const fetchArticleIDFailed = (error) => {
  return {
    type: articleConst.FETCH_ARTICLE_DETAIL_ID_FAILED,
    payload: {
      error,
    },
  };
};

export const fetchRelationArticle = (params) => {
  return {
    type: articleConst.FETCH_RELATION_ARTICLE,
    payload: {
      params,
    },
  };
};

export const fetchRelationSuccess = (data) => {
  return {
    type: articleConst.FETCH_RELATION_ARTICLE_SUCCESS,
    payload: {
      data,
    },
  };
};

export const fetchRelationFailed = (error) => {
  return {
    type: articleConst.FETCH_RELATION_ARTICLE_FAILED,
    payload: {
      error,
    },
  };
};

export const fetchCommentArticle = (params) => {
  return {
    type: articleConst.FETCH_ARTICLE_COMMENT,
    payload: {
      params,
    },
  };
};

export const fetchCommentArticleSuccess = (data) => {
  return {
    type: articleConst.FETCH_ARTICLE_COMMENT_SUCCESS,
    payload: {
      data,
    },
  };
};

export const fetchCommentArticleFailed = (error) => {
  return {
    type: articleConst.FETCH_ARTICLE_COMMENT_FAILED,
    payload: {
      error,
    },
  };
};

export const createLikeCommentArticle = (params) => {
  return {
    type: articleConst.CREATE_LIKE_COMMENT_ARTICLE,
    payload: {
      params,
    },
  };
};
export const createLikeCommentArticleSuccess = (data) => {
  return {
    type: articleConst.CREATE_LIKE_COMMENT_ARTICLE_SUCCESS,
    payload: {
      data,
    },
  };
};

export const createLikeCommentArticleFailed = (error) => {
  return {
    type: articleConst.CREATE_LIKE_COMMENT_ARTICLE_FAILED,
    payload: {
      error,
    },
  };
};

export const createUnLikeCommentArticle = (params) => {
  return {
    type: articleConst.CREATE_UNLIKE_COMMENT_ARTICLE,
    payload: {
      params,
    },
  };
};
export const createUnLikeCommentArticleSuccess = (data) => {
  return {
    type: articleConst.CREATE_UNLIKE_COMMENT_ARTICLE_SUCCESS,
    payload: {
      data,
    },
  };
};

export const createUnLikeCommentArticleFailed = (error) => {
  return {
    type: articleConst.CREATE_UNLIKE_COMMENT_ARTICLE_FAILED,
    payload: {
      error,
    },
  };
};

export const fetchAnswerCommentArticle = (params) => {
  return {
    type: articleConst.FETCH_COMMENT_ANSWER_ARTICLE,
    payload: {
      params,
    },
  };
};

export const fetchAnswerCommentArticleSuccess = (data) => {
  return {
    type: articleConst.FETCH_COMMENT_ANSWER_ARTICLE_SUCCESS,
    payload: {
      data,
    },
  };
};

export const createAnswerCommentArticle = (params) => {
  return {
    type: articleConst.CREATE_ANSWER_COMMENT_ARTICLE,
    payload: {
      params,
    },
  };
};

export const createAnswerCommentArticleSuccess = (data) => {
  return {
    type: articleConst.CREATE_ANSWER_COMMENT_ARTICLE_SUCCESS,
    payload: {
      data,
    },
  };
};

export const createAnswerCommentArticleFailed = (error) => {
  return {
    type: articleConst.CREATE_ANSWER_COMMENT_ARTICLE_FAILED,
    payload: {
      error,
    },
  };
};

export const fetchAnswerCommentArticleFailed = (error) => {
  return {
    type: articleConst.FETCH_COMMENT_ANSWER_ARTICLE_FAILED,
    payload: {
      error,
    },
  };
};

export const createCommentArticle = (params) => {
  return {
    type: articleConst.CREATE_ARTICLE_COMMENT,
    payload: {
      params,
    },
  };
};

export const createCommentArticleSuccess = (data) => {
  return {
    type: articleConst.CREATE_ARTICLE_COMMENT_SUCCESS,
    payload: {
      data,
    },
  };
};

export const createCommentArticleFailed = (error) => {
  return {
    type: articleConst.CREATE_ARTICLE_COMMENT_FAILED,
    payload: {
      error,
    },
  };
};

export const fetchFeatureArticle = (params) => {
  return {
    type: articleConst.FETCH_FEATURE_ARTICLE,
    payload: {
      params,
    },
  };
};

export const fetchFeatureArticleSuccess = (data) => {
  return {
    type: articleConst.FETCH_FEATURE_ARTICLE_SUCCESS,
    payload: {
      data,
    },
  };
};

export const fetchFeatureArticleFailed = (error) => {
  return {
    type: articleConst.FETCH_FEATURE_ARTICLE_FAILED,
    payload: {
      error,
    },
  };
};

export const fetchFavoriteArticle = (params) => {
  return {
    type: articleConst.FETCH_FAVORITES_ARTICLE,
    payload: {
      params,
    },
  };
};

export const fetchFavoriteArticleSuccess = (data) => {
  return {
    type: articleConst.FETCH_FAVORITES_ARTICLE_SUCCESS,
    payload: {
      data,
    },
  };
};
export const fetchFavoriteArticleFailed = (error) => {
  return {
    type: articleConst.FETCH_FAVORITES_ARTICLE_FAILED,
    payload: {
      error,
    },
  };
};
export const createFavoriteArticle = (params) => {
  return {
    type: articleConst.CREATE_FAVORITE_ARTICLE,
    payload: {
      params,
    },
  };
};
export const createFavoriteArticleSuccess = (data) => {
  return {
    type: articleConst.CREATE_FAVORITE_ARTICLE_SUCCESS,
    payload: {
      data,
    },
  };
};

export const createFavoriteArticleFailed = (error) => {
  return {
    type: articleConst.CREATE_FAVORITE_ARTICLE_FAILED,
    payload: {
      error,
    },
  };
};

export const fetchObjectHashTag = (params) => {
  return {
    type: articleConst.FETCH_OBJECT_HASHTAG,
    payload: {
      params,
    },
  };
};

export const fetchObjectHashTagSuccess = (data) => {
  return {
    type: articleConst.FETCH_OBJECT_HASHTAG_SUCCESS,
    payload: {
      data,
    },
  };
};

export const fetchObjectHashTagFailed = (error) => {
  return {
    type: articleConst.FETCH_OBJECT_HASHTAG_FAILED,
    payload: {
      error,
    },
  };
};

export const deleteFavoriteArticle = (params) => {
  return {
    type: articleConst.DELETE_FAVORITE_ARTICLE,
    payload: {
      params,
    },
  };
};

export const deleteFavoriteArticleSuccess = (data) => {
  return {
    type: articleConst.DELETE_FAVORITE_ARTICLE_SUCCESS,
    payload: {
      data,
    },
  };
};

export const deleteFavoriteArticleFailed = (error) => {
  return {
    type: articleConst.DELETE_FAVORITE_ARTICLE_FAILED,
    payload: {
      error,
    },
  };
};

export const loadMoreCommentsArticle = (params) => {
  return {
    type: articleConst.LOAD_MORE_COMMENT_ARTICLE,
    payload: {
      params,
    },
  };
};

export const loadMoreCommentsArticlesSuccess = (data) => {
  return {
    type: articleConst.LOAD_MORE_COMMENT_ARTICLE_SUCCESS,
    payload: {
      data,
    },
  };
};

export const loadMoreCommentsArticlesFailed = (error) => {
  return {
    type: articleConst.LOAD_MORE_COMMENT_ARTICLE_FAILED,
    error,
  };
};

export const reportCommentArticle = (params) => {
  return {
    type: articleConst.REPORT_COMMENT_ARTICLE,
    payload: {
      params,
    },
  };
};
export const reportCommentArticleFailed = (error) => {
  return {
    type: articleConst.REPORT_COMMENT_ARTICLE_FAILED,
    payload: {
      error,
    },
  };
};

export const userReplyComment = (params) => {
  return {
    type: articleConst.REPLY_COMMENT,
    payload: {
      params,
    },
  };
};

export const userReplyCommentSuccess = (data) => {
  return {
    type: articleConst.REPLY_COMMENT_SUCCESS,
    payload: {
      data,
    },
  };
};

export const userReplyCommentFailed = (error) => {
  return {
    type: articleConst.REPLY_COMMENT_FAILED,
    payload: {
      error,
    },
  };
};
export const updateComment = (params) => {
  return {
    type: articleConst.UPDATE_COMMENT,
    payload: {
      params,
    },
  };
};

export const updateCommentSuccess = (data) => {
  return {
    type: articleConst.UPDATE_COMMENT_SUCCESS,
    payload: {
      data,
    },
  };
};

export const updateCommentFailed = (error) => {
  return {
    type: articleConst.UPDATE_COMMENT_FAILED,
    payload: {
      error,
    },
  };
};
export const managerUpdateComment = (params) => {
  return {
    type: articleConst.MANAGER_UPDATE_COMMENT,
    payload: {
      params,
    },
  };
};

export const managerUpdateCommentSuccess = (data) => {
  return {
    type: articleConst.MANAGER_UPDATE_COMMENT_SUCCESS,
    payload: {
      data,
    },
  };
};

export const updateChildComment = (params) => {
  return {
    type: articleConst.UPDATE_CHILD_COMMENT,
    payload: {
      params,
    },
  };
};

export const updateChildCommentSuccess = (data) => {
  return {
    type: articleConst.UPDATE_CHILD_COMMENT_SUCCESS,
    payload: {
      data,
    },
  };
};

export const updateChildCommentFailed = (error) => {
  return {
    type: articleConst.UPDATE_CHILD_COMMENT_FAILED,
    payload: {
      error,
    },
  };
};
export const managerUpdateChildComment = (params) => {
  return {
    type: articleConst.MANAGER_UPDATE_CHILD_COMMENT,
    payload: {
      params,
    },
  };
};

export const managerUpdateChildCommentSuccess = (data) => {
  return {
    type: articleConst.MANAGER_UPDATE_CHILD_COMMENT_SUCCESS,
    payload: {
      data,
    },
  };
};

export const managerDestroyChildComment = (id) => {
  return {
    type: articleConst.MANAGER_DESTROY_CHILD_COMMENT,
    payload: {
      id,
    },
  };
};
export const managerDestroyChildCommentSuccess = (data) => {
  return {
    type: articleConst.MANAGER_DESTROY_CHILD_COMMENT_SUCCESS,
    payload: {
      data,
    },
  };
};
export const managerDestroyComment = (id) => {
  return {
    type: articleConst.MANAGER_DESTROY_COMMENT,
    payload: {
      id,
    },
  };
};
export const managerDestroyCommentSuccess = (id) => {
  return {
    type: articleConst.MANAGER_DESTROY_COMMENT_SUCCESS,
    payload: {
      id,
    },
  };
};
export const userDestroyComment = (id) => {
  return {
    type: articleConst.USER_DESTROY_COMMENT,
    payload: {
      id,
    },
  };
};
export const userDestroyCommentSuccess = (id) => {
  return {
    type: articleConst.USER_DESTROY_COMMENT_SUCCESS,
    payload: {
      id,
    },
  };
};
export const userDestroyChildComment = (id) => {
  return {
    type: articleConst.USER_DESTROY_CHILD_COMMENT,
    payload: {
      id,
    },
  };
};
export const userDestroyChildCommentSuccess = (id) => {
  return {
    type: articleConst.USER_DESTROY_CHILD_COMMENT_SUCCESS,
    payload: {
      id,
    },
  };
};
export const fetchLoadMoreComment = (params) => {
  return {
    type: articleConst.FETCH_LOAD_MORE_COMMENT_ARTICLE,
    payload: {
      params,
    },
  };
};

export const fetchLoadMoreCommentSuccess = (data) => {
  return {
    type: articleConst.FETCH_LOAD_MORE_COMMENT_ARTICLE_SUCCESS,
    payload: {
      data,
    },
  };
};
