import React from "react";

export default function ListOfStep(props) {
  const { data, changeCatalogue, activeId } = props;
  return (
    <div className="pro-tech-lst">
      <div className="arg-type-main row">
        <div className="col-md-3 col-sm-12 text-center">
          <div className="wrap-img">
            <div
              className="bg-img-orgnal"
              style={{
                backgroundImage: `url(${data?.data?.image ?? "/images/product-image-default.png"})`,
              }}
            ></div>
          </div>
          <div className="fs-5 text-center">{data?.data?.name}</div>
        </div>
        <div className="col-md-9 col-sm-12">
          <div className="arg-type-step row">
            {data.children?.length > 0 &&
              _.sortBy(data.children, function (t) {
                return t.order;
              }).map(function (step) {
                const active = activeId === step.id;
                return (
                  <a
                    role="button"
                    className={`step-by-step col-md-6 col-sm-6`}
                    key={step.id}
                    onClick={() => changeCatalogue(step.id)}
                  >
                    <div className="d-flex align-items-center">
                      <div className="step-icon">
                        <div className={`numb fw-bold ${active && "active-tab"}`}>{step.order}</div>
                      </div>
                      <div className={`step-txt ${active && "green-clr"} `}>{step.name}</div>
                    </div>
                  </a>
                );
              })}
          </div>
        </div>
      </div>
    </div>
  );
}
