import React, { useRef } from "react";
import ReactTimeAgo from "react-time-ago";
import { handleHref } from "lib";
import { useContext } from "react";
import AppContext from "components/app_context";
import { Link } from "react-router-dom";
function Notifications(props) {
  const { data, removeNoti, viewNoti, loadMoreNoti, isLoading} = props;
  const { current_user } = useContext(AppContext);
  const endRef = useRef(null);

  const loadMoreNotification = () => {
    if (endRef.current) {
      const { scrollTop, scrollHeight, clientHeight } = endRef.current;
      if (scrollTop + clientHeight === scrollHeight) {
        loadMoreNoti();
      }
    }
  };

  return (
    <div
      className={`${
        data !== undefined && data.length > 0
          ? "notification scrollbar dropdown-menu dropdown-menu-end"
          : "d-none"
      }`}
      aria-labelledby="dropdownNotify"
      ref={endRef}
      onScroll={() => loadMoreNotification()}
    >
      <ul>
        {data !== undefined &&
          data.map(function (noti, index) {
            let type_name =
              noti.notification_type === "system"
                ? "Hệ thống"
                : noti.notification_type === "market_place"
                ? "Giá cả thị trường"
                : noti.notification_type === "weather"
                ? "Thời tiết"
                : "Người theo dõi";
            return (
              <li
                key={index}
                className="align-items-center"
                onClick={() => viewNoti(noti.id)}
              >
                <div style={{maxWidth: '205px'}}>
                  <Link to={handleHref(noti, current_user?.manager_type)}>
                    {" "}
                    <span>{noti.title}</span>
                    <br />
                    <small>
                      <ReactTimeAgo date={Date.parse(noti.created_at)} />
                    </small>
                  </Link>
                </div>
                <span className={`${noti.status} new-circle`} />
                <span
                  className="icon-close"
                  title="Delete"
                  onClick={() => removeNoti(noti.id)}
                />
              </li>
            );
          })}
      </ul>
        {/* =============đợi lấy yêu cầu==========================
        {isLoadMore && <span className="txt fw-500  w-100 more-notification pointer" onClick={() => loadMoreNoti()}> <u>Xem thêm</u></span>}
        =============đợi lấy yêu cầu========================== */}
    </div>
  );
}

export default Notifications;
